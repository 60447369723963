import React, { useEffect, useState } from "react"
import { useHistory } from "react-router"
import styled, { css } from "styled-components"
import branding from "./branding/branding"
import { useLanguageState } from "./globalStates/LanguageState"
import { systemCheckPageRoute } from "./navigationArea/RoutePaths"
import LanguageToggler from "./ui/LanguageToggle"

const PreLoginAppSiteRoot = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
    overflow: hidden;
    height: 100vh;
    font-family: ${branding.font1};
`

const PreLoginAppStyleContainer = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fff;
    width: 100%;
    z-index: 2;
`

const HeaderRoot = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 2rem;
    position: relative;
    z-index: 10;

    & img {
        max-width: ${branding.loginRegistrationSite.centerLoginLogoMaxWidth ?? "150px"};
        max-height: ${branding.loginRegistrationSite.centerLoginLogoMaxHeight ?? "50px"};
        object-fit: contain;
        user-select: none;
    }
`

const InfoRoot = styled.div`
    position: relative;
    height: 100vh;
`

const InfoContainer = styled.div` 
    margin: 0;
    position: absolute;
    bottom: 30%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
    width: 90%;

    @media (min-width: 700px) {
        width: 600px;
    }

    @media (max-height: 900px) {
        bottom: 0;
        top: 50%;
    }
`

const Title = styled.div`
    font-size: 24px;
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 10px;
    color: ${branding.loginRegistrationSite.mobileLoginTextColor};
`

const Subtitle = styled.div`
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    color: ${branding.loginRegistrationSite.mobileLoginTextColor};
`

const Text = styled.div`
    margin-top: 10px;
    margin-bottom: 10px;
    color: ${branding.loginRegistrationSite.mobileLoginTextColor};
`

const ButtonsContainer = styled.div`
    margin-top: 40px;
    width: 300px;
    margin-left: auto;
    margin-right: auto;
`

const MainButton = styled.button<{ backgroundColor?: string, textColor?: string }>`
    flex: 0 0 auto;
    margin-top: 10px;
    height: 35px;
    line-height: 30px;
    margin-bottom: 10px;
    border: 1px solid ${branding.loginRegistrationSite.mobileLoginButtonColor};
    border-radius: 5px;
    background-color: ${(props) => props.backgroundColor ?? branding.loginRegistrationSite.mobileLoginButtonColor};;
    color: ${(props) => props.textColor ?? "#fff"};
    transition: 0.5s;
    cursor: pointer;
    width: 290px;
    outline: none;

    &:focus { outline: none; }

    ${props => props.disabled
        ? css`
            opacity: 0.5;
            transition-property: none;
        `
        : css`
            &:hover { opacity: 0.7; }
        `
    };
`

const FooterRoot = styled.div`
    bottom: 0;
    position: fixed;
    width: 100%;
    display: flex;
    justify-content: center;
    background: #fff !important;
`

const FooterContainer = styled.div`
    text-decoration: underline;
    color: ${branding.loginRegistrationSite.mobileLoginTextColor};
    font-family: ${branding.font1};
    font-size: 14px;
    text-align: center;
    margin-bottom: 14px;
    cursor: pointer;
`

const MobilePreLoginApp: React.FunctionComponent = () => {
    const strings = useLanguageState().getStrings()
    const ticketSalePhasePageTitle = strings.ticketSalePhasePageTitle || "";

    const languageState = useLanguageState();
    const lang = languageState.getLanguage()

    // eslint-disable-next-line
    const [localization, setLocalization] = useState(strings.ticketSale);
  
    useEffect(() => {
        setLocalization(strings.ticketSale)
        // eslint-disable-next-line 
        }, [lang])

    const history = useHistory()

    const onBuyTicketButtonClick = () => {
        window.open(`${strings.ticketSale.ticketsUrl}`, "_blank")
    }

    const onCheckYourSystemButtonClick = () => {
        history.push(systemCheckPageRoute)
    }

    const onImprintButtonClick = () => {
        window.open(`${strings.ticketSale.imprintLinkUrl}`, "_blank")
    };


    
    return <PreLoginAppSiteRoot>
        <PreLoginAppStyleContainer>
            <HeaderRoot>
                <img src="./branding/logo.png" height="17.25px" alt={strings.configuration.ogTitle} />
                <div style={{ height: "17.25px" }}>
                    <LanguageToggler margin="0" fontSize="16px" color={branding.loginRegistrationSite.mobileLoginTextColor} fullToggler={true} />
                </div>
            </HeaderRoot>
            <InfoRoot>
                <InfoContainer>
                    <Title>
                        {ticketSalePhasePageTitle}
                    </Title>
                    <Subtitle>
                        {strings.ticketSale.leadDescriptionText}
                    </Subtitle>
                    <Text>
                        {strings.ticketSale.checkYourSystemDescriptionText}
                    </Text>
                    <ButtonsContainer>
                        <MainButton backgroundColor="#fff" textColor={branding.loginRegistrationSite.mobileLoginButtonColor}>
                            <p onClick={onCheckYourSystemButtonClick}>
                                {strings.ticketSale.checkYourSystemButton}
                            </p>
                        </MainButton>
                        {
                            branding.ticketSale.getYourTicketVisible &&
                            <MainButton>
                                <p onClick={onBuyTicketButtonClick}>
                                    {strings.ticketSale.getYourTicketNowButton}
                                </p>
                            </MainButton>
                        }
                    </ButtonsContainer>
                </InfoContainer>
            </InfoRoot>
            <FooterRoot>
                <FooterContainer onClick={onImprintButtonClick}>
                    {strings.ticketSale.imprintLink}
                </FooterContainer>
            </FooterRoot>
        </PreLoginAppStyleContainer>
    </PreLoginAppSiteRoot>
}

export default MobilePreLoginApp