import React, { useState, useEffect } from "react"
import { useLanguageState } from "../globalStates/LanguageState"
import { ModalRoot, SubmitButtonContainer, SubmitButtonNew, DisagreeButton } from "./SayHelloModal"
import branding from "../branding/branding"
import { EventDate } from "../backendServices/Types"
import { loadPersonData, syncFavorites } from "../backendServices/BackendServices"
import { useFavoriteState } from "../globalStates/Favorites"
import moment from "moment"
import { useLoggedInState } from "../globalStates/LoggedInUser"
import CenteredLoader from "./CenteredLoader"

interface BookmarkSpeakersEventsModalProps {
    speakerId: string
    onClose: () => void
}



const BookmarkSpeakersEventsModal: React.FunctionComponent<BookmarkSpeakersEventsModalProps> = (props: BookmarkSpeakersEventsModalProps) => {

    const strings = useLanguageState().getStrings()

    const favoriteState = useFavoriteState()

    const userState = useLoggedInState()

    const profileId = userState.user()?.profileId

    const [isLoaded, setIsLoaded] = useState<boolean>(true)

    const [speakerName, setSpeakerName] = useState<string>("")
    const [eventData, setEventData] = useState<EventDate[]>([])

    function handleClose() {
        props.onClose()
    }

    useEffect(() => {
        if (!branding.personDetailPageContent.bookmarkEventsPopupVisible) {
            return
        }

        loadPersonData({ id: props.speakerId }).then((res) => {
            if (res.content) {

                setSpeakerName([res.content.title, res.content.firstName, res.content.lastName].filter(Boolean).join(" "))
                setEventData(res.content.eventDates)
            }
        })
    }, [props.speakerId])

    function syncFav(eventId: string) {
        if (profileId) {
            syncFavorites({
                profileId: profileId,
                body: {
                    currentTime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"), //"2020-01-01 12:00:00", 
                    lastSyncTime: favoriteState.getLastSyncTime(),
                    changedFavorites: [{
                        id: eventId,
                        kind: "EVENTDATE",
                        deleted: favoriteState.is("eventdate", eventId) ? false : true,
                        lastModified: moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
                    }]
                }
            }).then((resp) => {
                favoriteState.setLastSyncTime(new Date())
            }).catch((e: { message: React.SetStateAction<string> }) => {
                // syncFavorites failed, logged in BackendServices
            })
        }
    }

    const handleBookmarking = async () => {
        setIsLoaded(false)

        eventData.forEach((event: EventDate) => {
            if (!(favoriteState.is("eventdate", event.id))) {
                favoriteState.toggle("eventdate", event.id)
                syncFav(event.id)
            }
        })

        setIsLoaded(true)

        props.onClose()
    }

    const replacePlaceholderText = (template: string) => {
        //var template = strings.globalTexts.guestRestrictionMessage

        var contentString = template.split("{$speakerName}").join(speakerName)

        return contentString
    }

    if (!branding.personDetailPageContent.bookmarkEventsPopupVisible) {
        return null
    }

    return <ModalRoot backdrop="static" show={true} animation={false}>
        <div onClick={e => e.stopPropagation()}>
            <div className="modal-header">
                <h3 className="modal-title">
                    {replacePlaceholderText(strings.personDetailPageContent.bookmarkEventsPopupText)}
                </h3>
                <button type="button" className="close" onClick={() => handleClose()} aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div className="modal-footer" style={{ marginTop: "20px" }}>
                <SubmitButtonContainer>
                    <DisagreeButton onClick={() => handleClose()} className="d-flex align-items-center">
                        {strings.personDetailPageContent.bookmarkEventsPopupCancelButtonText}
                    </DisagreeButton>
                    <div>
                        {!isLoaded && <CenteredLoader />}
                    </div>
                    <SubmitButtonNew type="submit" onClick={() => handleBookmarking()} className="d-flex align-items-center justify-content-center">
                        {strings.personDetailPageContent.bookmarkEventsPopupConfirmButtonText}
                    </SubmitButtonNew>
                </SubmitButtonContainer>
            </div>
        </div>
    </ModalRoot >
}

export default BookmarkSpeakersEventsModal