import styled, { keyframes } from "styled-components"
import React from "react"


const IconLineTipKeyFrames = keyframes`
    0% {
        width: 0;
        left: 1.25%;
        top: 23.75%;
    }

    54% {
        width: 0;
        left: 1.25%;
        top: 23.75%;
    }

    70% {
        width: 62.5%;
        left: -10%;
        top: 46.25%;
    }

    84% {
        width: 21.25%;
        left: 26.25%;
        top: 60%;
    }

    100% {
        width: 31.25%;
        left: 17.5%;
        top: 56.25%;
    }
`

const IconLineLongKeyFrames = keyframes`
    0% {
        width: 0;
        right: 57.5%;
        top: 67.5%;
    }

    65% {
        width: 0;
        right: 57.5%;
        top: 67.5%;
    }

    84% {
        width: 68.75%;
        right: 0;
        top: 43.75%;
    }

    100% {
        width: 58.75%;
        right: 10%;
        top: 47.5%;
    }
`


const Root = styled.div<SuccessCheckmarkProps>`
    position: relative;
    width: ${(p) => p.size}px ;
    height: ${(p) => p.size}px ;
`

const CheckIcon = styled.div<SuccessCheckmarkProps>`
    width: 100%;
    height: 100%;
    position: relative;
    border-radius: 50%;
    box-sizing: content-box;
    border: ${(p) => p.size / 16.0}px solid #00B300;
    background-color: ${(p) => p.backgroundColor};
`
const IconLine = styled.span<SuccessCheckmarkProps>`
    height: 6.25%;
    background-color: ${(p) => p.color};
    display: block;
    position: absolute;
    z-index: 10;
`

const LineTip = styled(IconLine)`
    top: 57.5%;
    left: 17.5%;
    width: 31.25%;
    border-radius: 40% / 200%;
    transform: rotate(45deg);
    animation: ${IconLineTipKeyFrames} 0.75s;
`

const LineLong = styled(IconLine)`
    top: 47.5%;
    right: 10%;
    width: 58.75%;
    border-radius: 40% / 400%;
    transform: rotate(-45deg);
    animation: ${IconLineLongKeyFrames} 0.75s;
`

const IconFix = styled.div`
    top: 10%;
    width: 6.25%;
    left: 32.5%;
    z-index: 1;
    height: 106.25%;
    position: absolute;
    transform: rotate(-45deg);
`

interface SuccessCheckmarkProps {
    color: string
    backgroundColor: string
    size: number
}

const SuccessCheckmark: React.FC<SuccessCheckmarkProps> = (props) => {
    return <Root {...props}>
        <CheckIcon {...props} >
            <LineTip {...props} />
            <LineLong {...props} />
            <IconFix {...props} />
        </CheckIcon>
    </Root>
}


export default SuccessCheckmark
