import React, { useState, useEffect } from 'react'
import branding from '../../branding/branding';
import { RouteComponentProps, Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import GuestUserBannerSharedState from '../guestUserBanner/GuestUserBannerSharedState';
import GuestUserBanner from '../guestUserBanner/GuestUserBanner';
import TopBar from '../../navigationArea/TopBar';
import Breadcrumb from '../../navigationArea/Breadcrumb';
import { calcBreadcrumbLocations } from '../../tracking/RouteTracker';
import { useLanguageState } from '../../globalStates/LanguageState';
import { mySchedulePageRoute } from '../../navigationArea/RoutePaths';
import { useLoggedInState } from '../../globalStates/LoggedInUser';
import LobbyPageContent from './LobbyPageContent'
import MyPageContent from './MyPageContent'
import PrivacyPolicyModal from '../../ui/PrivacyPolicyModal';
import { hasAccessToOrganization } from '../../utils/Authorization';
import GuestModal from '../../ui/GuestModal';
import SayHelloModal from '../../ui/SayHelloModal';
import RecommendOrganizationModal from '../../ui/RecommendOrganizationModal';
import { Exhibitor, ShareTargetType } from '../../backendServices/Types';
import { trackVisit, CalendarEntry } from '../../backendServices/BackendServices';
import { buildDetailLink } from '../detailPages/DetailNavLink';
import CalendarEntryModal, { CalendarEntryModalViewMode } from '../../ui/CalendarEntryModal';
import CrsTabs from '../../ui/CrsTabs';
import BookmarkSpeakersEventsModal from '../../ui/BookmarkSpeakersEventsModal';
import { ContentScrollContainer } from '../../ui/ScrollContainer';
import TopBannerSharedState from '../advertisingBanner/TopBannerSharedState';
import { checkIfMobile } from '../..';
import useWindowDimensions from '../../ui/WindowDimensionsHook';


export const TileWrapperSpeakers = styled.div<{ $count?: number }>`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(${props => props.$count}, 280px);
  grid-gap: ${branding.receptionPage.speakersTileGridGap ?? "10px"};
  /* grid-gap: 20px; */
  padding-bottom: 1.25rem;
`

export const MobileTileWrapperSpeakers = styled.div<{ $count?: number }>`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(${props => props.$count}, 95px);
  grid-gap: ${branding.receptionPage.speakersTileGridGap ?? "10px"};
  /* grid-gap: 20px; */
  padding-bottom: 1.25rem;
`

/*********************************************************************************************
* FAIR INFO
**********************************************************************************************/

const FairInfoRoot = styled.div`
  display: flex; 
  flex-flow: column;
`

const FairInfoContainer = styled.div<{ containerBackground: string, isLobbyHeader: boolean }>`
  position: relative;
  color: white;
  margin: 0 -15px;
  /* height: 550px; */
  padding-top: 12.5%;
  background: ${props => props.isLobbyHeader ? branding.receptionPage.lobbyHeaderLinearGradient1 : branding.receptionPage.lobbyHeaderLinearGradient2},${props => props.containerBackground};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  -webkit-background-size: cover; 
  -moz-background-size: cover;
  -o-background-size: cover;
  display: flex;
  flex-direction: column;

  .networkingCollapsed {
    border-bottom: 0;
    padding-left: 8px;
    padding-right: 10px;
    a {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .active {
    border-bottom: 0;
    padding-left: 8px;
    padding-right: 10px;
    a {
      padding-left: 0;
      padding-right: 0;
    }
  }
`;

const FairInfoContainerContent = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0; 
    left: 0;
`

const HeaderTitleRoot = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  vertical-align: center;
  justify-content: center;
  flex: 1;
`

const HeaderTitleText = styled.div`
  color: ${branding.secondaryTextColor ?? "#fff"};
  font-size: ${branding.receptionPage.headerTitleInitialFontSize ?? "100px"};
  font-family: ${branding.receptionPage.headerTitleFontFamily};
  text-align: center;
  font-style: normal;
  text-transform: ${branding.receptionPage.headerTitleTextTransform};
  font-weight: ${branding.receptionPage.headerTitleFontWeight};
  letter-spacing: ${branding.receptionPage.headerTitleLetterSpacing};
  z-index: 2;
  margin-top: ${branding.receptionPage.lobbyHeaderTitleMarginTop ?? "40px"};
  @media only screen and (max-width: 2200px){
    font-size: ${5 - (branding.receptionPage.headerTitleFontSizeIndex ?? 0) + "vw"} ;
    margin-top: ${branding.receptionPage.lobbyHeaderTitleMarginTopForSmallerScreen ?? "0px"};
  }
  @media only screen and (max-width: 1800px){
    font-size: ${4.38 - (branding.receptionPage.headerTitleFontSizeIndex ?? 0) + "vw"} ;
    margin-top: ${branding.receptionPage.lobbyHeaderTitleMarginTopForSmallerScreen ?? "0px"};
  }

  @media only screen and (max-width: 1500px){
    font-size: ${3.91 - (branding.receptionPage.headerTitleFontSizeIndex ?? 0) + "vw"} ;
    margin-top: ${branding.receptionPage.lobbyHeaderTitleMarginTopForSmallerScreen ?? "0px"};
  }

  @media only screen and (max-width: 1200px){
    font-size: ${3.6 - (branding.receptionPage.headerTitleFontSizeIndex ?? 0) + "vw"} ;
    margin-top: ${branding.receptionPage.lobbyHeaderTitleMarginTopForSmallerScreen ?? "0px"};
  }

`

const HeaderFooterText = styled.div<{ title: string, description: string }>`
  font-size: ${props => props.title.length > props.description?.length ? 2.5 - branding.receptionPage.headerFooterFontSizeIndex + "vw" : `${branding.receptionPage.receptionPageHeaderFontSize ? branding.receptionPage.receptionPageHeaderFontSize : "22px"}`};
  font-family: ${branding.font1};
  font-style: normal;
  font-weight: ${branding.receptionPage.receptionPageHeaderFontWeight};
  z-index: 2;
  color: ${branding.secondaryTextColor ?? "#fff"};
`

const MyScheduleHeader = styled.div<{ title: string, description: string }>`
  font-size: ${props => props.title.length > props.description?.length ? "2.5vw" : "22px"};
  font-family: ${branding.font1};
  font-style: normal;
  font-weight: 300;
  color: ${branding.secondaryTextColor ?? "#fff"};
  a {
    color: ${branding.secondaryTextColor ?? "#fff"};
    border-bottom: 1px solid ${branding.secondaryTextColor ?? "#fff"};
  }
  z-index: 2;
  margin-top: ${branding.receptionPage.myFairHeaderSubtitleMarginTop ?? "5px"};
`

interface FairInfoProps {
  eventKey: string
  onRouteChange: (route: string) => void
}

const FairInfo: React.FunctionComponent<FairInfoProps> = (props) => {
  const languageState = useLanguageState()
  const strings = languageState.getStrings()
  const scheduleLink = <Link style={{ textDecoration: "none" }} to={mySchedulePageRoute}>{strings.receptionPage.receptionPageMyHeaderDescriptionSecondPart}</Link>
  const title = props.eventKey === "LOBBY" ? strings.receptionPage.receptionPageLobbyHeaderTitle : strings.receptionPage.receptionPageMyHeaderTitle
  const description = props.eventKey === "LOBBY" ? strings.receptionPage.receptionPageLobbyHeaderDescription : branding.receptionPage.receptionPageMyFairHeaderDesc

  const locations = calcBreadcrumbLocations(strings)
  let breadcrumb = [{ to: locations[0], name: strings.sideIconBar.lobbyMenuText }]
  if (props.eventKey === strings.receptionPage.receptionPageMyHeaderTitle) {
    breadcrumb.push(
      { to: "/", name: strings.receptionPage.receptionPageMyHeaderTitle }
    )
  }

  const lobbyHeaderPool: string[] = branding.receptionPage.lobbyHeaderPool

  const lobbyHeader: string = lobbyHeaderPool[(Math.random() * lobbyHeaderPool.length) | 0]

  const isLobbyHeader: boolean = props.eventKey === "LOBBY"

  return (
    <FairInfoRoot>
      <FairInfoContainer
        containerBackground={isLobbyHeader ? ("url(" + lobbyHeader + ")") : "url(/branding/banner.jpg)"}
        isLobbyHeader={isLobbyHeader}>
        <FairInfoContainerContent>
          {<HeaderTitleRoot>
            <HeaderTitleText>{title}</HeaderTitleText>
            {isLobbyHeader ?
              <HeaderFooterText
                title={title}
                description={description}>
                {description}
              </HeaderFooterText> :
              <MyScheduleHeader title={title} description={description}>{strings.receptionPage.receptionPageMyHeaderDescriptionFirstPart} {scheduleLink} {strings.receptionPage.receptionPageMyHeaderDescriptionThirdPart}</MyScheduleHeader>
            }
          </HeaderTitleRoot>
          }
        </FairInfoContainerContent>
      </FairInfoContainer>
    </FairInfoRoot>
  )
}


/*********************************************************************************************
* HEADER ROW
**********************************************************************************************/

const HeaderRow = styled.div`
  display: flex;
  position: relative;
  flex-flow: row;
  justify-content: flex-end;
  height: 60px;
  color: ${branding.mainInfoColor};

`
interface HeaderRowProps {
  eventKey: string
  onEventKeySelect: React.Dispatch<React.SetStateAction<string>>
}

const HeaderRowContent: React.FunctionComponent<HeaderRowProps> = (props) => {
  const languageState = useLanguageState()
  const strings = languageState.getStrings()

  const itemsOrder: any = branding.receptionPage.crsTabsItemOrder;

   enum viewType {
    LOBBY = 'LOBBY', 
    MY_FAIR = 'MY_FAIR'
}
  
  let items: any = []

  itemsOrder.forEach((item: any) => {
    let newItem
    switch(item) {
      case viewType.LOBBY:
       newItem = { label: strings.receptionPage.eventKeyLobby, value: viewType.LOBBY }
       break
       case viewType.MY_FAIR:
        newItem = { label: strings.receptionPage.eventKeyMyPage, value: viewType.MY_FAIR }
        break
    }
      items.push(newItem)
  })


  return (
    <HeaderRow>
      <CrsTabs
        tabItems={items}
        activeItem={items.length === 1 ? items[0].value : props.eventKey}
        onTabItemClicked={props.onEventKeySelect}
      />
    </HeaderRow>
  )
}


/*********************************************************************************************
* RECEPTION PAGE CONTENT
**********************************************************************************************/

const ReceptionPageRoot = styled.div<{ guestBannerHeight: number }>`
    height: 100%;//calc(100% - ${props => (props.guestBannerHeight + 50) + "px"}); 
`

const MainContent = styled.div`
  height: 100%;
  width: 100%;
  background-color: white;
`

const MainContentRoot = styled.div`
  padding-bottom: 44px;
`

const ToggledContent = styled.div<{ displayed: string }>`
  display: ${props => props.displayed};
  flex-flow: column;
`



interface ReceptionPageProps extends RouteComponentProps {
  receptionRoute: string
  trigger: number
  changeRoute: (route: string) => void
}

const ReceptionPageContentRefactored: React.FunctionComponent<ReceptionPageProps> = (props) => {
  let userState = useLoggedInState()
  const { guestUserBannerRef, setGuestUserBannerRef } = GuestUserBannerSharedState()
  const { topBarRef, setTopBarRef } = TopBannerSharedState()
  const [eventKey, setEventKey] = useState(props.receptionRoute ? props.receptionRoute : "LOBBY");
  const [action, setAction] = useState<"MEETING" | "INTEREST" | "RECOMMEND">()
  const [showPrivacyPolicyModal, setShowPrivacyPolicyModal] = useState<boolean>(false)
  const [showRequestMeetingModal, setShowRequestMeetingModal] = useState(false)
  const [showRequestOrganizationModal, setShowRequestOrganizationModal] = useState(false)
  const [showBookmarkEventsModal, setShowBookmarkEventsModal] = useState<boolean>(false)
  const [target, setTarget] = useState<Exhibitor>()
  const [showGuestModal, setShowGuestModal] = useState<boolean>(false)
  const [targetId, setTargetId] = useState<string>("")
  const [type, setType] = useState<ShareTargetType>()
  const [selecetedMeeting, setSelectedMeeting] = useState<CalendarEntry>()
  const [link, setLink] = useState<string>("")
  const [showRecommendOrganizationModal, setShowRecommendOrganizationModal] = useState(false)
  const [viewMode, setViewMode] = useState(CalendarEntryModalViewMode.CREATE);
  const [refreshKey, setRefreshKey] = useState(0);

  const windowSize = useWindowDimensions()

  const isMobile: boolean = checkIfMobile(windowSize)

  const policyCallback = (accepted: boolean) => {
    switch (action) {
      case 'MEETING':
        setShowRequestMeetingModal(true)
        break
      case 'INTEREST':
        setShowRequestOrganizationModal(true)
        break
    }
    setAction(undefined)
    setShowPrivacyPolicyModal(false)
  }

  // useEffect(() => {
  //   if(props.receptionRoute)
  //   setEventKey(props.receptionRoute)
  // },[props.receptionRoute])

  const onAction = (action: "MEETING" | "INTEREST" | "RECOMMEND", organization: Exhibitor) => {
    const profileId = userState.user()?.profileId
    if (profileId) {
      if (!hasAccessToOrganization(userState.user()!, organization.id)) {
        setShowGuestModal(true)
        return
      }
      switch (action) {
        case 'MEETING':
          trackVisit(profileId, organization.id, "CALENDARENTRY#CLICK")
          setAction(action)
          setTarget(organization)
          setShowPrivacyPolicyModal(true)
          break
        case 'INTEREST':
          trackVisit(profileId, organization.id, "INTEREST#CLICK")
          setAction(action)
          setTarget(organization)
          setShowPrivacyPolicyModal(true)
          break
        case 'RECOMMEND':
          trackVisit(profileId, organization.id, "RECOMMENDATION#CLICK")
          setAction(undefined)
          setTargetId(organization.id)
          setType(ShareTargetType.ORGANIZATION)
          setLink(buildDetailLink(organization.id, organization.name, 'organization'))
          setShowRecommendOrganizationModal(true);
          break

      }
    }
  }

  const reload = props.location.state
  const history = useHistory()

  const strings = useLanguageState().getStrings()

  const locations = calcBreadcrumbLocations(strings)
  let breadcrumb = [{ to: locations[0], name: strings.sideIconBar.lobbyMenuText }]
  if (eventKey === "MY_FAIR") {
    breadcrumb.push(
      { to: "/", name: strings.receptionPage.eventKeyMyPage}
    )
  }

  useEffect(() => {
    if (reload) {
      setEventKey("LOBBY")
      props.changeRoute("LOBBY")
      history.replace('', !reload)
    } else {
      props.changeRoute(eventKey)
    }
    //eslint-disable-next-line
  }, [eventKey])

  useEffect(() => {
    setEventKey(props.receptionRoute ? props.receptionRoute : "LOBBY")
  }, [props.trigger, props.receptionRoute])

  const heightToAdjust = (topBarRef && topBarRef.current ? topBarRef.current.clientHeight : 0) + (guestUserBannerRef && guestUserBannerRef.current ? guestUserBannerRef.current.clientHeight : 0)
  return (
    <ReceptionPageRoot guestBannerHeight={guestUserBannerRef && guestUserBannerRef.current ? guestUserBannerRef.current.clientHeight : 0}>
      {!isMobile &&
        <>
          <GuestUserBanner setRef={setGuestUserBannerRef} />
          <TopBar setRef={setTopBarRef} showShadow={true} />
        </>
      }
      <MainContent className="scroll">
        <ContentScrollContainer adjustForHeaderWith={heightToAdjust + "px"}>
          <MainContentRoot style={{ paddingBottom: isMobile ? "130px" : "44px" }}>
            {showPrivacyPolicyModal && <PrivacyPolicyModal callback={policyCallback} organizationId={target!.id} />}
            {showGuestModal && <GuestModal close={() => setShowGuestModal(false)} />}
            {showRequestOrganizationModal && <SayHelloModal organizationModal={true} targetId={target!.id} handleClose={() => setShowRequestOrganizationModal(false)}></SayHelloModal>}
            {showRecommendOrganizationModal && <RecommendOrganizationModal targetId={targetId} type={type!} link={`https://${window.location.hostname}` + link} sotUser={[]} close={() => setShowRecommendOrganizationModal(false)}></RecommendOrganizationModal>}
            {showBookmarkEventsModal && <BookmarkSpeakersEventsModal speakerId={targetId} onClose={() => setShowBookmarkEventsModal(false)} />}
            {showRequestMeetingModal &&
              <CalendarEntryModal viewMode={viewMode}
                calendarEntry={viewMode !== CalendarEntryModalViewMode.CREATE ? selecetedMeeting : undefined}
                organization={target ? target!.id : ""} sotUser={[]}
                close={() => setShowRequestMeetingModal(false)}
                onUpdated={() => setRefreshKey(1)}
              />}


            {!isMobile &&
              <>
                {FairInfo({ eventKey: eventKey, onRouteChange: props.changeRoute })}

                <Breadcrumb breadcrumb={breadcrumb} />
                {HeaderRowContent({ eventKey: eventKey, onEventKeySelect: setEventKey })}
              </>
            }
            <ToggledContent displayed={"flex"}>
              {eventKey === "LOBBY" ? <LobbyPageContent
                onShowfloorAction={onAction}
                onViewModeSet={setViewMode}
                eventKey={eventKey}
                refreshKey={refreshKey}
                onSetShowOrganizationModal={setShowRecommendOrganizationModal}
                showBookmarkEventsModal={showBookmarkEventsModal}
                onSetShowBookmarkEventsModal={setShowBookmarkEventsModal}
                onSetTargetId={setTargetId}
                onSetLink={setLink}
                onSetType={setType}
                profileId={userState.user()?.profileId!}
                isMobile={isMobile} />
                :
                <MyPageContent
                  onShowfloorAction={onAction}
                  onViewModeSet={setViewMode}
                  onMeetingModalRequestSet={setShowRequestMeetingModal}
                  eventKey={eventKey}
                  guestBannerHeight={guestUserBannerRef?.current?.clientHeight || 0}
                  onSetShowOrganizationModal={setShowRecommendOrganizationModal}
                  onSetShowBookmarkEventsModal={setShowBookmarkEventsModal}
                  onSetTargetId={setTargetId}
                  onSetLink={setLink}
                  onSetType={setType}
                  onSelectedMeetingSet={setSelectedMeeting}
                  showBookmarkEventsModal={showBookmarkEventsModal} />
              }
            </ToggledContent>
          </MainContentRoot>
        </ContentScrollContainer>
      </MainContent>
    </ReceptionPageRoot>
  )
}

export default ReceptionPageContentRefactored