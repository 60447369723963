import { createState, useState, self, State } from '@hookstate/core';
import { Persistence } from '@hookstate/persistence';
import { Auth } from 'aws-amplify';
import { useEffect, useMemo } from 'react';
import { PresenceType } from '../backendServices/BackendServices';
import { PrivacyUserAnswer } from '../backendServices/Types';
import { updateOrInvalidateSession } from '../contentArea/loginregistration/LoginRegistrationSite';


export const localStorageKey = 'virtualGuide-loggedInUser'
var jwt = require("jsonwebtoken");
export interface User {
    profileId: string,
    email: string,
    firstName?: string,
    lastName?: string,
    infotext?: string,
    infotextDe?: string,
    interests?: Array<String>,
    lookingfor?: Array<String>,
    offering?: Array<String>,
    logoUrl?: string,
    matchActive?: boolean,
    industry?: string,
    industryDe?: string,
    countrycode?: string,
    postalCode?: string,
    adress1?: string,
    adress2?: string,
    adress3?: string,
    city?: string,
    languages?: Array<any>,
    middleName?: string,
    mobile?: string,
    phone?: string,
    position?: string,
    positionDe?: string,
    company?: string,
    areaOfResponsibility?: string,
    presence?: PresenceType,
    facebook?: string,
    linkedIn?: string,
    googleplus?: string,
    youTube?: string,
    twitter?: string,
    xing?: string,
    pinterest?: string,
    instagram?: string,
    type?: string,
    visible?: boolean,
    showroomStandby?: boolean,
    organizations?: Array<{ id: string, name: string, logo?: string }>,
    eventDates?: Array<{ id: string, name: string }>,
    events?: Array<{ id: string, name: string }>,
    invitingOrganization?: { id: string, name: string }
}

export interface LoggendInUserContextMethods {
    isLoggedIn: boolean
    sessionVerificationDone: boolean
    user: () => User | undefined
    jwtToken: () => string | undefined
    updateToken: (jwtToken: string) => void
    setUser: (user?: User) => void
    isMatchActive: () => boolean
    setMatchActive: (matchActive: boolean) => void
    setFirstName: (firstName: string) => void
    setLastName: (lastName: string) => void
    setLanguages: (languages: any[]) => void
    setCompany: (company: string) => void
    setPosition: (position: string) => void
    setPositionDe: (positionDe: string) => void
    setAreaOfResponsibility: (areaOfResponsibility: string) => void
    setIndustry: (industry: string) => void
    setIndustryDe: (industryDe: string) => void
    setCountryCode: (countrycode: string) => void
    setMobile: (mobile: string) => void
    setPhone: (phone: string) => void
    setAdress1: (adress1: string) => void
    setAdress2: (adress2: string) => void
    setAdress3: (adress3: string) => void
    setCity: (city: string) => void
    setPostalCode: (postalCode: string) => void
    setProfileImage: (imageUrl: string) => void
    setInterests: (interests: string[]) => void
    setOrganizations: (organizations: any) => void
    setEventDates: (eventDates: any) => void
    setEvents: (events: any) => void
    setInfotext: (infotext: string) => void
    setInfotextDe: (infotextDe: string) => void
    setFacebook: (facebook: string) => void
    setLinkedin: (linkedIn: string) => void
    setGooglePlus: (googleplus: string) => void
    setYouTube: (youTube: string) => void
    setTwitter: (twitter: string) => void
    setXing: (xing: string) => void
    setPinterest: (pinterest: string) => void
    setInstagram: (instagram: string) => void
    setType: (type: string) => void
    setVisible: (visible: boolean) => void
    setShowroomStandby: (showroomStandby: boolean) => void
    setPrivacyUserAnswer: (privacyUserAnswer: PrivacyUserAnswer) => void
    setInvitingOrganization: (invitingOrganization: any) => void
    getPrivacyUserAnswer: (questionId: String) => PrivacyUserAnswer | undefined
    setUserState: (newState: { jwtToken?: string, user?: User, privacyUserAnswer2?: PrivacyUserAnswer[] }) => void
    logout: () =>  void
    wasLeadFinderNoticeShown: () => boolean
    setLeadFinterNoticeShown: () => void
}


export interface UserState {
    user?: User,
    jwtToken?: string,
    privacyUserAnswer2?: PrivacyUserAnswer[]
    leadFinderNoticeShown?: boolean,
    sessionVerificationDone: boolean
}


const state = createState<UserState>({ sessionVerificationDone: false })
let sessionBeeingVerified = false
resetSessionVerificationDone()
const useStateWrapper = (state: State<UserState>) => {
    const jwtToken = state.jwtToken.get()
    const isLoggedIn = useMemo(() => {
        return jwt.decode(jwtToken)?.sotUserId !== undefined
    }, [jwtToken])

    state[self].attach(Persistence(localStorageKey))

    useEffect(() => {
        if (!sessionBeeingVerified && jwtToken) {
            sessionBeeingVerified = true
            const validation = async () => {
                await updateOrInvalidateSession(isLoggedIn, invalidateSession, setUser, setMatchActive)
                state.sessionVerificationDone[self].set(true)
            }
            validation()
        }
    // eslint-disable-next-line
    }, [jwtToken])

    function invalidateSession() {
        setUserState({ jwtToken: undefined, user: undefined, privacyUserAnswer2: undefined })
        Auth.signOut()
    }

    function setUserState(newState: { jwtToken?: string, user?: User, privacyUserAnswer2?: PrivacyUserAnswer[] }) {
        state[self].set((prev) => {
            prev.jwtToken = newState.jwtToken
            prev.user = newState.user
            prev.privacyUserAnswer2 = newState.privacyUserAnswer2
            return prev
        })
    }

    function setUser(user?: User) {
        state[self].set(prevState => {
            prevState.user = user
            return prevState
        })
    }

    function setMatchActive(matchActive: boolean) {
        state[self].set(prevState => {
            prevState.user!!.matchActive = matchActive
            return prevState
        })
    }

    return ({
        isLoggedIn: isLoggedIn,
        sessionVerificationDone: state[self].get().sessionVerificationDone,
        user: () => {
            return state[self].get().user
        },
        jwtToken: () => {
            return state.jwtToken.get()
        },
        updateToken: (jwtToken: string) => {
            state[self].set(prevState => {
                prevState.jwtToken = jwtToken;
                return prevState
            })
        },
        setUser: setUser,
        isMatchActive: () => {
            return state.user[self].value?.matchActive === true
        },
        setMatchActive: setMatchActive,
        setFirstName: (firstName: string) => {
            state[self].set(prevState => {
                prevState.user!!.firstName = firstName
                return prevState
            })
        },
        setLastName: (lastName: string) => {
            state[self].set(prevState => {
                prevState.user!!.lastName = lastName
                return prevState
            })
        },
        setLanguages: (languages: any[]) => {
            state[self].set(prevState => {
                prevState.user!!.languages = languages
                return prevState
            })
        },
        setCompany: (company: string) => {
            state[self].set(prevState => {
                prevState.user!!.company = company
                return prevState
            })
        },
        setPosition: (position: string) => {
            state[self].set(prevState => {
                prevState.user!!.position = position
                return prevState
            })
        },
        setPositionDe: (positionDe: string) => {
            state[self].set(prevState => {
                prevState.user!!.positionDe = positionDe
                return prevState
            })
        },
        setAreaOfResponsibility: (areaOfResponsibility: string) => {
            state[self].set(prevState => {
                prevState.user!!.areaOfResponsibility = areaOfResponsibility
                return prevState
            })
        },
        setIndustry: (industry: string) => {
            state[self].set(prevState => {
                prevState.user!!.industry = industry
                return prevState
            })
        },
        setIndustryDe: (industryDe: string) => {
            state[self].set(prevState => {
                prevState.user!!.industryDe = industryDe
                return prevState
            })
        },
        setCountryCode: (countrycode: string) => {
            state[self].set(prevState => {
                prevState.user!!.countrycode = countrycode
                return prevState
            })
        },
        setMobile: (mobile: string) => {
            state[self].set(prevState => {
                prevState.user!!.mobile = mobile
                return prevState
            })
        },
        setPhone: (phone: string) => {
            state[self].set(prevState => {
                prevState.user!!.phone = phone
                return prevState
            })
        },
        setAdress1: (adress1: string) => {
            state[self].set(prevState => {
                prevState.user!!.adress1 = adress1
                return prevState
            })
        },
        setAdress2: (adress2: string) => {
            state[self].set(prevState => {
                prevState.user!!.adress2 = adress2
                return prevState
            })
        },
        setAdress3: (adress3: string) => {
            state[self].set(prevState => {
                prevState.user!!.adress3 = adress3
                return prevState
            })
        },
        setCity: (city: string) => {
            state[self].set(prevState => {
                prevState.user!!.city = city
                return prevState
            })
        },
        setPostalCode: (postalCode: string) => {
            state[self].set(prevState => {
                prevState.user!!.postalCode = postalCode
                return prevState
            })
        },
        setProfileImage: (imageUrl: string) => {
            state[self].set(prevState => {
                prevState.user!!.logoUrl = imageUrl
                return prevState
            })
        },
        setInterests: (interests: string[]) => {
            state[self].set(prevState => {
                prevState.user!!.interests = interests
                return prevState
            })
        },
        setOrganizations: (organizations: any) => {
            state[self].set(prevState => {
                prevState.user!!.organizations = organizations
                return prevState
            })
        },
        setEventDates: (eventDates: any) => {
            state[self].set(prevState => {
                prevState.user!!.eventDates = eventDates
                return prevState
            })
        },
        setEvents: (events: any) => {
            state[self].set(prevState => {
                prevState.user!!.events = events
                return prevState
            })
        },
        setInfotext: (infotext: string) => {
            state[self].set(prevState => {
                prevState.user!!.infotext = infotext
                return prevState
            })
        },
        setInfotextDe: (infotextDe: string) => {
            state[self].set(prevState => {
                prevState.user!!.infotextDe = infotextDe
                return prevState
            })
        },
        setFacebook: (facebook: string) => {
            state[self].set(prevState => {
                prevState.user!!.facebook = facebook
                return prevState
            })
        },
        setLinkedin: (linkedIn: string) => {
            state[self].set(prevState => {
                prevState.user!!.linkedIn = linkedIn
                return prevState
            })
        },
        setGooglePlus: (googleplus: string) => {
            state[self].set(prevState => {
                prevState.user!!.googleplus = googleplus
                return prevState
            })
        },
        setYouTube: (youTube: string) => {
            state[self].set(prevState => {
                prevState.user!!.youTube = youTube
                return prevState
            })
        },
        setTwitter: (twitter: string) => {
            state[self].set(prevState => {
                prevState.user!!.twitter = twitter
                return prevState
            })
        },
        setXing: (xing: string) => {
            state[self].set(prevState => {
                prevState.user!!.xing = xing
                return prevState
            })
        },
        setPinterest: (pinterest: string) => {
            state[self].set(prevState => {
                prevState.user!!.pinterest = pinterest
                return prevState
            })
        },
        setInstagram: (instagram: string) => {
            state[self].set(prevState => {
                prevState.user!!.instagram = instagram
                return prevState
            })
        },
        setType: (type: string) => {
            state[self].set(prevState => {
                prevState.user!!.type = type
                return prevState
            })
        },
        setVisible: (visible: boolean) => {
            state[self].set(prevState => {
                prevState.user!!.visible = visible
                return prevState
            })
        },
        setShowroomStandby: (showroomStandby: boolean) => {
            state[self].set(prevState => {
                prevState.user!!.showroomStandby = showroomStandby
                return prevState
            })
        },
        setPrivacyUserAnswer: (privacyUserAnswer: PrivacyUserAnswer) => {
            state[self].set(prevState => {
                const arr = prevState.privacyUserAnswer2?.filter(answer => answer.questionId !== privacyUserAnswer.questionId) || []
                arr.push(privacyUserAnswer)
                prevState.privacyUserAnswer2 = arr
                return prevState
            })
        },
        setInvitingOrganization: (invitingOrganization: any) => {
            state[self].set(prevState => {
                prevState.user!!.invitingOrganization = invitingOrganization
                return prevState
            })
        },
        getPrivacyUserAnswer: (questionId: String) => {
            return state[self].get().privacyUserAnswer2?.find(answer => answer.questionId === questionId)
        },
        setUserState: setUserState,
        logout: () => {
            invalidateSession()
        },
        wasLeadFinderNoticeShown: () => {
            return state[self].value?.leadFinderNoticeShown === true
        },
        setLeadFinterNoticeShown: () => {
            state[self].set(prevState => {
                prevState.leadFinderNoticeShown = true
                return prevState
            })
        }
    })
}

export const useLoggedInState = (): LoggendInUserContextMethods => useState(state)[self].map(useStateWrapper)

export function getMyIdFromLocalStorage(): string | undefined {
    const profilJson = JSON.parse(localStorage.getItem(localStorageKey) ?? "null") as UserState | null
    return profilJson?.user?.profileId ?? undefined
}

export function getMyEmailFromLocalStorage(): string | undefined {
    const profilJson = JSON.parse(localStorage.getItem(localStorageKey) ?? "null") as UserState | null
    return profilJson?.user?.email ?? undefined
}

export function getMyTypeFromLocalStorage(): string | undefined {
    const profilJson = JSON.parse(localStorage.getItem(localStorageKey) ?? "null") as UserState | null
    return profilJson?.user?.type ?? undefined
}

/**
 * Is there a better fix? SessionVerificationDone should be false on every page refresh. But it's part of the state and therefore persisted in the localstorage.
 * Didn't find a solution for this property to be transient. Therefor it's reset with this function. 
 */
function resetSessionVerificationDone() {
    const userState = JSON.parse(localStorage.getItem(localStorageKey) ?? "null") as UserState | null
    if (!userState)
        return
    userState.sessionVerificationDone = false
    localStorage.setItem(localStorageKey, JSON.stringify(userState))
}