import React, { useEffect, useRef, useState } from "react"
import { Col, Row, Tooltip } from "react-bootstrap"
import OverlayTrigger from "react-bootstrap/esm/OverlayTrigger"
import styled from "styled-components"
import { EntityType } from "../../../backendServices/Types"
import branding from "../../../branding/branding"
import { useLanguageState } from "../../../globalStates/LanguageState"
import { BookmarkWithToggle } from "../../../ui/BookmarkWithToggle"
import ScrollBarComponent from "../../../ui/ScrollBar"
import { DetailNavLink } from "../DetailNavLink"
import { ScrollableDiv } from "./DetailPageSections"
import { HeaderTitle } from "./StyledComponents"

const ScrollerShadowTop = styled.div`
    position: absolute;
    width: 100%;
    height: 10%;
    background: transparent;
    background: ${branding.primaryScrollWhiteShadowTTB};
    left: 0;
    top: 0;
    z-index: 2;
`

const ScrollerShadowBottom = styled.div`
    position: absolute;
    width: 100%;
    height: 10%;
    background: transparent;
    background: ${branding.primaryScrollWhiteShadowTTB};
    left: 0;
    bottom: 0;
    z-index: 2;
    transform: rotate(180deg);
`

export interface EntityRowData {
    id: string
    name: string
    initials: string
    pictureURL: string
    description: { teaser: string }
}
interface EntityRowsProps {
    type: EntityType
    headerTitle: string
    rowsData: EntityRowData[]
    hideShadows?: boolean
}

export const DetailPageEntityRows: React.FunctionComponent<EntityRowsProps> = (props) => {
    const [position, setPosition] = useState(0);
    const [value, setValue] = useState(0);
    const scrollRef = useRef<HTMLDivElement>(null)
    const maxScrollRange = scrollRef.current?.scrollHeight! - scrollRef.current?.clientHeight!;
    const width = maxScrollRange !== 0 ? "95%" : "100%";
    // Are shadows active
    const [isShadowTop, setIsShadowTop] = useState<boolean>(false)
    const [isShadowBottom, setIsShadowBottom] = useState<boolean>(false)

    useEffect(() => {
        scrollRef.current?.scrollTo(0, position)
    }, [position])

    // DETERMING IF THE SHADOWS SHOULD BE VISIBLE
    useEffect(() => {
        if ((scrollRef.current!.scrollWidth === scrollRef.current!.clientHeight) || props.hideShadows) return
        value === 0 ? setIsShadowTop(false) : setIsShadowTop(true)
        maxScrollRange - value < 40 ? setIsShadowBottom(false) : setIsShadowBottom(true)
        // eslint-disable-next-line
    }, [value, maxScrollRange])

    return (
        <Col xs={12}>
            <Row className="mt-5">
                <Col xs={12} className="p-0 mb-4">
                    <HeaderTitle>{`${props.headerTitle} (${props.rowsData.length})`}</HeaderTitle>
                </Col>
                <div style={{ width: '100%', position: 'relative' }}>
                    <ScrollableDiv ref={scrollRef} onScroll={() => setValue(scrollRef.current?.scrollTop!)} width={width}>
                        {
                            props.rowsData.map((rowData) => <DetailPageEntityRow type={props.type} rowData={rowData} key={rowData.id} />)
                        }
                    </ScrollableDiv>
                    {isShadowTop && !props.hideShadows && <ScrollerShadowTop />}
                    {isShadowBottom && !props.hideShadows && <ScrollerShadowBottom />}
                </div>
                <ScrollBarComponent value={value} maxScrollRange={maxScrollRange ? maxScrollRange : 0} onScroll={(val: number) => { setPosition(val); setValue(val); }} isVertical={true}></ScrollBarComponent>
            </Row>
        </Col>
    )
}

const DetailRowEntryRoot = styled.div`
    display: flex;
    border-bottom: ${branding.mainBorder ? branding.mainBorder : '1px solid #d9d9d9'};
    padding: 25px 0;
`

const StyledDetailNaviLink = styled(DetailNavLink)`
`

const DetailRowContent = styled.div`
`

const RowEntryNameLabel = styled.div`
    font-weight: 700;
    font-style: normal;
    font-size: 16px;
    line-height: 1rem;
    margin-bottom: 10px;
`

const RowEntryTeaserLabel = styled.div`
    font-size: 14px;
    font-weight: 400;
    margin-top: 3px;
`

const MenuPanel = styled.div`
    display: flex;
    margin-left: auto;
    color: ${branding.primaryColor};
`

const MenuButton = styled.div`
    cursor: pointer;
    margin-left: 20px;
    height: 25px;
    width: 25px;

    &.disabled {
        opacity: 0.6;
        cursor: not-allowed;
        pointer-events: none;
    }
`

const LogoRoot = styled.div<{ hasPictureUrl: boolean, isRectangle: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    border: ${branding.mainBorder ? branding.mainBorder : '1px solid #d9d9d9'};
    border-radius: 5px;
    width: ${props => props.isRectangle ? '151px' : '95px'};
    height: 95px;
    background: ${props => props.hasPictureUrl ? "#FFF" : "lightgrey"};
    margin-right: 25px;
    padding: 1px;
`
const LogoDiv = styled.img`
    object-fit: contain;
    width: 100%;
    height: 100%;
`

interface EntityRowProps {
    type: EntityType
    rowData: EntityRowData
}

export const DetailPageEntityRow: React.FunctionComponent<EntityRowProps> = (props) => {
    const strings = useLanguageState().getStrings()
    return (<>
        <DetailRowEntryRoot>
            <StyledDetailNaviLink id={props.rowData.id} type={props.type} name={props.rowData.name}>
                <LogoRoot hasPictureUrl={props.rowData.pictureURL !== null} isRectangle={props.type === 'product'}>
                    {props.rowData.pictureURL ? <LogoDiv src={props.rowData.pictureURL} alt="" /> : <h2>{props.rowData.initials}</h2>}
                </LogoRoot>
            </StyledDetailNaviLink>
            <DetailRowContent>
                <DetailNavLink id={props.rowData.id} type={props.type} name={props.rowData.name}>
                    <RowEntryNameLabel>{props.rowData.name}</RowEntryNameLabel>
                    <RowEntryTeaserLabel>{props.rowData?.description?.teaser}</RowEntryTeaserLabel>
                </DetailNavLink>
            </DetailRowContent>
            <MenuPanel>
                <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip id="button-tooltip-share" style={{ fontFamily: branding.font1 }}>{strings.backofficeStaff.bookmark}</Tooltip>}>
                    <MenuButton><BookmarkWithToggle newBookmarkItem={true} fontSize={"25px"} color={branding.sideIconBar.sideIconColorDark} stroke={branding.sideIconBar.sideIconColorDark} type={props.type} id={props.rowData.id} /></MenuButton>
                </OverlayTrigger>
            </MenuPanel>
        </DetailRowEntryRoot>
    </>
    )
}