import React, { useEffect, useState, useRef } from "react"
import styled from "styled-components"
import Scrollbar, { ScrollbarProps } from "react-scrollbars-custom";
import ScrollBarComponent from "./ScrollBar"

const ScrollContainerRoot = styled(Scrollbar) <{ scrollBarAlwaysVisible?: boolean }>`
    & .ScrollbarsCustom-Track {
        opacity: ${props => props.scrollBarAlwaysVisible ? 0.6 : 0.4};
        transition: opacity 0.4s ease-in-out;
        visibility: ${props => props.scrollBarAlwaysVisible ? 'visible' : 'hidden'}; 
        width: 8px !important;
    }
    &:hover .ScrollbarsCustom-Track {
        opacity: 1;
        visibility: visible;
        overflow-y: auto;
    }
`

export type ScrollContainerProps = ScrollbarProps & {
    scrollBarAlwaysVisible?: boolean
}
const ScrollContainer: React.FC<ScrollContainerProps> = (props) => {
    return React.createElement(ScrollContainerRoot, { ...props, disableTracksWidthCompensation: true }, props.children)
}
export default ScrollContainer

const ScrollContainerStyled = styled(ScrollContainer) <{ width?: string, white?: string, padding?: string, paddingLeft?: string, containerwidth?: string }>`

  ${props => props.containerwidth ? `width: ${props.containerwidth} !important;}` : ""};
  & .ScrollbarsCustom-Content {
    ${props => props.padding ? `padding: ${props.padding};` : `padding: 0 25px 60px ${props.paddingLeft} !important;`}
    width: ${props => props.width};
  }

  & .ScrollbarsCustom-Track {
      z-index: 2;
      width: 8px !important;
  }

  .ScrollbarsCustom-Thumb {
      background-color: ${props => props.white === "true" ? "white !important" : ""};
  }
`;

const RoundScrollableDiv = styled.div<{ width?: string, height?: string }>`
    height: ${props => props.height ? props.height : "auto;"};
    width: ${props => props.width ? props.width : "100%;"};
    overflow: auto;
    -ms-overflow-style: none;  
    scrollbar-width: none; 
    ::-webkit-scrollbar {
        display: none;
    }
`

const HorizontalRoundScrollableDiv = styled.div<{ width: string, height?: string }>`
    height: ${props => props.height ? props.height : "auto;"};
    width: ${props => props.width ? props.width : "100%;"};
    overflow: auto;
    
    &::-webkit-scrollbar {
        width: 8px;
        height: calc(100% - 20px);
        background-color: transparent;
        border-radius: 4px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: transparent;
        border-radius: 4px;
        cursor: pointer;
        width: 100%;
    }

    &:hover {
        &::-webkit-scrollbar-thumb {
            background-color: rgba(0, 0, 0, .4);
        }
        &::-webkit-scrollbar {
            background-color: rgba(0, 0, 0, .1);
        }
    }

    @media(max-width: 640px) {
        width: 100%;
    } 
`

const RoundScrollbarParent = styled.div<{ startScrolling: boolean }>`
    width: calc(100% - 50px);
    margin-bottom: 20px;
`

const HorizontalScrollbarKiller = styled.div`
    position: absolute;
    width: 100%;
    height: 15px;
    background: #FFF;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
`

interface ContentScrollContainerProps {
    adjustForHeaderWith?: string
    noScrollX?: boolean
    width?: string
    containerWidth?: string
    paddingLeft?: string
    padding?: string
    scrollType?: string
    white?: string
    handleScroll?(value: number): void
    roundHorizontalScrollbar?: boolean
    position?: number
    scrollBarAlwaysVisible?: boolean
}
export const ContentScrollContainer: React.FC<ContentScrollContainerProps> = (props) => {
    const scrollRef = useRef<HTMLDivElement>(null)
    const [position, setPosition] = useState(0);
    const [value, setValue] = useState(0);

    const [isLoaded, setIsLoaded] = useState<boolean>(false)

    const maxScrollRange = scrollRef.current?.scrollHeight! - scrollRef.current?.clientHeight!;
    let lastY = props.roundHorizontalScrollbar ? scrollRef?.current?.scrollTop! : 0;

    useEffect(() => {
        if (props.position) {
            setPosition(props.position - 15)
        }
    }, [props.position])


    useEffect(() => {
        if (props.roundHorizontalScrollbar) {
            scrollRef.current?.scrollTo(position, lastY)

            const timeout = setTimeout(() => {
                setIsLoaded(true)
            }, 1000)

            return () => clearTimeout(timeout)
        }
        else
            scrollRef.current?.scrollTo(0, position)

        //eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [position])


    if (props.roundHorizontalScrollbar) {
        return (
            <>
                <RoundScrollbarParent startScrolling={scrollRef?.current?.scrollTop! > 20}>
                    <ScrollBarComponent value={value} maxScrollRange={scrollRef.current?.scrollWidth! - scrollRef.current?.offsetWidth!}
                        onScroll={(e: any) => {
                            setPosition(e);
                            setValue(e);
                        }} />
                </RoundScrollbarParent>
                <HorizontalRoundScrollableDiv ref={scrollRef} onScroll={(e: any) => {
                    if (isLoaded) {
                        const currY = e.currentTarget.scrollTop
                        const y = (currY > lastY) ? 'down' : ((currY === lastY) ? 'none' : 'up');
                        if (props.handleScroll && (y === 'down' || y === "up")) {
                            props.handleScroll(e.currentTarget);

                        }
                        // setValue(e.currentTarget.scrollTop.scrollLeft)
                        setValue(scrollRef.current?.scrollLeft!)
                        lastY = currY;
                    }
                    else {
                        scrollRef.current?.scrollTo(0, lastY)
                        setValue(0)
                    }
                }}
                    width={props.width!} height={props.adjustForHeaderWith ? "calc(100vh - " + props.adjustForHeaderWith + ")" : "100vh"}>
                    {props.children}
                    <HorizontalScrollbarKiller />
                </HorizontalRoundScrollableDiv>
            </>
        )
    } else
        return <>
            {props.scrollType === "round" ?
                <>
                    <RoundScrollableDiv ref={scrollRef} onScroll={() => setValue(scrollRef.current?.scrollTop!)} width={props.width} height={props.adjustForHeaderWith ? "calc(100vh - " + props.adjustForHeaderWith + ")" : "100vh"}>
                        {props.children}
                    </RoundScrollableDiv>
                    <ScrollBarComponent value={value} maxScrollRange={maxScrollRange ? maxScrollRange : 0} onScroll={(val: number) => { setPosition(val); setValue(val); }} isVertical={true}></ScrollBarComponent>
                </>
                :
                <ScrollContainerStyled scrollBarAlwaysVisible={props.scrollBarAlwaysVisible} onScroll={(e: any) => { if (props.handleScroll) props.handleScroll(e); }} noScrollX={props.noScrollX} style={{
                    height: props.adjustForHeaderWith ? "calc(100vh - " + props.adjustForHeaderWith + ")" : "100vh"
                }}
                    {...props}>{props.children}</ScrollContainerStyled>
            }
        </>
}

ContentScrollContainer.defaultProps = {
    noScrollX: true,
    width: "100%",
    white: "false",
} as Partial<ContentScrollContainerProps>
