import styled from "styled-components"
import React from "react"
import { VGIcon } from "./Icons"
import { AvatarWithDefault } from "./AvatarWithDefault"
import branding from "../branding/branding";

interface RootProps {
    backgroundColor?: string
    labelColor?: string
}

const PersonChipRoot = styled.div<RootProps>`
    cursor: default;
    margin: 5px;
    margin-left: 0;
    height: 36px;
    border-radius: 20px;
    border: 1px solid;
    display: flex;
    ${props => props.backgroundColor ? "background-color: " + props.backgroundColor : ""};
    ${props => props.labelColor ? "color: " + props.labelColor : ""};
    ${props => props.labelColor ? "border-color: " + props.labelColor : ""};
`

interface LabelContainerProps {
    hasSublabel: boolean
}
const LabelContainer = styled.div<LabelContainerProps>`
    color: ${branding.mainInfoColor ?? "#000"};
    font-family: ${branding.font1};
    ${props => props.hasSublabel ? "" : "margin-top: auto; margin-bottom: auto;"};
`

const LabelPanel = styled.div`
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 5px;
    margin-right: 10px;
`

const SublabelPanel = styled.div`
    margin-left: 5px;
    margin-right: 10px;
    font-size: 10px;
    margin-top: -6px;
`

const IconPanel = styled.div`
    margin-top: auto;
    margin-bottom: auto;
    margin-left: -5px;
    height: 30px;
    width: 30px;
    cursor: pointer;
`

const AvatarPanel = styled.div`
    margin: 1px;
    cursor: pointer;
`

interface PersonChipProps {
    userId: string
    label: string
    sublabel?: string
    labelColor?: string
    logoUrl?: string
    icon?: VGIcon
    onIconClick?: () => void
    onAvatarPanelClick?: () => void
    backgroundColor?: string
}

const PersonChip: React.FC<PersonChipProps> = (props) => {
    return <PersonChipRoot labelColor={props.labelColor} backgroundColor={props.backgroundColor}>
        <AvatarPanel onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            if(props.onAvatarPanelClick)
                props.onAvatarPanelClick()
        }}>
            <AvatarWithDefault size={32} src={props.logoUrl} alt={props.label} backgroundSize="cover" />
        </AvatarPanel>
        <LabelContainer hasSublabel={props.sublabel !== undefined}>
            <LabelPanel>{props.label}</LabelPanel>
            {props.sublabel && <SublabelPanel>{props.sublabel}</SublabelPanel>}
        </LabelContainer>
        {props.icon && <IconPanel onClick={props.onIconClick}>{props.icon}</IconPanel>}
    </PersonChipRoot>
}


export default PersonChip
