import React, { useState, useEffect } from "react"
import { Row, Col, Modal } from "react-bootstrap"
import { useLoggedInState } from "../globalStates/LoggedInUser"
import { useLanguageState } from "../globalStates/LanguageState"
import branding from "../branding/branding"
import styled from "styled-components"
import { Contact, ModalType } from "../backendServices/Types"
import { IconClose } from "./Icons"
import { loadUserData, getInterest, SpeakerResponse, loadPersonData, BackendServiceError } from "../backendServices/BackendServices"
import ContextMenu from "./ContextMenu"
import { useFavoriteState } from "../globalStates/Favorites"
import { useContactState } from "../communicationArea/ContactState"
import { useAppState } from "../globalStates/AppState"
import { useMeetingContext } from "../conference/context/MeetingContext"
import { useChimeContext } from "../conference/context/ChimeContext"
import { createActions, CommunicationModals } from "../communicationArea/CommunicationOptions"
import { DetailNavLink } from "../contentArea/detailPages/DetailNavLink"
import { mergeWith, isEmpty } from "lodash"
import { GetConnectionDetails } from "../utils/SayHello"
import { AvatarWithDefault } from "./AvatarWithDefault"


const ContactModalRoot = styled(Modal) <{ hideModalOpacity: boolean }>`
    width: 500px;
    height: 650px;
    left: 50%;
    transform: translateX(-250px);
    overflow: hidden !important;

    & .modal-dialog {
        opacity: ${props => props.hideModalOpacity ? 0 : 1};
    }

    & .modal-body{
        overflow: hidden !important;
    }

    & .modal-content{
        padding: 20px;
        border-radius: 6px;
    }
`

interface ContactModalProps {
    userId: string
    close: () => void
    open?: () => void
    onHideModalOpacityChange?: Function
    personId?: string
}

const ContactModal = (props: ContactModalProps) => {
    const [hideModalOpacity, setHideModalOpacity] = useState<boolean>(false)

    return (
        <ContactModalRoot hideModalOpacity={hideModalOpacity} show={true} animation={false} backdrop="static" onHide={props.close} onShow={() => props.open}>
            <Modal.Body>
                <ContactModalContent onHideModalOpacityChange={setHideModalOpacity} userId={props.userId} personId={props.personId} close={props.close} />
            </Modal.Body>
        </ContactModalRoot>

    )
}


const ContactModalContentRoot = styled.div`
    margin: auto;
    align-items: center;
    justify-content: center;
    z-index: 100;
    background-color: white; 
    max-width: 450px;
    min-width: 350px;
    transition: top .5s;
`

const ContactModalContentContent = styled.div`
font-family: ${branding.font1};
color: ${branding.primaryColor ?? '#000'};
    & .user-name{
        font-size: 18px;
        font-weight: bold;
    }
    & .title{
        margin: 0;
    }
`
const ContactModalContentActionRoot = styled.div`
   text-align: center;
   margin-top: 1rem;
`

const ContactModalContentInterestsRoot = styled.div`
    font-family: ${branding.font1};
    color: ${branding.mainInfoColor ?? "#000"};
    position: relative;
    border-top: 1px solid ${branding.mainInfoColor ?? "#000"};
    padding: .5rem 0; 
    margin-top: 1rem;
`
const ContactModalContentInterestsContent = styled.div`
    max-height: 150px; 
    overflow-y: auto;
`
const ContactModalContentInterestsItem = styled.div<{ bold: Boolean }>`
    display: inline-block;
    padding: .5rem;
    border: 1px solid ${branding.mainInfoColor ?? "#000"};
    font-family: ${props => props.bold ? branding.font2 : branding.font1};
    font-weight: ${props => props.bold ? 'bold' : 'normal'};
    text-transform: ${props => props.bold ? 'uppercase' : 'unset'};
    margin: .3rem;
`
const ContactModalContentInterestsBottomGradient = styled.div`
    height: 30px;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    background: rgb(255,255,255);
    background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
`
const ContactModalContentCloseBtn = styled.div`
    position: absolute;
    z-index: 105;
    right: 20px; 
    top: 10px; 
    cursor: pointer;
`

const ContactModalContent: React.FC<ContactModalProps> = (props) => {
    const strings = useLanguageState().getStrings()
    const userState = useLoggedInState()
    const loggedInUserId = userState.user()?.profileId
    const favoriteState = useFavoriteState()
    const contactState = useContactState()
    const appState = useAppState()
    const meeting = useMeetingContext()
    const chime = useChimeContext()

    const [user, setUser] = useState<Contact>()
    const [isUser, setIsUser] = useState(false)
    const [sotUserId, setSotUserId] = useState<string>("")


    const [bookmarked, setBookmarked] = useState<boolean>(false)
    const [userTypeStatus, setUserTypeStatus] = useState("")

    const connectionStatusTemp = contactState.getConnectionStatus(sotUserId)

    const [connectionStatus, setConnectionStatus] = useState(contactState.getConnectionStatus(sotUserId))




    const [modalType, setModalType] = useState<ModalType>('none')

    const menuCallback: (param: { modalType?: ModalType }) => void = ({ modalType }) => {
        if (modalType) {
            setModalType(modalType)
        }
    }

    useEffect(() => {
        loadUser()
        // eslint-disable-next-line
    }, [props.userId, connectionStatusTemp])

    useEffect(() => {
        if (modalType === 'meeting' || modalType === 'share' || modalType === 'report')
            props.onHideModalOpacityChange!(true)
        // eslint-disable-next-line
    }, [modalType])

    useEffect(() => {
        setUserTypeStatus(contactState.getUserType(props.userId))
        setBookmarked(favoriteState.is("person", props.userId ? sotUserId : props.personId!))

        GetConnectionDetails(loggedInUserId || "", sotUserId).then(res => {
            setConnectionStatus(res as string)
        }).catch(err => {
            setConnectionStatus("UNRELATED")
        });

        //eslint-disable-next-line
    }, [])

    useEffect(() => {
        setConnectionStatus(contactState.getConnectionStatus(sotUserId))
        setUserTypeStatus(contactState.getUserType(sotUserId))
        setBookmarked(favoriteState.is("person", props.userId ? sotUserId : props.personId!))
        // eslint-disable-next-line
    }, [props.userId, contactState, favoriteState])



    async function loadUser() {
        if (!loggedInUserId) {
            return
        }
        if (props.personId) {
            try {
                const personData: SpeakerResponse = await loadPersonData({ id: props.personId })
                const contactDataPerson = personData.content
                const connectedSotUserId = contactDataPerson.sotUserId

                if (connectedSotUserId) {
                    const response = await loadUserData({ targetProfileId: connectedSotUserId, loggedInUserId: loggedInUserId })
                    if ((response as BackendServiceError).httpStatus) {
                        return
                    }

                    setIsUser(true)
                    const userDataResponse = (response as SpeakerResponse)
                    mergePersonData(userDataResponse, personData)
                } else {
                    setIsUser(false)
                    mergePersonData(personData)
                }
                setSotUserId(connectedSotUserId ? connectedSotUserId : props.personId)
            } catch (error) {
            }

        } else {
            try {
                const response = await loadUserData({ targetProfileId: props.userId, loggedInUserId: loggedInUserId })

                const userDataResponse = (response as SpeakerResponse)
                setUser(userDataResponse.content)
                setIsUser(true)
                setSotUserId(props.userId)

            } catch (e) {
            }
        }

    }

    function mergePersonData(primaryData: SpeakerResponse, secondaryData?: SpeakerResponse) {
        var primaryContact = primaryData.content
        if (secondaryData) {
            const secondaryContact = secondaryData.content
            primaryContact = mergeWith({}, secondaryContact, primaryContact, (obj, src) => src == null || isEmpty(src) ? obj : src)
        }
        setUser(primaryContact)
        contactState.setAll([{ id: primaryContact.id, connectionStatus: primaryContact.myConnectionStatus, userType: primaryContact.type }])
    }


    const personName = [user?.title, user?.firstName, user?.lastName].join(" ")

    const personPosition: string = [user?.position, user?.company]
        .filter(Boolean)
        .join(" " + strings.communicationArea.personCompanyLink + " ")

    return (user ?
        <ContactModalContentRoot >
            <CommunicationModals show={modalType} contact={user} onHide={() => { setModalType('none'); props.onHideModalOpacityChange!(false) }} />
            <ContactModalContentCloseBtn onClick={() => props.close()}>
                {IconClose({ fill: branding.sideIconBar.sideIconColorDark })}
            </ContactModalContentCloseBtn>
            <div>
                <Row>
                    <Col xs={3}>
                        <DetailNavLink type={isUser ? 'user' : 'person'} id={isUser ? sotUserId : props.personId!} name={`/person/${user?.firstName}_${user?.lastName}`}>
                            <AvatarWithDefault size={80} src={user?.logoUrl} alt={[user?.firstName, user?.lastName].join(" ") ?? "#"} backgroundSize="cover" />
                        </DetailNavLink>
                    </Col>
                    <Col xs={8}>
                        <DetailNavLink type={isUser ? 'user' : 'person'} id={isUser ? sotUserId : props.personId!} name={`/person/${user?.firstName}_${user?.lastName}`}>
                            <ContactModalContentContent>
                                <p className={'user-name'}>{personName}</p>
                                <p className={'title'}>{personPosition}</p>
                            </ContactModalContentContent>
                        </DetailNavLink>
                    </Col>
                </Row>
                {user?.interests?.length! > 0 && <Row className="m-0">
                    <ContactModalContentInterestsRoot>
                        <ContactInterests user={user} />
                        <ContactModalContentInterestsBottomGradient />
                    </ContactModalContentInterestsRoot>
                </Row>}
                <ContactModalContentActionRoot>
                    <ContextMenu collapsed={false} items={() => createActions(
                        userState.user(),
                        user,
                        favoriteState,
                        contactState,
                        appState,
                        meeting,
                        chime,
                        strings,
                        bookmarked,
                        connectionStatus,
                        userTypeStatus,
                        menuCallback,
                        undefined,
                        !isUser)} />
                </ContactModalContentActionRoot>
            </div>
        </ContactModalContentRoot> : <div />
    )
}

export default ContactModal;

interface ContactInterestsProps {
    user: Contact
}


const ContactInterests: React.FunctionComponent<ContactInterestsProps> = (props: ContactInterestsProps) => {
    const strings = useLanguageState().getStrings()
    const userState = useLoggedInState()


    const [uniqueInterests, setUniqueInterests] = useState<Array<any>>([])

    const [commonInterests, setCommonInterests] = useState<Array<any>>([])

    useEffect(() => {
        getInterest().then(res => {
            if (res.content.interests) {
                let allInterests = res.content.interests

                let myInterestsTemp = userState.user()?.interests!
                let usersInterestsTemp = props.user.interests

                setCommonInterests(allInterests.filter((i: any) => myInterestsTemp?.includes(i.id) && usersInterestsTemp?.includes(i.id)))
                setUniqueInterests(allInterests.filter((i: any) => myInterestsTemp?.includes(i.id) === false && usersInterestsTemp?.includes(i.id)))
            }
        })
        // eslint-disable-next-line
    }, [])

    return (props.user.interests ?
        <ContactModalContentInterestsContent>
            <p>{strings.personDetailPageContent.interestsTitle}</p>
            {
                commonInterests.map(interest => {
                    return (
                        <ContactModalContentInterestsItem key={interest.id} bold={true}>
                            {interest.name}
                        </ContactModalContentInterestsItem>
                    );
                })
            }
            {
                uniqueInterests.map(interest => {
                    return (
                        <ContactModalContentInterestsItem key={interest.id} bold={false}>
                            {interest.name}
                        </ContactModalContentInterestsItem>
                    );
                })
            }
        </ContactModalContentInterestsContent>
        : <div />
    )
}
