import styled from "styled-components"
import React, { useState } from "react"
import { Row, Col } from 'react-bootstrap'
import { Company } from "../backendServices/Types"
import { syncFavorites, trackVisit } from "../backendServices/BackendServices"
import { useLoggedInState } from "../globalStates/LoggedInUser"
import branding from "../branding/branding"
import { useLanguageState } from "../globalStates/LanguageState"
import { IconMeetingSchedule, IconCall, IconBookmarkFilled, IconDownloadVCard, IconBookmark, IconShare, IconRecommend } from "./Icons"

import { DetailNavLink, buildDetailLink } from "../contentArea/detailPages/DetailNavLink"
import { useFavoriteState } from "../globalStates/Favorites"
import { ShareTargetType } from "../backendServices/Types"

import moment from "moment"

import { saveExhibitorVCard } from "../communicationArea/VCard"
import { hasAccessToOrganization } from "../utils/Authorization"

import CalendarEntryModal, { CalendarEntryModalViewMode } from "./CalendarEntryModal"
import RecommendOrganizationModal from "./RecommendOrganizationModal"
import SayHelloModal from "./SayHelloModal"
import useWindowDimensions from "./WindowDimensionsHook"
import { checkIfMobile } from ".."

const CompanyDetailsRoot = styled(Row)`
    font-size: 12px;
    line-height: 17px;
    margin: 50px 0px 10px 0px;
    border-top: ${branding.mainBorder ? branding.mainBorder : '1px solid #d9d9d9'};
    border-bottom: ${branding.mainBorder ? branding.mainBorder : '1px solid #d9d9d9'};
`

const MobileCompanyDetailsRoot = styled(Row)`
    font-size: 12px;
    line-height: 17px;
`

const CompanyLogoRoot = styled(Col)`
    margin: 25px 0;
`

const CompanyLogoDiv = styled(DetailNavLink)`
    width: 150px;
    height: 150px;
    background: white;
    border: 1px solid #D9D9D9;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    /* margin-top: 20px; */
    margin-right: 20px;

    @media(max-width: 1600px) {
        width: 120px;
        height: 120px;
    }

    @media(max-width: 1400px) {
        width: 100px;
        height: 100px;
    }

    @media(max-width: 1200px) {
        width: 80px;
        height: 80px;
    }
`

const MobileCompanyLogoDiv = styled(DetailNavLink)`
    width: 97px;
    height: 97px;
    background: white;
    border: 1px solid #D9D9D9;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
`
const CompanyLogo = styled.img`
    object-fit: contain;
    height: 100%;
    width: 100%;
`

const CompanyAction = styled.div`
  display: flex;
  cursor: pointer;
  flex-flow: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
  font-family: ${branding.font1};
  color: ${branding.personDetailPageContent.personContactDetailsContainerTextColor ?? "#000"};
  @media(max-width: 1300px)
  {
    /* flex-flow: column;  */
  }
`


const MobileCompanyAction = styled.div`
  display: flex;
  cursor: pointer;
  flex-flow: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 15px;
  font-family: ${branding.font1};
  color: ${branding.personDetailPageContent.personContactDetailsContainerTextColor ?? "#000"};
`


const CompanyActionText = styled.div` 
  font-family: ${branding.font1};
  text-decoration: none;
  font-size: 14px;
  color: ${branding.personDetailPageContent.personContactDetailsContainerTextColor ?? "#000"};
  border-radius: 10px;
  margin-left: 20px;
  &:hover {
    color: ${branding.personDetailPageContent.personContactDetailsContainerTextColorOnHover ?? "#000"};
}
 
@media(max-width: 1450px)
{
    font-size: 12px;
}
@media(max-width: 1110px)
{
    font-size: 10px;
}
`

const MobileCompanyActionText = styled.div` 
  font-family: ${branding.font1};
  text-decoration: none;
  font-size: 14px;
  line-height: 17px;
  color: ${branding.personDetailPageContent.personContactDetailsContainerTextColor ?? "#000"};
  border-radius: 10px;
  margin-left: 20px;
  &:hover {
    color: ${branding.personDetailPageContent.personContactDetailsContainerTextColorOnHover ?? "#000"};
}
`

interface CompanyDetailsSectionProps {
    organization: any
}

const CompanyDetails: React.FunctionComponent<CompanyDetailsSectionProps> = React.memo((props) => {

    const strings = useLanguageState().getStrings()
    const language = useLanguageState().getLanguage()

    const loggedInUser = useLoggedInState().user()
    const favorites = useFavoriteState()

    const windowSize = useWindowDimensions()

    const link = buildDetailLink(props.organization.id, props.organization.name, 'organization')

    // eslint-disable-next-line
    const [showRequestMeetingModal, setShowRequestMeetingModal] = useState(false);
    const [showRequestOrganizationModal, setShowRequestOrganizationModal] = useState<boolean>(false)
    const [showRecommendOrganizationModal, setShowRecommendOrganizationModal] = useState<boolean>(false)

    const updateBookmarkedStatus = () => {
        if (props.organization.id) {
            if (loggedInUser?.profileId) {
                favorites.toggle("organization", props.organization.id)
                syncFavorites({
                    profileId: loggedInUser.profileId,
                    body: {
                        currentTime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                        lastSyncTime: favorites.getLastSyncTime(),
                        changedFavorites: [{
                            id: props.organization.id,
                            kind: ("organization").toUpperCase(),
                            deleted: favorites.is("organization", props.organization.id) ? false : true,
                            lastModified: moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
                        }]
                    }
                }).then((resp) => {
                    favorites.setLastSyncTime(new Date())
                }).catch((e: { message: React.SetStateAction<string> }) => {
                    // syncFavorites failed, logged in BackendServices
                })
            }
        }
    }

    function getVCardNote() {
        var date = moment()

        var template = strings.organizationDetailPageContent.vCardNoteTemplate
        var contentString = template.split('{$eventGuideName}').join(strings.configuration.ogTitle)
        contentString = contentString.split('{$date}').join(language === "en" ? date.format("MMMM DD, YYYY") : date.format("DD. MMMM YYYY"))

        return contentString
    }

    function doVCard(organization: Company) {
        saveExhibitorVCard(organization, getVCardNote())
    }

    if (checkIfMobile(windowSize)) {
        return <>
            <MobileCompanyDetailsRoot>
                <Col xs={5}>
                    <MobileCompanyLogoDiv type="organization" id={props.organization.id} name={props.organization.name}>
                        <CompanyLogo src={(props.organization.logo || props.organization.pictureURL) || "/branding/rsz_defaultgrey.png" } />
                    </MobileCompanyLogoDiv>
                </Col>
                <Col xs={19}>

                    <MobileCompanyAction onClick={() => {
                        setShowRequestMeetingModal(true)
                        trackVisit(loggedInUser!.profileId, props.organization.id, "CALENDARENTRY#CLICK")
                    }}>
                        {IconMeetingSchedule({ stroke: branding.sideIconBar.sideIconColorDark ?? "#000", fill: branding.sideIconBar.sideIconColorDark ?? "#000", width: "15", height: "15" })}
                        <MobileCompanyActionText>
                            {strings.organizationDetailPageContent.requestMeeting}
                        </MobileCompanyActionText>
                    </MobileCompanyAction>

                    <MobileCompanyAction onClick={() => {
                        setShowRequestOrganizationModal(true)
                        trackVisit(loggedInUser!.profileId, props.organization.id, "INTEREST#CLICK")
                    }}>
                        {IconCall({ stroke: branding.sideIconBar.sideIconColorDark ?? "#000", fill: branding.sideIconBar.sideIconColorDark ?? "#000", width: "15", height: "15" })}
                        <MobileCompanyActionText>
                            {strings.organizationDetailPageContent.registerInterest}
                        </MobileCompanyActionText>
                    </MobileCompanyAction>

                    <MobileCompanyAction onClick={() => {
                        updateBookmarkedStatus()
                    }}>
                        {favorites.is("organization", props.organization.id) ? IconBookmarkFilled({ fill: branding.sideIconBar.sideIconColorDark, width: "15", height: "15" }) : IconBookmark({ fill: branding.sideIconBar.sideIconColorDark, width: "15", height: "15" })}
                        <MobileCompanyActionText>
                            {strings.organizationDetailPageContent.bookmark}
                        </MobileCompanyActionText>
                    </MobileCompanyAction>

                    <MobileCompanyAction onClick={() => {
                        setShowRecommendOrganizationModal(true)
                    }}>
                        {IconShare({ stroke: branding.sideIconBar.sideIconColorDark ?? "#000", fill: branding.sideIconBar.sideIconColorDark ?? "#000", width: "15", height: "15" })}
                        <MobileCompanyActionText>
                            {strings.organizationDetailPageContent.recommend}
                        </MobileCompanyActionText>
                    </MobileCompanyAction>

                    {branding.organizationDetailPageContent.vCardDownloadAvailable &&
                        hasAccessToOrganization(loggedInUser!, props.organization.id) &&
                        <MobileCompanyAction onClick={() => {
                            doVCard(props.organization)
                        }}>
                            {IconDownloadVCard({ fill: branding.sideIconBar.sideIconColorDark, width: "15", height: "15" })}
                            <MobileCompanyActionText>
                                {strings.contactEntry.downloadVCardTitle}
                            </MobileCompanyActionText>
                        </MobileCompanyAction>
                    }
                </Col>
            </MobileCompanyDetailsRoot>
            {showRequestMeetingModal && <CalendarEntryModal viewMode={CalendarEntryModalViewMode.CREATE} organization={props.organization.id} close={() => setShowRequestMeetingModal(false)} />}
            {showRequestOrganizationModal && <SayHelloModal organizationModal={true} targetId={props.organization.id || ""} handleClose={() => setShowRequestOrganizationModal(false)}></SayHelloModal>}
            {showRecommendOrganizationModal && <RecommendOrganizationModal targetId={props.organization.id || ""} type={ShareTargetType.ORGANIZATION} link={`https://${window.location.hostname}` + link} sotUser={[]} close={() => setShowRecommendOrganizationModal(false)}></RecommendOrganizationModal>}
        </>
    }

    return <>
        <CompanyDetailsRoot>
            <CompanyLogoRoot xs={12} md={2}>
                <CompanyLogoDiv type="organization" id={props.organization.id} name={props.organization.name}>
                    <CompanyLogo src={(props.organization.logo || props.organization.pictureURL) || "/branding/rsz_defaultgrey.png" } />
                </CompanyLogoDiv>
            </CompanyLogoRoot>
            <Col xs={12} md={9} className="offset-md-1">
                <Row>
                    <Col xs={24} md={12}>
                        <div style={{ fontSize: "18px", marginTop: "40px", marginBottom: "30px" }}>
                            {props.organization.name}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={4}>
                        <CompanyAction onClick={() => {
                            setShowRequestMeetingModal(true)
                            trackVisit(loggedInUser!.profileId, props.organization.id, "CALENDARENTRY#CLICK")
                        }}>
                            {IconMeetingSchedule({ stroke: branding.sideIconBar.sideIconColorDark ?? "#000", fill: branding.sideIconBar.sideIconColorDark ?? "#000" })}
                            <CompanyActionText>
                                {strings.organizationDetailPageContent.requestMeeting}
                            </CompanyActionText>
                        </CompanyAction>
                    </Col>
                    <Col xs={12} md={4}>
                        <CompanyAction onClick={() => {
                            setShowRecommendOrganizationModal(true)
                            trackVisit(loggedInUser!.profileId, props.organization.id, "RECOMMENDATION#CLICK")
                        }}>
                            {IconRecommend({ stroke: branding.sideIconBar.sideIconColorDark ?? "#000", fill: branding.sideIconBar.sideIconColorDark ?? "#000" })}
                            <CompanyActionText>
                                {strings.organizationDetailPageContent.recommend}
                            </CompanyActionText>
                        </CompanyAction>
                    </Col>
                    {branding.organizationDetailPageContent.vCardDownloadAvailable &&
                        hasAccessToOrganization(loggedInUser!, props.organization.id) &&
                        <Col xs={12} md={4}>
                            <CompanyAction onClick={() => {
                                doVCard(props.organization)
                            }}>
                                {IconDownloadVCard({ fill: branding.sideIconBar.sideIconColorDark })}
                                <CompanyActionText>
                                    {strings.contactEntry.downloadVCardTitle}
                                </CompanyActionText>
                            </CompanyAction>
                        </Col>
                    }
                </Row>
                <Row>
                <Col xs={12} md={4}>
                        <CompanyAction onClick={() => {
                            setShowRequestOrganizationModal(true)
                            trackVisit(loggedInUser!.profileId, props.organization.id, "INTEREST#CLICK")
                        }}>
                            {IconCall({ stroke: branding.sideIconBar.sideIconColorDark ?? "#000", fill: branding.sideIconBar.sideIconColorDark ?? "#000" })}
                            <CompanyActionText>
                                {strings.organizationDetailPageContent.registerInterest}
                            </CompanyActionText>
                        </CompanyAction>
                    </Col>
                    <Col xs={12} md={6}>
                        <CompanyAction onClick={() => {
                            updateBookmarkedStatus()
                        }}>
                            {favorites.is("organization", props.organization.id) ? IconBookmarkFilled({ fill: branding.sideIconBar.sideIconColorDark }) : IconBookmark({ fill: branding.sideIconBar.sideIconColorDark })}
                            <CompanyActionText>
                                {strings.organizationDetailPageContent.bookmark}
                            </CompanyActionText>
                        </CompanyAction>
                    </Col>
                </Row>
            </Col>
      
         
       
        </CompanyDetailsRoot>
        {showRequestMeetingModal && <CalendarEntryModal viewMode={CalendarEntryModalViewMode.CREATE} organization={props.organization.id} close={() => setShowRequestMeetingModal(false)} />}
        {showRequestOrganizationModal && <SayHelloModal organizationModal={true} targetId={props.organization.id || ""} handleClose={() => setShowRequestOrganizationModal(false)}></SayHelloModal>}
        {showRecommendOrganizationModal && <RecommendOrganizationModal targetId={props.organization.id || ""} type={ShareTargetType.ORGANIZATION} link={`https://${window.location.hostname}` + link} sotUser={[]} close={() => setShowRecommendOrganizationModal(false)}></RecommendOrganizationModal>}
    </>
})

export default CompanyDetails