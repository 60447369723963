import { mySchedulePageRoute, homePageRoute } from './../../navigationArea/RoutePaths';
import moment, { Moment } from 'moment';
import { EventDate } from './../../backendServices/Types';
import { CalendarEntry } from "../../backendServices/BackendServices";
import branding from "../../branding/branding";
import { momentWithoutTimezoneFromTimezonedMoment } from '../../utils/DateUtils';

export interface Location {
    name: string,
    events: EventDate[]
    meetings?: CalendarEntry[]
}
export interface DayData {
    locations: Map<string, Location>,
    times: string[],
    dayStartTime: Date,
    date: string,
    helper: ScheduleHelper,
}
export class ScheduleHelper {

    dayStartHours = parseInt(branding.programSchedule.dayStartHours || "9")
    dayStartMinutes = 0;

    //defaultEndDate = "20:00" // TODO from config?
    //pixelPerMinute = 6; // TODO from config?
    timeIncrements = window.location.pathname === mySchedulePageRoute || window.location.pathname === homePageRoute ? 30 : 15; // TODO from config?
    minutesOfDayDisplayed = 0;
    pixelPerSlot = parseInt(branding.programPageContent.pixelsPerSlot)

    getHoursAndMinutesForCustomObjects(eventDate: any, isStart: boolean) {
        var hours: number;
        var minutes: number;
        if (eventDate && eventDate.hasOwnProperty("startHour")) {
            hours = isStart ? eventDate.startHour : eventDate.endHour;
            minutes = isStart ? eventDate.startMinutes : eventDate.endMinutes;
        } else {
            const timezone = JSON.parse(localStorage.getItem("virtualGuide-app") ?? "{}").timezone ?? moment.tz.guess()
            var meetingDate = momentWithoutTimezoneFromTimezonedMoment(moment(new Date(isStart ? eventDate.start : eventDate.end)), timezone).toDate();
            hours = meetingDate.getHours();
            minutes = meetingDate.getMinutes();
        }

        return {
            hours: hours,
            minutes: minutes
        };
    }

    getMinutesSinceDayStart(hours: number, minutes: number): number {
        return hours * 60 + minutes;
    }

    getTimeStringFromMinutes(minutes: number): string {
        const hours = Math.floor(minutes / 60);
        const minutesForString = minutes % 60;
        return (hours < 10 ? "0" : "") + hours + ":" + (minutesForString < 10 ? "0" : "") + minutesForString;
    }

    // Convert Event Time into Slots Measurement
    getSlot(eventDate: any): number {
        const timeObject = this.getHoursAndMinutesForCustomObjects(eventDate, true);

        // return this.getSlot2(eventDate.startHour, eventDate.startMinutes);
        return this.getSlot2(timeObject.hours, timeObject.minutes);
    }
    // Convert String Time into Slot Measurement
    getSlot2(hours: number, minutes: number): number {
        return (hours * 60 + minutes) / this.timeIncrements;
    }

    getEndSlot(eventDate: any): number {
        const timeObject = this.getHoursAndMinutesForCustomObjects(eventDate, false);

        return (timeObject.hours * 60 + timeObject.minutes) / this.timeIncrements;
    }

    // Margin Top of Time Panel
    getMarginTop(eventDate: any): number {
        return (this.getSlot(eventDate) - this.getSlot2(this.dayStartHours, this.dayStartMinutes)) * this.pixelPerSlot;
    }

    // Margin Top between 2 Time Panel
    getMarginTopBetween(placed: any, toPlace: any): number {
        return (this.getSlot(toPlace) - this.getEndSlot(placed)) * this.pixelPerSlot;
    }

    // Height of one Panel
    getHeightForEventDate(eventDate: any): number {
        return (this.getEndSlot(eventDate) - this.getSlot(eventDate)) * this.pixelPerSlot;
    }

    getMinutesSinceDayStartForEventDateStart(eventDate: EventDate): number {
        const minutesSinceDayStart = this.getMinutesSinceDayStart(eventDate.startHour, eventDate.startMinutes);
        // const minuteRestAfterFullFrames = minutesSinceDayStart % this.timeIncrements;
        // return minutesSinceDayStart - minuteRestAfterFullFrames - (minuteRestAfterFullFrames === 0 ? this.timeIncrements : 0);
        return minutesSinceDayStart;
    }

    getMinutesSinceDayStartForEventDateEnd(eventDate: EventDate): number {
        const minutesSinceDayStart = this.getMinutesSinceDayStart(eventDate.startHour, eventDate.startMinutes);
        return minutesSinceDayStart - (minutesSinceDayStart % this.timeIncrements) + this.timeIncrements;
    }

    getTimeLabelHeight(): number {
        return this.pixelPerSlot;
    }

    getMinuteIncrement(): number {
        return this.timeIncrements;
    }

    updateTimes(eventDate: EventDate, dayData: DayData) {
        if (this.minutesOfDayDisplayed <= 0) {
            this.minutesOfDayDisplayed = dayData.helper.getMinutesSinceDayStartForEventDateStart(eventDate);
            const startDateHours = Math.floor(this.minutesOfDayDisplayed / 60);
            const startDateMinutes = this.minutesOfDayDisplayed % 60;
            dayData.dayStartTime = new Date(0, 0, 0, startDateHours, startDateMinutes);
            dayData.helper.dayStartHours = startDateHours
            dayData.helper.dayStartMinutes = startDateMinutes;
        }

        let lastMinutesOfDayDisplayed = dayData.helper.getMinutesSinceDayStartForEventDateEnd(eventDate);
        for (; dayData.helper.minutesOfDayDisplayed <= lastMinutesOfDayDisplayed; this.minutesOfDayDisplayed += dayData.helper.getMinuteIncrement()) {
            dayData.times.push(dayData.helper.getTimeStringFromMinutes(this.minutesOfDayDisplayed));
        }
    }
    updateTimesEventDate(startEventDate: EventDate, endEventDate: EventDate, dayData: DayData) {
        let minutesSinceDayStart = dayData.helper.getMinutesSinceDayStartForEventDateStart(startEventDate);
        const eventTimeEndMinutes = endEventDate.endHour * 60 + endEventDate.endMinutes;

        const startDateHours = Math.floor((minutesSinceDayStart) / 60);
        const startDateMinutes = (minutesSinceDayStart) % 60;
        dayData.dayStartTime = new Date(0, 0, 0, startDateHours, startDateMinutes);
        dayData.helper.dayStartHours = startDateHours
        dayData.helper.dayStartMinutes = startDateMinutes;

        for (; minutesSinceDayStart < eventTimeEndMinutes + 15; minutesSinceDayStart += 15) {
            dayData.times.push(dayData.helper.getTimeStringFromMinutes(minutesSinceDayStart));
        }
    }

    parseTime(time: string) {
        return {
            hours: time.split(":")[0],
            minutes: time.split(":")[1]
        }
    }

    updateTimesCustom(start: string, end: string, dayData: DayData) {
        // const startObject = this.parseTime(start);
        const endObject = this.parseTime(end);
        var startH = this.dayStartHours
        var endH = parseInt(endObject.hours);

        for (var i = startH; i <= endH + 1; i++) {
            dayData.times.push(i < 10 ? "0" + i + ":00" : i + ":00");
            dayData.times.push(i < 10 ? "0" + i + ":30" : i + ":30");
        }

        dayData.dayStartTime = new Date(0, 0, 0, 9, 0);
    }

    getTopForCurrentTime() {
        var timezone = JSON.parse(localStorage.getItem("virtualGuide-app") ?? "{}").timezone ?? moment.tz.guess()
        var currentDate = momentWithoutTimezoneFromTimezonedMoment(moment(), timezone).toDate();
        var x = (currentDate.getHours() * 60 + currentDate.getMinutes()) / this.timeIncrements;
        var y = (this.dayStartHours * 60 + this.dayStartMinutes) / this.timeIncrements;
        return {
            "top": (x - y) * this.pixelPerSlot,
            "timeEN": moment().tz(timezone).format("h:mm a").toUpperCase(),
            "timeDE": moment().tz(timezone).format("HH:mm")
        };
    }

    /// Masterclas paralelel layout helper
    getMasterClassColumnWidth(eventDates: EventDate[]): number {

        let dates = this.orderEventDatesToColumns(eventDates);

        return Math.max(...dates.map(m => m.column)) + 1;
    }

    getMasterClassColumnPosition(eventDates: EventDate[], eventDateId: string): number {

        let dates = this.orderEventDatesToColumns(eventDates);


        let result = dates.filter(m => m.id === eventDateId);
        return result[0].column;
    }

    orderEventDatesToColumns(eventDates: EventDate[]): { id: string, start: Moment, end: Moment, column: number }[] {
        let format = 'hh:mm:ss';

        let dates: { id: string, start: Moment, end: Moment, column: number }[] = eventDates
            .map((m, index) => ({ id: m.id, start: moment(m.start, format), end: moment(m.end, format), column: index }))
            .sort((a, b) => a.start < b.start ? -1 : a.start > b.start ? 1 : 0)

        for (let i = 0; i < dates.length; i++) {
            for (let j = 0; j < dates.length; j++)
                if (dates[j].start.isSameOrAfter(dates[i].end)) {

                    if (dates.filter(m => m.column === dates[i].column).length === 1)
                        dates[j].column = dates[i].column
                    else
                        dates[j].column = dates[j].column - 1
                }
        }
        return dates;
    }
}
