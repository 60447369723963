// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from 'react';

import styled from 'styled-components';
import { useChimeContext, MeetingStatusCode } from '../context/ChimeContext';
import { IconMicrophoneOff, IconMicrophone, IconRaiseHand, IconVolumeHigh } from '../../ui/Icons';
import { useActiveSpeakerContext } from '../context/ActiveSpeakerContext';
import branding from '../../branding/branding';

const VideoNameplateRoot = styled.div<{ isSpeaking?: boolean }>`
  position: absolute;
  font-size: 12px;
  top: 8px;
  left: 50%;
  transform: translateX(-50%);
  max-width: 100%;
  padding: 3px 10px;
  border-radius: 15px;
  background-color: #000;
  border: 2px solid ${props => props.isSpeaking ? "#00f" : "#fff"};
  color: #fff;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  line-height: 22px;
`

const Name = styled.div`
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 5px;
`

type Props = {
  attendeeId: string
};

export default function VideoNameplate(props: Props) {
  const activeSpeakerContext = useActiveSpeakerContext()
  const chime = useChimeContext()
  const attendee = chime.getAttendee(props.attendeeId)
  if (!attendee || chime.getMeetingStatus().meetingStatus !== MeetingStatusCode.Succeeded)
    return null
  const isSpeaking = activeSpeakerContext.isActiveSpeaker(props.attendeeId)
  return (
    <VideoNameplateRoot isSpeaking={isSpeaking}>
      {isSpeaking && IconVolumeHigh({fill: branding.sideIconBar.sideIconColorDark})}
      {attendee.name && <Name>{attendee.name}</Name>}
      {attendee.muted && IconMicrophoneOff({fill: branding.sideIconBar.sideIconColorDark, width: "15", height: "15" })}
      {!attendee.muted && IconMicrophone({fill: branding.sideIconBar.sideIconColorDark, width: "15", height: "15" })}
      {attendee.handRaised && IconRaiseHand({fill: branding.sideIconBar.sideIconColorDark})}
    </VideoNameplateRoot>
  );
}
