import React, { ChangeEvent } from "react"
import styled from "styled-components"
import branding from "../branding/branding"
import { isSafari } from "react-device-detect"

const TextInputAreaContainer = styled.div<{ width: string, marginRight: string }>`
    position: relative;
    display:inline-block;
    width: ${props => props.width};
    margin-right: ${props => props.marginRight ? props.marginRight : '0'};
`

const StartInputAdornment = styled.div`
    position: absolute;
    left: 3px;
    bottom: 5px;
`

const EndInputAdornment = styled.div`
    position: absolute;
    right: 3px;
    bottom: 5px;
`

const TextInputArea = styled.input<{ width: string, height: string, fontFamily: string, fontSize: string, containsStartAdornment: boolean, backgroundColor: string, color: string, border?: string, outline?: string, textTransform: string, fontWeight: string | number, letterSpacing: string, paddingLeft: string, margin?: string, borderColor: string }>`
    border: 0px;
    left: 10px;
    display:inline-block;
    word-break: break-word;
    white-space: pre-line;
    resize: none;
    color: ${props => props.color};
    height: ${props => props.height};
    background-color: ${props => props.backgroundColor};
    text-transform: ${props => props.textTransform};
    font-weight: ${props => props.fontWeight};
    letter-spacing: ${props => props.letterSpacing};
    width: 100%;
    font-family: ${props => props.fontFamily};
    font-size: ${props => props.fontSize};border-bottom: ${props => props.border ? props.border : (branding.mainBorder ? branding.mainBorder : '1px solid #d9d9d9')};
    border-top: ${props => props.border ?? "none"};
    border-left:${props => props.border ?? "none"};
    border-right:${props => props.border ?? "none"};
    border-radius: ${props => props.border ? "5px" : "0"};
    padding-left:  ${props => props.containsStartAdornment ? "30px" : props.paddingLeft};
    ${props => props.margin ?? "margin: " + props.margin};
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;    
    box-sizing: border-box;
    border-color: ${branding.inputUnFocusedBorderColor};

    &:hover {
        outline: none;
        border-bottom: ${props => (props.outline === "none" ? "2px solid" : props.outline) + " " + props.color};
        border-top: ${props => props.outline + " " + props.color};
        border-left: ${props => props.outline + " " + props.color};
        border-right: ${props => props.outline + " " + props.color};
    }

    &:focus {
        outline: none;
        border-bottom: ${props => (props.outline === "none" ? "2px solid" : props.outline) + " " + props.color};
        border-top: ${props => props.outline + " " + props.color};
        border-left: ${props => props.outline + " " + props.color};
        border-right: ${props => props.outline + " " + props.color};
        color: ${branding.inputFocusedColor}
    }

    &::placeholder{
        color: ${branding.placeholderUnFocusedColor};
    }

    &::-webkit-input-placeholder{
        color: ${branding.placeholderUnFocusedColor};
    }

`

const MultiLineContainer = styled.div<{ height: string, width: string, border?: string, outline?: string, background?: string, zIndex?: number }>`
    display: inline-block;
    padding: 0.5px;
    position: relative;
    width: ${props => props.width};
    height: ${props => props.height};
    z-index: ${props => props.zIndex ?? 1};
    background: ${(props) => props.background ?? "transparent"};

    &::after {
        padding: 5px;
        background: ${(props) => props.background ?? "transparent"};
        position: absolute;
        border: ${(props) => props.border ?? (branding.mainBorder ?? '1px solid #d9d9d9')};
        border-radius: 5px;
        content: '';
        top: -2px;
        left: -2px;
        bottom: -4px;
        right: -2px;  
    }

    &:hover {
        &::after {
            border: ${(props) => props.outline ?? ("2px solid " + branding.mainInfoColor)};
        }
    }

    &:focus {
        &::after {
            border: ${(props) => props.outline ?? ("2px solid " + branding.mainInfoColor)};
        }
    }
`

const MultiLineTextInputArea = styled.textarea<{ width: string, height: string, fontFamily: string, fontSize: string }>`
    
    height: ${props => props.height};
    word-break: break-word;
    white-space: pre-line;
    resize: none;
    color: ${branding.mainInfoColor};
    background: transparent;
    width: 100%;
    font-family: ${props => props.fontFamily};
    font-size: ${props => props.fontSize};
    position: absolute;
    z-index: 10;
    border: none;
    outline: none;

    &:hover {
        outline: none;
    }

    &:focus {
        outline: none;
    }
`

interface TextFieldProps {
    type?: string
    rows?: number
    maxLength?: number
    placeholder?: string
    value?: string
    width?: string
    height?: string
    fontFamily?: string
    fontSize?: string
    setValue?: (value: string) => void
    onChange?: (value: string) => void
    startAdornment?: any
    endAdornment?: any
    backgroundColor?: string
    textColor?: string
    search?: boolean
    disabled?: boolean
    textTransform?: string
    fontWeight?: string | number
    letterSpacing?: string
    borderAround?: string
    outline?: string
    focused?: boolean
    autoComplete?: string
    paddingLeft?: string
    margin?: string
    onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void
    marginRight?: string
    borderColor?: string
    name?: string
    zIndex?: number
}

export const TextField: React.FunctionComponent<TextFieldProps> = React.memo((props: TextFieldProps) => {

    return <TextInputAreaContainer width={props.width ?? "100%"} marginRight={props.marginRight ?? '0'}>
        {props.startAdornment && <StartInputAdornment>
            {props.startAdornment}
        </StartInputAdornment>}
        {<TextInputArea
            {...props}
            disabled={props.disabled}
            readOnly={props.disabled}
            autoFocus={props.focused ?? false}
            type={props.type ?? "text"}
            backgroundColor={props.backgroundColor ?? "transparent"}
            color={(props.disabled && !isSafari) ? branding.inputUnFocusedColor : (props.textColor ?? branding.mainInfoColor)}
            borderColor={props.disabled ? branding.inputUnFocusedBorderColor : (props.borderColor || (props.textColor ?? branding.mainInfoColor))}
            spellCheck={false}
            containsStartAdornment={props.startAdornment ?? false}
            width={props.width ?? "100%"}
            height={props.height ?? "30px"}
            fontFamily={props.fontFamily ?? branding.font1}
            fontSize={props.fontSize ?? "15px"}
            paddingLeft={props.paddingLeft ?? "0px"}
            value={props.value}
            autoComplete={props.autoComplete ?? "off"}
            placeholder={props.placeholder}
            border={props.disabled ? "1px solid" + branding.inputUnFocusedBorderColor : props.borderAround}
            fontWeight={props.fontWeight ?? "normal"}
            textTransform={props.textTransform ?? "none"}
            letterSpacing={props.letterSpacing ?? "0px"}
            outline={props.outline ?? "none"}
            style={{ cursor: props.disabled ? "not-allowed" : "auto" }}
            name={props.name}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                if (props.setValue)
                    props.setValue(e.target.value)

                if (props.onChange)
                    props.onChange(e.target.value)
            }}
            onKeyPress={props.onKeyPress}
        />}
        {props.endAdornment && <EndInputAdornment>
            {props.endAdornment}
        </EndInputAdornment>}
    </TextInputAreaContainer>
})

export const MultiLineTextField: React.FunctionComponent<TextFieldProps> = React.memo((props: TextFieldProps) => {

    return <MultiLineContainer height={props.height ?? "30px"} width={props.width ?? "100%"} border={props.borderAround} outline={props.outline} background={props.backgroundColor} zIndex={props.zIndex}>
        <MultiLineTextInputArea
            width={props.width ?? "100%"}
            height={props.height ?? "70px"}
            spellCheck={false}
            fontFamily={props.fontFamily ?? branding.font1}
            fontSize={props.fontSize ?? "16px"}
            maxLength={props.maxLength || 1000}
            rows={props.rows ?? 5} max-rows={10}
            value={props.value}
            placeholder={props.placeholder ?? ""}
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                if (props.setValue)
                    props.setValue(e.target.value)
            }} >{props.value}</MultiLineTextInputArea>
    </MultiLineContainer>
})