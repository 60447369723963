import { BasisPremiumType } from "../branding/BasisPremiumBranding";
import { CalendarEntry } from "./BackendServices";

export interface Stand {
    hallNr: string,
    standNr: string,
    type: string,
    hallName: string,
    hallNameShort: string,
    standName: string,
    standNameShort: string,
    categories: Category[]
}
export interface Contact {
    showroomStandby: any;
    id: string,
    initials: string,
    logoUrl: string,
    firstName: string,
    middleName: string,
    infotext: string,
    lastName: string,
    countrycode?: string,
    phone: string,
    mobile?: string,
    email: string,
    fax: string,
    position: string,
    company: string,
    industry: string,
    languages?: Array<String>,
    organization: string,
    comment: string,
    stands: Stand[],
    socialMedias: SocialMedia[],
    categories: Category[],
    exhibitors: Exhibitor[],
    eventDates: EventDate[],
    interests?: Array<String>,
    lookingfor?: Array<String>,
    offering?: Array<String>,
    facebook?: string,
    linkedIn?: string,
    googleplus?: string,
    youTube?: string,
    twitter?: string,
    xing?: string,
    pinterest?: string,
    instagram?: string,
    organizations?: Array<{ id: string, name: string }>,
    sotUserId?: string,
    person?: string,
    myConnectionStatus: string,
    type: string,
    topics?: string[],
    categoryBadges?: CategoryBadges[]
    description?: Description,
    visible: boolean,
    title: string
}
export interface News {
    id: string,
    type: "news"
    initials: string,
    logoURL: string,
    pictureURL: string,
    name: string,
    text: string,
    orderkey: string,
    attachments: Attachment[],
    organization: Company,
    links: Link[]
    categories: Category[],
    descriptionTeaser: string
    description: {
        title: string
        text: string
        teaser: string
    }
}
export interface Exhibitor {
    id: string,
    type: "organization",
    orderkey: string,
    initials: string,
    email: string,
    name: string,
    countryCode: string,
    country: string,
    city: string,
    postCode: string,
    basisPremium: BasisPremiumType,
    pictureURL: string,
    pictureText: string,
    backgroundImageURL: string
    teaser: string,
    stands: Stand[],
    contacts: Contact[],
    categories: Category[],
    products: Product[]
    news: News[]
    eventdates: EventDate[];
    order?: number
}
export interface Description {
    title: string,
    text: string,
    teaser: string
}
export interface SocialMedia {
    link: string,
    type: string
}
export interface Link {
    id: string,
    url: string,
    text: string
}
export interface Office {
    adress: string,
    city: string,
    country: string,
    postBoxPostCode: string
}
export interface Category {
    id: string,
    alias: string,
    name: string,
    nameDe: string,
    orderkey: string,
    orderkeyDe: string,
    badgeName: string,
    badgeType: string,
    code: string,
    parent: string,
    parentDe: string,
    iconSmallUrl: string,
    iconMediumUrl: string,
    iconLargeUrl: string,
    colorBooth: string,
    colorBorder: string,
    colorFill: string,
    colorText: string,
    nodes?: Category[],
    key?: string,
    label?: string
    labelDe?: string
}
export interface Product {
    id: string
    type: "product"
    productid: string
    initials: string
    name: string
    logoUrl: string
    pictureURL: string
    pictureText: string
    description: Description
    categories: Category[]
    attachments: Attachment[]
    exhibitors: Exhibitor[]
    persons: Person[]
    links: Link[]
    organizations: Company[]
    descriptionTeaser: string
}
export interface Coupon {
    id: string
    initials: string
    title: string
    teaser: string
    description: string
    pictureURL: string
    pictureText: string
    url: string
    validStart: string
    validEnd: string
    company: {
        id: string
        name: string
        pictureURL: string
    }
}
export interface Attachment {
    id: string
    url: string
    title: string
    description: string
    largeThumbnailUrl: string
    smallThumbnailUrl: string
    contentType: string
}
export interface Trademark {
    id: string
    type: "trademark"
    initials: string
    name: string
    logoUrl: string
    pictureURL: string
    pictureText: string
    description: Description
    categories: Category[]
    attachments: Attachment[]
    links: Link[]
    organizations: Company[]
    descriptionTeaser: string
}
export interface DescriptionCompany {
    description: Description
}
export interface Company {
    exhibitors: Exhibitor[];
    id: string
    alias: string
    orderkey: string
    initials: string
    name: string
    basisPremium: string
    adress1: string
    postCode: string
    postBoxCity: string
    web: string
    description: Description
    pictureURL: string
    pictureText: string
    backgroundImageURL: string
    foundingYear: string
    size: string
    industry: string
    socialMedias: SocialMedia[]
    links: Link[]
    offices: Office[]
    stands: Stand[]
    persons: Person[]
    products: Product[]
    trademarks: Trademark[]
    coupons: Coupon[]
    city: string
    country: string
    phone: string
    email: string
    news: News[]
    categories: Category[]
    attachments: Attachment[]
    eventDates: EventDate[]
    headerVideoUrl: string
    headerVideoImageUrl: string
    children: Company[]
    parent: Company
    showProducts: boolean
    showTrademarks: boolean
    iframeUrl: string
    iframeUrlDe: string
    conferenceRooms: ConferenceRoom[]
}
export interface Person {
    /**
    * @title Person id
    * @description Global identifier provided for specific person   
    * @title_de Person id
    * @description_de Global identifier provided for specific person   
    */
    id: string,
    /**
    * @title Person user id
    * @description Corresponding sot user id binded to the person   
    * @title_de Person user id
    * @description_de Corresponding sot user id binded to the person   
    */
    userId: string,
    /**
    * @title Person user logo url
    * @description Corresponding sot user logo url binded to the person   
    * @title_de Person user logo url
    * @description_de Corresponding sot user logo url binded to the person   
    */
    userLogoUrl: string,
    /**
    * @title Persons name initials
    * @description Initials provided for person   
    * @title_de Persons name initials
    * @description_de Initials provided for person   
    */
    initials: string,
    /**
    * @title Persons first name
    * @description Text for the persons first name   
    * @title_de Persons first name
    * @description_de Text for the persons first name   
    */
    firstName: string,
    /**
    * @title Persons middle name
    * @description Text for the persons middle name   
    * @title_de Persons middle name
    * @description_de Text for the persons middle name   
    */
    middleName: string,
    /**
    * @title Persons last name
    * @description Text for the persons last name   
    * @title_de Persons last name
    * @description_de Text for the persons last name   
    */
    lastName: string,
    /**
    * @title Persons email address
    * @description Text for the persons email address   
    * @title_de Persons email address
    * @description_de Text for the persons email address   
    */
    email: string,
    personFunctionIdRef: string,
    personFunctionName?: string,
    personFunctionTargetName?: string,
    /**
    * @title Persons work position
    * @description Text for the persons work position   
    * @title_de Persons work position
    * @description_de Text for the persons work position   
    */
    position: string,
    /**
    * @title Persons organization
    * @description Name of the organization that person belongs to
    * @title_de Persons organization
    * @description_de Name of the organization that person belongs to   
    */
    organization: string,
    /**
    * @title Persons logo url 
    * @description Custom logo url that person has
    * @title_de Persons logo url 
    * @description_de Custom logo url that person has  
    */
    logoUrl: string,
    /**
    * @title Persons category configurations
    * @description Configuration for persons categories
    * @title_de Persons category configurations
    * @description_de Configuration for persons categories  
    */
    categories: Category[],
    /**
    * @title Persons topic id 
    * @description Id for the topic that person belongs to 
    * @title_de Persons topic id 
    * @description_de Id for the topic that person belongs to   
    */
    topic: any,
    /**
    * @title Persons visiblity 
    * @description Enable / disable persons visibility 
    * @title_de Persons visiblity 
    * @description_de Enable / disable persons visibility   
    */
    visible: boolean,
    /**
    * @title Showroom standby option 
    * @description Enable / disable visibility for showroom standby option for the specific person 
    * @title_de Showroom standby option 
    * @description_de Enable / disable visibility for showroom standby option for the specific person   
    */
    showroomStandby: boolean,
    /**
    * @title Persons name 
    * @description Complete persons name (first name + last name) 
    * @title_de Persons name 
    * @description_de Complete persons name (first name + last name)   
    */
    name: string,
    /**
    * @title Persons title 
    * @description Text for persons title 
    * @title_de Persons title 
    * @description_de Text for persons title   
    */
    title: string
}
export interface EventDate {
    id: string,
    location: string,
    date: string,
    start: string,
    startHour: number,
    dateTimeStart: string,
    startMinutes: number,
    end: string,
    endHour: number,
    endMinutes: number,
    dateTimeEnd: string,
    name: string,
    persons: Person[],
    teaser: string,
    text: string,
    formattedDate: string,
    logoText: string
    logoUrl: string
    attachments: Attachment[]
    organizationId?: string
    organizationLogoUrl?: string
    organizationName?: string
    channel?: Channel
    liveStreamingPreviewUrl: string,
    videoOnDemandUrl: string,
    type?: string
    order?: number
    categories?: Category[]
    roundTableAccessStatus?: string
    startdatetime: string
    enddatetime: string
    links: Link[]
    breakoutAccess: boolean
    isPrivate?: boolean
}



export interface Channel {
    id: string,
    name: string,
    slidoName: string,
    url: string,
    thumbnailUrl: string
    currentEventDateTitle?: string
}
export interface Suggestion {
    id: string,
    value: string,
    title: string,
    subtitle: string,
    icon: string,
    type?: EntityType
}
export interface SuggestGroup {
    id: string,
    name: string,
    trackingName: string,
    maxSuggestions: number,
    suggestions: Suggestion[],
}

export interface Organization {
    id: string,
    name: string
    city: string,
    country: string,
    logo: string
    pictureURL: string
}
export interface NetworkingUser {
    id: string,
    firstName: string,
    lastName?: string,
    logoUrl?: string,
    loaded: boolean,
    position?: string,
    company?: string,
    speaker?: number,
    contactOfExhibitor?: number,
    showLoader?: boolean,
    rating?: number,
    organizations?: Array<Organization>,
    interests?: Array<string>
    myConnectionStatus?: string,
    person?: string
    type?: string
}
export interface InvitePerson {
    id: string,
    firstName: string,
    lastName?: string,
    logoUrl?: string,
    userId?: string,
    meetings?: CalendarEntry[]
    eventDates?: EventDate[]
}

export interface PrivacyUserAnswer {
    sotUserId: string
    questionText: string
    answerText: string
    accepted: boolean
    questionId: string
}

export interface VirtualCafeAccessUser {
    id: string
    firstName: string
    lastName: string
    logoUrl: string
    position: string
}

export interface NewsItem {
    id: string
    title: string
    text: string
    subtitle: string
    date: string
    imageurl: string
    links: { link_text: string, link_url: string }[]
}

export interface CategoryBadges {
    id: string
    badgeName: string
    badgeType: string
    colorBorder: string
    colorBooth: string
    colorText: string
    colorFill: string
}

export interface SotUser {
    /**
    * @title Series of topics user id
    * @description Global identifier provided for specific series of topics user   
    * @title_de Series of topics user id
    * @description_de Global identifier provided for specific series of topics user   
    */
    id: string
    /**
    * @title Series of topics userid
    * @description User identifier provided for specific series of topics user   
    * @title_de Series of topics userid
    * @description_de User identifier provided for specific series of topics user   
    */
    userId: string
    /**
    * @title Series of topics user name initials
    * @description Initials provided for sot user   
    * @title_de Series of topics user name initials
    * @description_de Initials provided for sot user   
    */
    initials: string
    /**
    * @title Series of topics user first name
    * @description Text for the first name provided for sot user   
    * @title_de Series of topics user first name
    * @description_de Text for the first name provided for sot user   
    */
    firstName: string
    /**
    * @title Series of topics user last name
    * @description Text for the last name provided for sot user   
    * @title_de Series of topics user last name
    * @description_de Text for the last name provided for sot user   
    */
    lastName: string
    /**
    * @title Series of topics user position
    * @description Text for position provided for sot user   
    * @title_de Series of topics user position
    * @description_de Text for position provided for sot user   
    */
    position: string
    /**
    * @title Series of topics user organization
    * @description Name of the organization that sot user belongs to
    * @title_de Series of topics user organization
    * @description_de Name of the organization that sot user belongs to   
    */
    organization: string
    /**
    * @title Series of topics user logo url 
    * @description Custom logo url that sot user has
    * @title_de Series of topics user logo url 
    * @description_de Custom logo url that sot user has  
    */
    logoUrl: string
    /**
    * @title Series of topics user description teaser
    * @description Text for sot user description teaser
    * @title_de Series of topics user description teaser
    * @description_de Text for sot user description teaser  
    */
    descriptionTeaser: string
}

export interface ConferenceRoom {
    id: string
    title: string
    titleDe: string
    backgroundImage: string
    teaser: string
    teaserDe: string
    sortKey: string
    organization: string
    accessType: UserConferenceRoomAccessType
}

export enum UserConferenceRoomAccessType {
    PUBLIC = "PUBLIC",
    ORGA_MANAGED = "ORGA_MANAGED"
}

export enum ShareTargetType {
    ORGANIZATION = "ORGANIZATION",
    PERSON = "PERSON",
    SOTUSER = "SOTUSER",
    EVENTDATE = "EVENTDATE"
}

export type EntityType = "category" | "eventdate" | "person" | "organization" | "product" | "trademark" | "news" | "networking_user" | "sotuser"

export type ModalType = 'none' | 'connect' | 'meeting' | 'call' | 'share' | 'report' | 'guest'