import React, { useState } from "react"
import { useLanguageState } from "../globalStates/LanguageState"
import { KickOrBanMode } from "../conference/components/Roster"
import { ModalRoot, TextareaRoot, SubmitButtonContainer, SubmitButtonNew, DisagreeButton } from "./SayHelloModal"
import { IconRemoveFromCall } from "./Icons"
import branding from "../branding/branding"

interface KickUserModalProps {
    kickOrBan: { mode: KickOrBanMode, attendeeId?: string, userName?: string }
    setKickOrBan: (value: { mode: KickOrBanMode, attendeeId?: string, userName?: string }) => void
    chime: any
}


interface MessageContentProps {
    message?: string,
    setMessage(e: string): void
}


const MessageContent = (props: MessageContentProps) => {
    const strings = useLanguageState().getStrings()

    return (
        <TextareaRoot className="form-group">
            <textarea
                value={props.message}
                name="modalInputMessage"
                onChange={(e) => props.setMessage(e.target.value)}
                className="form-control"
                placeholder={strings.conferenceTexts.kickBanConfirmationBody}></textarea>
        </TextareaRoot>
    )
}


const KickUserModal: React.FunctionComponent<KickUserModalProps> = (props: KickUserModalProps) => {

    const strings = useLanguageState().getStrings()

    const [message, setMessage] = useState<string>("")

    function handleClose() {
        props.setKickOrBan({ mode: KickOrBanMode.NONE })
    }

    const handleKickOrBan = async () => {
        if (props.kickOrBan.mode === KickOrBanMode.KICK) {
            props.chime.modKick(props.kickOrBan.attendeeId!, message)
        } else {
            props.chime.modBan(props.kickOrBan.attendeeId!, message)
        }
        props.setKickOrBan({ mode: KickOrBanMode.NONE })
    }

    return <ModalRoot backdrop="static" show={true} animation={false}>
        <div onClick={e => e.stopPropagation()}>
            <div className="modal-header">
                <span className="headerImage">
                    {IconRemoveFromCall({ fill: branding.mainInfoColor })}
                </span>
                <h3 className="modal-title">
                    {(props.kickOrBan.mode === KickOrBanMode.KICK ? strings.conferenceTexts.kickConfirmationHeader : strings.conferenceTexts.banConfirmationHeader) + props.kickOrBan.userName}
                </h3>
                <button type="button" className="close" onClick={() => handleClose()} aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div className="modal-body">
                <MessageContent setMessage={setMessage} message={message}></MessageContent>
            </div>
            <div className="modal-footer">
                <SubmitButtonContainer>
                    <DisagreeButton onClick={() => handleClose()} className="d-flex align-items-center">
                        {strings.globalTexts.cancel}
                    </DisagreeButton>
                    <SubmitButtonNew type="submit" onClick={() => handleKickOrBan()} className="d-flex align-items-center justify-content-center">
                        {props.kickOrBan.mode === KickOrBanMode.KICK ? strings.conferenceTexts.kick : strings.conferenceTexts.ban}
                    </SubmitButtonNew>
                </SubmitButtonContainer>
            </div>
        </div>
    </ModalRoot >
}

export default KickUserModal