import React, { useRef, useEffect, useState } from "react"
import styled, { css } from "styled-components"
import { Dropdown, Row, Col } from "react-bootstrap"
import { detect, Browser, OperatingSystem } from "detect-browser"
import { useLanguageState } from "../globalStates/LanguageState"
import SuccessCheckmark from "../ui/SuccessCheckmark"
import { useHistory } from "react-router-dom"
import { defaultLogger as logger } from "../globalStates/AppState"
import branding from "../branding/branding"
import { isAndroid, isIOS } from "react-device-detect"

enum StepResult {
    PERMISSION_REQUIRED,
    PERMISSION_ALLOWED,
    PERMISSION_DENIED,
    PERMISSION_NOT_SUPPORTED,
    SUCCESS,
    ERROR,
    PENDING,
    PENDING_USER,
    USER_SKIPPED,
    NETWORK_TO_SLOW
}

type StepBrowserProps = {
    index: number;
    result: StepResult;
    errorState?: ErrorState | null;
};

const ErrorStatusMessage = styled.div`
    text-align: left;
`

const StepJavascript: React.FunctionComponent<StepBrowserProps> = (props) => {
    const strings = useLanguageState().getStrings();

    return (
        <>
            {(props.result === StepResult.ERROR) && (
                <ErrorStatusMessage>
                    {strings.systemCheck.stepJavascriptError}
                </ErrorStatusMessage>
            )}
        </>
    );
};
const StepBrowser: React.FunctionComponent<StepBrowserProps> = (props) => {
    const strings = useLanguageState().getStrings()

    return (
        <>
            {(props.result === StepResult.ERROR) && (
                <ErrorStatusMessage>
                    {strings.systemCheck.stepBrowserError}
                </ErrorStatusMessage>
            )}
        </>
    );
};

const StepLocalStorage: React.FunctionComponent<StepBrowserProps> = (props) => {
    const strings = useLanguageState().getStrings()

    return (
        <>
            {(props.result === StepResult.ERROR) && (
                <ErrorStatusMessage>
                    {strings.systemCheck.stepLocalStorageError}
                </ErrorStatusMessage>
            )}
        </>
    );
};

type StepHardwareProps = {
    index: number;
    result: StepResult;
    devices?: StepItemDevice[] | null;
    errorState?: ErrorState | null;
    onRetry: () => void;
    onSkip: () => void;
    onContinue: () => void;
    onPermissionContinue: () => void;
    onManualAudioRecheckClick?: (type?: string) => void;
};

const StyledDropdownToggle = styled(Dropdown.Toggle)`
    border: 1px solid #000;
    border-radius: 5px;
    text-align: left;
    margin-bottom: 10px;
    background-color: #fff;
    outline: none;
    width: 290px;
    height: 35px;
    text-overflow: ellipsis;

    &:focus {
        outline:0 !important;
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
    }
`

const StyledDropdownItem = styled(Dropdown.Item)`

    width: 290px;
    height: 35px;
    text-overflow: ellipsis;
`

const MainButton = styled.button<{ backgroundColor?: string, textColor?: string }>`
    flex: 0 0 auto;
    margin-top: 10px;
    height: 35px;
    margin-bottom: 10px;
    border: 1px solid ${branding.loginRegistrationSite.mobileLoginButtonColor};
    border-radius: 5px;
    background-color: ${(props) => props.backgroundColor ?? branding.loginRegistrationSite.mobileLoginButtonColor};;
    color: ${(props) => props.textColor ?? "#fff"};
    transition: 0.5s;
    cursor: pointer;
    width: 290px;
    outline: none;

    &:focus { outline: none; }

    ${props => props.disabled
        ? css`
            opacity: 0.5;
            transition-property: none;
        `
        : css`
            &:hover { opacity: 0.7; }
        `
    };
`

const StepCamera: React.FunctionComponent<StepHardwareProps> = (props) => {
    const [activeDevice, setActiveDevice] = useState<StepItemDevice | null>(null);
    const videoCameraRef = useRef<HTMLVideoElement>(null);
    const strings = useLanguageState().getStrings();
    const onRetryClick = () => props.onRetry();
    const onSkipClick = () => props.onSkip();
    const onContinueClick = () => props.onContinue();
    const onPermissionContinueClick = () => props.onPermissionContinue();
    const onDeviceChange = (device: StepItemDevice) => setActiveDevice(device);

    const [videoVisible, setVideoVisible] = useState<boolean>(false)

    useEffect(() => {

        let mediaStreamSource: MediaStream | null = null;
        let copy = videoCameraRef.current;

        if (activeDevice === null) {
            const selectedDevice = props.devices?.find((x) => x.selected) || null;
            if (selectedDevice !== null) {
                setActiveDevice(selectedDevice);
            }
        }

        if (props.result === StepResult.PERMISSION_ALLOWED) {
            if (activeDevice !== null) {
                navigator.mediaDevices
                    .getUserMedia({
                        video: {
                            deviceId: activeDevice.deviceId,
                        },
                    })
                    .then((stream) => {
                        mediaStreamSource = stream;
                        if (copy !== null) {
                            copy.srcObject = mediaStreamSource;
                            copy.play().then(() => { }).catch(() => { /* ignore render error */ });
                        }
                    })
                    .catch((error) => logger.error({ message: "SystemCheckWizard Error during video preview", errorMessage: error.message, errorStack: error.stack }));
            }
        }
        return () => {
            if (copy !== null) {
                copy.pause();
                copy.srcObject = null;
            }
            mediaStreamSource?.getTracks().forEach((a) => a.stop());
        };
    }, [props.result, props.devices, activeDevice, videoVisible]);

    return (
        <>
            {props.result === StepResult.PERMISSION_REQUIRED && (
                <>
                    <p className="status">{strings.systemCheck.devicePermissionAsk.replace("#DEVICE#", strings.systemCheck.camera)}</p>
                    <MainButton onClick={onPermissionContinueClick}>
                        {strings.systemCheck.continue}
                    </MainButton>
                </>
            )}
            {props.result === StepResult.PERMISSION_ALLOWED && (
                <>

                    <Dropdown className="full-w" >
                        <StyledDropdownToggle variant="default" className="text-truncate">
                            {activeDevice?.label}
                        </StyledDropdownToggle>
                        <Dropdown.Menu>
                            {props.devices?.map((device) => (
                                <StyledDropdownItem className="text-truncate" key={device.deviceId} onSelect={() => onDeviceChange(device)}>
                                    {device.label}
                                </StyledDropdownItem>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                    {
                        !videoVisible &&
                        <MainButton onClick={() => setVideoVisible(true)}>
                            {strings.systemCheck.startCameraTest}
                        </MainButton>
                    }

                    {
                        videoVisible &&
                        <>
                            <video ref={videoCameraRef} id="videoCamera" className="mx-auto my-4" width="290px" style={{ borderRadius: "5px", marginTop: "-10px", marginBottom: "-10px" }} />
                            <MainButton onClick={onContinueClick}>
                                {strings.systemCheck.continueToMicrophone}
                            </MainButton>
                        </>
                    }
                </>
            )}
            {(props.result === StepResult.PERMISSION_DENIED || props.result === StepResult.ERROR) && (
                <>
                    {strings.systemCheck.stepCameraError}

                    <MainButton onClick={onRetryClick}>
                        {strings.systemCheck.retry}
                    </MainButton>
                    <MainButton onClick={onSkipClick}>
                        {strings.systemCheck.skipAndContinue}
                    </MainButton>
                </>
            )}
        </>
    );
};

const StepMicrophone: React.FunctionComponent<StepHardwareProps> = (props) => {
    const [activeDevice, setActiveDevice] = useState<StepItemDevice | null>(null);
    const pidsWrapperRef = useRef<HTMLDivElement>(null);
    const soundOutputRef = useRef<HTMLVideoElement>(null);
    const strings = useLanguageState().getStrings();
    const onRetryClick = () => props.onRetry();
    const onSkipClick = () => props.onSkip();
    const onContinueClick = () => props.onContinue();
    const onPermissionContinueClick = () => props.onPermissionContinue();
    const onMicrophoneChange = (device: StepItemDevice) => {
        setActiveDevice(device);
    };

    useEffect(() => {
        // don't close audioContext or you will need to restart browser tab
        const AudioContext = (window as any).AudioContext || (window as any).webkitAudioContext;
        let audioContext: AudioContext = new AudioContext();
        let analyser: AnalyserNode | null = null;
        let mediaStreamAudioSource: MediaStreamAudioSourceNode | null = null;
        let animateId = 0;
        let copy = soundOutputRef.current;

        if (activeDevice === null) {
            const selectedDevice = props.devices?.find((x) => x.selected) || null;
            if (selectedDevice !== null) {
                setActiveDevice(selectedDevice);
            }
        }

        if (props.result === StepResult.PERMISSION_ALLOWED) {
            if (activeDevice !== null) {
                navigator.mediaDevices
                    .getUserMedia({
                        audio: {
                            deviceId: activeDevice.deviceId,
                            echoCancellation: true,
                            noiseSuppression: true,
                            autoGainControl: true,
                        },
                    })
                    .then((stream) => {
                        mediaStreamAudioSource = audioContext.createMediaStreamSource(stream);

                        analyser = audioContext.createAnalyser();
                        analyser.fftSize = 1024;

                        // connect audio device to analyser
                        mediaStreamAudioSource.connect(analyser);

                        // stream ouput into hidden video control
                        if (copy !== null) {
                            copy.srcObject = mediaStreamAudioSource.mediaStream;
                            copy.play().then(() => { }).catch(() => { /* ignore render error */ });
                        }

                        // request animation and create volume for pids
                        function doAnimate() {
                            animateId = requestAnimationFrame(doAnimate);

                            if (analyser !== null) {
                                let sum = 0;

                                // create buffer
                                let buffer = new Uint8Array(analyser.frequencyBinCount);

                                // populate buffer
                                analyser.getByteFrequencyData(buffer);

                                // get RMS
                                buffer.forEach((x) => (sum += Math.abs(x)));

                                // calculate aprox volume
                                const volume = Math.round(sum / buffer.length);

                                // create pids
                                if (pidsWrapperRef.current !== null) {
                                    const children = Array.from(pidsWrapperRef.current.children);
                                    const total = Math.round((volume / 100) * children.length);
                                    const pidsWithSound = children.slice(0, total);

                                    children.forEach((x) => {
                                        (x as HTMLDivElement).style.backgroundColor = "#808080";
                                    });

                                    pidsWithSound.forEach((x) => {
                                        (x as HTMLDivElement).style.backgroundColor = "#00B300";
                                    });
                                }
                            }
                        }

                        doAnimate();
                    })
                    .catch((error) => logger.error({ message: "SystemCheckWizard Error during microphone check", errorMessage: error.message, errorStack: error.stack }));
            }
        }
        return () => {
            // cancel animation
            cancelAnimationFrame(animateId);
            // pause microphone and remove stream
            if (copy !== null) {
                copy.pause();
                copy.srcObject = null;
            }
            // disconnect all tracks and disconnect stream
            mediaStreamAudioSource?.mediaStream.getTracks().forEach((a) => a.stop());
            mediaStreamAudioSource?.disconnect();
        };
    }, [props.result, props.devices, activeDevice]);

    return (
        <>
            {props.result === StepResult.PERMISSION_REQUIRED && (
                <>
                    <p className="status">{strings.systemCheck.devicePermissionAsk.replace("#DEVICE#", strings.systemCheck.microphone)}</p>
                    <MainButton onClick={onPermissionContinueClick}>
                        {strings.systemCheck.continue}
                    </MainButton>
                </>
            )}
            {props.result === StepResult.PERMISSION_ALLOWED && (
                <>
                    <Dropdown className="full-w">
                        <StyledDropdownToggle variant="default" className="text-truncate">
                            {activeDevice?.label}
                        </StyledDropdownToggle>
                        <Dropdown.Menu>
                            {props.devices?.map((device) => (
                                <StyledDropdownItem className="text-truncate" key={device.deviceId} onSelect={() => onMicrophoneChange(device)}>
                                    {device.label}
                                </StyledDropdownItem>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                    <MainButton onClick={onContinueClick}>
                        {strings.systemCheck.grantMicAccess}
                    </MainButton>
                </>
            )}
            {(props.result === StepResult.PERMISSION_DENIED || props.result === StepResult.ERROR) && (
                <>
                    <ErrorStatusMessage>
                        {strings.systemCheck.stepMicrophoneError}
                    </ErrorStatusMessage>
                    <MainButton onClick={onRetryClick}>
                        {strings.systemCheck.retry}
                    </MainButton>
                    <MainButton onClick={onSkipClick}>
                        {strings.systemCheck.skipAndContinue}
                    </MainButton>
                </>
            )}
        </>
    );
};

const StepAudio: React.FunctionComponent<StepHardwareProps> = (props) => {
    const [activeDevice, setActiveDevice] = useState<StepItemDevice | null>(null);
    const inputAudioRef = useRef<HTMLInputElement>(null);
    const soundOutputRef = useRef<HTMLVideoElement>(null);
    const testSoundButtonRef = useRef<HTMLButtonElement>(null);
    const strings = useLanguageState().getStrings();
    const onRetryClick = () => props.onRetry();
    const onSkipClick = () => props.onSkip();
    const onContinueClick = () => props.onContinue();
    //const onManualAudioRecheckClick = () => props.onManualAudioRecheckClick!!("audio");
    const onAudioChange = (device: StepItemDevice) => setActiveDevice(device);

    const browserCheckResult = detect()
    const isFirefox = browserCheckResult && browserCheckResult.type === "browser" && browserCheckResult.name === "firefox"
    const isSafari = browserCheckResult && browserCheckResult.type === "browser" && browserCheckResult.name === "safari"


    const onTestSoundClick = () => {
        if (soundOutputRef.current !== null) {
            testSoundButtonRef.current?.setAttribute("disabled", "disabled");
            soundOutputRef.current.play();
            soundOutputRef.current.addEventListener("ended", () => {
                testSoundButtonRef.current?.removeAttribute("disabled");
            });
        }
    };

    /*
    const onRangeChange = (value: string) => {
        if (soundOutputRef.current !== null) {
            soundOutputRef.current.volume = parseInt(value, 10) / 100;
        }
    };
    */

    useEffect(() => {

        if (activeDevice === null) {
            const selectedDevice = props.devices?.find((x) => x.selected) || null;
            if (selectedDevice !== null) {
                setActiveDevice(selectedDevice);
            }
        }

        if (props.result === StepResult.PERMISSION_ALLOWED) {
            if (activeDevice !== null && soundOutputRef.current !== null) {
                if (browserCheckResult?.os !== "Android OS") { // On Android this does not exist.
                    (soundOutputRef.current as any).setSinkId(activeDevice.deviceId);
                }
                if (inputAudioRef.current !== null) {
                    const volume = parseInt(inputAudioRef.current.value, 10);
                    soundOutputRef.current.volume = volume / 100;
                }
            }
        }
    }, [props.result, props.devices, activeDevice, browserCheckResult]);

    return (
        <>
            {props.result === StepResult.PENDING && <p className="status">{strings.systemCheck.stepSpeakerPending}</p>}
            {(props.result === StepResult.PERMISSION_NOT_SUPPORTED || props.result === StepResult.PERMISSION_DENIED) && (
                <>
                    <video controls={true} style={{ height: "100px", width: "450px" }} className="mx-auto mb-0 d-none" ref={soundOutputRef} >
                        <source src="/ringing.caf" type="audio/x-caf" />
                        <source src="/ringing.opus" type="audio/ogg" />
                    </video>
                    {
                        ((!isFirefox && !isSafari) && (props.devices && activeDevice)) &&
                        <Dropdown className="full-w">
                            <StyledDropdownToggle variant="default" className="text-truncate">
                                {activeDevice?.label}
                            </StyledDropdownToggle>
                            <Dropdown.Menu>
                                {props.devices?.map((device) => (
                                    <StyledDropdownItem className="text-truncate" key={device.deviceId} onSelect={() => onAudioChange(device)}>
                                        {device.label}
                                    </StyledDropdownItem>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    }
                    <Row>
                        <Col md={12}>
                            <MainButton backgroundColor="#fff" textColor={branding.loginRegistrationSite.mobileLoginButtonColor} onClick={onTestSoundClick}>
                                {strings.systemCheck.testSound}
                            </MainButton>
                        </Col>
                        <Col md={12}>
                            <MainButton onClick={onContinueClick}>
                                {strings.systemCheck.continue}
                            </MainButton>
                        </Col>
                    </Row>
                </>
            )}
            {props.result === StepResult.PENDING_USER && (
                <>
                    <Row>
                        <Col md={12}>
                            <MainButton onClick={onRetryClick}>
                                {strings.systemCheck.retry}
                            </MainButton>
                        </Col>
                        <Col md={12}>
                            <MainButton onClick={onSkipClick}>
                                {strings.systemCheck.skipAndContinue}
                            </MainButton>
                        </Col>
                    </Row>
                </>
            )}
            {props.result === StepResult.PERMISSION_REQUIRED &&
                <>
                    <p className="status">{strings.systemCheck.devicePermissionAsk.replace("#DEVICE#", strings.systemCheck.speaker)}</p>
                    <Row>
                        <Col md={12}>
                            <MainButton onClick={onRetryClick}>
                                {strings.systemCheck.retry}
                            </MainButton>
                        </Col>
                        <Col md={12}>
                            <MainButton onClick={onSkipClick}>
                                {strings.systemCheck.skipAndContinue}
                            </MainButton>
                        </Col>
                    </Row>
                </>
            }
            {props.result === StepResult.PERMISSION_ALLOWED && (
                <>
                    <video controls={true} style={{ height: "100px", width: "450px" }} className="mx-auto mb-0 d-none" ref={soundOutputRef} >
                        <source src="/ringing.caf" type="audio/x-caf" />
                        <source src="/ringing.opus" type="audio/ogg" />
                    </video>
                    {
                        ((!isFirefox && !isSafari) && (props.devices && activeDevice)) &&
                        <Dropdown className="full-w">
                            <StyledDropdownToggle variant="default" className="text-truncate">
                                {activeDevice?.label}
                            </StyledDropdownToggle>
                            <Dropdown.Menu>
                                {props.devices?.map((device) => (
                                    <StyledDropdownItem className="text-truncate" key={device.deviceId} onSelect={() => onAudioChange(device)}>
                                        {device.label}
                                    </StyledDropdownItem>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    }
                    <Row>
                        <Col md={12}>
                            <MainButton backgroundColor="#fff" textColor={branding.loginRegistrationSite.mobileLoginButtonColor} onClick={onTestSoundClick}>
                                {strings.systemCheck.testSound}
                            </MainButton>
                        </Col>
                        <Col md={12}>
                            <MainButton onClick={onContinueClick}>
                                {strings.systemCheck.continue}
                            </MainButton>
                        </Col>
                    </Row>
                </>
            )}
            {props.result === StepResult.ERROR && (
                <>
                    <ErrorStatusMessage>
                        {strings.systemCheck.stepSpeakerError}
                    </ErrorStatusMessage>
                    <MainButton onClick={onRetryClick}>
                        {strings.systemCheck.retry}
                    </MainButton>
                </>
            )}
        </>
    );
};

type Download = {
    startTime: number
    timeSinceLastEvent: number
    bytesSinceLastEvent: number
    previousBytesComplete: number
    averageSpeed: number
    currentSpeed: number
    movingSpeedBits: number
    movingSpeedMegaBytes: number
    sizeDownloaded: number
    speedDataPoints: number[]
}

type StepDownloadProps = {
    index: number;
    result: StepResult;
    onRetry: (type: string) => void;
    onSkip: () => void;
    onContinue: () => void;
    onComplete: () => void;
};

const StepDownload: React.FunctionComponent<StepDownloadProps> = (props) => {
    const onRetryClick = () => props.onRetry("download");
    const onSkipClick = () => props.onSkip();
    const onContinueClick = () => props.onContinue();
    const strings = useLanguageState().getStrings();

    const [downloadSpeed, setDownloadSpeed] = useState(0);
    let download: Download = {
        startTime: 0,
        timeSinceLastEvent: 0,
        bytesSinceLastEvent: 0,
        previousBytesComplete: 0,
        averageSpeed: 0,
        currentSpeed: 0,
        movingSpeedBits: 0,
        movingSpeedMegaBytes: 0,
        sizeDownloaded: 0,
        speedDataPoints: []
    }

    const testFinished = () => {
        setDownloadSpeed(download.movingSpeedMegaBytes)

        if (download.movingSpeedMegaBytes < 1.5) {
            props.onComplete();
        } else {
            props.onContinue();
        }
    }

    const makeDownload = () => {
        download.startTime = Date.now()
        download.timeSinceLastEvent = Date.now()

        downloadProgressEventCallback(0)

        var xhr = new XMLHttpRequest();

        xhr.onprogress = (e) => {
            downloadProgressEventCallback(e.loaded)
        }

        xhr.onload = (e) => {
            downloadProgressEventCallback(e.total)
            testFinished()
        }

        xhr.onabort = (e) => {
            downloadProgressEventCallback(e.loaded)
            testFinished()
        }

        let url = `https://s3-eu-west-1.amazonaws.com/public.corussoft.de/networkspeedtest.bin`

        xhr.open("GET", url, true);
        xhr.setRequestHeader('Cache-Control', 'no-cache'); // don't cache file
        xhr.send();

        //downloading large file in duration of 6 seconds and after that calculating network speed
        setTimeout(() => {
            xhr.abort()
        }, 6000);
    }

    const downloadProgressEventCallback = (bytesComplete: number) => {
        const now = Date.now()
        const bytesSinceLastEvent = bytesComplete - download.previousBytesComplete
        const timeSinceLastEvent = (now - download.timeSinceLastEvent) / 1000
        const elapsedTime = (now - download.startTime) / 1000
        const currentSpeed = (bytesSinceLastEvent * 8) / timeSinceLastEvent

        if (currentSpeed !== Infinity && !isNaN(currentSpeed))
            download.speedDataPoints.push(currentSpeed)

        download.bytesSinceLastEvent = bytesSinceLastEvent
        download.previousBytesComplete = bytesComplete
        download.timeSinceLastEvent = now
        download.averageSpeed = (bytesComplete * 8) / elapsedTime // Bits per seconds
        download.currentSpeed = currentSpeed // Bits per seconds
        download.movingSpeedBits = calculateMovingAverage(download.speedDataPoints) // Bits per seconds
        download.movingSpeedMegaBytes = download.movingSpeedBits / 8 / 1024 / 1024 // MBps
    }

    //calculating the moving average every time when some progress is done
    const calculateMovingAverage = (history: number[]): number => {
        let vals = history
        const size = history.length
        const sum = vals && vals.length > 0 ? vals.reduce((a, b) => a + b) : 0
        let mSum = 0, mCount = 0

        if (size >= 8) {
            const mean = sum / size
            const varianceTemp = vals.reduce((total, currentValue) => Math.pow((currentValue - mean), 2) + total)
            const variance = varianceTemp / size
            const standardDev = Math.sqrt(variance)
            vals = vals.map((x) => { return (x - mean) / standardDev })

            const deviationRange = 2
            for (let i = 0; i < size; i++) {
                if ((vals[i] <= deviationRange) && (vals[i] >= -deviationRange)) {
                    mCount++
                    mSum += history[i]
                }
            }

        } else {
            mCount = size
            mSum = history && history.length > 0 ? history.reduce((a, b) => a + b) : 0
        }

        return mSum / mCount
    }

    // Note: the empty deps array [] means
    // this useEffect will run once
    // similar to componentDidMount()
    useEffect(() => {
        makeDownload();
        // eslint-disable-next-line
    }, []);

    // Should work??
    useEffect(() => {
        if (props.result === StepResult.PENDING_USER) {
            makeDownload();
        }
        // eslint-disable-next-line
    }, [props.result])

    return (
        <>
            {
                (props.result === StepResult.PENDING || props.result === StepResult.PENDING_USER) &&
                <p className="status">{strings.systemCheck.stepDownloadPending}</p>}

            {
                props.result === StepResult.SUCCESS && (
                    <>
                        <h1 className="download-test">
                            {downloadSpeed.toFixed(2)}
                            <span>MBps</span>
                        </h1>
                        <MainButton onClick={onContinueClick}>
                            {strings.systemCheck.showResult}
                        </MainButton>
                    </>
                )}
            {props.result === StepResult.NETWORK_TO_SLOW && (
                <>
                    <h1 className="download-test">
                        {downloadSpeed.toFixed(2)}
                        <span>MBps</span>
                    </h1>
                    <p className="status text-error">
                        {strings.systemCheck.stepDownloadError}
                    </p>
                    <Row>
                        <Col md={12}>
                            <MainButton onClick={onRetryClick}>
                                {strings.systemCheck.retry}
                            </MainButton>
                        </Col>
                        <Col md={12}>
                            <MainButton onClick={onSkipClick}>
                                {strings.systemCheck.skipAndContinue}
                            </MainButton>
                        </Col>
                    </Row>
                </>
            )}
            <br />
        </>
    );
};

const StepCompletedMessage: React.FunctionComponent = () => {
    const history = useHistory();
    const strings = useLanguageState().getStrings();
    const onBuyTicketClick = () => window.open(strings.ticketSale.ticketsUrl, "_blank");
    const onBackToHomeClick = () => {
        // reset steps when you go from login to check, complete and return to check
        localSteps.forEach((x, i) => {
            x.result = StepResult.PENDING;
            x.active = i === 0;
        });
        history.push("/");
    };

    return (
        <>
            {!localSteps.some((x) => x.result === StepResult.USER_SKIPPED && x.index < 7) && (
                <>
                    <div style={{ height: "300px", display: "flex", justifyContent: "center", width: "97%", marginTop: "40px", marginBottom: "30px" }}>
                        <SuccessCheckmark color="#00B300" backgroundColor="#fff" size={250} />
                    </div>
                    <Title>
                        {strings.systemCheck.done}
                    </Title>
                </>
            )}
            {localSteps.some((x) => x.result === StepResult.USER_SKIPPED && x.index < 7) && (
                <>
                    <div style={{ height: "250px", display: "flex", justifyContent: "center", width: "100%", marginTop: "30px", marginBottom: "20px" }}>
                        <WarningIcon size={200} />
                    </div>
                    <Subtitle>
                        {strings.systemCheck.skippedTestResult}
                    </Subtitle>

                </>
            )}
            <Row>
                <Col md={12}>
                    <MainButton backgroundColor="#fff" textColor={branding.loginRegistrationSite.mobileLoginButtonColor} onClick={onBackToHomeClick}>
                        {strings.systemCheck.stepSystemBackToHome}
                    </MainButton>
                </Col>
                {branding.ticketSale.getYourTicketVisible && <Col md={12}>
                    <MainButton onClick={onBuyTicketClick}>
                        {strings.systemCheck.buyTicket}
                    </MainButton>
                </Col>}
            </Row>
        </>
    );
};

interface stepProps {
    currentStep: StepItem;
    contiuneFunction?: () => void;
    skipFunction?: () => void;
    retryFunction?: (type?: string) => void;
    onCompleteFunction?: () => void;
}

const WizardStepsPanels: React.FC<stepProps> = (props: stepProps) => {
    switch (props.currentStep.index) {
        case 0:
            return <StepJavascript index={props.currentStep.index} result={props.currentStep.result} />;
        case 1:
            return <StepBrowser index={props.currentStep.index} result={props.currentStep.result} errorState={props.currentStep.errorState} />;
        case 2:
            return <StepLocalStorage index={props.currentStep.index} result={props.currentStep.result} errorState={props.currentStep.errorState} />;
        case 3:
            return (
                <StepCamera
                    index={props.currentStep.index}
                    result={props.currentStep.result}
                    devices={props.currentStep.devices}
                    errorState={props.currentStep.errorState}
                    onRetry={props.retryFunction!!}
                    onSkip={props.skipFunction!!}
                    onContinue={props.contiuneFunction!!}
                    onPermissionContinue={props.retryFunction!!}
                />
            );
        case 4:
            return (
                <StepMicrophone
                    index={props.currentStep.index}
                    result={props.currentStep.result}
                    devices={props.currentStep.devices}
                    errorState={props.currentStep.errorState}
                    onRetry={props.retryFunction!!}
                    onSkip={props.skipFunction!!}
                    onContinue={props.contiuneFunction!!}
                    onPermissionContinue={props.retryFunction!!}
                />
            );
        case 5:
            return (
                <StepAudio
                    index={props.currentStep.index}
                    result={props.currentStep.result}
                    devices={props.currentStep.devices}
                    errorState={props.currentStep.errorState}
                    onRetry={props.retryFunction!!}
                    onSkip={props.skipFunction!!}
                    onContinue={props.contiuneFunction!!}
                    onPermissionContinue={props.retryFunction!!}
                    onManualAudioRecheckClick={props.retryFunction!!}
                />
            );
        case 6:
            return (
                <StepDownload
                    index={props.currentStep.index}
                    result={props.currentStep.result}
                    onSkip={props.skipFunction!!}
                    onRetry={props.retryFunction!!}
                    onContinue={props.contiuneFunction!!}
                    onComplete={props.onCompleteFunction!!}
                />
            );
        case 7:
            return <StepCompletedMessage />;
    }
    return <></>;
}

declare type DeviceTypeName = "microphone" | "speaker" | "camera" | "system";
declare type ErrorType = "browserPermission" | "osLevelPermission" | "missingHardware";

type ErrorState = {
    type: ErrorType;
    os: OperatingSystem;
    browser: Browser;
    deviceTypeName: DeviceTypeName;
};

interface StepItemDevice {
    deviceId: string;
    label: string;
    selected: boolean;
}

interface StepItem {
    index: number;
    active: boolean;
    result: StepResult;
    devices?: StepItemDevice[] | null;
    errorState?: ErrorState | null;
}

const localSteps: StepItem[] = [
    {
        index: 0,
        active: true,
        result: StepResult.PENDING,
    },
    {
        index: 1,
        active: false,
        result: StepResult.PENDING,
    },
    {
        index: 2,
        active: false,
        result: StepResult.PENDING,
    },
    {
        index: 3,
        active: false,
        result: StepResult.PENDING,
        devices: [],
    },
    {
        index: 4,
        active: false,
        result: StepResult.PENDING,
        devices: [],
    },
    {
        index: 5,
        active: false,
        result: StepResult.PENDING,
        devices: [],
    },
    {
        index: 6,
        active: false,
        result: StepResult.PENDING,
    },
    {
        index: 7,
        active: false,
        result: StepResult.PENDING,
    },
];

const Circle = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: solid 1px #000;
  background-color: #fff;
  color: #000;
  text-align: center;
  line-height: 25px;
  position: relative;
`;

interface CheckPointProps {
    name: string;
    result: StepResult;
    index: number
    isCurrentStep: boolean
}

const CheckPointRoot = styled.div`
  display: flex;
  text-align: left;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const CheckPointLabel = styled.div`
  margin-left: 20px;
  padding-top: 0.15rem;
`;

const FailureIconRoot = styled.svg`
  stroke: hsl(0, 90%, 50%);
  position: relative;
`;

const FailureIcon: React.FC = (props) => {
    return (
        <FailureIconRoot width="29" height="29" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <ellipse ry="9" rx="9" id="svg_15" cy="10" cx="10" fillOpacity="1" strokeWidth="1" stroke="#ff5656" fill="#000000" />
            <line id="svg_17" y2="14.32414" x2="14.37298" y1="5.64384" x1="5.69269" fillOpacity="null" strokeWidth="1.5" stroke="#ffffff" fill="none" />
            <line transform="rotate(90, 10.0328, 9.984)" id="svg_19" y2="14.32414" x2="14.37298" y1="5.64384" x1="5.69269" fillOpacity="null" strokeWidth="1.5" stroke="#ffffff" fill="none" />
        </FailureIconRoot>
    );
};

const WarningIconRoot = styled.svg<WarningIconProps>`
  stroke: hsl(0, 90%, 50%);
  position: relative;
  width: ${props => props.size ?? "27"}px;
`;
interface WarningIconProps {
    size?: number
}

const WarningIcon: React.FC<WarningIconProps> = (props) => {
    return (
        <WarningIconRoot {...props} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path id="svg_14" d="m1.15587,17.52957l8.84935,-15.36423l8.84935,15.36423l-17.69869,0z" fillOpacity="null" strokeOpacity="null" strokeWidth="1.5" stroke="#FFF093" fill="#FFF093" />
            <text transform="matrix(0.639255, 0, 0, 0.601184, -24.7637, -51.7539)" textAnchor="start" fontFamily="'Times New Roman', Times, serif" fontSize="24" id="svg_8" y="113.03489" x="50.49311" strokeWidth="0" stroke="#FFF093" fill="#000000">!</text>
        </WarningIconRoot>
    );
}

const Title = styled.div`
    font-size: 24px;
    line-height: 28px;
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 10px;
    color: ${branding.loginRegistrationSite.mobileLoginTextColor};
`

const Subtitle = styled.div`
    font-size: 16px;
    line-height: 18.75px;
    margin-top: 10px;
    margin-bottom: 10px;
    color: ${branding.loginRegistrationSite.mobileLoginTextColor};
`


const CheckPointIcon = styled.div``;
const CheckPoint: React.FC<CheckPointProps> = (props) => {

    return (
        <CheckPointRoot>
            <CheckPointIcon>
                {((props.result === StepResult.PENDING) || (props.result === StepResult.PENDING_USER) || (props.result === StepResult.PERMISSION_ALLOWED) || (props.result === StepResult.PERMISSION_NOT_SUPPORTED)) && <Circle>{props.index + 1}</Circle>}
                {props.result === StepResult.SUCCESS && <SuccessCheckmark color="#00B300" backgroundColor="#fff" size={25} />}
                {(props.result === StepResult.PERMISSION_DENIED || props.result === StepResult.PERMISSION_REQUIRED) && <WarningIcon />}
                {props.result === StepResult.USER_SKIPPED && <WarningIcon />}
                {(props.result === StepResult.ERROR || props.result === StepResult.NETWORK_TO_SLOW) && <FailureIcon />}
            </CheckPointIcon>
            <CheckPointLabel style={{ fontWeight: props.isCurrentStep ? "bold" : "normal" }}>
                {props.name}
            </CheckPointLabel>
        </CheckPointRoot>
    );
}

const SystemCheckWizard: React.FunctionComponent = () => {
    const languageState = useLanguageState();
    const language = languageState.getLanguage();
    const strings = languageState.getStrings();
    const [currentStep, setCurrentStep] = useState<StepItem>(localSteps[0]);

    const retryFunction = (type?: string) => setPendingAndStayOnCurrentStep(type);

    const skipFunction = () => setUserSkippedAndContinueOnNextStep();

    const continueFunction = () => setSuccessAndContinueOnNextStep();

    const onDownloadCompleteFunction = () => setNetworkSlowAndStayOnCurrentStep();

    const createTemp = () => {
        return Object.assign({}, currentStep);
    };

    const storeStep = (step: StepItem) => {
        localSteps[step.index] = step;
    };

    const setActiveStep = (step: StepItem) => {
        localSteps.forEach((x) => {
            x.active = false;
            storeStep(x);
        });
        step.active = true;
        storeStep(step);
    };

    const setSuccessAndContinueOnNextStep = () => {
        const temp = createTemp();
        temp.result = StepResult.SUCCESS;
        storeStep(temp);
        const next = localSteps[temp.index + 1];
        setActiveStep(next);
        setCurrentStep(next);
    };

    const setUserSkippedAndContinueOnNextStep = () => {
        const temp = createTemp();
        temp.result = StepResult.USER_SKIPPED;
        storeStep(temp);
        const next = localSteps[temp.index + 1];
        setActiveStep(next);
        setCurrentStep(next);
    };

    const setPendingAndStayOnCurrentStep = (type?: string) => {
        const temp = createTemp();
        temp.result = type ? StepResult.PENDING_USER : StepResult.PENDING;
        storeStep(temp);
        setCurrentStep(temp);
    };

    const setNetworkSlowAndStayOnCurrentStep = () => {
        const temp = createTemp();
        temp.result = StepResult.NETWORK_TO_SLOW;
        storeStep(temp);
        setCurrentStep(temp);
    };

    useEffect(() => {
        const delayForAutomaticSteps = 750; // ms

        const createTemp = () => {
            return Object.assign({}, currentStep);
        };

        const storeStep = (step: StepItem) => {
            localSteps[step.index] = step;
        };

        const setActiveStep = (step: StepItem) => {
            localSteps.forEach((x) => {
                x.active = false;
                storeStep(x);
            });
            step.active = true;
            storeStep(step);
        };

        // to simulate delay for javascript and browser, as they are to fast
        const setSuccessAndStayOnCurrentStep = () => {
            const temp = createTemp();
            temp.result = StepResult.SUCCESS;
            storeStep(temp);
            setCurrentStep(temp);
        };

        // to simulate delay for javascript and browser, as they are to fast
        const setContinueOnNextStep = () => {
            const next = localSteps[currentStep.index + 1];
            setActiveStep(next);
            setCurrentStep(next);
        };

        const setSuccessAndContinueOnNextStep = () => {
            const temp = createTemp();
            temp.result = StepResult.SUCCESS;
            storeStep(temp);
            const next = localSteps[currentStep.index + 1];
            setActiveStep(next);
            setCurrentStep(next);
        };

        const setPermissionAllowedAndStayOnCurrentStep = () => {
            const temp = createTemp();
            temp.result = StepResult.PERMISSION_ALLOWED;
            storeStep(temp);
            setCurrentStep(temp);
        };

        const setPermissionDeniedAndStayOnCurrentStep = (errorState: ErrorState) => {
            const temp = createTemp();
            temp.result = StepResult.PERMISSION_DENIED;
            temp.errorState = errorState;
            storeStep(temp);
            setCurrentStep(temp);
        };

        const setPermissionNotSupportedAndStayOnCurrentStep = (errorState?: ErrorState | null) => {
            const temp = createTemp();
            temp.result = StepResult.PERMISSION_NOT_SUPPORTED;
            temp.errorState = errorState;
            storeStep(temp);
            setCurrentStep(temp);
        };

        const setDevicesAndStayOnCurrentStep = (devices: StepItemDevice[]) => {
            const temp = createTemp();
            temp.devices = devices;
            storeStep(temp);
            setCurrentStep(temp);
        };

        const setErrorAndStayOnCurrentStep = (errorState?: ErrorState | null) => {
            const temp = createTemp();
            temp.result = StepResult.ERROR;
            temp.errorState = errorState;
            storeStep(temp);
            setCurrentStep(temp);
        };

        const tryParseHardwareErrors = (deviceType: MediaDeviceKind, error: any) => {
            // There are quite a few more errors possible: https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia

            // User does not have the device -> all supported mac devices have camera and mic

            // Edge/Chrome: 1. Permission not granted
            // x  Uncaught DOMException: Permission denied -> es wird kein 2. mal gefragt
            // Edge/Chrome: 2. Permission in Browser granted but in os not granted
            // x  Uncaught DOMException: Permission denied by system -> auch wenn nur eins blockiert ist
            // Edge: 3. Mit x weggeklickt
            // x  Uncaught DOMException: Permission dismissed -> Maximal 3 mal, danach Uncaught DOMException: Permission denied

            // Firefox 1: Permisson not granted
            // x   MediaStreamError { details: {…}, name: "NotAllowedError", message: "The request is not allowed by the user agent or the platform in the current context.", constraint: "", stack: "" } -> Temporär und dauerhaft verboten, es wird kein 2. mal gefragt.
            // Firefox 2: Permission granted but in os not granted
            // x   MediaStreamError { details: {…}, name: "NotFoundError", message: "The object can not be found here.", constraint: "", stack: "" } -> Es wird kein 2. mal gefragt -> auch wenn nur eins blockiert ist
            // Firefox 3 hat kein x zum wegklicken, man kann es nur aktiv ignorieren

            // Chrome vermutlich gleich wie chromium-edge

            // Safari 1: Permission not granted
            // x  NotAllowedError: The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission. -> Es wird kein 2. mal gefragt, erst nach page reload, wenn nicht „niemals für diese webseite“ ausgewählt wurde. Nach freigabe muss ein Reload erfolgen.
            // Safari 2: Safari can not be blocked in OS
            // Safari 3: The popup can not be ignored, it is modal

            // WINDOWS
            // Chrome: 1. Permission not granted
            // x     Uncaught (in promise) DOMException: Permission denied
            // Chrome: 2. Permission in Browser granted but in os not granted
            // x     Uncaught (in promise) DOMException: Could not start audio/Video source
            // Chrome: 3. Mit x weggeklickt
            // x     Uncaught (in promise) DOMException: Permission dismissed
            // Chrome: 4 user does not have xyz
            // x Uncaught DOMException: Requested device not found

            const browserCheckResult = detect(); // global in this component?
            let deviceTypeName: DeviceTypeName = deviceType === "audioinput" ? "microphone" : deviceType === "audiooutput" ? "speaker" : deviceType === "videoinput" ? "camera" : "camera";

            let errorState: ErrorState | null = null;

            if (browserCheckResult !== null) {
                const name = browserCheckResult.name
                // Mac Start
                if (browserCheckResult.os !== null && browserCheckResult.os === "Mac OS") {
                    if (browserCheckResult.type === "browser") {
                        if (name === "firefox" || name === "safari") {
                            if (error.name === "NotAllowedError") {
                                errorState = {
                                    type: "browserPermission",
                                    os: browserCheckResult.os,
                                    browser: name,
                                    deviceTypeName: deviceTypeName,
                                };
                            }
                        }
                        if (name === "firefox" || name === "safari") {
                            if (error.name === "NotFoundError") {
                                errorState = {
                                    type: "osLevelPermission",
                                    os: browserCheckResult.os,
                                    browser: name,
                                    deviceTypeName: deviceTypeName,
                                };
                            }
                        }
                        // user don't have devices on safari macos
                        if (name === "safari") {
                            if (error.name === "OverconstrainedError") {
                                errorState = {
                                    type: "missingHardware",
                                    os: browserCheckResult.os,
                                    browser: name,
                                    deviceTypeName: deviceTypeName,
                                };
                            }
                        }
                        if (name === "chrome" || name === "edge-chromium") {
                            if (error.name === "NotAllowedError" && error.message === "Permission denied by system") {
                                errorState = {
                                    type: "osLevelPermission",
                                    os: browserCheckResult.os,
                                    browser: name,
                                    deviceTypeName: deviceTypeName,
                                };
                            }
                        }
                    }
                }
                // Mac End

                // Windows Start
                if (browserCheckResult.os !== null && browserCheckResult.os.startsWith("Windows")) { // 7, 8, 8.1, 10 and 95 if you want
                    if (browserCheckResult.type === "browser") {
                        if (name === "firefox") {
                            if (error.name === "NotAllowedError") {
                                errorState = {
                                    type: "browserPermission",
                                    os: browserCheckResult.os,
                                    browser: name,
                                    deviceTypeName: deviceTypeName,
                                };
                            }
                        }
                        if (name === "firefox") {
                            if (error.name === "NotReadableError") {
                                errorState = {
                                    type: "osLevelPermission",
                                    os: browserCheckResult.os,
                                    browser: name,
                                    deviceTypeName: deviceTypeName,
                                };
                            }
                        }
                        if (name === "chrome" || name === "edge-chromium" || name === "firefox") {
                            if (error.name === "NotFoundError" && (error.message === "Requested device not found" || error.message === "The object can not be found here.")) {
                                errorState = {
                                    type: "missingHardware",
                                    os: "Windows 10",
                                    browser: name,
                                    deviceTypeName: deviceTypeName,
                                };
                            }
                        }
                        if (name === "chrome" || name === "edge-chromium") {
                            if (error.message === "Could not start video source" || error.message === "Could not start audio source") {
                                errorState = {
                                    type: "osLevelPermission",
                                    os: "Windows 10", // Can not be empty, but not used
                                    browser: name,
                                    deviceTypeName: deviceTypeName,
                                };
                            }
                        }
                    }
                }
                // Windows End

                // Non OS Specific start
                if (name === "chrome" || name === "edge-chromium") {
                    if (error.name === "NotAllowedError" && (error.message === "Permission denied" || error.message === "Permission dismissed")) {
                        errorState = {
                            type: "browserPermission",
                            os: "Windows 10", // Can not be empty, but not used
                            browser: name,
                            deviceTypeName: deviceTypeName,
                        };
                    }
                }
                // Non OS Specific end
                setPermissionDeniedAndStayOnCurrentStep(errorState!!);
            } else {
                // we don't know how to handle it
                //setPermissionDeniedAndStayOnCurrentStep();
            }
        };

        const checkJavascript = () => {
            if (currentStep.result === StepResult.PENDING) {
                const delay = setTimeout(() => {
                    clearTimeout(delay);
                    setSuccessAndStayOnCurrentStep();
                }, delayForAutomaticSteps);
            }

            if (currentStep.result === StepResult.SUCCESS) {
                const delay = setTimeout(() => {
                    clearTimeout(delay);
                    setContinueOnNextStep();
                }, delayForAutomaticSteps);
            }
        };

        const checkBrowser = () => {
            if (currentStep.result === StepResult.PENDING) {
                const delay = setTimeout(() => {
                    clearTimeout(delay);



                    const browserCheckResult = detect();
                    if (browserCheckResult && browserCheckResult.type === "browser") {
                        const name = browserCheckResult.name;
                        const version = parseInt(browserCheckResult.version);

                        // Guranteed to work only partly:
                        if ((name === "firefox" && version < 60) || (name === "chrome" && version < 78) || (name === "edge-chromium" && version < 79) || (name === "safari" && version < 13)) {
                            setErrorAndStayOnCurrentStep();
                        }

                        // Guranteed not to work at all:
                        if (name === "ie" || (name === "edge" && !isAndroid)) {
                            setErrorAndStayOnCurrentStep();
                        }

                        if ((name === "firefox" && version >= 60) || (name === "chrome" && version >= 78) || (name === "edge-chromium" && version >= 79) || ((name === "edge" || name === "edge-chromium") && (isAndroid || isIOS) && version >= 44) || (name === "safari" && version >= 13) || (name === "ios" && version >= 13)) {
                            setSuccessAndStayOnCurrentStep();
                        }
                    }
                }, delayForAutomaticSteps);
            }

            // better ui, simulate success
            if (currentStep.result === StepResult.SUCCESS) {
                const delay = setTimeout(() => {
                    clearTimeout(delay);
                    setContinueOnNextStep();
                }, delayForAutomaticSteps);
            }
        };

        const checkLocalStorage = () => {
            if (currentStep.result !== StepResult.ERROR) {
                try {
                    if (typeof localStorage.length === "number") {
                        const delay = setTimeout(() => {
                            clearTimeout(delay);
                            setSuccessAndContinueOnNextStep();
                        }, delayForAutomaticSteps);
                    }
                } catch (error) {
                    const browserCheckResult = detect();
                    if (browserCheckResult && browserCheckResult.type === "browser") {
                        const errorState: ErrorState = {
                            type: "browserPermission",
                            os: browserCheckResult.os!,
                            browser: browserCheckResult.name,
                            deviceTypeName: "system"
                        };
                        setErrorAndStayOnCurrentStep(errorState);
                    } else {
                        setErrorAndStayOnCurrentStep();
                    }
                }
            }
        };

        const checkForHardware = (kind: MediaDeviceKind) => {
            if (currentStep.result === StepResult.PENDING || currentStep.result === StepResult.PERMISSION_REQUIRED) {
                const browserCheckResult = detect();
                if ((browserCheckResult && browserCheckResult.type === "browser" && kind === "audiooutput" && browserCheckResult.name === "firefox")
                    || (kind === "audiooutput" && browserCheckResult?.os === "Android OS")) { // Chrome on Android has no setSinkId, so do the simple test
                    setPermissionNotSupportedAndStayOnCurrentStep(); // ff has no implementation of this feature.
                    return;
                }
                const constraints: MediaStreamConstraints = {
                    audio: kind === "audioinput" || kind === "audiooutput",
                    video: kind === "videoinput",
                };

                navigator.mediaDevices
                    .getUserMedia(constraints)
                    .then((stream) => {
                        // when you acquire stream via getUserMedia, you need to relase it, so that other calls can get it.
                        // later on in flow, camera, microphone will use it, so dispose it here first, as we need it for devices
                        stream.getTracks().forEach((x) => x.stop());

                        // we got permission, let's check devices before showing camera
                        navigator.mediaDevices.enumerateDevices().then((devices: MediaDeviceInfo[]) => {
                            // device id can be:
                            // - default
                            // - communications
                            // - random guid
                            const filterDevices = devices.filter((x) => x.kind === kind);

                            if (kind === "audiooutput" && filterDevices.length === 0) {
                                setPermissionNotSupportedAndStayOnCurrentStep();
                            } else if (filterDevices.length === 0) {
                                setErrorAndStayOnCurrentStep();
                            } else {
                                setPermissionAllowedAndStayOnCurrentStep();
                            }
                        });
                    })
                    .catch((error) => {
                        tryParseHardwareErrors(kind, error);
                    });
            }

            if (currentStep.result === StepResult.PERMISSION_ALLOWED) {
                navigator.mediaDevices
                    .enumerateDevices()
                    .then((devices: MediaDeviceInfo[]) => {
                        // devices are checked above
                        const requestedDevices = devices
                            .filter((x) => x.kind === kind)
                            .map((x, i) => ({
                                deviceId: x.deviceId,
                                label: x.label,
                                selected: i === 0,
                            }));

                        // only if we don't have it, will cause infinite loop if removed
                        if (currentStep.devices?.length === 0) {
                            setDevicesAndStayOnCurrentStep(requestedDevices);
                        }
                    })
                    .catch((error: any) => {
                        logger.error({ message: "SystemCheckWizzard Error querying user devices ", errorMessage: error.message, errorStack: error.stack });
                    });
            }
        };

        const checkCompletedMessage = () => {
            if (currentStep.result === StepResult.PENDING) {
                const delay = setTimeout(() => {
                    clearTimeout(delay);
                    setSuccessAndStayOnCurrentStep();
                }, delayForAutomaticSteps);
            }
        };

        if (currentStep.index === 0) {
            checkJavascript();
        }

        if (currentStep.index === 1) {
            checkBrowser();
        }

        if (currentStep.index === 2) {
            checkLocalStorage();
        }

        if (currentStep.index === 3) {
            checkForHardware("videoinput");
        }

        if (currentStep.index === 4) {
            checkForHardware("audioinput");
        }

        if (currentStep.index === 5) {
            checkForHardware("audiooutput");
        }

        if (currentStep.index === 6) {
            // Step Download Component makes the check by itself.
        }

        if (currentStep.index === 7) {
            checkCompletedMessage();
        }
    }, [currentStep, currentStep.index, currentStep.result, currentStep.devices, language]);

    const getSuccessText = (stepindex: number, result: StepResult): string => {
        switch (stepindex) {
            case 0:
                return result === StepResult.SUCCESS ? strings.systemCheck.stepJavascriptSuccess : strings.systemCheck.stepJavascriptName;
            case 1:
                return result === StepResult.SUCCESS ? strings.systemCheck.stepBrowserSuccess : strings.systemCheck.stepBrowserName;
            case 2:
                return result === StepResult.SUCCESS ? strings.systemCheck.stepLocalStorageSuccess : strings.systemCheck.stepLocalStorageName;
            case 3:
                return result === StepResult.USER_SKIPPED ? strings.systemCheck.skippedCheck.replace("#DEVICE#", strings.systemCheck.camera) : (result === StepResult.SUCCESS ? strings.systemCheck.stepCameraSuccess : strings.systemCheck.camera)
            case 4:
                return result === StepResult.USER_SKIPPED ? strings.systemCheck.skippedCheck.replace("#DEVICE#", strings.systemCheck.microphone) : strings.systemCheck.microphone
            case 5:
                return result === StepResult.USER_SKIPPED ? strings.systemCheck.skippedCheck.replace("#DEVICE#", strings.systemCheck.speaker) : strings.systemCheck.speaker
            case 6:
                return result === StepResult.USER_SKIPPED ? "You skipped this check" : (result === StepResult.SUCCESS ? strings.systemCheck.stepDownloadSuccess : strings.systemCheck.stepDownloadName)
        }
        return "";
    };

    return <>
        <div style={{ top: "50px" }}>
            {currentStep.index < 7 &&
                <>
                    <Title>
                        {strings.systemCheck.headline}
                    </Title>
                    <Subtitle>
                        {strings.systemCheck.description}
                    </Subtitle>
                    {localSteps
                        .filter((x) => x.index < 7)
                        .map((step, index) => (
                            <>
                                <CheckPoint key={index} index={index} name={getSuccessText(step.index, step.result)} result={step.result} isCurrentStep={currentStep.index === index} />

                                {
                                    currentStep.index === index &&
                                    <WizardStepsPanels currentStep={currentStep} contiuneFunction={continueFunction} skipFunction={skipFunction} retryFunction={retryFunction} onCompleteFunction={onDownloadCompleteFunction} />
                                }
                            </>
                        ))}

                </>
            }

            {
                currentStep.index === 7 &&
                <>
                    <StepCompletedMessage />
                </>
            }
        </div>
    </>
}

export default SystemCheckWizard