import { PressMediaPageContentBranding } from '../contentArea/pressMedia/PressMediaPageContentBranding';
import { ContextMenuBranding } from './../ui/ContextMenuBranding';
import { GuestUserBannerBranding, TopBannerConfig } from '../contentArea/guestUserBanner/GuestUserBannerBranding';
import { OrganizationDetailPageContentBranding, PersonDetailPageContentBranding, ProductDetailPageContentBranding, TrademarkDetailPageContentBranding, EventDateDetailPageContentBranding, NewsDetailPageContentBranding } from './../contentArea/detailPages/DetailPageBranding';
import { SayHelloModalBranding } from './../ui/SayHelloModalBranding';
import { FilterBarBranding } from '../ui/FilterBarBranding';
import { MyProfilePageContentBranding } from '../contentArea/myprofile/MyProfilePageContentBranding';
import { NavigationAreaBranding } from '../navigationArea/NavigationAreaBranding';
import { ReceptionPageSiteBranding, MySchedulePageBranding } from '../contentArea/reception/ReceptionPageContentBranding';
import { GlobalStatePopupTextsBranding } from './../globalStates/GlobalStatePopupTextsBranding'
import { LoginRegistrationSiteBranding } from '../contentArea/loginregistration/LoginRegistrationSiteBranding'
import { ErrorSiteBranding } from '../contentArea/errorPages/ErrorSiteBranding'
import { CommunicationAreaBranding } from '../communicationArea/CommunicationAreaBranding'
import { ContactEntryBranding } from '../communicationArea/ContactEntryBranding'
import { SideIconBarBranding } from '../navigationArea/SideIconBar/SideIconBarBranding';
import { NetworkingAreaBranding } from '../contentArea/networkingGraph/NetworkingPageContentBranding'
import { CalendarEntryModalBranding } from '../ui/CalendarEntryModalBranding';
import { CalendarEntryListViewBranding } from '../communicationArea/CalendarEntryListViewBranding';
import { MeetingRoomGroupType, DeviceSwitcherBranding, ConferenceTexts, RosterBranding } from '../conference/AudioVideoBranding';
import { ProgramScheduleBranding, ProgramSpeakersBranding, ProgramPageContentBranding } from '../contentArea/program/ProgramBranding';
import { SystemCheckBranding } from '../systemCheck/SystemCheckBranding';
import { GlobalTextsBranding } from '../globalStates/GlobalTextsBranding'
import { ThirdPartyResources } from './ThirdPartyResources';
import { TicketSaleBranding } from '../PreLoginAppBranding';
import { CalendarEntryExportBranding } from '../communicationArea/CalendarEntryExportBranding';
import { NotificationBranding } from '../conference/context/NotificationContextBranding';
import { RecommendModalBranding } from '../ui/RecommendOrganizationModalBranding';
import { BackofficeStaffBranding, BackofficeContentBranding, BackofficeLVisitorsBranding, BackofficeStaffReqBranding, BackofficeMeetingsBranding, BackofficeReportingBranding, BackofficeLoungeBranding } from '../contentArea/backoffice/BackofficeBranding';
import { TopBannerBranding } from '../contentArea/advertisingBanner/TopBannerBranding';
import { CrsMultiSwitcherBranding } from '../ui/CrsMultiSwitchBranding';
import { CrsTabsBranding } from '../ui/CrsTabsBranding';
import { SettingsTabBranding } from '../communicationArea/settings/SettingsTabBranding';
import { BasisPremiumBranding } from './BasisPremiumBranding';
import { ConversationEntryBranding } from '../communicationArea/ConversationEntryBranding';
import { CrsTimePickerBranding } from '../ui/CrsTimePicker/CrsTimePickerBranding';
import { MediaPartnerPageContentBranding, RulesPageContentBranding, IFrameTestPageContentBranding, SponsorsPageContentBranding, CouponsBranding, NewsPageContentBranding, ProductsPageContentBranding, TrademarksPageContentBranding, ExhibitorsPageContentBranding, BusinessAreaPageContentBranding, HelpPageContentBranding, CategoriesPageContentBranding } from '../contentArea/ContentAreaPageBranding';
import { BrandingImpl } from '../BrandingConfiguration';
import { ChatBranding } from '../communicationArea/ChatBranding';
import { EventDateIcsExportBranding } from '../communicationArea/EventDateIcsExportBranding';


export interface Branding {
  /**
   * @title App & General Configuration
   * @description Configuration information for the app to be runnable
   * @title_de App & Allgemeine Konfiguration
   * @description_de Konfigurationsinformationen, damit die App überhaupt läuft
   */
  configuration: Configuration
  /**
   * @title Presence Configuration
   * @description Configuration options for the presence state of users
   * @title_de Online-Status Konfiguration
   * @description_de Einstellungsmöglichkeiten für die Anzeige des Online-Status der Benutzer
   */
  presenceConfiguration: PresenceConfiguration
  /**
   * @title Dates for the different phases
   * @description Dates and other settings for the timing of the different app phases (e.g. live start date, onboarding start date, ...)
   * @title_de Daten der einzelnen Phasen
   * @description_de Daten und andere Einstellungen für die unterschiedlichen Phasen (z.b. Datum des Live-Betrieb starts, Datum des onboarding starts)
   */
  eventTiming: EventTiming
  /**
   * // TODO use time to live value returned from backend on going live instead
   * @title Greenroom live delay
   * @description Time in seconds for the greenroom to go live after pressing live button
   * @title_de Greenrom Live Verzögerung
   * @description_de Zeit in Sekunden bevor Greenroom tatsächlich live geht nach Button Klick
   */
  greenroomGoLiveFollowupDelaySec: number
  /**
   * @title Page Title
   * @description Title displayed in browser window for the app
   * @title_de Seitentitel
   * @description_de Titel im Browser-Fenter für die App
   */
  pageTitle: string
  /**
   * @title Page Title for Sale Phase
   * @description Title displayed in browser window for the app
   * @title_de Seitentitel während Sale Phase
   * @description_de Titel im Browser-Fenter für die App
   */
  ticketSalePhasePageTitle: LocalizedString
  /**
   * @title Ticket sale phase header title font size
   * @description Font size for ticket sale phase header title
   * @title_de Ticket sale phase header title font size
   * @description_de Font size for ticket sale phase header title
   */
  ticketSalePhaseHeaderTitleFontSize: string
  /**
   * @title Available Languages 
   * @description Choosable Languages
   * @title_de Verfügbare Sprachen
   * @description_de Sprachen die für die App zur Verfügung stehen
   */
  availableLanguages: string[]
  /**
   * @title Global Popup Texts
   * @description Global Popup Texts
   * @title_de Globale Popup Texte
   * @description_de Globale Popup Texte
   */
  globalStatePopupTexts: GlobalStatePopupTextsBranding
  /**
   * @title Login / Registration Configuration
   * @description Configuration options for login/registration process
   * @title_de Login / Registrierung Konfiguration
   * @description_de Konfigurationsoptionen für die Login/Registrierungsseite
   */
  loginRegistrationSite: LoginRegistrationSiteBranding
  /**
   * @title Error Page Configuration
   * @description Configuration options for the error page
   * @title_de Fehlerseiten Konfiguration
   * @description_de Einstellungsmöglichkeiten für die Fehlerseite
   */
  errorSite: ErrorSiteBranding
  /**
   * @title BasisPremiumConfig per Value
   * @description BasisPremium dependend configuration options
   * @title_de BasisPremiumConfig pro Wert
   * @description_de BasisPremium abhängige Einstellungsmöglichkeiten
   */
  basisPremium: BasisPremiumBranding
  /**
  * @title Communication Area Configuration
  * @description Configuration options for the communication area
  * @title_de Kommunikationszenter Konfiguration
  * @description_de Einstellungsmöglichkeiten für das Kommunikationszenter
  */
  communicationArea: CommunicationAreaBranding
  /**
  * @title Chat Configuration
  * @description Configuration options for the chat
  * @title_de Chat Konfiguration
  * @description_de Configuration options for the chat
  */
  chatBranding: ChatBranding
  /**
  * @title Contact Entry Configuration
  * @description Configuration options for the contact entry 
  * @title_de Contact Entry Konfiguration
  * @description_de Configuration options for the contact entry
  */
  contactEntry: ContactEntryBranding
  /**
  * @title Device switcher Configuration
  * @description Configuration options for the device switcher
  * @title_de Device switcher Konfiguration
  * @description_de Configuration options for the device switcher
  */
  deviceSwitcher: DeviceSwitcherBranding
  /**
  * @title Side Icon bar Configuration
  * @description Configuration options for the side Icon bar 
  * @title_de Side Icon bar Konfiguration
  * @description_de Configuration options for the side Icon bar
  */
  sideIconBar: SideIconBarBranding
  /**
  * @title Navigation area Configuration
  * @description Configuration options for the navigation area 
  * @title_de Navigation area Konfiguration
  * @description_de Configuration options for the navigation area
  */
  navigationArea: NavigationAreaBranding
  /**
  * @title Sponsors page Configuration
  * @description Configuration options for the sponsors page 
  * @title_de Sponsors page Konfiguration
  * @description_de Configuration options for the sponsors page
  */
  sponsorsPageContent: SponsorsPageContentBranding
  /**
  * @title Media & Partners page Configuration
  * @description Configuration options for the media & partners page 
  * @title_de Media & Partners page Konfiguration
  * @description_de Configuration options for the media & partners page
  */
  mediaPartnerPageContent: MediaPartnerPageContentBranding
  /**
  * @title Calendar entry modal page Configuration
  * @description Configuration options for the calendar entry modal page 
  * @title_de Calendar entry modal page Konfiguration
  * @description_de Configuration options for the calendar entry modal page
  */
  calendarEntryModalPageContent: CalendarEntryModalBranding
  /**
  * @title Calendar entry list view Configuration
  * @description Configuration options for the calendar entry list view 
  * @title_de Calendar entry list view Konfiguration
  * @description_de Configuration options for the calendar entry list view
  */
  calendarEntryListView: CalendarEntryListViewBranding
  /**
  * @title Calendar entry export Configuration
  * @description Configuration options for the calendar entry export 
  * @title_de Calendar entry export Konfiguration
  * @description_de Configuration options for the calendar entry export
  */
  calendarEntryExport: CalendarEntryExportBranding
  /**
  * @title Event date export Configuration
  * @description Configuration options for the Event date export 
  * @title_de Event date export Konfiguration
  * @description_de Configuration options for the Event date export
  */
  eventDateIcsExport: EventDateIcsExportBranding
  /**
  * @title My profile page content configuration
  * @description Configuration options for my profile page content 
  * @title_de My profile page content Konfiguration
  * @description_de Konfiguration options for my profile page content
  */
  myProfilePageContent: MyProfilePageContentBranding
  /**
  * @title Person Detail Page Content configuration
  * @description Configuration options for person detail page content 
  * @title_de Person Detail Page Content configuration
  * @description_de Configuration options for person detail page content
  */
  personDetailPageContent: PersonDetailPageContentBranding
  /**
  * @title Say hello modal configuration
  * @description Configuration options for Say hello modal 
  * @title_de Say hello modal configuration
  * @description_de Configuration options for Say hello modal
  */
  sayHelloModal: SayHelloModalBranding
  /**
  * @title Filter bar configuration
  * @description Configuration options for filter bar 
  * @title_de Filter bar configuration
  * @description_de Configuration options for filter bar
  */
  filterBar: FilterBarBranding
  /**
  * @title Organization detail page content configuration
  * @description Configuration options for organization detail page content 
  * @title_de Organization detail page content configuration
  * @description_de Configuration options for organization detail page content
  */
  organizationDetailPageContent: OrganizationDetailPageContentBranding
  /**
  * @title Backoffice content configuration
  * @description Configuration options for Backoffice content 
  * @title_de Backoffice content configuration
  * @description_de Configuration options for Backoffice content
  */
  backofficeContent: BackofficeContentBranding
  /**
 * @title Backoffice staff configuration
 * @description Configuration options for backoffice staff content 
 * @title_de Backoffice staff configuration
 * @description_de Configuration options for backoffice staff content 
 */
  backofficeStaff: BackofficeStaffBranding
  /**
  * @title Backoffice staff req configuration
  * @description Configuration options for backoffice staff req content 
  * @title_de Backoffice staff req configuration
  * @description_de Configuration options for backoffice staff req content 
  */
  backofficeStaffReq: BackofficeStaffReqBranding
  /**
  * @title Backoffice meetings configuration
  * @description Configuration options for backoffice meetings content 
  * @title_de Backoffice meetings configuration
  * @description_de Configuration options for backoffice meetings content 
  */
  backofficeMeetings: BackofficeMeetingsBranding
  /**
 * @title Backoffice visitors configuration
 * @description Configuration options for backoffice visitors content 
 * @title_de Backoffice visitors configuration
 * @description_de Configuration options for backoffice visitors content 
 */
  backofficeVisitors: BackofficeLVisitorsBranding
  /**
  * @title Backoffice lounge configuration
  * @description Configuration options for backoffice lounge content 
  * @title_de Backoffice lounge configuration
  * @description_de Configuration options for backoffice lounge content 
  */
  backofficeLounge: BackofficeLoungeBranding
  /**
  * @title Backoffice lounge configuration
  * @description Configuration options for backoffice lounge content 
  * @title_de Backoffice lounge configuration
  * @description_de Configuration options for backoffice lounge content 
  */
  backofficeReporting: BackofficeReportingBranding
  /**
  * @title Product detail page content configuration
  * @description Configuration options for product detail page content
  * @title_de Product detail page content configuration
  * @description_de Configuration options for product detail page content
  */
  productDetailPageContent: ProductDetailPageContentBranding
  /**
  * @title Trademark detail page content configuration
  * @description Configuration options for trademark detail page content
  * @title_de Trademark detail page content configuration
  * @description_de Configuration options for trademark detail page content
  */
  trademarkDetailPageContent: TrademarkDetailPageContentBranding
  /**
  * @title News detail page content configuration
  * @description Configuration options for news detail page content
  * @title_de News detail page content configuration
  * @description_de Configuration options for news detail page content
  */
  newsDetailPageContent: NewsDetailPageContentBranding
  /**
  * @title Eventdate detail page content configuration
  * @description Configuration options for eventdate detail page content
  * @title_de Eventdate detail page content configuration
  * @description_de Configuration options for eventdate detail page content
  */
  eventDateDetailPageContent: EventDateDetailPageContentBranding
  /**
  * @title Products page content configuration
  * @description Configuration options for products page content
  * @title_de Products page content configuration
  * @description_de Configuration options for products page content
  */
  productsPageContent: ProductsPageContentBranding
  /**
  * @title News page content configuration
  * @description Configuration options for news page content
  * @title_de News page content configuration
  * @description_de Configuration options for news page content
  */
  newsPageContent: NewsPageContentBranding
  /**
  * @title Trademarks page content configuration
  * @description Configuration options for trademarks page content
  * @title_de Trademarks page content configuration
  * @description_de Configuration options for trademarks page content
  */
  trademarksPageContent: TrademarksPageContentBranding
  /**
  * @title Exhibitors page content configuration
  * @description Configuration options for exhibitors page content
  * @title_de Exhibitors page content configuration
  * @description_de Configuration options for exhibitors page content
  */
  exhibitorsPageContent: ExhibitorsPageContentBranding
  /**
  * @title Categories page content configuration
  * @description Configuration options for categories page content
  * @title_de Categories page content configuration
  * @description_de Configuration options for categories page content
  */
  categoriesPageContent: CategoriesPageContentBranding
  /**
  * @title Program page content configuration
  * @description Configuration options for program page content
  * @title_de Program page content configuration
  * @description_de Configuration options for program page content
  */
  programPageContent: ProgramPageContentBranding
  /**
  * @title Program schedule configuration
  * @description Configuration options for program schedule     
  * @title_de Program schedule configuration
  * @description_de Configuration options for program schedule     
  */
  programSchedule: ProgramScheduleBranding
  /**
  * @title Program speakers configuration
  * @description Configuration options for program speakers     
  * @title_de Program speakers configuration
  * @description_de Configuration options for program speakers     
  */
  programSpeakers: ProgramSpeakersBranding
  /**
  * @title Press and media page content configuration
  * @description Configuration options for press and media page content     
  * @title_de Press and media page content configuration
  * @description_de Configuration options for press and media page content     
  */
  pressMediaPageContent: PressMediaPageContentBranding
  /**
  * @title Business area page content configuration
  * @description Configuration options for business area page content     
  * @title_de Business area page content configuration
  * @description_de Configuration options for business area page content     
  */
  businessAreaPageContent: BusinessAreaPageContentBranding
  /**
  * @title System check configuration
  * @description Configuration options for system check     
  * @title_de System check configuration
  * @description_de Configuration options for system check     
  */
  systemCheck: SystemCheckBranding
  /**
  * @title Reception page content configuration
  * @description Configuration options for reception page content     
  * @title_de Reception page content configuration
  * @description_de Configuration options for reception page content     
  */
  receptionPage: ReceptionPageSiteBranding
  /**
  * @title Meeting room max tiles
  * @description Maximum tiles number for meeting rooms (default value: 16)     
  * @title_de Meeting room max tiles
  * @description_de Maximum tiles number for meeting rooms (default value: 16) 
  */
  meetingRoomMaxTiles: number
  /**
  * @title Meeting room margin right
  * @description Margin right value for meeting rooms    
  * @title_de Meeting room margin right
  * @description_de Margin right value for meeting rooms    
  */
  meetingRoomMarginRight: string
  /**
  * @title Live tile margings
  * @description Margin values for live tiles    
  * @title_de Live tile margings
  * @description_de Margin values for live tiles    
  */
  liveTileMargins: string
  /**
  * @title Meeting room groups disable picture overlay
  * @description Enable / disable meeting room groups overlay value   
  * @title_de Meeting room groups disable picture overlay
  * @description_de Enable / disable meeting room groups overlay value    
  */
  meetingRoomGroupsDisablePictureOverlay: boolean
  /**
  * @title Meeting room group overlay value 
  * @description Linear gradient value for darken overlay when it is enabled
  * @title_de Meeting room group overlay value
  * @description_de Linear gradient value for darken overlay when it is enabled
  */
  meetingRoomGroupsOverlayValue: string
  /**
  * @title Meeting room groups title font style
  * @description Font style for meeting room groups title
  * @title_de Meeting room groups title font style
  * @description_de Font style for meeting room groups title
  */
  meetingRoomGroupsTitleFontStyle: string
  /**
  * @title Meeting room groups hoster font style
  * @description Font style for meeting room groups hoster
  * @title_de Meeting room groups hoster font style
  * @description_de Font style for meeting room groups hoster
  */
  meetingRoomGroupsHosterFontStyle: string
  /**
  * @title Meeting room groups description font style
  * @description Font style for meeting room groups description
  * @title_de Meeting room groups description font style
  * @description_de Font style for meeting room groups description
  */
  meetingRoomGroupsDescriptionFontStyle: string
  /**
  * @title Meeting room groups configuration
  * @description Configuration options for meeting room groups description
  * @title_de Meeting room groups configuration
  * @description_de Configuration options for meeting room groups description
  */
  meetingRoomGroups: MeetingRoomGroupType[]
  /**
  * @title Conference texts 
  * @description Configuration for texts used in audio/video rooms
  * @title_de Conference texts
  * @description_de Configuration for texts used in audio/video rooms
  */
  conferenceTexts: ConferenceTexts
  /**
  * @title Conference list tiles configuration
  * @description Configuration options for Conference list tiles description
  * @title_de Conference list tiles configuration
  * @description_de Configuration options for Conference list tiles description
  */
  conferenceListTiles: ConferenceListTiles
  /**
 * @title Third party resources configuration
 * @description Configuration options for Third party resources description
 * @title_de Third party resources configuration
 * @description_de Configuration options for Third party resources description
 */
  thirdPartyResources: ThirdPartyResources[]
  /**
  * @title Theme color 
  * @description First color theme
  * @title_de Theme color
  * @description_de First color theme
  */
  theme1Color: string
  /**
  * @title Theme color 
  * @description Second color theme
  * @title_de Theme color
  * @description_de Second color theme
  */
  theme2Color: string
  /**
  * @title Connect with all participants button visiblity 
  * @description Enable / disable visibility of Connect with all participants button inside of the roster 
  * @title_de Connect with all participants button visiblity
  * @description_de Enable / disable visibility of Connect with all participants button inside of the roster 
  */
  hasConnectWithAllParticipants: boolean
  /**
  * @title Primary color 
  * @description Mostly used as dark background color 
  * @title_de Primary color
  * @description_de Mostly used as dark background color 
  */
  primaryColor: ColorString
  /**
* @title Placeholder focused color 
* @description Text color for placeholder when they are on focus 
* @title_de Placeholder focused color
* @description_de Text color for placeholder when they are on focus 
*/
  placeholderFocusedColor: ColorString
  /**
  * @title Placeholder unfocused color 
  * @description Text color for placeholder when they are not on focus 
  * @title_de Placeholder unfocused color
  * @description_de Text color for placeholder when they are not on focus 
  */
  placeholderUnFocusedColor: ColorString
  /**
  * @title Input focused color 
  * @description Text color for input when they are on focus 
  * @title_de Input focused color
  * @description_de Text color for input when they are on focus 
  */
  inputFocusedColor: ColorString
  /**
   * @title Input unfocused color 
   * @description Text color for input when they are not on focus 
   * @title_de Input unfocused color
   * @description_de Text color for input when they are not on focus 
   */
  inputUnFocusedColor: ColorString
  /**
  * @title Input focused color 
  * @description Text color for input when they are on focus 
  * @title_de Input focused color
  * @description_de Text color for input when they are on focus 
  */
  inputFocusedBorderColor: ColorString
  /**
  * @title Input unfocused color 
  * @description Text color for input when they are not on focus 
  * @title_de Input unfocused color
  * @description_de Text color for input when they are not on focus 
  */
  inputUnFocusedBorderColor: ColorString
  /**
  * @title Secondary color 
  * @description Mostly used as light background color  
  * @title_de Secondary color
  * @description_de Mostly used as light background color   
  */
  secondaryColor: ColorString
  /**
  * @title Active icon color 
  * @description Icon color set on click for side icon bar and communication center (to be highlighted) 
  * @title_de Active icon color
  * @description_de Icon color set on click for side icon bar and communication center (to be highlighted) 
  */
  activeIconColor: ColorString
  /**
  * @title Nav bar active icon color 
  * @description Nav bar Icon color set on click for side icon bar and communication center (to be highlighted) 
  * @title_de Nav bar active icon color 
  * @description_de Nav bar Icon color set on click for side icon bar and communication center (to be highlighted) 
  */
  navBarActiveIconColor: ColorString
  /**
 * @title Main info color 
 * @description Mostly used as color for various border colors / text colors / backgroud colors inside of the application 
 * @title_de Main info color
 * @description_de Mostly used as color for various border colors / text colors / backgroud colors inside of the application   
 */
  mainInfoColor: ColorString
  /**
  * @title Main info dark color 
  * @description  Color used as light color on dark background for message panel border color
  * @title_de Main info dark color
  * @description_de  Color used as light color on dark background for message panel border color 
  */
  mainInfoDarkColor: ColorString
  /**
  * @title Chat list color 
  * @description  Text color for chat list color
  * @title_de Chat list color
  * @description_de  Text color for chat list color 
  */
  chatListColor: ColorString
  /**
 * @title Chat list background communication area color 
 * @description  Background color for chat list inside of the communication area
 * @title_de Chat list background communication area color
 * @description_de  Background color for chat list inside of the communication area 
 */
  chatListBackgroundCommunicationAreaColor: ColorString
  /**
  * @title Chat input color 
  * @description  Text color for the message input of the chat used in the communication area
  * @title_de Chat input color
  * @description_de  Text color for the message input of the chat used in the communication area 
  */
  chatInputColor: ColorString
  /**
  * @title Chat input color time 
  * @description  Text color for the time input inside of the chat
  * @title_de Chat input color time
  * @description_de  Text color for the message input inside of the chat 
  */
  chatInputColorTime: ColorString
  /**
  * @title Chat darken input color 
  * @description  Text color used when the theme is dark and input color should be in light version 
  * @title_de Chat darken input color
  * @description_de  Text color used when the theme is dark and input color should be in light version
  */
  chatDarkenInputColor: ColorString
  /**
  * @title Side icon bar drawer screen overlay color 
  * @description Darken overlay background color showed when drawer is opened   
  * @title_de Side icon bar drawer screen overlay color
  * @description_de Darken overlay background color showed when drawer is opened    
  */
  darkenOverlayColor: string
  /**
  * @title Communication area search placeholder color 
  * @description Text color of the search placeholder inside of the communication area   
  * @title_de Communication area search placeholder color
  * @description_de Text color of the search placeholder inside of the communication area    
  */
  communicationAreaSearchPlaceholderColor: ColorString
  /**
  * @title Communication area default avatar color 
  * @description Icon color for default avatar icon inside of the communication area   
  * @title_de Communication area default avatar color
  * @description_de Icon color for default avatar icon inside of the communication area    
  */
  communicationAreaDefaultAvatarColor: ColorString
  /**
  * @title Secondary text color 
  * @description Mostly used as light version for text color  
  * @title_de Secondary text color
  * @description_de Mostly used as light version for text color   
  */
  secondaryTextColor: string
  /**
  * @title Darken theme background color 
  * @description Background color used when the dark theme is on  
  * @title_de Darken theme background color
  * @description_de Background color used when the dark theme is on   
  */
  darkenThemeBackgroundColor: ColorString
  /**
  * @title Darken theme hover color 
  * @description Background color on hover used when the dark theme is on  
  * @title_de Darken theme hover color
  * @description_de Background color on hover used when the dark theme is on   
  */
  darkenThemeHoverColor: string
  /**
  * @title Light theme hover color 
  * @description Background color on hover used when the light theme is on  
  * @title_de Light theme hover color
  * @description_de Background color on hover used when the light theme is on   
  */
  lightThemeHoverColor: string
  /**
  * @title Theme color primary 
  * @description Primary side bar color for applied theme  
  * @title_de Theme color primary
  * @description_de Primary side bar color for applied theme   
  */
  theme3ColorPrimary: string
  /**
 * @title Theme color secondary 
 * @description Secondary side bar color for applied theme  
 * @title_de Theme color secondary
 * @description_de Secondary side bar color for applied theme   
 */
  theme3ColorSecondary: string
  /**
  * @title Crs multi switcher configuration
  * @description Configuration options for crs multi switcher     
  * @title_de Crs multi switcher configuration
  * @description_de Configuration options for crs multi switcher     
  */
  crsMultiSwitcher: CrsMultiSwitcherBranding
  /**
  * @title Crs tabs configuration
  * @description Configuration options for Crs tabs     
  * @title_de Crs tabs configuration
  * @description_de Configuration options for Crs tabs     
  */
  crsTabs: CrsTabsBranding
  /**
  * @title Help page configuration
  * @description Configuration options for help page     
  * @title_de Help page configuration
  * @description_de Configuration options for help page     
  */
  helpPage: HelpPageContentBranding
  /**
  * @title Video 1
  * @description Link for the video 1      
  * @title_de Video 1
  * @description_de Link for the video 1      
  */
  video1: string
  /**
  * @title Video 2
  * @description Link for the video 2      
  * @title_de Video 2
  * @description_de Link for the video 2      
  */
  video2: string
  /**
  * @title Video 3
  * @description Link for the video 3      
  * @title_de Video 3
  * @description_de Link for the video 3      
  */
  video3: string
  /**
  * @title Font 1
  * @description Mostly used for main font inside of the application     
  * @title_de Font 1
  * @description_de Mostly used for main font inside of the application       
  */
  font1: string
  /**
  * @title Font 2
  * @description Mostly used as secondary font inside of the application     
  * @title_de Font 2
  * @description_de Mostly used as secondary font inside of the application       
  */
  font2: string
  /**
  * @title Loader bg color
  * @description Background color for the centered loader     
  * @title_de Loader bg color
  * @description_de Background color for the centered loader       
  */
  loaderBgColor: string
  /**
  * @title Loader spin color
  * @description Top border color for the centered loader     
  * @title_de Loader spin color
  * @description_de Top border color for the centered loader       
  */
  loaderSpinColor: ColorString
  /**
  * @title Custom scrollbar bg color
  * @description Background color for custom scrollbar     
  * @title_de Custom scrollbar bg color
  * @description_de Background color for custom scrollbar       
  */
  customScrollbarBgColor: ColorString
  /**
  * @title Custom scrollbar track bg color
  * @description Background color for custom scrollbar track    
  * @title_de Custom scrollbar track bg color
  * @description_de Background color for custom scrollbar track      
  */
  customScrollbarTrackBgColor: ColorString
  /**
  * @title Custom scrollbar border color
  * @description Border color for custom scrollbar    
  * @title_de Custom scrollbar border color
  * @description_de Border color for custom scrollbar      
  */
  customScrollbarBorderColor: string
  /**
  * @title Custom scrollbar width
  * @description Main width for custom scrollbar    
  * @title_de Custom scrollbar width
  * @description_de Main width for custom scrollbar      
  */
  customScrollbarWidth: string
  /**
 * @title Custom scrollbar height
 * @description Main height for custom scrollbar    
 * @title_de Custom scrollbar height
 * @description_de Main height for custom scrollbar      
 */
  customScrollbarHeight: string
  /**
  * @title Danger button color
  * @description Main danger button color    
  * @title_de Danger button color
  * @description_de Main danger button color       
  */
  dangerButtonColor: ColorString
  /**
  * @title Networking area configuration
  * @description Configuration options for networking area     
  * @title_de Networking area configuration
  * @description_de Configuration options for networking area     
  */
  networkingArea: NetworkingAreaBranding
  /**
  * @title Filter button color
  * @description Text color for the filter button     
  * @title_de Filter button color
  * @description_de Text color for the filter button     
  */
  filterButtonColor: ColorString
  /**
  * @title Global textst configuration
  * @description Configuration options for global texts     
  * @title_de Global textst configuration
  * @description_de Configuration options for global texts     
  */
  globalTexts: GlobalTextsBranding
  /**
 * @title Ticket sale configuration
 * @description Configuration options for ticket sale     
 * @title_de Ticket sale configuration
 * @description_de Configuration options for ticket sale     
 */
  ticketSale: TicketSaleBranding
  /**
  * @title Notifications
  * @description Configuration for titles, texts and text templates for Event Guide notifications
  * @title_de Notifications
  * @description_de Enthält Aliase von Kategorien mit dem Typ = Interesse und synthetisch = true, um als Abzeichen angezeigt zu werden
  */
  notification: NotificationBranding
  /**
  * @title Backend error configuration
  * @description Configuration options for backend error     
  * @title_de Backend error configuration
  * @description_de Configuration options for backend error     
  */
  backendError: BackendErrorBranding
  /**
  * @title Recommend modal configuration
  * @description Configuration options for recommend modal     
  * @title_de Recommend modal configuration
  * @description_de Configuration options for recommend modal     
  */
  recommendModal: RecommendModalBranding
  /**
  * @title Top sponsors
  * @description List of top sponsors     
  * @title_de Top sponsors
  * @description_de List of top sponsors     
  */
  topSponsors: TopSponsor[]
  /**
  * @title Idea sponsors
  * @description List of idea sponsors     
  * @title_de Idea sponsors
  * @description_de List of idea sponsors     
  */
  ideaSponsors: TopSponsor[]
  /**
  * @title Topic badges
  * @description List of topic badges     
  * @title_de Topic badges
  * @description_de List of topic badges     
  */
  topicBadges: TopicBadge[]
  /**
  * @title Category badges 
  * @description Contains aliases of categories with has type = Interest and synthetic=true to be displayed as badge  
  * @title_de Kategorie Abzeichen
  * @description_de Enthält Aliase von Kategorien mit dem Typ = Interesse und synthetisch = true, um als Abzeichen angezeigt zu werden
  */
  categoryBadges: string[]
  /**
  * @title Press 
  * @description Text for press label  
  * @title_de Press
  * @description_de Text for press label
  */
  press: LocalizedString
  /**
  * @title Coupons configuration
  * @description Configuration options for coupons    
  * @title_de Coupons configuration
  * @description_de Configuration options for coupons    
  */
  coupons: CouponsBranding
  /**
  * @title Top banner configuration
  * @description Configuration options for top banner    
  * @title_de Top banner configuration
  * @description_de Configuration options for top banner    
  */
  topBanner: TopBannerBranding
  /**
* @title Guest user banner configuration
* @description Configuration options for guest user banner   
* @title_de Guest user banner configuration
* @description_de Configuration options for guest user banner   
*/
  guestUserBanner: GuestUserBannerBranding
  /**
  * @title Config for topbanner
  * @description Config for topbanner
  * @title_de Einstellungen für Topbanner
  * @description_de Einstellungen für Topbanner
  */
  topBannerConfig: TopBannerConfig
  /**
  * @title Config for rules page
  * @description Config for rules page
  * @title_de Einstellungen für Regelwerk Seite
  * @description_de Einstellungen für Regelwerk Seite
  */
  rulesPage: RulesPageContentBranding
  /**
  * @title Config for topbar
  * @description Config for topbar (e.g. global search)
  * @title_de Einstellungen für Topbar
  * @description_de Einstellungen für Topbar (z.B. globale Suche)
  */
  topBar: TopBarBranding
  /**
 * @title Roster
 * @description Configuration options for audio/video call roster
 * @title_de Roster
 * @description_de Einstellungen für audio/video Anruf Teilnehmerliste
 */
  roster: RosterBranding
  /**
  * @title Settings Tab 
  * @description Configuration options for the settings tab
  * @title_de Einnstellungs Tab
  * @description_de Konfiguration für Einstellungs Tab
  */
  settingsTab: SettingsTabBranding
  /**
  * @title Settings for "My Schedule"
  * @description Settings for "My Schedule"
  * @title_de Einstellungen für "Mein Terminkalender"
  * @description_de Einstellungen für "Mein Terminkalender"
  */
  mySchedule: MySchedulePageBranding
  /**
  * @title Scripttag for hubspot
  * @description Scripttag for hubspot
  * @title_de Scripttag für hubspot
  * @description_de Scripttag für hubspot
  */
  hubspotScriptTag: string

  /**
   * @title GTM noscript
   * @description Google Tag Manager's noscript element
   * @title_de GTM noscript
   * @description_de Google Tag Manager's noscript element
   */
  gtmNoScript: string

  /**
   * @title GTM data layer
   * @description Google Tag Manager's data layer script
   * @title_de GTM data layer
   * @description_de Google Tag Manager's data layer script
   */
  gtmScriptDataLayer: string

  /**
   * @title GTM script
   * @description Google Tag Manager's initially loaded script
   * @title_de GTM script
   * @description_de Google Tag Manager's initially loaded script
   */
  gtmTagScript: string

  /**
 * @title GTM fair number
 * @description Code used by Google Tag Manager for single Event Guide's tracking
 * @title_de GTM fair number
 * @description_de Code used by Google Tag Manager for single Event Guide's tracking
 */
  gtmFairNumber: string

  /**
 * @title OneTrust cookie script
 * @description OneTrust cookie script
 * @title_de OneTrust cookie script
 * @description_de OneTrust cookie script
 */
  cookieOneTrustScript: string

  /**
   * @title JIRA support desk data
   * @description JIRA support desk data
   * @title_de JIRA support desk data
   * @description_de JIRA support desk data
   */
  jiraSupportDeskData: JiraSupportDeskData,
  /**
 * @title Show person name in url 
 * @description Enable / disable visibility of person name in url  
 * @title_de Show person name in url 
 * @description_de Enable / disable visibility of person name in url   
 */
  showPersonNameInUrl: boolean
  /**
   * @title Video background
   * @description Background color for the video
   * @title_de Video background
   * @description_de Background color for the video
   */
  videoBackground: ColorString
  /**
   * @title Showroom meeting duration 
   * @description Number value for the showroom meeting duration (default value: 4200)
   * @title_de Showroom meeting duration 
   * @description_de Number value for the showroom meeting duration (default value: 4200)
   */
  showroomMeetingDuration: number
  /**
   * @title Default toggle icon 
   * @description Enable / disable visibility of default toggle icon  
   * @title_de Default toggle icon 
   * @description_de Enable / disable visibility of default toggle icon   
   */
  defaultToggleIcon: boolean

  /**
   * @title Conversation entry
   * @description Configuration of chat list items
   * @title_de Conversation entry
   * @description_de Configuration of chat list items
   */
  conversationEntry: ConversationEntryBranding


  /**
   * @title Global opt-in
   * @description Configure Event Guide's tracking to be global, rather than for every company
   * @title_de Global opt-in
   * @description_de Configure Event Guide's tracking to be global, rather than for every company
   */
  globalOptIn: boolean

  /**
   * @title Tracking branding (NONE)
   * @description Configuration of tracking data for NONE basis premium package
   * @title_de Tracking branding (NONE)
   * @description_de Configuration of tracking data for NONE basis premium package
   */
  trackingBrandingNone: TrackingBranding

  /**
   * @title Tracking branding (BASIC)
   * @description Configuration of tracking data for BASIC basis premium package
   * @title_de Tracking branding (BASIC)
   * @description_de Configuration of tracking data for BASIC basis premium package
   */
  trackingBrandingBasic: TrackingBranding

  /**
   * @title Tracking branding (STANDARD)
   * @description Configuration of tracking data for STANDARD basis premium package
   * @title_de Tracking branding (STANDARD)
   * @description_de Configuration of tracking data for STANDARD basis premium package
   */
  trackingBrandingStandard: TrackingBranding

  /**
   * @title Tracking branding (PREMIUM)
   * @description Configuration of tracking data for PREMIUM basis premium package
   * @title_de Tracking branding (PREMIUM)
   * @description_de Configuration of tracking data for PREMIUM basis premium package
   */
  trackingBrandingPremium: TrackingBranding

  /**
   * @title Tracking branding (ULTIMATE)
   * @description Configuration of tracking data for ULTIMATE basis premium package
   * @title_de Tracking branding (ULTIMATE)
   * @description_de Configuration of tracking data for ULTIMATE basis premium package
   */
  trackingBrandingUltimate: TrackingBranding

  /**
 * @title CSV exports
 * @description Configuration of column names in backoffice CSV exports
 * @title_de CSV exports
 * @description_de Configuration of column names in backoffice CSV exports
 */
  csvExportBranding: CSVExportBranding
  /**
   * @title Timepicker Styling
   * @description Styling of timepicker (e.g. schedule)
   * @title_de Timepicker Styling
   * @description_de Styling von Timepicker (e.g. Termine)
   */
  timePickerBranding: CrsTimePickerBranding
  /**
   * @title Config for entity contextmenu
   * @description Config for entity contextmenu (e.g. person row)
   * @title_de Einstellungen für Entitie Kontextmenu
   * @description_de Einstellungen für Entitie Kontextmenu (e.g. Personenzeile)
   */
  contextMenuBranding: ContextMenuBranding
  /**
   * @title Dropdown Styling
   * @description Styling of Dropdown fields (e.g. Profilpage)
   * @title_de Dropdown Styling
   * @description_de Styling von Dropdowns (e.g. Profilseite)
   */
  dropdownStyle: DropdownStyle
  /**
   * @title Searchbar text-transform(CSS)
   * @description text-transform for the searchbar
   * @title_de Suchleiste text-transform(CSS)
   * @description_de text-transform der Suchleiste
   */
  iframeTest: IFrameTestPageContentBranding
  /**
   * @title Videoplayer branding
   * @description branding for the videoplayer (streaming)
   * @title_de Videoplayer Einstellungen
   * @description_de Einstellungen für den Videoplayer (streaming)
   */
  videoPlayerBranding: VideoPlayerBranding
  /**
   * @title Streaming Page branding
   * @description Streaming Page branding
   * @title_de Einstellung für Streaming-Seite
   * @description_de Einstellungen für die Streaming-Seite
   */
  videoPageContentBranding: VideoPageContentBranding
  /**
   * @title Searchbar font-family(CSS)
   * @description font-family for the searchbar
   * @title_de Suchleiste font-family(CSS)
   * @description_de font-family der Suchleiste
   */
  searchBarFontFamily: string
  /**
   * @title Searchbar font-size(CSS)
   * @description font-size for the searchbar
   * @title_de Suchleiste font-size(CSS)
   * @description_de font-size der Suchleiste
   */
  searchBarFontSize: string
  /**
   * @title Searchbar letter-spacing(CSS)
   * @description letter-spacing for the searchbar
   * @title_de Suchleiste letter-spacing(CSS)
   * @description_de letter-spacing der Suchleiste
   */
  searchBarLetterSpacing: string
  /**
   * @title Searchbar font-weight(CSS)
   * @description font-weight for the searchbar
   * @title_de Suchleiste font-weight(CSS)
   * @description_de font-weight der Suchleiste
   */
  searchBarFontWeight: string
  /**
   * @title Searchbar text-transform(CSS)
   * @description text-transform for the searchbar
   * @title_de Suchleiste text-transform(CSS)
   * @description_de text-transform der Suchleiste
   */
  searchBarTextTransform: string

  /**
   * @title List of root category aliases
   * @description Expect a list of category aliases. If provided the main category list will have those as root categories.
   * @title_de List of root category aliases
   * @description_de Expect a list of category aliases. If provided the main category list will have those as root categories.
   */
  mainRootCategories: Array<String>
  /**
  * @title Main border
  * @description Sets the main border width and color which will be used in multiple places throughout the application 
  * @title_de Main border
  * @description_de Sets the main border width and color which will be used in multiple places throughout the application
  */
  mainBorder: string

  /**
 * @title List divider color
 * @description Definies the color of the list devider in list view 
 * @title_de List divider color
 * @description_de Definies the color of the list devider in list view 
 */
  listDividerColor: string
  /**
   * @title Main icons color
   * @description Color that will be used for all of the main icons inside of the application.  
   * @title_de Main icons color
   * @description_de Color that will be used for all of the main icons inside of the application.
   */
  mainIconsColor: string
  /**
  * @title Primary scroll white shadow - left to right
  * @description White shadow that will be used as a indicator that the user can scroll and have more content on the lists or wherever it is needed. 
  * @title_de Primary scroll white shadow - left to right 
  * @description_de White shadow that will be used as a indicator that the user can scroll and have more content on the lists or wherever it is needed. 
  */
  primaryScrollWhiteShadowLTR: string
  /**
 * @title Primary scroll white shadow - top to bottom
 * @description White shadow that will be used as a indicator that the user can scroll and have more content on the lists or wherever it is needed. 
 * @title_de Primary scroll white shadow - top to bottom 
 * @description_de White shadow that will be used as a indicator that the user can scroll and have more content on the lists or wherever it is needed. 
 */
  primaryScrollWhiteShadowTTB: string
  /**
 * @title Horizontal scroll shadow blur
 * @description Amount of blur that we will have on the horizontal scroller shadows expressed in px.
 * @title_de Horizontal scroll shadow blur
 * @description_de Amount of blur that we will have on the horizontal scroller shadows expressed in px.
 */
  horizontalScrollShadowBlur: string
  /**
 * @title Vertical scroll shadow - top to bottom
 * @description Dark shadow that will be used on the top level (fixed) elements. 
 * @title_de Vertical scroll shadow - top to bottom
 * @description_de Dark shadow that will be used on the top level (fixed) elements. 
 */
  primaryScrollDarkShadowTTB: string
  /**
 * @title Vertical scroll shadow - bottom to top
 * @description Dark shadow that will be used on the top level (fixed) elements. 
 * @title_de Vertical scroll shadow - bottom to top
 * @description_de Dark shadow that will be used on the top level (fixed) elements. 
 */
   primaryScrollDarkShadowBTT: string
}

export interface JiraSupportDeskData {
  /**
   * @title Show Jira
   * @description Should jira button be visible.
   * @title_de Jira anzeigen
   * @description_de Soll Jira Button angezeigt werden.
   */
  visible: boolean,
  /**
   * @title Key
   * @description Jira key for support desk
   * @title_de Key
   * @description_de Key für support desk
   */
  key: string
}

export interface DropdownStyle {
  /**
   * @title Primary color
   * @description Primary color. See https://react-select.com/styles
   * @title_de Primärfarbe
   * @description_de Primärfarbe. Siehe https://react-select.com/styles
   */
  primaryColor: string
  /**
   * @title Primary color 25
   * @description Primary color. See https://react-select.com/styles
   * @title_de Primärfarbe 25
   * @description_de Primärfarbe. Siehe https://react-select.com/styles
   */
  primary25Color: string
  /**
   * @title Primary color 50
   * @description Primary color. See https://react-select.com/styles
   * @title_de Primärfarbe 50
   * @description_de Primärfarbe. Siehe https://react-select.com/styles
   */
  primary50Color: string
  /**
   * @title Primary color 75
   * @description Primary color. See https://react-select.com/styles
   * @title_de Primärfarbe 75
   * @description_de Primärfarbe. Siehe https://react-select.com/styles
   */
  primary75Color: string
}

export interface TopicBadge {
  /**
   * @title Name of the topic
   * @description Name of the topic for which this badge should be displayed.
   * @title_de Name des Topics
   * @description_de Name des Topics für den dieses Topic angezeigt werden soll.
   */
  topicName: string
  /**
   * @title Badge Text
   * @description Text on the Badge. Can be different from the topic.
   * @title_de Badget Text
   * @description_de Badgetext. Kann sich vom topic unterscheiden.
   */
  badgeText: string
  /**
   * @title Text color
   * @description Color of the text in the badge
   * @title_de Textfarbe
   * @description_de Farbe des Badge-Textes
   */
  textColor: string
  /**
   * @title Background color
   * @description Color of the badge background.
   * @title_de Hintergrundfarbe
   * @description_de Hintergrundfarbe des Badges.
   */
  backgroundColor: string
  /**
   * @title Border color
   * @description Color of the badge border.
   * @title_de Rahmenfarbe
   * @description_de Farbe des Badgerahmens
   */
  borderColor: string
}

export interface EventTiming {
  /**
   * @title Ticketshop Opening
   * @description Time for when ticketshop is open. Format ISO_8601 "2000-01-01T00:00:00+02:00"
   * @title_de Ticketshop Öffnung
   * @description_de Zeitpunkt der Öffnung des Ticketshops. Format ISO_8601 "2000-01-01T00:00:00+02:00"
   */
  ticketShopOpenDateTime: string
  /**
   * @title Onboarding Start
   * @description Time for when onboarding starts. Format ISO_8601 "2000-01-01T00:00:00+02:00"
   * @title_de Onboarding Beginn
   * @description_de Zeitpunkt für den Start des Onboardings. Format ISO_8601 "2000-01-01T00:00:00+02:00"
   */
  onboardingOpenDateTime: string
  /**
   * @title Reporting Start
   * @description Time for when reportings starts. Format ISO_8601 "2000-01-01T00:00:00+02:00"
   * @title_de Reporting Beginn
   * @description_de Zeitpunkt für den Start des Reportings. Format ISO_8601 "2000-01-01T00:00:00+02:00"
   */
  reportingOpenDateTime: string
  /**
   * @title Login Start
   * @description Time for when login is possible. Format ISO_8601 "2000-01-01T00:00:00+02:00"
   * @title_de Login Beginn
   * @description_de Zeitpunkt für die Öffnung des Logins. Format ISO_8601 "2000-01-01T00:00:00+02:00"
   */
  loginOpenDateTime: string
  /**
   * @title Softopening Start
   * @description Time for when softopening starts. Format ISO_8601 "2000-01-01T00:00:00+02:00"
   * @title_de Softopening Beginn
   * @description_de Zeitpunkt für den Start des Softopening. Format ISO_8601 "2000-01-01T00:00:00+02:00"
   */
  softOpeningDateTime: string
  /**
   * @title Begin of Fair
   * @description Date for the event start. Format ISO_8601 "2000-01-01T00:00:00+02:00"
   * @title_de Beginn der Veranstaltung
   * @description_de Zeitpunkt für Veranstaltungsstart. Format ISO_8601 "2000-01-01T00:00:00+02:00"
   */
  eventStartDateTime: string
  /**
   * @title End of Fair
   * @description Date for the fair end. Format ISO_8601 "2000-01-01T00:00:00+02:00"
   * @title_de Ende der Veranstaltung
   * @description_de Zeitpunkt für Veranstaltungsende. Format ISO_8601 "2000-01-01T00:00:00+02:00"
   */
  eventEndDateTime: string
  /**
   * @title Default eventdate starttime 
   * @description Default starttime for eventdates without starttime. Format HH:mm
   * @title_de Default EventDate Startzeit
   * @description_de Default Startzeit von Eventdates ohne Startzeit. Format HH:mm
   */
  eventDateDefaultStartTime: string
  /**
   * @title Default eventdate time 
   * @description Default starttime for eventdates without starttime. Format HH:mm
   * @title_de Default EventDate Startzeit
   * @description_de Default Startzeit von Eventdates ohne Startzeit. Format HH:mm
   */
  eventDateDefaultEndTime: string
  /**
   * @title Days with Events
   * @description List of days where events are running. Format ISO_8601 "2020-09-25T00:00:00+02:00"
   * @title_de Tage mit Events
   * @description_de Liste mit Tagen an denen Events stattfinden. Format ISO_8601 "2020-09-25T00:00:00+02:00"
   */
  eventDays: string[]
  /**
   * @title Days where Calendar Entries are allowed
   * @description List of days where calendar entries are allowed. Format ISO_8601 "2020-09-25T00:00:00+02:00"
   * @title_de Tage an denen Kalender Einträge erlaubt sind
   * @description_de Liste mit Tagen an denen Kalender Einträge erlaubt sind. Format ISO_8601 "2020-09-25T00:00:00+02:00"
   */
  meetingDays: string[]
  /**
   * @title Event Days Format Pattern
   * @description Pattern for formatting event dates. (e.g. EEEE, MMMM d, yyyy)
   * @title_de Event Tage Format
   * @description_de Format zur Formatierung von Event Tage Daten. (z.B. EEEE, d. MMMM yyyy)
   */
  eventDaysFormatPattern: LocalizedString
  /**
   * @title Event Days Format Pattern (short)
   * @description Pattern for formatting event dates. (e.g. MMMM d, yyyy)
   * @title_de Event Tage Format (kurz)
   * @description_de Format zur Formatierung von Event Tage Daten. (z.B. d. MMMM yyyy)
   */
  eventDaysFormatPatternShort: LocalizedString
  /**
   * @title Event Days Format Pattern with Time
   * @description Pattern for formatting event dates with time. (e.g. dddd, MMMM d [at] HH:mm)
   * @title_de Event Tage Format mit Zeit
   * @description_de Format zur Formatierung von Event Tage Daten mit Zeit. (z.B. dddd d. MMMM [um] HH:mm)
   */
  eventDaysFormatPatternWithTime: LocalizedString
  /**
   * @title Event Days Format Pattern Detail Page
   * @description Pattern for formatting event dates on detail page. (e.g. dddd, MMMM d [at] HH:mm)
   * @title_de Event Tage Format Detail Seite
   * @description_de Format zur Formatierung von Event Tage Daten auf Detailseite. (z.B. dddd d. MMMM [um] HH:mm)
   */
  eventDaysFormatPatternEvtDetailPage: LocalizedString
}

export type LocalizedString = string & { __localizedBrand?: undefined }
export type ColorString = string & { __colorBrand?: undefined }

export interface PluralString {
  /**
   * @title Singular
   * @description Text for singular string
   * @title_de Singular
   * @description_de Text for singular string
   */
  singular: LocalizedString
  /**
   * @title Plural
   * @description Text for singular plural
   * @title_de Plural
   * @description_de Text for singular plural
   */
  plural: LocalizedString
}
export interface ResolvedPluralString {
  /**
   * @title Singular
   * @description Text for singular string
   * @title_de Singular
   * @description_de Text for singular string
   */
  singular: string
  /**
   * @title Plural
   * @description Text for singular plural
   * @title_de Plural
   * @description_de Text for singular plural
   */
  plural: string
}

export function getPluralString(pluralString: ResolvedPluralString, count: number, countPlaceHolder?: string) {
  const stringResource = count === 1 ? pluralString.singular : pluralString.plural
  if (countPlaceHolder) {
    return stringResource.split(`{$${countPlaceHolder}}`).join(`${count}`)
  }
  return stringResource
}

export interface MeetingDurationType {
  /**
   * @title Timelimit for call
   * @description Timelimit for call
   * @title_de Zietlimit für Anrufe
   * @description_de Zeitlimit für Anrufe
   */
  call: number
  /**
   * @title Timelimit for calenderEntry
   * @description Timelimit for calls from a calendar entry
   * @title_de Zietlimit für Kalender Einträge
   * @description_de Zeitlimit für Anrufe in einem Kalender Eintrag
   */
  calenderEntry: number
}

export interface Configuration {
  /**
   * @title topic name
   * @description Name of the topic for the data access (e.g. 2021_hh)
   * @title_de topic name
   * @description_de Name des topics für den Datenzugriff (z.b. 2021_hh)
   */
  topicName: string
  /**
   * @title userpool name
   * @description Name of the userpool for the user data access
   * @title_de userpool Name
   * @description_de Name des userPool für den Benutzerdaten Zugriff
   */
  userPoolName: string
  /**
   * @title seriesOfTopics Name
   * @description Name for the seriesoftopics, needed for authentification
   * @title_de seriesOfTopics Name
   * @description_de Name des seriesoftopics, wird für die Benutzer-Authentifikation
   */
  sotName: string
  /**
   * @title seriesOfTopics accessToken
   * @description AccessToken for the seriesoftopics, needed for authentification
   * @title_de seriesOfTopics accessToken
   * @description_de AccessToken des seriesoftopics, wird für die Benutzer-Authentifikation
   */
  sotAccessToken: string
  /**
   * @title Networking Toggle
   * @description Display toggle for turning on and off the networking feature for the user
   * @title_de Networking Button
   * @description_de Zeigt den Button zum ein-/ausschalten des Networking Features für den Nutzer
   */
  networkingToggle: boolean
  /**
   * @title Open Graph Title
   * @description Title for the meta-tag og:title
   * @title_de Open Graph Titel
   * @description_de Titel für das og:title meta-tag
   */
  ogTitle: LocalizedString
  /**
   * @title Open Graph Description
   * @description Title for the meta-tag og:title
   * @title_de Open Graph Beschreibung
   * @description_de Titel für das og:description meta-tag
   */
  ogDescription: LocalizedString
  /**
   * @title google meta-tag
   * @description Content for the meta-tag "google"
   * @title_de google meta-tag
   * @description_de Inhalt für das meta-tag "google"
   */
  browserTranslation: string
  /**
   * // TODO delete me when DEP-1966 done
   * @title Speaker Person Functions
   * @description Person Functions which identifies a person as a speaker
   * @title_de Speaker Personen Funktionen
   * @description_de Funktionsnamen die eine Person als Speaker ausweisen
   */
  speakerPersonFunctions: string[]
  /**
   * // TODO delete me when DEP-1966 done
   * @title Default Staff Person Function
   * @description default staff person function when adding a person as staff member
   * @title_de Default Staff Personen Funktionen
   * @description_de Default Funktion für Personen die als Staff hinzugefügt werden
   */
  defaultStaffPersonFunction: string
  /**
   * @title Calendar Entry Participant Limit
   * @description Limit for participants in a calendar entry. It has to be equal or greater then Chat Participant Limit(chatParticipantLimit)
   * @title_de Kalendar Eintrag Personen Limit
   * @description_de Limit für Anzahl an Personen in einem Kalender Eintrag. Muss größer oder gleich des Chat Personen Limit sein (chatParticipantLimit)
   */
  calendarEntryParticipantLimit: number
  /**
   * @title Show Privacy Modal for Event Dates
   * @description Show Privacy Modal for Event Dates
   * @title_de Zeige das Privatsphäre Popup für Events
   * @description_de Zeige das Privatsphäre Popup für Events
   */
  showPrivacyModelForEventDates: boolean
  /**
   * @title Chat Participant Limit
   * @description Limit for participants in a chat
   * @title_de Chat Personen Limit
   * @description_de Limit für die Anzahl an Personen in einem Chat
   */
  chatParticipantLimit: number
  /**
   * @title Virtual Cafe Participant Limit
   * @description Limit for participants in a virtual cafe
   * @title_de Virtual Cafe Personen Limit
   * @description_de Limit für die Anzahl an Personen in einem Virtual Cafe
   */
  virtualCafeParticipantLimit: number
  /**
   * @title Greenroom Participant Limit
   * @description Limit for participants in a greenroom
   * @title_de Greenroom Personen Limit
   * @description_de Limit für die Anzahl an Personen in einem Greenroom
   */
  greenRoomParticipantLimit: number
  /**
   * @title Breakout Participant Limit
   * @description Limit for participants in a breakout
   * @title_de Breakout Personen Limit
   * @description_de Limit für die Anzahl an Personen in einem Breakout
   */
  breakoutParticipantLimit: number
  /**
   * @title Conference room participant Limit
   * @description Limit for participants in a conference room
   * @title_de Conference Raum Personen Limit
   * @description_de Limit für die Anzahl an Personen in einem Conference Raum
   */
  conferenceRoomParticipantLimit: number
  /**
   * @title Showroom Participant Limit
   * @description Limit for participants in a showroom
   * @title_de Showroom Personen Limit
   * @description_de Limit für die Anzahl an Personen in einem Showroom
   */
  showRoomParticipantLimit: number
  /**
   * @title Roundtable Participant Limit
   * @description Limit for participants in a roundtable
   * @title_de Roundtable Personen Limit
   * @description_de Limit für die Anzahl an Personen in einem Roundtable
   */
  roundTableParticipantLimit: number
  /**
   * @title Roundtable Open Before
   * @description Time in minutes the roundtable is open before it starts
   * @title_de Roundtable Offen vor Start
   * @description_de Zeit die der Roundtable offen ist, bevor er wirklich startet
   */
  roundTableOpenBeforeMinutes: number
  /**
   * @title Roundtable Open After
   * @description Time in minutes the roundtable is open after it ends
   * @title_de Roundtable Offen nach Ende
   * @description_de Zeit die der Roundtable offen ist, nachdem er wirklich endet
   */
  roundTableOpenAfterMinutes: number
  /**
   * @title Meeting Durations for calls
   * @description Max duration for calls
   * @title_de Meeting Dauer
   * @description_de Max Zeit für Anrufe
   * @usedInBackend
   */
  meetingDurations: MeetingDurationType
  /**
   * @title Filter for EventDate relevant persons
   * @description Filter persons whicht are related to eventdates in speaker component and list on lobby
   * @title_de Filter EventDate relevante Personen
   * @description_de Filter Personen die mit EventDates verknüpft sind in Speaker Komponente und Liste in Lobbys
   */
  eventDateParticipation: boolean
  /**
   * @title Backoffice Toggles for Detailpage Information
   * @description Show toggles in backoffice for show/hide of products / trademarks on organization detailpage
   * @title_de Backoffice Buttons für Detailpage Informationen
   * @description_de Zeige Buttons im Backoffice für das ein-/ausblenden von Produkten und Trademarks
   */
  detailSectionToggle: boolean
  /**
   * @title Internal Email Adresses
   * @description Email adresses for identifing users as internal for analytics
   * @title_de Interne Email Adressen
   * @description_de E-Mail Adressen um Benutzer für analytics als intern zu markieren 
   */
  internEmailAdresses: string[]
  /**
   * @title Breakout room enable
   * @description Breakout room enable
   * @title_de Breakout Raum aktivieren
   * @description_de Breakout Raum aktivieren
   */
  breakoutEnabled: boolean
  /**
   * @title Conference room timer remaining seconds
   * @description When there is no moderator in room, reduce timer to start counting from given value
   * @title_de Konferenzraum-Timer verbleibende Sekunden
   * @description_de Wenn kein Moderator im Raum ist, reduzieren Sie den Timer, um ab dem angegebenen Wert zu zählen
  */
  conferenceRoomNoModRemainingDuration: number
}

export interface PresenceConfiguration {
  /**
   * @title Activate Presence State
   * @description (de)activate the presence feature
   * @title_de Online-Status für Benutzer aktivieren
   * @description_de (De)Aktiviert das Online-Status Feature 
   */
  usePresence: boolean
  /**
   * @title Offline after x ms
   * @description Number of milliseconds before a user is marked as offline. Also used as interval for the update of the localusers presence.
   * @title_de Offline nach x ms
   * @description_de Anzahl an Millisekunden bevor ein Benutzer als Offline angezeigt wird. Wird auch als Interval für das Update des Online-Status des lokalen Benutzers genutzt.
   */
  offlineAfterXMillis: number
  /**
   * @title Min Request Debounce Millis
   * @description Minimum of milliseconds between retrieving presence states
   * @title_de Min Request Debounce Millis
   * @description_de Minimum an Millisekunden zwischen Online-Status Abfragen
   */
  minRequestDebounceMillis: number
  /**
   * @title Debounce Increase Factor
   * @description Factor for increasing retrieving times when an error occurs
   * @title_de Debounce Erhöhungs Faktor
   * @description_de Faktor zur Erhöhung der Verzögerung zwischen Online-Status Abfragen, wenn ein Fehler auftritt
   */
  requestDebounceIncFactor: number
  /**
   * @title Debounce Decrease Factor
   * @description Factor for decreasing retrieving times when everything is working fine
   * @title_de Debounce Verringerungs Faktor
   * @description_de Faktor zur Verringerung der Verzögerung zwischen Online-Status Abfragen, wenn alles ok ist
   */
  requestDebounceDecFactor: number
}

export interface ConferenceListTiles {
  /**
  * @title Gradient div background first 
  * @description Div gradient for conference list tiles
  * @title_de Gradient div background first
  * @description_de Div gradient for conference list tiles
  */
  gradientDivBackgroundFirst: string
  /**
  * @title Gradient div background second 
  * @description Div gradient for conference list tiles second
  * @title_de Gradient div background second
  * @description_de Div gradient for conference list tiles second
  */
  gradientDivBackgroundSecond: string
  /**
   * @title Header sticky background 
   * @description Background for sticky header (example: rgba(240,240,240,0.7))
   * @title_de Header sticky background
   * @description_de Background for sticky header (example: rgba(240,240,240,0.7))
   */
  headerStickyBackground: string
  /**
  * @title Card background 
  * @description Background for card (example: rgb(250,250,250))
  * @title_de Card background
  * @description_de Background for card (example: rgb(250,250,250))
  */
  cardBackground: string
  /**
   * @title Corousel background 
   * @description Background for corousel (example: rgba(200,204,204,0.4))
   * @title_de Corousel background
   * @description_de Background for corousel (example: rgba(200,204,204,0.4))
   */
  corouselBackground: string
  /**
   * @title Sponsors background 1 
   * @description Background color 1 for sponsors 
   * @title_de Sponsors background 1
   * @description_de Background color 1 for sponsors 
   */
  sponsorsBackground1: string
  /**
   * @title Sponsors background 2 
   * @description Background color 2 for sponsors 
   * @title_de Sponsors background 2
   * @description_de Background color 2 for sponsors 
   */
  sponsorsBackground2: string
  /**
   * @title Sponsors background 3 
   * @description Background color 3 for sponsors 
   * @title_de Sponsors background 3
   * @description_de Background color 3 for sponsors 
   */
  sponsorsBackground3: string
  /**
   * @title Sponsors background 4 
   * @description Background color 4 for sponsors 
   * @title_de Sponsors background 4
   * @description_de Background color 4 for sponsors 
   */
  sponsorsBackground4: string
  /**
   * @title Sponsors background 5 
   * @description Background color 5 for sponsors 
   * @title_de Sponsors background 5
   * @description_de Background color 5 for sponsors 
   */
  sponsorsBackground5: string
}

export interface TopSponsor {
  /**
  * @title id 
  * @description Value for top sponsor id  
  * @title_de id
  * @description_de Value for top sponsor id 
  */
  id: string
  /**
   * @title name 
   * @description Value for top sponsor name  
   * @title_de name
   * @description_de Value for top sponsor name 
   */
  name: string
  /**
   * @title logo 
   * @description Value for top sponsor logo  
   * @title_de logo
   * @description_de Value for top sponsor logo 
   */
  logo: string
  /**
  * @title Use custom name 
  * @description Enable / disable usability of custom name  
  * @title_de Use custom name
  * @description_de Enable / disable usability of custom name 
  */
  useCustomName: boolean
  /**
   * @title Order
   * @description Order of sponsors. -1 is default and this way everything will be ordered as coming from backend
   * @title_de Reihenfolge
   * @description_de Reihenfolge der Sponsoren. -1 ist der Defaultwert und führt dazu das die Reihenfolge des Backends verwendet
   */
  order: number
}
export interface TopBarBranding {
  /**
   * @title Font weight
   * @description Font weight
   * @title_de Font weight
   * @description_de Font weight
   */
  fontWeight: string

  /**
   * @title Text color
   * @description Text color
   * @title_de Text color
   * @description_de Text color
   */
  textColor: string

  /**
   * @title Background color
   * @description Background color
   * @title_de Background color
   * @description_de Background color
   */
  background: string

  /**
   * @title Search title text color
   * @description Search title text color
   * @title_de Search title text color
   * @description_de Search title text color
   */
  searchTitleColor: string

  /**
   * @title Search title font family
   * @description Search title font family
   * @title_de Search title font family
   * @description_de Search title font family
   */
  searchTitleFontFamily: string

  /**
 * @title Search title font size
 * @description Search title font size
 * @title_de Search title font size
 * @description_de Search title font size
 */
  searchTitleFontSize: string

  /**
 * @title Search title font weight
 * @description Search title font weight
 * @title_de Search title font weight
 * @description_de Search title font weight
 */
  searchTitleFontWeight: string

  /**
 * @title Search title text transform value
 * @description Search title text transform value
 * @title_de Search title text transform value
 * @description_de Search title text transform value
 */
  searchTitleTextTranform: string

  /**
 * @title Search title letter spacing
 * @description Search title letter spacing
 * @title_de Search title letter spacing
 * @description_de Search title letter spacing
 */
  searchTitleLetterSpacing: string

  /**
   * @title Breadcrumb font family
   * @description Breadcrumb font family
   * @title_de Breadcrumb font family
   * @description_de Breadcrumb font family
   */
  breadcrumbTextFontFamily: string

  /**
   * @title Breadcrumb font size
   * @description Breadcrumb font size
   * @title_de Breadcrumb font size
   * @description_de Breadcrumb font size
   */
  breadcrumbTextFontSize: string

  /**
   * @title Breadcrumb font weight
   * @description Breadcrumb font weight
   * @title_de Breadcrumb font weight
   * @description_de Breadcrumb font weight
   */
  breadcrumbTextFontWeight: string

  /**
* @title Breadcrumb text transform value
* @description Breadcrumb text transform value
* @title_de Breadcrumb text transform value
* @description_de Breadcrumb text transform value
*/
  breadcrumbTextTransform: string

  /**
* @title Breadcrumb letter spacing
* @description Breadcrumb letter spacing
* @title_de Breadcrumb letter spacing
* @description_de Breadcrumb letter spacing
*/
  breadcrumbLetterSpacing: string
}

export interface TrackingBranding {
  /**
 * @title Detail page column visibility
 * @description Detail page column visibility
 * @title_de Detail page column visibility
 * @description_de Detail page column visibility
 * * 
 * @UsedByBackend
 */
  detailpage: boolean

  /**
  * @title Showroom column visibility
  * @description Showroom column visibility
  * @title_de Showroom column visibility
  * @description_de Showroom column visibility
  * 
  * @UsedByBackend
  */
  showroom: boolean

  /**
  * @title Calendar entry column visibility
  * @description Calendar entry column visibility
  * @title_de Calendar entry column visibility
  * @description_de Calendar entry column visibility
  * 
  * @UsedByBackend
  */
  calendarEntry: boolean

  /**
  * @title Registered interest column visibility
  * @description Registered interest column visibility
  * @title_de Registered interest column visibility
  * @description_de Registered interest column visibility
  * 
  * @UsedByBackend
  */
  interest: boolean

  /**
  * @title Recommendation column visibility
  * @description Recommendation column visibility
  * @title_de Recommendation column visibility
  * @description_de Recommendation column visibility
  * 
  * @UsedByBackend
  */
  recommend: boolean

  /**
  * @title Virtual Cafe column visibility
  * @description Virtual Cafe column visibility
  * @title_de Virtual Cafe column visibility
  * @description_de Virtual Cafe column visibility
  * 
  * @UsedByBackend
  */
  lounge: boolean

  /**
  * @title Links column visibility
  * @description Links column visibility
  * @title_de Links column visibility
  * @description_de Links column visibility
  * 
  * @UsedByBackend
  */
  links: boolean

  /**
  * @title Media column visibility
  * @description Media column visibility
  * @title_de Media column visibility
  * @description_de Media column visibility
  * 
  * @UsedByBackend
  */
  media: boolean

  /**
  * @title Livestreams column visibility
  * @description Livestreams column visibility
  * @title_de Livestreams column visibility
  * @description_de Livestreams column visibility
  * 
  * @UsedByBackend
  */
  stream: boolean

  /**
  * @title Schedule column visibility
  * @description Schedule column visibility
  * @title_de Schedule column visibility
  * @description_de Schedule column visibility
  * 
  * @UsedByBackend
  */
  schedule: boolean

  /**
  * @title Goodie bag column visibility
  * @description Goodie bag column visibility
  * @title_de Goodie bag column visibility
  * @description_de Goodie bag column visibility
  * 
  * @UsedByBackend
  */
  coupon: boolean

  /**
  * @title Press column visibility
  * @description Press column visibility
  * @title_de Press column visibility
  * @description_de Press column visibility
  * 
  * @UsedByBackend
  */
  press: boolean

  /**
  * @title Magazine column visibility
  * @description Magazine column visibility
  * @title_de Magazine column visibility
  * @description_de Magazine column visibility
  * 
  * @UsedByBackend
  */
  magazine: boolean

  /**
  * @title Street column visibility
  * @description Street column visibility
  * @title_de Street column visibility
  * @description_de Street column visibility
  * 
  * @UsedByBackend
  */
  street: boolean

  /**
  * @title HouseNr column visibility
  * @description HouseNr column visibility
  * @title_de HouseNr column visibility
  * @description_de HouseNr column visibility
  * 
  * @UsedByBackend
  */
  houseNr: boolean

  /**
  * @title ZIP code column visibility
  * @description ZIP code column visibility
  * @title_de ZIP code column visibility
  * @description_de ZIP code column visibility
  * 
  * @UsedByBackend
  */
  zipCode: boolean

  /**
  * @title City column visibility
  * @description City column visibility
  * @title_de City column visibility
  * @description_de City column visibility
  * 
  * @UsedByBackend
  */
  city: boolean

  /**
  * @title Visitors tab visibility
  * @description Visitors tab visibility
  * @title_de Visitors tab visibility
  * @description_de Visitors tab visibility
  */
  showVisitorsTab: boolean

  /**
 * @title Guests list option visibility in visitors tab
 * @description Guests list option visibility in visitors tab
 * @title_de Guests list option visibility in visitors tab
 * @description_de Guests list option visibility in visitors tab
 */
  showGuestsSelect: boolean

  /**
 * @title Visitors list option visibility in visitors tab
 * @description Visitors list option visibility in visitors tab
 * @title_de Visitors list option visibility in visitors tab
 * @description_de Visitors list option visibility in visitors tab
 */
  showVisitorsSelect: boolean

  /**
 * @title Leads list option visibility in visitors tab
 * @description Leads list option visibility in visitors tab
 * @title_de Leads list option visibility in visitors tab
 * @description_de Leads list option visibility in visitors tab
 */
  showLeadsSelect: boolean

  /**
 * @title Contacts list option visibility in visitors tab
 * @description Contacts list option visibility in visitors tab
 * @title_de Contacts list option visibility in visitors tab
 * @description_de Contacts list option visibility in visitors tab
 */
  showContactsSelect: boolean

  /**
 * @title Database selects list option visibility in visitors tab
 * @description Database selects list option visibility in visitors tab
 * @title_de Database selects list option visibility in visitors tab
 * @description_de Database selects list option visibility in visitors tab
 */
  showDbSelect: boolean


  /**
  * @title Reports tab visibility
  * @description Reports tab visibility
  * @title_de Reports tab visibility
  * @description_de Reports tab visibility
  */
  showReportTab: boolean

  /**
   * @title Enable download of visitors
   * @description Enable download of visitors lists
   * @title_de Enable download of visitors
   * @description_de Enable download of visitors lists
   */
  enableDownloadVisitors: boolean

  /**
  * @title Enable download of statistics
  * @description Enable download of statistics reports
  * @title_de Enable download of statistics
  * @description_de Enable download of statistics reports
  */
  enableDownloadStatistics: boolean
}

export interface CSVExportBranding {
  /**
   * @title First name
   * @description First name
   * @title_de First name
   * @description_de First name
   * 
   * @UsedByBackend
   */
  firstName: LocalizedString

  /**
  * @title Last name
  * @description Last name
  * @title_de Last name
  * @description_de Last name
  * 
  * @UsedByBackend
  */
  lastName: LocalizedString

  /**
  * @title Company
  * @description Company
  * @title_de Company
  * @description_de Company
  * 
  * @UsedByBackend
  */
  company: LocalizedString

  /**
  * @title Job title
  * @description Job title
  * @title_de Job title
  * @description_de Job title
  * 
  * @UsedByBackend
  */
  jobTitle: LocalizedString

  /**
  * @title Street adress
  * @description Street adress
  * @title_de Street adress
  * @description_de Street adress
  * 
  * @UsedByBackend
  */
  street: LocalizedString

  /**
  * @title House No.
  * @description House No.
  * @title_de House No.
  * @description_de House No.
  * 
  * @UsedByBackend
  */
  houseNr: LocalizedString

  /**
  * @title ZIP code
  * @description ZIP code
  * @title_de ZIP code
  * @description_de ZIP code
  * 
  * @UsedByBackend
  */
  zipCode: LocalizedString

  /**
  * @title City
  * @description City
  * @title_de City
  * @description_de City
  * 
  * @UsedByBackend
  */
  city: LocalizedString

  /**
  * @title Country
  * @description Country
  * @title_de Country
  * @description_de Country
  * 
  * @UsedByBackend
  */
  country: LocalizedString

  /**
  * @title E-mail
  * @description E-mail
  * @title_de E-mail
  * @description_de E-mail
  * 
  * @UsedByBackend
  */
  email: LocalizedString

  /**
  * @title Phone
  * @description Phone
  * @title_de Phone
  * @description_de Phone
  * 
  * @UsedByBackend
  */
  phone: LocalizedString

  /**
  * @title Interests
  * @description Interests
  * @title_de Interests
  * @description_de Interests
  * 
  * @UsedByBackend
  */
  interests: LocalizedString

  /**
  * @title Opt-in
  * @description Opt-in
  * @title_de Opt-in
  * @description_de Opt-in
  * 
  * @UsedByBackend
  */
  optIn: LocalizedString

  /**
  * @title Detail page
  * @description Detail page
  * @title_de Detail page
  * @description_de Detail page
  * 
  * @UsedByBackend
  */
  detailPage: LocalizedString

  /**
  * @title Showroom
  * @description Showroom
  * @title_de Showroom
  * @description_de Showroom
  * 
  * @UsedByBackend
  */
  showroom: LocalizedString

  /**
  * @title Requsted meeting
  * @description Requsted meeting
  * @title_de Requsted meeting
  * @description_de Requsted meeting
  * 
  * @UsedByBackend
  */
  requestedMeeting: LocalizedString

  /**
  * @title Registered interest
  * @description Registered interest
  * @title_de Registered interest
  * @description_de Registered interest
  * 
  * @UsedByBackend
  */
  registeredInterest: LocalizedString

  /**
  * @title Recommendation
  * @description Recommendation
  * @title_de Recommendation
  * @description_de Recommendation
  * 
  * @UsedByBackend
  */
  recommendation: LocalizedString

  /**
  * @title Virtual Cafe
  * @description Virtual Cafe
  * @title_de Virtual Cafe
  * @description_de Virtual Cafe
  * 
  * @UsedByBackend
  */
  virtualCafe: LocalizedString

  /**
  * @title Virtual Space
  * @description Virtual Space
  * @title_de Virtual Space
  * @description_de Virtual Space
  * 
  * @UsedByBackend
  */
  virtualSpace: LocalizedString

  /**
  * @title Link
  * @description Link
  * @title_de Link
  * @description_de Link
  * 
  * @UsedByBackend
  */
  link: LocalizedString

  /**
  * @title Media
  * @description Media
  * @title_de Media
  * @description_de Media
  * 
  * @UsedByBackend
  */
  media: LocalizedString

  /**
  * @title Livestream
  * @description Livestream
  * @title_de Livestream
  * @description_de Livestream
  * 
  * @UsedByBackend
  */
  livestream: LocalizedString

  /**
  * @title Schedule slot
  * @description Schedule slot
  * @title_de Schedule slot
  * @description_de Schedule slot
  * 
  * @UsedByBackend
  */
  conferenceSlot: LocalizedString

  /**
  * @title Goodie bag
  * @description Goodie bag
  * @title_de Goodie bag
  * @description_de Goodie bag
  * 
  * @UsedByBackend
  */
  goodieBag: LocalizedString

  /**
  * @title Last interaction
  * @description Last interaction
  * @title_de Last interaction
  * @description_de Last interaction
  * 
  * @UsedByBackend
  */
  lastInteraction: LocalizedString

  /**
  * @title Marked by
  * @description Marked by
  * @title_de Marked by
  * @description_de Marked by
  * 
  * @UsedByBackend
  */
  markedBy: LocalizedString

  /**
  * @title Marked at
  * @description Marked at
  * @title_de Marked at
  * @description_de Marked at
  * 
  * @UsedByBackend
  */
  markedAt: LocalizedString

  /**
  * @title Press
  * @description Press
  * @title_de Press
  * @description_de Press
  * 
  * @UsedByBackend
  */
  press: LocalizedString

  /**
  * @title Magazine
  * @description Magazine
  * @title_de Magazine
  * @description_de Magazine
  * 
  * @UsedByBackend
  */
  magazine: LocalizedString
}

export interface VideoPlayerBranding {
  /**
   * @title Picture-In-Picture-Player double click hint
   * @description Hint that is displayed when mouse hovers over Picture-In-Picture-Player and should include page redirect hint to channel page on double click
   * @title_de Picture-In-Picture-Player Doppelklickhinweis
   * @description_de Hinweis, der angezeigt wird, wenn sich Cursor auf Picture-In-Picture-Player befindet, sollte dem Nutzer erklären, dass durch Doppelklick auf die Channelpage weitergeleitet wird
   */
  pipDoubleClickHint: LocalizedString
  /**
   * @title Picture-In-Picture-Player Stream Error Message
   * @description Message that is displayed in Picture-In-Picture-Player when video streaming source has a problem
   * @title_de Picture-In-Picture-Player Videostreaming-Fehlermeldung
   * @description_de Fehlermeldung, die im Picture-In-Picture-Player angezeigt wird, wenn die Videostreamingquelle ein Problem hat
   */
  pipStreamErrorMessage: LocalizedString
  /**
   * @title Video-Player Stream Error Message
   * @description Message that is displayed in Video-Player when video streaming source has a problem
   * @title_de Video-Player Videostreaming-Fehlermeldung
   * @description_de Fehlermeldung, die im Video-Player angezeigt wird, wenn die Videostreamingquelle ein Problem hat
   */
  videoPlayerStreamErrorMessage: LocalizedString
  /**
   * @title Dummy Video-Player No Access Message
   * @description Message that is displayed in Dummy Video-Player when user does not have access to current event
   * @title_de Dummy Video-Player No Access Message
   * @description_de Message that is displayed in Dummy Video-Player when user does not have access to current event
   */
  dummyVideoPlayerNoAccessMessage: LocalizedString

}

export interface VideoPageContentBranding {
  /**
   * @title Breakout message
   * @description Breakout message
   * @title_de Breakout-Nachricht
   * @description_de Breakout-Nachricht
   */
  joinBreakoutMessage: LocalizedString
  /**
   * @title Button text
   * @description Join breakout button
   * @title_de Schaltflächentext
   * @description_de Text für Join-Breakout-Schaltfläche
   */
  joinBreakoutButtonText: LocalizedString
  /**
   * @title Button color
   * @description Color of button
   * @title_de Knopffarbe
   * @description_de Farbe der Taste
   */
  joinBreakoutButtonColor: ColorString
  /**
   * @title Button text color
   * @description Text color for join breakout button
   * @title_de Schaltfläche Textfarbe
   * @description_de Textfarbe für Join-Breakout-Schaltfläche
   */
  joinBreakoutButtonTextColor: ColorString
  /**
   * @title Participants counter text
   * @description Participants counter text
   * @title_de Teilnehmer kontern den Text
   * @description_de Teilnehmer kontern den Text
   */
  participantsCounterText: LocalizedString
  /**
   * @title Coming up text
   * @description Text for "Coming up" message before switching to the next event
   * @title_de Coming up text
   * @description_de Text for "Coming up" message before switching to the next event
   */
  comingUpText: LocalizedString
  /**
  * @title Minutes before switching to the next event
  * @description Set how many minutes before should the channel switch to the next event
  * @title_de Minutes before switching to the next event
  * @description_de Set how many minutes before should the channel switch to the next event
  */
  minutesBeforeSwitchingToTheNextEvent: number
  /**
   * @title Polling time in millis
   * @description Time in millis which execute update interval for checking moderators inside breakout room, if value <= 0 , polling is disabled
   * @title_de Polling time in millis
   * @description_de Time in millis which execute update interval for checking moderators inside breakout room, if value <= 0 , polling is disabled
   */
  pollingTime: number
}

export interface BackendErrorBranding {
  /**
   * @title Error message
   * @description Text for the error message
   * @title_de Error message
   * @description_de Text for the error message
   */
  errorMessage: LocalizedString
  /**
   * @title Error button title
   * @description Text for the error button title
   * @title_de Error button title
   * @description_de Text for the error button title
   */
  errorButtonTitle: LocalizedString
  /**
  * @title Backend error color
  * @description Text color and border color for the backend error button
  * @title_de Backend error color
  * @description_de Text color and border color for the backend error button
  */
  backendErrorColor: ColorString
  /**
   * @title Backend error dark color
   * @description Text color and border color in lighter version applied when the meeting page was opened
   * @title_de Backend error dark color
   * @description_de Text color and border color in lighter version applied when the meeting page was opened
   */
  backendErrorDarkColor: ColorString
}

declare let branding: Branding
branding = new BrandingImpl(branding, "en")
const brandingImplCallable = branding as any

function getBranding(): Branding {
  return branding
}
export default getBranding()

export function setBrandingLanguage(lang: string) {
  brandingImplCallable.setLang(lang)
}