
export const homePageRoute: string = "/"
export const systemCheckPageRoute: string = "/check"
export const networkingPageRoute: string = "/networking"
export const myProfilePageRoute: string = "/myprofile"
export const editMyProfilePageRoute: string = "/myprofile?mode=EDIT"
export const categoriesPageRoute: string = "/showfloor#category"
export const helpPageRoute: string = "/help"
export const rulesPageRoute: string = "/rules"
export const termsOfUsePageRoute: string = "/termsofuse"
export const testPageRoute: string = "/test"
export const historyPageRoute: string = "/history"
export const pressMediaPageRoute: string = "/press"
export const businessAreaPageRoute: string = "/business"
export const iframeTestPageRoute: string = "/iframetest"


/* Details */
export const organizationBackOfficePageRoute: string = "/company/:organizationName?--:organizationId/backoffice"
export const organizationDetailPageRoute: string = "/company/:organizationName?--:organizationId"
export const personDetailPageRoutePerson: string = "/person/:personName?--p-:personId"
export const personDetailPageRouteUser: string = "/person/:personName?--u-:userId"
export const eventDateDetailPageRoute: string = "/eventdate/:eventdateName?--:eventDateId"
export const productDetailPageRoute: string = "/product/:productName?--:productId"
export const newsDetailPageRoute: string = "/news/:newsName?--:newsId"
export const trademarkDetailPageRoute: string = "/trademark/:trademarkName?--:trademarkId"
export const couponDetailPageRoute: string = "/coupon/:couponName?--:couponId"
export const meetingPageRoute: string = "/meeting/:roomName"
export const conferenceGroupPageRoute: string = "/meetings/:groupId"
export const videoRoomPageRoute: string = "/channel/:channelId"

export const conferencePageRoute: string = "/meetings"
export const showfloorPageRoute: string = "/showfloor"
export const exhibitorsPageRoute: string = "/showfloor#organization"
export const productsPageRoute: string = "/showfloor#product"
export const trademarksPageRoute: string = "/showfloor#trademark"
export const newsPageRoute: string = "/showfloor#news"
export const couponsPageRoute: string = "/coupon"
export const sponsorsPageRoute: string = "/sponsor"
export const experiencesPageRoute: string = "/experiences"
export const programPageRoute: string = "/program"
export const schedulePageRoute: string = "/program#schedule"
export const speakersPageRoute: string = "/program#speakers"
export const bookmarkedSpeakersPageRoute: string = "/myspeakers"
export const bookmarkedshowfloorPageRoute: string = "/myexhibitors"
export const mySchedulePageRoute: string = "/myschedule"
export const mediaPartnerPageRoute: string = "/mediapartner"
export const exhibitorStartupPageRoute: string = "/startup"


