import React from 'react'
import styled from "styled-components"
import { buildDetailLink, DetailNavLink } from "../contentArea/detailPages/DetailNavLink"
import { ShareTargetType, ModalType } from "../backendServices/Types"
import { useLoggedInState } from "../globalStates/LoggedInUser"
import { useAppState } from "../globalStates/AppState"
import { useMeetingContext } from "../conference/context/MeetingContext"
import { useLanguageState } from "../globalStates/LanguageState"
import { useState, useEffect } from "react"
import { ChatConversationParam } from "../communicationArea/ChatPage"
import { BackendServiceError, SettingsKey, setUserSetting, Settings, loadUserData, SpeakerResponse } from "../backendServices/BackendServices"
import { Category } from "../backendServices/Types"
import AvatarWithPresenceState from "./AvatarWithPresenceState"
import { IconChat, IconRemoveStaff, IconPhone, IconMeetingSchedule, IconShare, IconShow, IconHide, IconCamera, IconCameraOff } from "./Icons"
import BookmarkWithToggle from "./BookmarkWithToggle"
import { isExplorationOrPostEventPhase } from "../utils/EventPhaseChecker"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import branding from "../branding/branding"
import BadgeArea from './BadgeArea'
import ConfirmCall from '../communicationArea/CommunicationOptions'
import { DivBadge } from "./BadgeArea"
import { useRecommendOrganizationModal } from './RecommendOrganizationModal'
import { useCalendarEntryModal } from './CalendarEntryModal'


export enum StaffType {
    PERSON,
    USER
}

export interface Staff {
    firstName: string
    lastName: string
    id: string
    type: StaffType
    logoUrl: string
    position: string
    company: string
    sotUserId?: string
    visible: boolean
    showroomStandby: boolean
    title: string
    categories: Category[]
}

const StaffEntryRoot = styled.div`
    padding: 25px 0;
    display: flex;
    border-bottom: 1px solid ${branding.listDividerColor};

    &.disabled {
        cursor: auto;
        color: gray;
        pointer-events: none;
    }



`

const StyledDetailNaviLink = styled(DetailNavLink)`
    margin-top: 7px;
    margin-bottom: 8px; 
`

const StaffContent = styled.div`
    padding-left: 25px;
    margin-left: 2px;
`

const OrganizationNameLabel = styled.div`
    color: ${branding.mainInfoColor};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
   
`

const OrganizationPositionLabel = styled.div`
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    color: ${branding.mainInfoColor};
    line-height: 0.8rem;
`

const MenuPanel = styled.div`
    display: flex;
    margin-left: auto;
    color: ${branding.primaryColor};
`

const MenuButton = styled.div`
    cursor: pointer;
    margin-bottom: auto;
    margin-left: 20px;
    height: 25px;
    width: 25px;

    &.disabled {
        opacity: 0.6;
        cursor: not-allowed;
        pointer-events: none;
    }
`

interface StaffEntryProps {
    staff: Staff
    organizationId: string
    backOffice: boolean
    removeStaffFromOrga?: (staff: Staff) => void //only for backoffice
    onAccessDenied?: () => void
    leadFinder?: boolean // only for lead finder
    setModalType?: (type: ModalType) => void // only for lead finder page
    setSelectedUser?: (value: any) => void // only for lead finder page
}

export const StaffEntry: React.FunctionComponent<StaffEntryProps> = (props) => {
    const loggedInState = useLoggedInState()
    const profileId = loggedInState.user()?.profileId || ""
    const appState = useAppState()
    const meeting = useMeetingContext()
    const strings = useLanguageState().getStrings()
    const [staff, setStaff] = useState<Staff>(props.staff)
    const [showMeAsContactToggled, setShowMeAsContactToggled] = useState<boolean>(props.staff.visible)
    const [showShowroomStandby, setShowShowroomStandby] = useState<boolean>(props.staff.showroomStandby)
    const [showCall, setShowCall] = useState(false)
    const { showRecommendOrganizationModal, OrganizationModal } = useRecommendOrganizationModal()
    const { showCalendarEntryModal, CalendarModal } = useCalendarEntryModal()
    const [isBlocked, setIsBlocked] = useState<boolean>(false)

    async function loadConnectionStatus() {
        const response = await loadUserData({ targetProfileId: props.staff.sotUserId ?? props.staff.id, loggedInUserId: profileId })

        if ((response as BackendServiceError).httpStatus) {
            return
        }

        setIsBlocked((response as SpeakerResponse).content.myConnectionStatus === "BLOCKING" || (response as SpeakerResponse).content.myConnectionStatus === "BLOCKED")
    }

    useEffect(() => {
        loadConnectionStatus()
        // eslint-disable-next-line
    }, [])

    const staffName = [staff.title, staff.firstName, staff.lastName].filter(Boolean).join(" ")
    const staffPosition: string = [staff.position, staff.company]
        .filter(Boolean)
        .join(" " + strings.communicationArea.personCompanyLink + " ");

    function chatWithUser() {
        if (loggedInState.user() && loggedInState.user()?.invitingOrganization && loggedInState.user()?.invitingOrganization?.id) {
            if (props.onAccessDenied) {
                props.onAccessDenied()
            }
            return
        }

        appState.setShowChatsTab(ChatConversationParam.privateConversationByOpponentId(staff.id))
    }

    function callUser() {
        if (loggedInState.user() && loggedInState.user()?.invitingOrganization && loggedInState.user()?.invitingOrganization?.id) {
            if (props.onAccessDenied) {
                props.onAccessDenied()
            }
            return
        }

        meeting.sendInvite(staff.id)
    }

    function setSettingVisible() {
        (async () => {
            const profileId = staff.sotUserId

            if (profileId === undefined) {
                return
            }
            const response = await setUserSetting(profileId as string, SettingsKey.VISIBLE, "" + (!showMeAsContactToggled), props.organizationId);
            if ((response as BackendServiceError).httpStatus) {
            } else {
                setShowMeAsContactToggled((response as Settings).visible)
            }
        })()
    }

    function setSettingShowroomStandby() {
        (async () => {
            const profileId = staff.sotUserId

            if (profileId === undefined) {
                return
            }

            const response = await setUserSetting(profileId as string, SettingsKey.SHOWROOMSTANDBY, "" + (!showShowroomStandby), props.organizationId);
            if ((response as BackendServiceError).httpStatus) {
            } else {
                setShowShowroomStandby((response as Settings).showroomStandby)
            }
        })()
    }

    async function loadUser() {
        if (props.staff.type === StaffType.PERSON && props.staff.sotUserId && profileId) {
            const mergedStaff = {
                ...props.staff,
                type: StaffType.USER,
                id: props.staff.sotUserId
            }
            setStaff(mergedStaff)
        } else {
            setStaff(props.staff)
        }
    }

    useEffect(() => {
        loadUser()
    }, [props.staff.id]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <StaffEntryRoot className={isBlocked ? "disabled" : ""}>
               <div style={{display: 'flex', width: '100%'}}>
                    <div style={{width: '95px'}}>
                    <StyledDetailNaviLink id={staff.id} type={staff.type === StaffType.USER ? "user" : 'person'} name={`/person/${staff.firstName}_${staff.lastName}`}>
                    <AvatarWithPresenceState userId={staff.id} avatarSize={101} showAvatarBadge={true} badgeSize={20} badgeRight={0} badgeTop={60} content={{ pictureUrl: staff.logoUrl, alt: [staff.firstName, staff.lastName].filter(Boolean).join(" ") }} />
                    </StyledDetailNaviLink>
                    </div>
                <div>
                <StaffContent>
                    <DetailNavLink id={staff.id} type={staff.type === StaffType.USER ? "user" : 'person'} name={`/person/${staff.firstName}_${staff.lastName}`}>
                        <OrganizationNameLabel style={{ color: isBlocked ? branding.organizationDetailPageContent.blockedStaffIconColor : branding.mainInfoColor, fontWeight: 'bold', marginBottom: '10px' }}>
                            {staffName}
                        </OrganizationNameLabel>
                        <OrganizationPositionLabel style={{ color: isBlocked ? branding.organizationDetailPageContent.blockedStaffIconColor : branding.mainInfoColor }}>
                            {staffPosition}
                        </OrganizationPositionLabel>
                        {staff.categories && <BadgeArea mainInfoColor={true} categories={staff.categories} type="A" />}
                    </DetailNavLink>
                    </StaffContent>
                </div>

              
                </div>
                <MenuPanel>
                    {props.backOffice && staff.type === StaffType.USER && profileId === staff.id &&
                        <div style={{ width: "100px", fontSize: '12px', display: 'flex', justifyContent: 'flex-end' }}>
                            <DivBadge marginTop="0px" bgImage="" bgColor="#fff" borderColor={branding.listDividerColor} textColor={branding.mainInfoColor} >
                                {strings.backofficeStaff.myBadgeText}
                            </DivBadge>
                        </div>
                    }
                    {props.backOffice && staff.type === StaffType.USER && profileId !== staff.id &&
                       <OverlayTrigger
                            trigger={["click", "focus", "hover"]}
                            placement="bottom"
                            overlay={<Tooltip id="button-tooltip-visible" style={{ fontFamily: branding.font1 }}>{showMeAsContactToggled ? strings.backofficeStaff.showMeAsContactDeactivateHoverText : strings.backofficeStaff.showMeAsContactHoverText}</Tooltip>}>
                            <MenuButton onClick={() => setSettingVisible()}>{showMeAsContactToggled ? IconShow({ fill: isBlocked ? branding.organizationDetailPageContent.blockedStaffIconColor : branding.sideIconBar.sideIconColorDark }) : IconHide({ fill: isBlocked ? branding.organizationDetailPageContent.blockedStaffIconColor : branding.sideIconBar.sideIconColorDark })}
                            </MenuButton>
                        </OverlayTrigger>
                    }
                    {props.backOffice && staff.type === StaffType.USER && profileId !== staff.id &&
                        <OverlayTrigger
                            trigger={["click", "focus", "hover"]}
                            placement="bottom"
                            overlay={<Tooltip id="button-tooltip-showroomStandby" style={{ fontFamily: branding.font1 }}>{showShowroomStandby ? strings.backofficeStaff.showShoowroomStandbyDeactivateHoverText : strings.backofficeStaff.showShoowroomStandbyHoverText}</Tooltip>}>
                            <MenuButton onClick={() => setSettingShowroomStandby()}>{showShowroomStandby ? IconCamera({ fill: isBlocked ? branding.organizationDetailPageContent.blockedStaffIconColor : branding.sideIconBar.sideIconColorDark }) : IconCameraOff({ fill: isBlocked ? branding.organizationDetailPageContent.blockedStaffIconColor : branding.sideIconBar.sideIconColorDark })}
                            </MenuButton>
                        </OverlayTrigger>
                    }
                    {staff.type === StaffType.USER && profileId !== staff.id &&
                        <OverlayTrigger
                            trigger={["click", "focus", "hover"]}
                            placement="bottom"
                            overlay={<Tooltip id="button-tooltip-chat" style={{ fontFamily: branding.font1 }}>{strings.backofficeStaff.startChat}</Tooltip>}>
                            <MenuButton onClick={() => {
                                if (props.backOffice)
                                    chatWithUser()
                                else
                                    
                                    appState.setShowChatsTab(ChatConversationParam.privateConversationByOpponentId(staff.sotUserId!))
                            }}>{IconChat({ fill: isBlocked ? branding.organizationDetailPageContent.blockedStaffIconColor : branding.sideIconBar.sideIconColorDark })}</MenuButton>
                        </OverlayTrigger>}
                    {staff.type === StaffType.USER && profileId !== staff.id &&
                        <OverlayTrigger
                            trigger={["click", "focus", "hover"]}
                            placement="bottom"
                            overlay={<Tooltip id="button-tooltip-call" style={{ fontFamily: branding.font1 }}>{strings.backofficeStaff.startCall}</Tooltip>}>
                            <MenuButton className={isExplorationOrPostEventPhase ? "disabled" : ""} onClick={() => props.backOffice ? callUser() : setShowCall(true)}>{IconPhone({ fill: isBlocked ? branding.organizationDetailPageContent.blockedStaffIconColor : branding.sideIconBar.sideIconColorDark, width: "20", height: "20" })}</MenuButton>
                        </OverlayTrigger>}
                    {!props.backOffice && staff.type === StaffType.USER && profileId !== staff.id &&
                        <OverlayTrigger
                            trigger={["click", "focus", "hover"]}
                            placement="bottom"
                            overlay={<Tooltip id="button-tooltip-meeting" style={{ fontFamily: branding.font1 }}>{strings.backofficeStaff.requestMeeting}</Tooltip>}>
                            <MenuButton onClick={() => {
                                if (props.leadFinder && props.setModalType && props.setSelectedUser) {
                                    props.setModalType("meeting")
                                    props.setSelectedUser(staff)
                                }
                                else
                                    showCalendarEntryModal(props.organizationId, [{
                                        id: staff.sotUserId ? staff.sotUserId : "",
                                        firstName: staff.firstName,
                                        lastName: staff.lastName,
                                        logoUrl: staff.logoUrl,
                                    }])
                            }}>{IconMeetingSchedule({ fill: isBlocked ? branding.organizationDetailPageContent.blockedStaffIconColor : branding.sideIconBar.sideIconColorDark })}</MenuButton>
                        </OverlayTrigger>}
                    {!props.backOffice &&
                        <OverlayTrigger
                            trigger={["click", "focus", "hover"]}
                            placement="bottom"
                            overlay={<Tooltip id="button-tooltip-share" style={{ fontFamily: branding.font1 }}>{strings.backofficeStaff.share}</Tooltip>}>
                            <MenuButton onClick={() => showRecommendOrganizationModal(ShareTargetType.PERSON, props.staff.id, buildDetailLink(props.staff.id, `/person/${props.staff.firstName}_${props.staff.lastName}`, "person"))}>{IconShare({ fill: isBlocked ? branding.organizationDetailPageContent.blockedStaffIconColor : branding.sideIconBar.sideIconColorDark })}</MenuButton>
                        </OverlayTrigger>
                    }
                    {!props.backOffice &&
                        <OverlayTrigger
                            trigger={["click", "focus", "hover"]}
                            placement="bottom"
                            overlay={<Tooltip id="button-tooltip-share" style={{ fontFamily: branding.font1 }}>{strings.backofficeStaff.bookmark}</Tooltip>}>
                            <MenuButton><BookmarkWithToggle  newBookmarkItem={true} fontSize={"25px"} color={isBlocked ? branding.organizationDetailPageContent.blockedStaffIconColor : branding.sideIconBar.sideIconColorDark} type={props.leadFinder ? "sotuser" : "person"} id={staff.id} /></MenuButton>
                        </OverlayTrigger>
                    }
                    {props.backOffice &&
                        <OverlayTrigger
                            trigger={["click", "focus", "hover"]}
                            placement="bottom"
                            overlay={<Tooltip id="button-tooltip-remove" style={{ fontFamily: branding.font1 }}>{strings.backofficeStaff.removeStaff}</Tooltip>}>
                            <MenuButton onClick={() => props.removeStaffFromOrga!(staff)}>{IconRemoveStaff({ fill: isBlocked ? branding.organizationDetailPageContent.blockedStaffIconColor : branding.sideIconBar.sideIconColorDark })}</MenuButton>
                        </OverlayTrigger>}
                </MenuPanel>
                {showCall && <ConfirmCall show={true} onHide={() => setShowCall(false)} sotUserId={staff.sotUserId || ""} />}
                <OrganizationModal />
                <CalendarModal />
            </StaffEntryRoot>
        </>
    )
}