import React, { useEffect, useState } from 'react'
import { useOnlineUsers } from '../../tracking/LoungeUsers'
import styled from 'styled-components'
import branding from "../../branding/branding";
import { useHistory, NavLink } from 'react-router-dom';
import { DetailNavLink } from '../detailPages/DetailNavLink';
import CrsPersons from '../../ui/CrsPersons';
import { ConferenceRoom, Person, UserConferenceRoomAccessType } from '../../backendServices/Types';
import { trackVisit, UserOrganizationVisitType, UserRestrictedAreaAccess, RestrictedAreaTypes, MeetingKind, RestrictedAreaType } from '../../backendServices/BackendServices';
import { useLoggedInState } from '../../globalStates/LoggedInUser';
import { useLanguageState } from '../../globalStates/LanguageState';
import { getExternalMeetingId, getUrlForMeeting } from '../../conference/context/ChimeContext';
import RequestAccessModal from '../../ui/RequestAccessModal';
import GuestModal from "../../ui/GuestModal";
import { isExplorationOrPostEventPhase } from "../../utils/EventPhaseChecker"
import { hasAccessToOrganization } from '../../utils/Authorization';
import useWindowDimensions from '../../ui/WindowDimensionsHook';
import { getIamPartOf } from '../../globalStates/IAmPartOf';
import queryString from 'query-string'
import { MeetingRoomGroupType, MeetingRoomType } from '../../conference/AudioVideoBranding';
import { MobileShowfloorTile } from '../reception/LobbyPageContent';

const queryParams: any = queryString.parse(window.location.search)

const Tile = styled(NavLink) <{ $pictureUrl?: string, margin?: string, disableOverlayGradient?: boolean }>`
    position: relative;
    display: flex;
    flex-flow: column;
    width: 500px;
    height: 280px;
    background: ${props => [
        props.disableOverlayGradient ? null : branding.meetingRoomGroupsOverlayValue,
        props.$pictureUrl ? `url("${props.$pictureUrl}");` : null
    ].filter(Boolean).join(",")};
    color: ${props => props.$pictureUrl ? "#fff" : "#000"};
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 20px;
    margin:${props => props.margin ?? "20px 20px 0 20px"};
    color: white !important;
    cursor: ${isExplorationOrPostEventPhase && !(getIamPartOf(queryParams) === "onboarding") ? "default" : "pointer"};
    border-radius: 5px;

    &.private {
        cursor: not-allowed;
    }

    &:hover, &:active, &:link, &:focus {
        text-decoration: none;
        color: #fff;
    }

    .pers-row {
        div{
            width:30px;
            height: 30px;
            margin-right: 5px;
            font-size: 14px;
            img {
                width:30px;
                height: 30px;
            }
        }
    }
    & h2 {
        font-family: ${branding.font2};
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 23px;
        text-transform: uppercase;
    }

    &:after {
        border-radius: 5px;
    }

    &:before {
        border-radius: 5px;
        content: '';
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: ${props => [
        props.disableOverlayGradient ? null : branding.meetingRoomGroupsOverlayValue
    ]};
        opacity: 0.2;
        z-index: 1;
    }
    &>*{
        z-index: 2;
    }
    .btn-reqest-access {
        font-family: ${branding.font1};
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
        border-radius: 1rem;
        &.focus, &:focus,
        &.hover, &:hover,
        &.active, &:active {
            box-shadow: none !important;
        }
    }

    .bottom-overlay { 
        position: absolute;
        height: 30%;
        z-index: 1;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgb(0,0,0);
        background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 100%);
    }
`

const MeetingRoomGroupTitle = styled.h2`
    white-space: pre-line;
    font: ${branding.meetingRoomGroupsTitleFontStyle ? branding.meetingRoomGroupsTitleFontStyle : "normal bold 18px/23px"} ${branding.font1} !important;
`

const HostedBy = styled.div`
    font: ${branding.meetingRoomGroupsHosterFontStyle ? branding.meetingRoomGroupsHosterFontStyle : "normal 300 10px/12px"} ${branding.font1};
    & div {
        display: inline;
        text-decoration: underline;
    }
`

const MeetingRoomGroupDescription = styled.div`
    margin-top: auto;
    white-space: pre-line;
    max-width: 260px;
    font: ${branding.meetingRoomGroupsDescriptionFontStyle ? branding.meetingRoomGroupsDescriptionFontStyle : "normal 300 12px/17px"} ${branding.font1};
`

/* #region  Meeting Room Group Component */
const ActionButtonDiv = styled.div<{ static?: boolean }>`
    display: flex;
    width: 140px;
    height: 30px;
    position: absolute;
    right: 20px;
    border: 1px solid white;
    border-radius: 50px;
    justify-content: center;
    align-items: center;
    color: black;
    background-color: white;
    text-decoration: none;
    cursor: ${props => props.static ? "default" : "pointer"};
    font-size: ${branding.receptionPage.virtualCafeAccessButtonFontSize ?? "12px"};
    line-height: 17px;
    font-family: ${branding.font1};


    :hover {
        background-color: ${props => props.static ? "none" : "rgba(0,0,0,0.2)"};
        color: ${props => props.static ? "black" : `white`};
        text-decoration: none;
    }
`
const MoreContactsText = styled.p`
    margin-bottom: 0rem;
`;
export interface MeetingRoomGroupProps {
    meetingRoomGroupConfig: MeetingRoomGroupType | ConferenceRoom
    accessStatus?: string
    source?: 'LOBBY' | 'ORG_DETAIL' | 'VC'
    setRefresh?: () => void
    margin?: string
    roomType: MeetingKind
    isMobile?: boolean
}

const PersonsRowWrapper = styled.div`
    .persons-row > div > div:last-child > div {
        position: absolute;
    }

    /* hack for CrsPersons */
  @media (max-width: 1400px) {
    .persons-row img {
      width: 36px;
      height: 36px;
    }
    .persons-row > div > div:last-child > div {
      position: absolute;
      width: 30px;
      height: 30px;
    }
  }
  @media (max-width: 1000px) {
    .persons-row img {
      width: 30px;
      height: 30px;
    }
    .persons-row > div > div:last-child > div {
      position: absolute;
      width: 30px;
      height: 30px;
    }
  }
`

const MobileVCInfosItem = styled.div`
  display: flex;
  font-family: ${branding.font1};
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  width: 171px;
  margin-top: 10px;

  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`

const MobileVCInfos = styled.div`
  display: flex;
  flex-flow: column;
  position: absolute;
  top: 5px;
  margin-left: 10px;

  ${MobileVCInfosItem}:first-child {
    width: 171px;
    font-weight: bold !important;
    font-size: 14px !important;
    line-height: 16px !important;
    -webkit-line-clamp: 3;
  }
`

const MeetingRoomGroup: React.FunctionComponent<MeetingRoomGroupProps> = (props) => {
    const onlineUsers = useOnlineUsers()
    const persons = props.source ? onlineUsers.getUsersInRoomOrLounge(props.meetingRoomGroupConfig.id).map((x: any) => { return { logoUrl: x.user.pictureUrl, id: x.id, name: x.user.name } }) : []
    const history = useHistory();
    const loggedInUserId = useLoggedInState().user()?.profileId
    const loggedUser = useLoggedInState().user()
    const visitType: UserOrganizationVisitType = props.source === 'ORG_DETAIL' ? 'VC#DETAIL' : props.source === 'LOBBY' ? 'VC#LOBBY' : 'VC#VC'
    const languageState = useLanguageState();
    const strings = languageState.getStrings();
    const [userRequestAccessLoaded, setUserRequestAccessLoaded] = useState<boolean>(false)
    const [userVirtualCafeAccess, setUserVirtualCafeAccess] = useState<UserRestrictedAreaAccess>()
    //const [error, setError] = useState<BackendServiceError | string | null>(null)
    const [showRequestAccessModal, setShowRequestAccessModal] = useState<boolean>(false)
    const [showGuestModal, setShowGuestModal] = useState<boolean>(false)
    const queryParams: any = queryString.parse(window.location.search)

    function getRestrictedAreaType(roomType: MeetingKind): RestrictedAreaType {
        switch (roomType) {
            case "virtualCafe":
                return RestrictedAreaTypes.VirtualCafe
            case "roundtable":
                return RestrictedAreaTypes.RoundTable
            case "conferenceroom":
                return RestrictedAreaTypes.ConferenceRoom
            default:
                return RestrictedAreaTypes.VirtualCafe;
        }
    }

    function checkStaffAccess(): boolean {
        return ((loggedUser?.organizations?.find(o => o.id === (props.meetingRoomGroupConfig as MeetingRoomGroupType).organizationId || o.id === (props.meetingRoomGroupConfig as ConferenceRoom).organization) !== undefined))!
    }

    let actionButtonField = null

    const onRequestAccessClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.preventDefault();
        event.stopPropagation();

        if (!loggedUser || !hasAccessToOrganization(loggedUser, (props.meetingRoomGroupConfig as MeetingRoomGroupType).organizationId ?? (props.meetingRoomGroupConfig as ConferenceRoom).organization)) {
            setShowGuestModal(true)
            return
        }

        setShowRequestAccessModal(true)
    }

    function setActionButtonField() {
        if (!((props.meetingRoomGroupConfig as MeetingRoomGroupType).isPrivate ?? (props.meetingRoomGroupConfig as ConferenceRoom).accessType === UserConferenceRoomAccessType.ORGA_MANAGED) || (props.accessStatus && props.accessStatus === "GRANTED") || userVirtualCafeAccess?.status === "GRANTED" || checkStaffAccess()) {
            return
        }
        if (props.accessStatus && !userRequestAccessLoaded) {
            if (props.accessStatus === "REQUESTED") {
                actionButtonField = (<ActionButtonDiv static={true}>
                    <MoreContactsText style={{ zIndex: 3 }}>{strings.globalTexts.requestSent}</MoreContactsText></ActionButtonDiv>)
            } else {
                actionButtonField = (<ActionButtonDiv onClick={onRequestAccessClick}>
                    <MoreContactsText style={{ zIndex: 3 }}>{strings.globalTexts.requestAccess}</MoreContactsText></ActionButtonDiv>)
            }
        }
        else if (userRequestAccessLoaded === true && userVirtualCafeAccess) {
            if (userVirtualCafeAccess && userVirtualCafeAccess.status === "REQUESTED") {
                actionButtonField = (<ActionButtonDiv static={true}>
                    <MoreContactsText style={{ zIndex: 3 }}>{strings.globalTexts.requestSent}</MoreContactsText></ActionButtonDiv>)
            } else {
                actionButtonField = (<ActionButtonDiv onClick={onRequestAccessClick}>
                    <MoreContactsText style={{ zIndex: 3 }}>{strings.globalTexts.requestAccess}</MoreContactsText></ActionButtonDiv>)
            }
        }
    }
    setActionButtonField()

    const onEnterCafe = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
        event.stopPropagation();

        if (isExplorationOrPostEventPhase && !(getIamPartOf(queryParams) === "onboarding"))
            return

        if (loggedUser && ((props.meetingRoomGroupConfig as MeetingRoomGroupType).organizationId ?? (props.meetingRoomGroupConfig as ConferenceRoom).organization) && !hasAccessToOrganization(loggedUser, (props.meetingRoomGroupConfig as MeetingRoomGroupType).organizationId ?? (props.meetingRoomGroupConfig as ConferenceRoom).organization)) {
            setShowGuestModal(true)
            return
        }

        if (!((props.meetingRoomGroupConfig as MeetingRoomGroupType).isPrivate ?? (props.meetingRoomGroupConfig as ConferenceRoom).accessType === UserConferenceRoomAccessType.ORGA_MANAGED) || (props.accessStatus && props.accessStatus === "GRANTED") || userVirtualCafeAccess?.status === "GRANTED" || checkStaffAccess()) {

            if (props.roomType === "conferenceroom") {
                history.push("/meeting/" + getExternalMeetingId(props.meetingRoomGroupConfig.id, "conferenceroom") + "/createorjoin")
            } else {
                history.push("/meetings/" + props.meetingRoomGroupConfig.id);
            }
            if (loggedInUserId && (props.meetingRoomGroupConfig as MeetingRoomGroupType).organizationId && props.roomType === "virtualCafe") {
                trackVisit(loggedInUserId, (props.meetingRoomGroupConfig as MeetingRoomGroupType).organizationId, visitType, props.meetingRoomGroupConfig.id)
            }
        }
        if (props.setRefresh)
            props.setRefresh()
    }

    useEffect(() => {
        if (props.roomType === "virtualCafe") {
            onlineUsers.subscribe()
            return () => onlineUsers.unsubscribe()
        }
    }, [props.meetingRoomGroupConfig.id]) //eslint-disable-line

    useEffect(() => {
        setActionButtonField()
        // eslint-disable-next-line
    }, [userVirtualCafeAccess, userRequestAccessLoaded])

    if (props.isMobile) {
        return <MobileShowfloorTile onClick={onEnterCafe} to={`${props.roomType === 'virtualCafe' ? "/meetings/" + props.meetingRoomGroupConfig.id : "/meeting/" + getExternalMeetingId(props.meetingRoomGroupConfig.id, "conferenceroom") + "/createorjoin"}`} $background={(props.meetingRoomGroupConfig as MeetingRoomGroupType).pictureUrl ?? (props.meetingRoomGroupConfig as ConferenceRoom).backgroundImage} >
            <MobileVCInfos>
                <MobileVCInfosItem>{((props.meetingRoomGroupConfig as MeetingRoomGroupType).title ?? languageState.getLanguage() === "en" ? (props.meetingRoomGroupConfig as ConferenceRoom).title : (props.meetingRoomGroupConfig as ConferenceRoom).titleDe)}</MobileVCInfosItem>
                <MobileVCInfosItem>{((props.meetingRoomGroupConfig as MeetingRoomGroupType).description ?? languageState.getLanguage() === "en" ? (props.meetingRoomGroupConfig as ConferenceRoom).teaser : (props.meetingRoomGroupConfig as ConferenceRoom).teaserDe)}</MobileVCInfosItem>
            </MobileVCInfos>
        </MobileShowfloorTile>
    }

    return <>
        <Tile to={`${props.roomType === 'virtualCafe' ? "/meetings/" + props.meetingRoomGroupConfig.id : "/meeting/" + getExternalMeetingId(props.meetingRoomGroupConfig.id, "conferenceroom") + "/createorjoin"}`} margin={props.margin} onClick={onEnterCafe} $pictureUrl={(props.meetingRoomGroupConfig as MeetingRoomGroupType).pictureUrl ?? (props.meetingRoomGroupConfig as ConferenceRoom).backgroundImage} disableOverlayGradient={branding.meetingRoomGroupsDisablePictureOverlay} className={((props.meetingRoomGroupConfig as MeetingRoomGroupType).isPrivate ?? (props.meetingRoomGroupConfig as ConferenceRoom).accessType === UserConferenceRoomAccessType.ORGA_MANAGED) && (props.accessStatus && props.accessStatus !== "GRANTED") && !checkStaffAccess() ? "private" : undefined} style={{ marginLeft: "0px", marginRight: branding.meetingRoomMarginRight }}>
            {(props.meetingRoomGroupConfig as MeetingRoomGroupType).bottomExtraOverlay && <div className={'bottom-overlay'}></div>}
            {showRequestAccessModal &&
                <RequestAccessModal
                    onUserRequestLoadedSet={setUserRequestAccessLoaded}
                    onUserRestrictedAreaAccessSet={setUserVirtualCafeAccess}
                    restrictedAreaId={props.meetingRoomGroupConfig.id}
                    restrictedAreaType={getRestrictedAreaType(props.roomType)}
                    onHide={() => { setShowRequestAccessModal(false) }}>
                </RequestAccessModal>}
            <div className="row">
                <div className={((props.meetingRoomGroupConfig as MeetingRoomGroupType).isPrivate ?? (props.meetingRoomGroupConfig as ConferenceRoom).accessType === UserConferenceRoomAccessType.ORGA_MANAGED) ? "col-7" : "col-12"}>
                    <MeetingRoomGroupTitle>{(((props.meetingRoomGroupConfig as MeetingRoomGroupType).titleVisible) ? props.meetingRoomGroupConfig.title : (languageState.getLanguage() === "en" ? (props.meetingRoomGroupConfig as ConferenceRoom).title : (props.meetingRoomGroupConfig as ConferenceRoom).titleDe))}</MeetingRoomGroupTitle>
                    {(props.meetingRoomGroupConfig as MeetingRoomGroupType).organizationName && (props.meetingRoomGroupConfig as MeetingRoomGroupType).organizationId && props.source !== 'ORG_DETAIL' && (
                        <HostedBy>
                            {strings.globalTexts.hostedByText}{" "}
                            <DetailNavLink id={(props.meetingRoomGroupConfig as MeetingRoomGroupType).organizationId} name={(props.meetingRoomGroupConfig as MeetingRoomGroupType).organizationName} type="organization" source={props.roomType === "virtualCafe" ? "VC" : undefined}>
                                {(props.meetingRoomGroupConfig as MeetingRoomGroupType).organizationName}
                            </DetailNavLink>
                        </HostedBy>
                    )}
                </div>
                <div>
                    {actionButtonField}
                </div>
            </div>
            <PersonsRowWrapper style={{ marginTop: "auto" }} className="row align-items-center">
                <div className={(persons.length > 3 ? "col-md-6" : "col-md-8") + " col-12"}>{((props.meetingRoomGroupConfig as MeetingRoomGroupType).description || (languageState.getLanguage() === "en" ? (props.meetingRoomGroupConfig as ConferenceRoom).teaser : (props.meetingRoomGroupConfig as ConferenceRoom).teaserDe)) && <MeetingRoomGroupDescription style={{ width: "unset" }}>{((props.meetingRoomGroupConfig as MeetingRoomGroupType).description || (languageState.getLanguage() === "en" ? (props.meetingRoomGroupConfig as ConferenceRoom).teaser : (props.meetingRoomGroupConfig as ConferenceRoom).teaserDe))}</MeetingRoomGroupDescription>}</div>
                <div className={(persons.length > 3 ? "col-md-6" : "col-md-4") + " col-12 mt-3 mt-md-0 align-self-end persons-row"}>
                    <CrsPersons justifycontent="flex-end" layoutType={1} showModalPopup={false} persons={persons as Person[]} personsToShow={4} width={"30px"} height={"30px"}></CrsPersons>
                </div>
            </PersonsRowWrapper>
        </Tile>
        {showGuestModal && <GuestModal close={() => setShowGuestModal(false)} />}
    </>
}

export default MeetingRoomGroup
/* #endregion */

/* #region  Meeting Room Component */
interface MeetingRoomProps {
    meetingRoom: MeetingRoomType
    groupId: string
    organizationId: string
    setRefresh?: () => void
}
export const MeetingRoom: React.FunctionComponent<MeetingRoomProps> = (props) => {
    const room = props.groupId + "/" + props.meetingRoom.id
    const onlineUsers = useOnlineUsers()
    const persons = onlineUsers.getUsersInRoomOrLounge(room).map((x: any) => { return { logoUrl: x.user.pictureUrl, name: x.user.name } })
    const history = useHistory();
    const loggedInUser = useLoggedInState().user()
    const windowSize = useWindowDimensions();


    useEffect(() => {
        onlineUsers.subscribe()
        return () => onlineUsers.unsubscribe()
    }, [room]) //eslint-disable-line

    const onEnterRoom = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
        event.stopPropagation();
        history.push(getUrlForMeeting(props.meetingRoom.id, "virtualCafe"));
        if (loggedInUser && props.organizationId) {
            trackVisit(loggedInUser.profileId, props.organizationId, 'VCROOM', props.groupId + '/' + props.meetingRoom.id)
        }
        if (props.setRefresh)
            props.setRefresh()
    }


    let customClassName = "d-flex flex-row justify-content-between align-items-center w-100"

    if (windowSize.width <= 1800)
        customClassName = "d-flex flex-column justify-content-center align-items-start w-100"

    return <Tile to={getUrlForMeeting(props.meetingRoom.id, "virtualCafe")} className={'tile-inside-vg-room'} onClick={onEnterRoom} $pictureUrl={props.meetingRoom.pictureUrl} disableOverlayGradient={branding.meetingRoomGroupsDisablePictureOverlay}>
        <div className="d-flex justify-content-between flex-column align-items-start h-100">
            {/* <h2>{props.title}</h2> TODO h2 makes all CAPS */}
            <MeetingRoomGroupTitle>{props.meetingRoom.titleVisible && props.meetingRoom.title}</MeetingRoomGroupTitle>
            <div className={customClassName}>
                <div className="mb-2" style={{ maxHeight: "140px", overflow: "hidden" }}> {/* Better option would be oveflow scroll, but we know that scoll bars do not behave the same on pc-s */}
                    {props.meetingRoom.description && <MeetingRoomGroupDescription>{props.meetingRoom.description}</MeetingRoomGroupDescription>}
                </div>
                <div className="crs-persons-parent">
                    <CrsPersons layoutType={1} showModalPopup={false} persons={persons as Person[]} personsToShow={4}></CrsPersons>
                </div>
            </div>
        </div>
    </Tile>
}

/* #endregion */