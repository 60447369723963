import React from 'react'
import styled from 'styled-components';
import branding from '../branding/branding';
import { VGIcon } from './Icons';
import { DetailNavLink, buildDetailLink } from '../contentArea/detailPages/DetailNavLink';
import { NavLink } from 'react-router-dom';


const LiveTileRoot = styled(NavLink)`
    position: relative;
    display: flex;
    flex-flow: column;
    height: 270px;
    margin: ${branding.liveTileMargins ?? "0px 10px 10px 0px"};
    border: ${branding.mainBorder ? branding.mainBorder : '1px solid #d9d9d9'};
    border-radius: 5px;
    overflow: hidden;

    &:after {
      border-radius: 5px;
    }

    &:hover, &:active, &:focus, &:visited {
      text-decoration: none;
    }

    & h2 {
      font-family: ${branding.font1};
        font-style: normal;
        font-size: 12px;
        line-height: 17px;
        text-transform: uppercase;
        display: flex;
        margin-left: 10px;
        margin-top: 3px;
        flex: 1;
        text-align: center;
    }
    &:before {
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
        border-radius: 5px;
    }

    video {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;
    }  
`

const MobileLiveTileRoot = styled(NavLink)`
    position: relative;
    display: flex;
    flex-flow: column;
    height: 270px;
    margin: ${branding.liveTileMargins ?? "0px 10px 10px 0px"};
    border: ${branding.mainBorder ? branding.mainBorder : '1px solid #d9d9d9'};
    border-radius: 5px;
    overflow: hidden;

    &:after {
      border-radius: 5px;
    }

    &:hover, &:active, &:focus, &:visited {
      text-decoration: none;
    }

    & h2 {
      font-family: ${branding.font1};
        font-style: normal;
        font-size: 12px;
        line-height: 17px;
        text-transform: uppercase;
        display: flex;
        margin-left: 5px;
        margin-top: 3px;
        flex: 1;
        text-align: center;
    }
    &:before {
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
        border-radius: 5px;
    }

    video {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;
    }  
`

const LiveTileHeader = styled.div`
  position: absolute;
  display: flex;
  flex-flow: row;
  padding: 15px;
  width: 100%;
  color: white;
  z-index: 2;

  background: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%);
  background-blend-mode: multiply;
`

const MobileLiveTileHeader = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  padding: 15px;
  padding-left: 5px;
  padding-top: 5px;
  overflow-y: hidden;
  width: 100%;
  color: white;
  z-index: 2;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%);
`


const LiveTileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 480px;
  font-family: ${branding.font1};
`

const MobileLiveTileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 120px;
  margin-bottom: -20px;
`

interface LiveEventDateProps {
  id: string
  title: string
  channelId: string
  channelIcon: VGIcon
  thumbnailUrl: string
  liveSessionName?: string
  isMobile?: boolean
}

const LiveEvent: React.FunctionComponent<LiveEventDateProps> = (props) => {
  if (props.isMobile) {
    return <MobileLiveTileWrapper>
      <MobileLiveTileRoot to={buildDetailLink(props.channelId, props.title, "channel")} style={{ height: "95px" }}>
        <DetailNavLink type="channel" id={props.channelId} name={props.title}>
          <MobileLiveTileHeader style={{ fontSize: "10px", lineHeight: "17px" }}>
            <div>{props.channelIcon}</div>
            <h2>{props.title}</h2>
          </MobileLiveTileHeader>
          < img src={props.thumbnailUrl} style={{ width: "135px", minWidth: "135px", height: "95px", cursor: "pointer", borderRadius: "5px" }} alt="" />
        </DetailNavLink>
      </MobileLiveTileRoot>
    </MobileLiveTileWrapper>
  }


  return (
    <LiveTileWrapper>
      <LiveTileRoot to={buildDetailLink(props.channelId, props.title, "channel")}>
        <DetailNavLink type="channel" id={props.channelId} name={props.title}>
          <LiveTileHeader>
            <div>{props.channelIcon}</div>
            <h2>{props.title}</h2>
          </LiveTileHeader>
          < img src={props.thumbnailUrl} style={{ width: "480px", minWidth: "480px", height: "270px", cursor: "pointer", borderRadius: "5px" }} alt="" />
        </DetailNavLink>
      </LiveTileRoot>
      <div style={{ width: "95%" }}>
        {props.liveSessionName || <div style={{ height: '30px' }}></div>}
      </div>

    </LiveTileWrapper>
  )
}

export default LiveEvent;