import React, { useRef, useState, useEffect } from 'react'
import styled from 'styled-components'
import branding from '../branding/branding';
import { NavLink } from 'react-router-dom';
import { VGIcon, IconArrowBreadcrumb, IconLinkRightArrow } from './Icons';
import ScrollBarComponent from './ScrollBar';
import { useAppState } from "../globalStates/AppState";
import useWindowDimensions from './WindowDimensionsHook';
import { checkIfMobile } from '..';


/* #region  SideIconBar and CommunicationArea values */
const widthValues = {
    sideIconBarWidth: 60,
    communicationAreaWidth: {
        open: 320,
        closed: 60
    }
}
/* #endregion */

/* #region  Main Containers */
const TileRowRoot = styled.div<{ margin?: string }>` 
    display: flex;
    flex-direction: column;
    position: relative;
    margin: ${props => props.margin ?? "50px 25px 0 25px"};

    .icon-container {
        padding-left: 0;
        width: 40px;
    }
`

const TileRowContentContainer = styled.div<{ isMyFairPage?: boolean, width: string, rounded?: boolean }>`
    ${props => props.isMyFairPage ? "margin-right: 0px;" : ""}; 
    display: flex;
    overflow: auto;
    -ms-overflow-style: none;  
    scrollbar-width: none; 
    padding-top: 5px;
    ::-webkit-scrollbar {
        display: ${props => props.rounded ? "none" : ""};
    }
    width: ${props => props.width};
`
const ScrollerShadowLeft = styled.div`
    position: absolute;
    width: 5%;
    background: transparent;
    background: ${branding.primaryScrollWhiteShadowLTR};
    left: 0;
    top: 65px;
    bottom: 26px;
    z-index: 2;
    filter: ${branding.horizontalScrollShadowBlur ? `blur(${branding.horizontalScrollShadowBlur})` : 'initial'};
`

const ScrollerShadowRight = styled.div`
    position: absolute;
    width: 5%;
    background: transparent;
    background: ${branding.primaryScrollWhiteShadowLTR};
    right: 0;
    top: 65px;
    bottom: 26px;
    z-index: 2;
    transform: rotate(180deg);
    filter: ${branding.horizontalScrollShadowBlur ? `blur(${branding.horizontalScrollShadowBlur})` : 'initial'};
`

const TileWrapper = styled.div`
    display: flex;
    flex-flow: row;
`
/* #endregion */

/* #region  Header section */

const HeaderRow = styled.div`
    display: flex;
    position: relative;
    flex-flow: row;
    justify-content: flex-end;
    height: 60px;
    color: ${branding.mainInfoColor};
`

const HeaderTitleTemplate = styled.div`
    display: flex;
    flex-flow: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    flex: 1;
    align-items: center;
    font-family: ${branding.font1};
`

const HeaderTitleLobbyType = styled(HeaderTitleTemplate)`
    font-family: ${branding.receptionPage.tileRowTitleFont};
    margin-top: 22px;
    font-size: ${branding.receptionPage.topBarTitleFontSize ?? "18px"};
    line-height: 24px;
    font-weight: ${branding.receptionPage.topBarTitleFontWeight ?? "normal"};
`

const MobileHeaderTitleLobbyType = styled(HeaderTitleTemplate)`
    font-family: ${branding.receptionPage.tileRowTitleFont};
    margin-top: 30px;
    font-size: 14px;
    line-height: 16px;
    font-weight: ${branding.receptionPage.topBarTitleFontWeight ?? "normal"};
`

const HeaderTitleOrgaDetailType = styled(HeaderTitleTemplate)`
  margin-top: 22px;
  font-size: 28px;

  @media(max-width: 1600px) {
       font-size: 24px;
  }
`

const HeaderIcon = styled.div<{ iconVisible?: boolean }>`
    width: 70px;
    padding: 18px;
    margin-top: 3px;
    visibility: ${props => props.iconVisible ? "visible" : "hidden"};
    color: ${branding.primaryColor};
`
const TopBarLinkRoot = styled.span`
    border-bottom: ${branding.receptionPage.topBarLinkRoot.borderBottom ? branding.receptionPage.topBarLinkRoot.borderBottom as string : ""};
    margin-bottom: ${branding.receptionPage.topBarLinkRoot.marginBottom ? branding.receptionPage.topBarLinkRoot.marginBottom as string : ""};
    font-size: ${branding.receptionPage.topBarLinkRoot.fontSize ? branding.receptionPage.topBarLinkRoot.fontSize as string : ""};
    
    @media(max-width: 2200px){  
        white-space: nowrap;
        margin-left: 5px;
    }
`
const TopBarLink = styled(NavLink)`
    margin-top: 30px;
    font-family: ${branding.font1};
    display: flex;
    /* color: #6dd400; */
    justify-content: flex-end;
    height: 0;
    color: ${branding.receptionPage.topBarLinkRightArrowColor ?? "#6dd400"};
    font-size: 16px;
    line-height: 12px;
    text-align: right;

    &:hover {
        color: ${branding.receptionPage.topBarLinkRightArrowColor ?? "#6dd400"};
        text-decoration: none;
    }
`

const MobileTopBarLink = styled(NavLink)`
    margin-top: 30px;
    font-family: ${branding.font1};
    display: flex;
    justify-content: flex-end;
    height: 0;
    color: ${branding.receptionPage.mobileTopBarLinkRightArrowColor ?? "#000"};
    font-size: 12px;
    line-height: 16px;
    text-align: right;

    &:hover {
        color: #000;
        text-decoration: none;
    }
`

const TopBarLinkHeaderTitle = styled(NavLink) <{ titleVisible?: boolean }>`
  text-decoration: ${branding.receptionPage.receptionPageHeaderTitleTextDecoration ?? "none"};
  color: ${branding.mainInfoColor ?? "#000"};
  visibility: ${props => props.titleVisible ? "visible" : "hidden"};
    &:hover {
        text-decoration: none;
        color: ${branding.mainInfoColor ?? "#000"};
    }
`



const TopBarLinkHeaderSubTitle = styled(NavLink)`
  font-family: ${branding.receptionPage.tileRowSubtitleFont};
  font-size: 14px;
  margin-left: 20px;
  margin-top: 25px;
  font-weight: 400;
  letter-spacing: 5.5px;
  text-transform: ${branding.receptionPage.tileRowSubtitleTextTransform};
  text-decoration: ${branding.receptionPage.receptionPageHeaderTitleTextDecoration ?? "none"};
  color: ${branding.mainInfoColor ?? "#000"};
    &:hover {
        text-decoration: none;
        color: ${branding.mainInfoColor ?? "#000"};
    }
`

const SimpleTitle = styled.div`
    font-weight: bold;
    font-size: 14px;
`


/* #endregion */


type TitleRowProps = {
    title: string;
    titleVisible?: boolean;
    titleNoLink?: string
    height?: string;
    icon?: VGIcon;
    iconVisible?: boolean;
    navLink?: string;
    navLinkText?: string;
    navLinkTextVisible?: boolean;
    scrollBar?: boolean;
    isMyFairPage?: boolean;
    tileRowMargin?: string
    type?: "orgaDetail" | "lobby"
    offsetLeft?: number
    defaultScrollbar?: boolean //displays default scrollbar instead of rounded
    childWidth: number // Needed for Scrollbar Calculation
    childCount: number // Needed for Scrollbar Calculation
    hideTitle?: boolean
    selectedItem?: number // Used to change scroll position based on the selected item 
    hideShadows?: boolean
};



const TileRow: React.FunctionComponent<TitleRowProps> = (props) => {
    const scrollRef = useRef<HTMLDivElement>(null)
    const [position, setPosition] = useState(0)
    const [value, setValue] = useState(0)

    //calculating width offset
    const appState = useAppState()
    const [tileRowContentContainerWidth, setTileRowContentContainerWidth] = useState<string>("auto")
    // Are shadows active
    const [isShadowLeft, setIsShadowLeft] = useState<boolean>(false)
    const [isShadowRight, setIsShadowRight] = useState<boolean>(false)

    const windowSize = useWindowDimensions()
    const ifMobile = checkIfMobile(windowSize)

    //every time when communication center is open/closed, or window width is changed
    //width of the content container should be precalculated (just for orgaDetail type)
    useEffect(() => {
        const communicationAreaWidth = appState.isNetworkingOpen() ? widthValues.communicationAreaWidth.open : widthValues.communicationAreaWidth.closed
        const adjustWidth = widthValues.sideIconBarWidth + (props.offsetLeft ?? 0) + communicationAreaWidth //left side bar + container left offset + communication area 
        const width = props.type === "orgaDetail" ? `calc(100vw - ${adjustWidth}px)` : "auto"
        setTileRowContentContainerWidth(width)

        //eslint-disable-next-line
    }, [props.offsetLeft])

    useEffect(() => {
        scrollRef.current?.scrollTo(position, 0)
    }, [position])


    // Setting scroller position based on the selected item 
    useEffect(() => {
        if (!props.selectedItem) {
            setValue(0)
            setPosition(0)
            return
        }

        let maxScrollRange = (props.childCount * props.childWidth) - (scrollRef.current?.offsetWidth ?? 0)
        let oneItemScrollValue = maxScrollRange / props.childCount

        setValue((props.selectedItem + 1) * oneItemScrollValue)
        setPosition((props.selectedItem + 1) * oneItemScrollValue)

        // eslint-disable-next-line
    }, [props.selectedItem])


    const maxScrollRange = (props.childCount * props.childWidth) - (scrollRef.current?.offsetWidth ?? 0)
    useEffect(() => {
        if (!props.scrollBar || (scrollRef.current!.scrollWidth === scrollRef.current!.clientWidth) || props.hideShadows) return
        value === 0 ? setIsShadowLeft(false) : setIsShadowLeft(true)
        maxScrollRange - value < 40 ? setIsShadowRight(false) : setIsShadowRight(true)
        // eslint-disable-next-line
    }, [value, maxScrollRange])

    const content = props.scrollBar ? <TileWrapper>{props.children}</TileWrapper> : props.children
    const headerTitle = props.type && props.type === "orgaDetail" ?
        <HeaderTitleOrgaDetailType>{props.title}</HeaderTitleOrgaDetailType> :
        <>
            {
                ifMobile ?
                    <MobileHeaderTitleLobbyType>{props.title}</MobileHeaderTitleLobbyType> :
                    <HeaderTitleLobbyType>{props.title}</HeaderTitleLobbyType>
            }

        </>

    if (ifMobile) {
        return <TileRowRoot margin="10px 0px 0 20px">
            {/* Header */}
            {!props.hideTitle && <HeaderRow style={{ marginTop: "-30px" }}>
                <HeaderTitleTemplate>
                    {
                        props.navLink &&
                        <TopBarLinkHeaderTitle titleVisible={true} to={props.navLink ? props.navLink : "/"}>{headerTitle}</TopBarLinkHeaderTitle>
                    }
                </HeaderTitleTemplate>
                {props.navLink && <TopBarLinkRoot style={{ marginRight: "20px", marginTop: "10px" }}>
                    <MobileTopBarLink to={props.navLink ? props.navLink : "/"}>{props.navLinkText} <span style={{ marginLeft: "10px" }}>{IconLinkRightArrow({ stroke: branding.receptionPage.mobileTopBarLinkRightArrowColor })}</span></MobileTopBarLink></TopBarLinkRoot>}
            </HeaderRow>}

            {/* Content */}
            <TileRowContentContainer
                ref={scrollRef}
                isMyFairPage={props.isMyFairPage}
                width={tileRowContentContainerWidth}
                rounded={!props.defaultScrollbar}
                onScroll={() => setValue(scrollRef.current?.scrollLeft!)}>
                {content}
            </TileRowContentContainer>
            {isShadowLeft && !props.hideShadows && <ScrollerShadowLeft />}
            {isShadowRight && !props.hideShadows && <ScrollerShadowRight />}
            {/* Scrollbar (hide scrollbar if there is no need to scroll) */}
            {(!props.defaultScrollbar && !ifMobile) && <ScrollBarComponent value={value} marginRight={props.isMyFairPage ? "0px" : "7px"} maxScrollRange={(props.childCount * props.childWidth) - (scrollRef.current?.offsetWidth ?? 0)} onScroll={(val: number) => { setPosition(val); setValue(val) }}></ScrollBarComponent>}
        </TileRowRoot>
    }


    return (
        <TileRowRoot margin={props.tileRowMargin}>

            {/* Header */}
            {!props.hideTitle && <HeaderRow>
                <HeaderTitleTemplate>
                    <div style={{ borderBottom: branding.receptionPage.tileRowBottomBorder, display: "flex", flexDirection: "row", height: "55px" }}>
                        {props.icon && <HeaderIcon className="icon-container" iconVisible={props.iconVisible} >{props.icon}</HeaderIcon>}
                        {props.navLink && <TopBarLinkHeaderTitle to={props.navLink ? props.navLink : "/"} titleVisible={props.titleVisible}>{headerTitle}</TopBarLinkHeaderTitle>}
                        {props.navLink && branding.receptionPage.tileRowShowNavLinkNextToTitle && <TopBarLinkHeaderSubTitle to={props.navLink ? props.navLink : "/"}>{props.navLinkText}</TopBarLinkHeaderSubTitle>}
                        {props.navLink && branding.receptionPage.tileRowShowNavLinkNextToTitle && <TopBarLinkHeaderSubTitle style={{ marginLeft: "10px" }} to={props.navLink ? props.navLink : "/"}>{IconArrowBreadcrumb({ fill: branding.receptionPage.tileRowArrowColor })}</TopBarLinkHeaderSubTitle>}
                        {props.titleNoLink && <SimpleTitle>{props.titleNoLink}</SimpleTitle>}

                    </div>
                </HeaderTitleTemplate>
                {props.navLink && props.navLinkTextVisible && !branding.receptionPage.tileRowShowNavLinkNextToTitle && <TopBarLinkRoot><TopBarLink to={props.navLink ? props.navLink : "/"}>{props.navLinkText} <span style={{ marginLeft: "10px" }}>{IconLinkRightArrow({ stroke: branding.receptionPage.topBarLinkRightArrowColor })}</span></TopBarLink></TopBarLinkRoot>}
            </HeaderRow>}

            {/* Content */}
            <TileRowContentContainer
                ref={scrollRef}
                isMyFairPage={props.isMyFairPage}
                width={tileRowContentContainerWidth}
                rounded={!props.defaultScrollbar}
                onScroll={() => setValue(scrollRef.current?.scrollLeft!)}>
                {content}
            </TileRowContentContainer>
            {isShadowLeft && !props.hideShadows && <ScrollerShadowLeft />}
            {isShadowRight && !props.hideShadows && <ScrollerShadowRight />}
            {/* Scrollbar (hide scrollbar if there is no need to scroll) */}
            {!props.defaultScrollbar && <ScrollBarComponent value={value} marginRight={props.isMyFairPage ? "0px" : "7px"} maxScrollRange={(props.childCount * props.childWidth) - (scrollRef.current?.offsetWidth ?? 0)} onScroll={(val: number) => { setPosition(val); setValue(val) }}></ScrollBarComponent>}
        </TileRowRoot>
    )
}

export default TileRow