import { ColorString, LocalizedString } from "../../branding/branding";
import { LoginWithSSOKoelnmesseBranding } from "./ssoKoelnmesse/LoginWithSSOKoelnmesseBranding";

export interface PrivacyDocsCheckbox {
  /**
   * @title Visible
   * @description Enable / disable visibility of pricady docs
   * @title_de Visible
   * @description_de Enable / disable visibility of pricady docs
   */
  visible: boolean
   /**
   * @title Label before link
   * @description Text for label before link
   * @title_de Label before link
   * @description_de Text for label before link
   */
  labelBeforeLink: LocalizedString
  /**
   * @title Label after link
   * @description Text for label after link
   * @title_de Label after link
   * @description_de Text for label after link
   */
  labelAfterLink: LocalizedString
  /**
   * @title Link title
   * @description Text for link title
   * @title_de Link title
   * @description_de Text for link title
   */
  linkTitle: LocalizedString
}

export enum LoginType {
  TOKEN = "TOKEN",
  PASSWORD = "PASSWORD",
  SSO_KOELNMESSE = "SSO_KOELNMESSE"
}

export interface LoginRegistrationSiteBranding {
  /**
   * @title Login with password
   * @description (de)activate login with password
   * @title_de Login mit Passwort
   * @description_de (De)aktiviert Login mit Passwort
   */
  loginType: LoginType
  /**
   * @title SSO Login 3rd party lib URL
   * @description Login for a lib used in sso Login. Can be left blank
   * @title_de SSO Login Drittanbieterbibliothek URL
   * @description_de URL für eine Lib die zur Nutzung des SSO Logins nötig ist. Kann leer sein.
   */
   ssoLoginLibScript: string
  /**
   * @title Submit EMail Button Text
   * @description  Text for the submit button for email
   * @title_de Absenden Button Text für E-Mail Eingabe
   * @description_de Text des Buttons bei der E-Mail Eingabe
   */
  submitEmailButton: LocalizedString
  /**
   * @title Placeholder Text for email field
   * @description  Placeholder Text for email field
   * @title_de Platzhaltertext für das E-Mail Feld
   * @description_de Platzhaltertext für das E-Mail Feld
   */
  emailPlaceHolder: LocalizedString
  /**
   * @title Placeholder Text for password field
   * @description  Placeholder text for password textfield
   * @title_de Platzhaltertext für das Passwort Feld
   * @description_de Platzhaltertext für das Passwort Feld
   */
  passwordPlaceHolder: LocalizedString
  /**
   * @title Submit Token Button Text
   * @description  Text for the submit button for token
   * @title_de Absenden Button Text für Token Eingabe
   * @description_de Text des Buttons bei der Token Eingabe
   */
  submitTokenButton: LocalizedString
  /**
   * @title Placeholder Text for token field
   * @description  Placeholder text for token textfield
   * @title_de Platzhaltertext für das Token Feld
   * @description_de Platzhaltertext für das Token Feld
   */
  tokenPlaceholder: LocalizedString
  /**
   * @title Error text for invalid token
   * @description Error text for invalid token
   * @title_de Fehlertext für nicht valides Token
   * @description_de Fehlertext für nicht valides Token
   */
  invalidToken: LocalizedString
  /**
   * @title Error text for wrong token
   * @description Error text for wrong token
   * @title_de Fehlertext für falsches Token
   * @description_de Fehlertext für falsches Token
   */
  wrongToken: LocalizedString
  /**
   * @title Create new token text
   * @description Create new token text
   * @title_de Text für neues Token erzeugen
   * @description_de Text für neues Token erzeugen
   */
  createNewToken: LocalizedString
  /**
   * @title Create no token text
   * @description Create no token text
   * @title_de Text für Token nicht bekommen
   * @description_de Text für Token nicht bekommen
   */
  noToken: LocalizedString
  /**
   * @title Resend token text
   * @description Resend token text
   * @title_de Text für Token erneut zusenden
   * @description_de Text für Token erneut zusenden
   */
  resendToken: LocalizedString
  /**
   * @title Text for ticket panel
   * @description Text for ticket panel
   * @title_de Text für den Ticketbereich
   * @description_de Text für den Ticketbereich
   */
  ticketPanel: LocalizedString
  /**
   * @title Buy Ticket Button Text
   * @description Buy Ticket Button Text
   * @title_de Ticket Kaufen Button Text
   * @description_de Ticket Kaufen Button Text
   */
  buyTicketButton: LocalizedString
  /**
   * @title Change Email Button Text
   * @description Change Email Button Text
   * @title_de E-Mail ändern Button Text
   * @description_de E-Mail ändern Button Text
   */
  changeEmailButton: LocalizedString
  /**
   * @title Error text for token creation failed
   * @description Error text for token creation failed
   * @title_de Fehlertext für fehlerhafte Tokenerzeugung
   * @description_de Fehlertext für fehlerhafte Tokenerzeugung
   */
  createTokenFailed: LocalizedString
  /**
   * @title text for token creation success
   * @description text for token creation success
   * @title_de Text für erfolgreiche Tokenerzeugung
   * @description_de Text für erfolgreiche Tokenerzeugung
   */
  createTokenSuccess: LocalizedString
    /**
   * @title Success message color
   * @description Text color of success messages in login panel
   * @title_de Success message color
   * @description_de Text color of success messages in login panel
   */
    successMessageColor: ColorString
  /**
   * @title Text for dataprivacy panel
   * @description Text for dataprivacy panel
   * @title_de Text für den Datenschutzbereich
   * @description_de Text für den Datenschutzbereich
   */
  dataPrivacyPanel: LocalizedString
  /**
   * @title Text for dataprivacy link
   * @description Text for dataprivacy link
   * @title_de Text für den Datenschutz-Link
   * @description_de Text für den Datenschutz-Link
   */
  dataPolicy: LocalizedString
  /**
   * @title Invalid Email Panel Text
   * @description Invalid Email Panel Text
   * @title_de Invalide Email Bereichstext
   * @description_de Invalide Email Bereichstext
   */
  invalidEmailPanel: LocalizedString
  /**
   * @title Configuration for Invalid Email Panel
   * @description Configuration for Invalid Email Panel
   * @title_de Invalide Email Bereichskonfiguration
   * @description_de Invalide Email Bereichskonfiguration
   */
  invalidEmailPanelWithLink: InvalidEmailPanelWithLink
  /**
   * @title Text for outdated dataprivacy panel
   * @description Text for outdated dataprivacy panel
   * @title_de Text für den veralteten Datenschutzbereich
   * @description_de Text für den veralteten Datenschutzbereich
   */
  outdatedPrivacyDocsPanel: LocalizedString
  /**
   * @title Error text for wrong password
   * @description Error text for wrong password
   * @title_de Fehlertext für falsches Passwort
   * @description_de Fehlertext für falsches Passwort
   */
  wrongPasswordPanel: LocalizedString
  /**
   * @title Error text for register email failed
   * @description Error text for register email failed
   * @title_de Fehlertext für fehlerhafte E-Mail Registrierung
   * @description_de Fehlertext für fehlerhafte E-Mail Registrierung
   */
  registerEmailFailed: LocalizedString
  /**
   * @title Error text for code resent limit exceeded
   * @description Error text for code resent limit exceeded
   * @title_de Error text for code resent limit exceeded
   * @description_de Error text for code resent limit exceeded
   */
  codeResendLimit: LocalizedString
  /**
   * @title Contact support text
   * @description Contact support text
   * @title_de Contact support text
   * @description_de Contact support text
  */
  contactSupportText: LocalizedString
  /**
   * @title Contact support URL
   * @description Contact support URL
   * @title_de Contact support URL
   * @description_de Contact support URL
  */
  contactSupportUrl: LocalizedString
  /**
   * @title Error text for maximum number of login attempts exceeded
   * @description Error text for maximum number of login attempts exceeded
   * @title_de Error text for maximum number of login attempts exceeded
   * @description_de Error text for maximum number of login attempts exceeded
  */
  maximumLoginAttempts: LocalizedString
  /**
   * @title Error text for thirdpartyuser not found
   * @description Error text for thirdpartyuser not found
   * @title_de Fehlertext für nicht gefunden thirdpartyuser
   * @description_de Fehlertext für nicht gefunden thirdpartyuser
   */
  thirdPartyUserNotFound: LocalizedString
  /**
   * @title Text for forgot password
   * @description Text for forgot password
   * @title_de Text für Passwort vergessen
   * @description_de Text für Passwort vergessen
   */
  forgotPassword: LocalizedString
  /**
   * @title Url for forgot password
   * @description Url for forgot password
   * @title_de Url für Passwort vergessen
   * @description_de Url für Passwort vergessen
   */
  passwordForgottenUrl: LocalizedString
  /**
   * @title Text for enter email
   * @description Text for enter email
   * @title_de Text für E-Mail eingeben
   * @description_de Text für E-Mail eingeben
   */
  enterEmail: LocalizedString
  /**
   * @title Text for enter passowrd
   * @description Text for enter password
   * @title_de Text für Passwort eingeben
   * @description_de Text für Passwort eingeben
   */
  enterPassword: LocalizedString
  /**
   * @title Text for send code
   * @description Text for  send code
   * @title_de Text für Code senden 
   * @description_de Text für Code senden 
   */
  sendCode: LocalizedString
  /**
   * @title Text for enter token
   * @description Text for enter token
   * @title_de Text für Token eingeben
   * @description_de Text für Token eingeben
   */
  enterToken: LocalizedString
  /**
   * @title Title for login
   * @description Title for login
   * @title_de Titel für Login
   * @description_de Titel für Login
   */
  centerLoginStyleTitle: LocalizedString
  /**
   * @title Title for login with token
   * @description Title for login with token
   * @title_de Titel für Login mit Token
   * @description_de Titel für Login mit Token
   */
  centerLoginStyleTitleCode: LocalizedString
  /**
   * @title Subtitle for login
   * @description Subtitle for login
   * @title_de Untertitel für Login
   * @description_de Untertitel für Login
   */
  centerLoginStyleSubtitle: LocalizedString
  /**
   * @title Subtitle for login
   * @description Subtitle for login
   * @title_de Kurzer Untertitel  für Login
   * @description_de Kurzer Untertitel  für Login
   */
  centerLoginStyleSubtitleShort: LocalizedString
  /**
   * @title Ticketlink Text
   * @description Ticketlink Text
   * @title_de Ticketlink Text
   * @description_de Ticketlink Text
   */
  centerLoginStyleTicketLink: LocalizedString
  /**
   * @title Ticketlink
   * @description Ticketlink
   * @title_de Ticketlink
   * @description_de Ticketlink
   */
  getTicketsLink: LocalizedString
  /**
   * @title Title for registration
   * @description Title for registration
   * @title_de Titel für Registration
   * @description_de Titel für Registration
   */
  loginRegistrationTitle: LocalizedString
  /**
   * @title Title font size for registration page
   * @description Title font size for registration page
   * @title_de Titel font size für Registration page
   * @description_de Titel font size für Registration page
   */
  loginRegistrationTitleFontSize: string
  /**
   * @title Subtitle font size for registration page
   * @description Subtitle font size for registration page
   * @title_de Subtitle font size für Registration page
   * @description_de Subtitle font size für Registration page
   */
  loginRegistrationSubtitleFontSize: string
  /**
   * @title Subtitle for registration page
   * @description Subtitle for registration page
   * @title_de Untertitel für Registration page
   * @description_de Untertitel für Registration page
   */
  loginRegistrationSubtitle: LocalizedString
  /**
   * @title Logout information text for timeout
   * @description Logout information text for timeout
   * @title_de Logout Informationstext bei Zeitüberschreitung
   * @description_de Logout Informationstext bei Zeitüberschreitung
   */
  logoutReasonSessionTimeout: LocalizedString,
  /**
   * @title Contact us label text
   * @description Contact us label text
   * @title_de Kontaktieren sie uns Label Text
   * @description_de Kontaktieren sie uns Label Text
   */
  contactUsLabel: LocalizedString,
  /**
   * @title Show/Hide Contact Us Label
   * @description Show/Hide Contact Us Label
   * @title_de Zeigen/Verstecken des Kontaktieren Sie uns Labels
   * @description_de Zeigen/Verstecken des Kontaktieren Sie uns Labels
   */
  contactUsLabelVisible: boolean,
  /**
   * @title Contact us color
   * @description Contact us color
   * @title_de Kontaktieren sie uns Farbe
   * @description_de Kontaktieren sie uns Farbe
   */
  contactUsLabelColor: ColorString
  /**
   * @title Logout information text for forbidden
   * @description Logout information text for forbidden
   * @title_de Logout Informationstext bei Zugriff verboten
   * @description_de Logout Informationstext bei Zugriff verboten
   */
  logoutReasonForbidden: LocalizedString,
  /**
   * @title Login backgroundcolor 1
   * @description Login backgroundcolor 1
   * @title_de Login Hintergrundfarbe 1
   * @description_de Login Hintergrundfarbe 1
   */
  loginBackgroundColor1: ColorString
  /**
   * @title Login backgroundcolor 2
   * @description Login backgroundcolor 2
   * @title_de Login Hintergrundfarbe 2
   * @description_de Login Hintergrundfarbe 2
   */
  loginBackgroundColor2: ColorString
  /**
   * @title Login input color
   * @description Login input
   * @title_de Login Eingabefeld Farbe
   * @description_de Login Eingabefeld Farbe
   */
  loginInputColor: ColorString
  /**
   * @title Login Button Backgroundcolor
   * @description Login Button Backgroundcolor
   * @title_de Login Button Hintergrundfarbe
   * @description_de Login Button Hintergrundfarbe
   */
  loginButtonBackgroundColor: ColorString
  /**
   * @title Login Button Textcolor
   * @description Login Button Textcolor
   * @title_de Login Button Textfarbe
   * @description_de Login Button Textfarbe
   */
  loginButtonTextColor: ColorString
  /**
   * @title Login Button Disabled Backgroundcolor
   * @description Login Button Disabled Backgroundcolor
   * @title_de Login Button deaktiviert Hintergrundfarbe
   * @description_de Login Button deaktiviert Hintergrundfarbe
   */
  loginButtonDisabledBackgroundColor: ColorString
  /**
   * @title Terms of use text
   * @description Terms of use text
   * @title_de Nutzungsbedingungen Text
   * @description_de Nutzungsbedingungen Text
   */
  termsOfUseText: LocalizedString
  /**
   * @title Terms of use url
   * @description Terms of use url
   * @title_de Nutzungsbedingungen url
   * @description_de Nutzungsbedingungen url
   */
  termsOfUseUrl: LocalizedString
  /**
   * @title Text for screen size not supported
   * @description Text for screen size not supported
   * @title_de Text für Bildschirmauflösung nicht unterstützt
   * @description_de Text für Bildschirmauflösung nicht unterstützt
   */
  notSupportedScreenSize: LocalizedString
  /**
   * @title CSS Border Value for Login Ticket Button
   * @description CSS Border Value for Login Ticket Button
   * @title_de CSS Rahmen Wert für Login Ticket Button
   * @description_de CSS Rahmen Wert für Login Ticket Button
   */
  centerLoginStyleTicketBorderBtn: string
  /**
   * @title CSS Color for Login Ticket Button Text
   * @description CSS Color for Login Ticket Button Text
   * @title_de CSS Farbe für Login Ticket Button Text
   * @description_de CSS Farbe für Login Ticket Button Text
   */
  centerLoginStyleTicketTextColorBtn: ColorString

  /**
   * @title CSS Background-Color for Login Ticket Button 
   * @description CSS Background-Color for Login Ticket Button 
   * @title_de CSS Hintergrundfarbe für Login Ticket Button 
   * @description_de CSS Hintergrundfarbe für Login Ticket Button
   */
  centerLoginStyleTicketBtnBgColor: ColorString

  /**
   * @title CSS Background-Color for Login Ticket Button on Hover
   * @description CSS Background-Color for Login Ticket Button on Hover
   * @title_de CSS Hintergrundfarbe für Login Ticket Button bei Mouseover
   * @description_de CSS Hintergrundfarbe für Login Ticket Button bei MouseOver
   */
  centerLoginStyleTicketBtnOnHoverBgColor: ColorString
  /**
   * @title CSS Text-Color for Login Ticket Button on Hover
   * @description CSS Text-Color for Login Ticket Button on Hover
   * @title_de CSS Textfarbe für Login Ticket Button bei Mouseover
   * @description_de CSS Textfarbe für Login Ticket Button bei MouseOver
   */
  centerLoginStyleTicketBtnOnHoverTextColor: ColorString
  /**
   * @title CSS Background-Color for System Check
   * @description CSS Background-Color for System Check
   * @title_de CSS Hintergrundfarbe für System Check
   * @description_de CSS Hintergrundfarbe System Check
   */
  centerLoginSystemCheckBackgroundColor: ColorString
  /**
   * @title CSS Color for Overlay
   * @description CSS Color for Overlay
   * @title_de CSS Farbe für Overlay
   * @description_de CSS Farbe für Overlay
   */
  centerLoginStyleOverlay: ColorString
  /**
   * @title Login Logo max width
   * @description Login Logo max width
   * @title_de Login Logo max Breite
   * @description_de Login Logo max Breite
   */
  centerLoginLogoMaxWidth: string
  /**
   * @title Login Logo max height
   * @description Login Logo max height
   * @title_de Login Logo max Höhe
   * @description_de Login Logo max Höhe
   */
  centerLoginLogoMaxHeight: string
  /**
   * @title CSS Button-Color 1 for Error Site
   * @description CSS Button-Color 1 for Error Site
   * @title_de CSS Buttonfarbe 1 für Fehlerseite
   * @description_de CSS Buttonfarbe 1 für Fehlerseite
   */
  errorSiteButtonColor1: ColorString
  /**
   * @title CSS Button-Color 2 for Error Site
   * @description CSS Button-Color 2 for Error Site
   * @title_de CSS Buttonfarbe 2 für Fehlerseite
   * @description_de CSS Buttonfarbe 2 für Fehlerseite
   */
  errorSiteButtonColor2: ColorString
  /**
   * @title emailQueryParam
   * @description emailQueryParam
   * @title_de emailQueryParam
   * @description_de emailQueryParam
   */
  emailQueryParam: string
  /**
   * @title passwordQueryParam
   * @description passwordQueryParam
   * @title_de passwordQueryParam
   * @description_de passwordQueryParam
   */
  passwordQueryParam: string
  /**
   * @title Show/Hide Imprint and Privacy Policy in Footer
   * @description Show/Hide Imprint and Privacy Policy in Footer
   * @title_de Zeigen/Verstecken des Impressums und der Datenschutzerklärung im Footer
   * @description_de Zeigen/Verstecken des Impressums und der Datenschutzerklärung im Footer
   */
  showImprintAndPrivacyPolicyInFooter: boolean
  /**
   * @title Show/Hide Imprint in Footer
   * @description Show/Hide Imprint in Footer
   * @title_de Zeigen/Verstecken des Impressums im Footer
   * @description_de Zeigen/Verstecken des Impressums im Footer
   */
  showImprintInFooter: boolean
  /**
   * @title Show/Hide Privacy Policy in Footer
   * @description Show/Hide Privacy Policy in Footer
   * @title_de Zeigen/Verstecken der Datenschutzerklärung im Footer
   * @description_de Zeigen/Verstecken der Datenschutzerklärung im Footer
   */
  showPrivacyPolicyInFooter: boolean
  /**
   * @title Show/Hide Terms of use in Footer
   * @description Show/Hide Terms of use in Footer
   * @title_de Zeigen/Verstecken der Datenschutz im Footer
   * @description_de Zeigen/Verstecken der Datenschutz im Footer
   */
  showTermsOfUseInFooter: boolean
  /**
   * @title Imprint Link Text
   * @description Imprintlink Text
   * @title_de Impressumlink Text
   * @description_de Impressumlink Text
   */
  imprintLink: LocalizedString
  /**
   * @title Imprint Link Url
   * @description Imprintlink Url
   * @title_de Impressumlink Url
   * @description_de Impressumlink Url
   */
  imprintLinkUrl: LocalizedString
  /**
  * @title Privacy Policy Link Text
  * @description Privacy Policy Link Text
  * @title_de Datenschutzerklärung Link Text
  * @description_de Datenschutzerklärung Link Text
  */
  privacyPolicyLink: LocalizedString
  /**
  * @title Privacy Policy Link Url
  * @description Privacy Policy Link Url
  * @title_de Datenschutzerklärung Link Url
  * @description_de Datenschutzerklärung Link Url
  */
  privacyPolicyLinkUrl: LocalizedString
  /**
  * @title Terms of use Link Text
  * @description Terms of use Link Text
  * @title_de Datenschutz Link Text
  * @description_de Datenschutz Link Text
  */
  termsOfUseLink: LocalizedString
  /**
  * @title Terms of use Link Url
  * @description Terms of use Link Url
  * @title_de Datenschutz Link Url
  * @description_de Datenschutz Link Url
  */
  termsOfUseLinkUrl: LocalizedString
  /**
  * @title CSS Text-Color for Login Title
  * @description CSS Text-Color for Login Title
  * @title_de CSS Textfarbe für Logintitel
  * @description_de CSS Textfarbe für Logintitel
  */
  loginTitleTextColor: ColorString
  /**
   * @title CSS Text-Color for Login Subtitle
   * @description CSS Text-Color for Login Subtitle
   * @title_de CSS Textfarbe für Loginuntertitel
   * @description_de CSS Textfarbe für Loginuntertitel
   */
  loginSubTitleTextColor: ColorString
  /**
  * @title CSS Font-Weight for Login Subtitle
  * @description CSS Font-Weight for Login Subtitle
  * @title_de CSS Font-Weight für Loginuntertitel
  * @description_de CSS Font-Weight für Loginuntertitel
  */
  loginSubTitleFontWeight: string
  /**
   * @title CSS Text-Color for Login Modal
   * @description CSS Text-Color for Login Modal
   * @title_de CSS Textfarbe für Loginpopup
   * @description_de CSS Textfarbe für Loginpopup
   */
  loginModalTextColor: ColorString
  /**
   * @title CSS Text-Color for Login Modal Subtitle
   * @description CSS Text-Color for Login Modal Subtitle
   * @title_de CSS Textfarbe für Loginpopup Untertitel
   * @description_de CSS Textfarbe für Loginpopup Text
   */
  loginModalSubtitleTextColor: ColorString
  /**
   * @title CSS Text-Color for Login Input field
   * @description CSS Text-Color for Login Input field
   * @title_de CSS Textfarbe für Login Textfeld
   * @description_de CSS Textfarbe für Login Textfeld
   */
  loginInputMailTextColor: string
  /**
   * @title CSS background color for Login Input field
   * @description CSS background color for Login Input field
   * @title_de CSS Hintergrundfarbe für Login Textfeld
   * @description_de CSS Hintergrundfarbe für Login Textfeld
   */
  loginInputMailBackgroundColor: string
  /**
   * @title CSS Text-Color for Login Input field placeholder
   * @description CSS Text-Color for Login Input field placeholder
   * @title_de CSS Textfarbe für Login Textfeld placeholder
   * @description_de CSS Textfarbe für Login Textfeld placeholder
   */
  loginInputMailPlaceholderColor: ColorString
  /**
   * @title CSS Color for Login Overlay
   * @description CSS Color for Login Overlay
   * @title_de CSS Farbe für Login Overlay
   * @description_de CSS Farbe für Login Overlay
   */
  loginBoxOverlayColor: ColorString
  /**
   * @title CSS Text-Color for Change Email Adress Link
   * @description CSS Text-Color for Change Email Adress Link
   * @title_de CSS Textfarbe für E-Mail Adresse ändern Link
   * @description_de CSS Textfarbe für E-Mail Adresse ändern Link
   */
  changeEmailAddressLinkColor: ColorString
  /**
   * @title Enables check for minimal resolution
   * @description Enables check for minimal resolution
   * @title_de Aktiviert die Überprüfung auf minimale Auflösung 
   * @description_de Aktiviert die Überprüfung auf minimale Auflösung 
   */
  enableMinimalResolutionCheck: boolean
  /**
  * @title Footer text color
  * @description Color for text of footer items
  * @title_de Footer text color
  * @description_de Color for text of footer items
  */
  footerTextColor: string
  /**
   * @title Privacy docs checkbox object
   * @description Privacy docs checkbox object
   * @title_de Privacy docs checkbox object
   * @description_de Privacy docs checkbox object
   */
  privacyDocsCheckbox: PrivacyDocsCheckbox
  /**
   * @title Privacy docs second checkbox object
   * @description Privacy docs second checkbox object
   * @title_de Privacy docs second checkbox object
   * @description_de Privacy docs second checkbox object
   */
  privacyDocsSecondCheckbox: PrivacyDocsCheckbox
  /**
   * @title Mobile login text color
   * @description Color of texts in mobile version of login page
   * @title_de Mobile login text color
   * @description_de Color of texts in mobile version of login page
   */
  mobileLoginTextColor: string
  /**
  * @title Mobile login button color
  * @description Color of buttons in mobile version of login page
  * @title_de Mobile login button color
  * @description_de Color of buttons in mobile version of login page
  */
  mobileLoginButtonColor: string
  /**
   * @title Get your ticket section top border color (mobile)
   * @description Get your ticket section top border color in mobile version of login page
   * @title_de Get your ticket section top border color (mobile)
   * @description_de Get your tickt section top border color in mobile version of login page
   */
  getYourTicketSectionTopBorderColor: string

  /**
  * @title Title of "More options for mobile" section
  * @description Title of "More options for mobile" section
  * @title_de Title of "More options for mobile" section
  * @description_de Title of "More options for mobile" section
  */
  moreOptionsForMobileTitle: LocalizedString

  /**
   * @title Description of "More options for mobile" section
   * @description Description of "More options for mobile" section
   * @title_de Description of "More options for mobile" section
   * @description_de Description of "More options for mobile" section
  */
  moreOptionsForMobileDescription: LocalizedString

  /**
  * @title Android app URL
  * @description Android app URL
  * @title_de Android app URL
  * @description_de Android app URL
  */
  androidAppUrl: string

  /**
   * @title iOS app URL
   * @description iOS app URL
   * @title_de iOS app URL
   * @description_de iOS app URL
   */
  iOSAppUrl: string

  /**
  * @title Download mobile app button text
  * @description Download mobile app button text
  * @title_de Download mobile app button text
  * @description_de Download mobile app button text
  */
  downloadMobileAppButtonText: LocalizedString

  /**
   * @title Continue with mobile website button text
   * @description Continue with mobile website button text
   * @title_de Continue with mobile website button text
   * @description_de Continue with mobile website button text
   */
  continueWithMobileButtonText: LocalizedString

  /**
  * @title App download mode enabled
  * @description Enable the showing of optional mobile app download pagge
  * @title_de App download mode enabled
  * @description_de Enable the showing of optional mobile app download pagge
  */
  appDownloadModeEnabled: boolean

  /**
  * @title Koelnmesse SSO Login Config
  * @description Config options for SSO Login via Koelnmesse
  * @title_de Kölnmesse SSO Login Konfiguration
  * @description_de Konfigurationsoptionen für SSO Login via Kölnmesse
  */
  ssoLoginKoelnmesse: LoginWithSSOKoelnmesseBranding
}

export interface InvalidEmailPanelWithLink {
  /**
   * @title Show Invalid Email Panel with Link
   * @description Show Invalid Email Panel with Link
   * @title_de Zeige Invalide Email Bereich
   * @description_de Zeige Invalide Email Bereich
   */
  visible: boolean
  /**
   * @title Text before Link
   * @description Text before Link
   * @title_de Text vor dem Link
   * @description_de Text vor dem Link
   */
  befLinkPart: LocalizedString
  /**
   * @title Link URL
   * @description Link URL
   * @title_de Link URL
   * @description_de Link URL
   */
  link: LocalizedString
  /**
   * @title Link text
   * @description Link text
   * @title_de Linktext
   * @description_de Linktext
   */
  text: LocalizedString
  /**
   * @title Textcolor
   * @description Textcolor
   * @title_de Textfarbe
   * @description_de Textfarbe
   */
  textColor: string
  /**
   * @title Text after Link
   * @description Text after Link
   * @title_de Text nach dem Link
   * @description_de Text nach dem Link
   */
  aftLinkPart: LocalizedString
}