import { LocalizedString } from "../../branding/branding";

export interface OffersNeedsCategoryType {
    /**
     * @title Id
     * @description Value for the offers and needs category id 
     * @title_de Id
     * @description_de Value for the offers and needs category id 
     */
    id: string
    /**
     * @title Name
     * @description Text for the offers and needs category name 
     * @title_de Name
     * @description_de Text for the offers and needs category name 
     */
    name: LocalizedString
    /**
     * @title Placeholder buying
     * @description Text for Im buying placeholder 
     * @title_de Placeholder buying
     * @description_de Text for Im buying placeholder 
     */
    placeholderBuying: LocalizedString
    /**
     * @title Placeholder selling
     * @description Text for Im selling placeholder
     * @title_de Placeholder selling
     * @description_de Text for Im selling placeholder 
     */
    placeholderSelling: LocalizedString
}

export interface MyProfilePageContentBranding {
    /**
    * @title My profile title
    * @description Text for my profile page title
    * @title_de My profile title
    * @description_de Text for my profile page title 
    */
    myProfileTitle: LocalizedString
    /**
    * @title Edit profile title
    * @description Text for edit profile page title
    * @title_de Edit profile title
    * @description_de Text for edit profile page title 
    */
     editProfileTitle: LocalizedString
    /**
     * @title Edit profile button text
     * @description Text for edit profile button
     * @title_de Edit profile button text
     * @description_de Text for edit profile button 
     */
    editProfileButtonText: LocalizedString
    /**
    * @title No interests selected message
    * @description Text for no selected interests message
    * @title_de No interests selected message
    * @description_de Text for no selected interests message 
    */
    noInterestsSelectedMessage: LocalizedString
    /**
     * @title No offers selected message
     * @description Text for no selected offers message
     * @title_de No offers selected message
     * @description_de Text for no selected offers message 
     */
    noOffersSelectedMessage: LocalizedString
    /**
     * @title No needs selected message
     * @description Text for no selected needs message
     * @title_de No needs selected message
     * @description_de Text for no selected needs message 
     */
    noNeedsSelectedMessage: LocalizedString
    /**
     * @title Go back label
     * @description Text for go back navigation label
     * @title_de Go back label
     * @description_de Text for go back navigation label 
     */
    goBackLabel: LocalizedString
    /**
     * @title Profile picture label
     * @description Text for profile picture label
     * @title_de Profile picture label
     * @description_de Text for profile picture label 
     */
    profilePictureLabel: LocalizedString
    /**
     * @title Upload new button label
     * @description Text for upload new image button label
     * @title_de Upload new button label
     * @description_de Text for upload new image button label 
     */
    uploadNewButtonText: LocalizedString
    /**
     * @title Formats text
     * @description Description text of possible image formats that can be uploaded
     * @title_de Formats text
     * @description_de Description text of possible image formats that can be uploaded 
     */
    formatsText: LocalizedString
    /**
    * @title Delete current picture button text
    * @description Text for delete current image button
    * @title_de Delete current picture button text
    * @description_de Text for delete current image button 
    */
    deleteCurrentPictureButtonText: LocalizedString
    /**
     * @title Name label
     * @description Text for persons name label
     * @title_de Name label
     * @description_de Text for persons name label 
     */
    nameLabel: LocalizedString
    /**
     * @title Name placeholder
     * @description Text for persons name placeholder
     * @title_de Name placeholder
     * @description_de Text for persons name placeholder 
     */
     namePlaceholder: LocalizedString
    /**
     * @title Surname label
     * @description Text for persons surname label
     * @title_de Surname label
     * @description_de Text for persons surname label 
     */
    surnameLabel: LocalizedString
     /**
     * @title Surname placheolder
     * @description Text for persons surname placheolder
     * @title_de Surname placheolder
     * @description_de Text for persons surname placheolder 
     */
      surnamePlaceholder: LocalizedString
    /**
     * @title Company label
     * @description Text for persons company label
     * @title_de Company label
     * @description_de Text for persons company label 
     */
    companyLabel: LocalizedString
    /**
     * @title Company placeholder
     * @description Text for persons company placeholder
     * @title_de Company placeholder
     * @description_de Text for persons company placeholder 
     */
     companyPlaceholder: LocalizedString
    /**
    * @title JobTitle label
    * @description Text for persons JobTitle label
    * @title_de JobTitle label
    * @description_de Text for persons JobTitle label
    */
    jobTitleLabel: LocalizedString
    /**
    * @title JobTitle placeholder
    * @description Text for persons JobTitle placeholder
    * @title_de JobTitle placeholder
    * @description_de Text for persons JobTitle placeholder
    */
     jobTitlePlaceholder: LocalizedString
    /**
    * @title JobTitleDe label
    * @description Text for persons JobTitleDe label
    * @title_de JobTitleDe label
    * @description_de Text for persons JobTitleDe label
    */
    jobTitleDeLabel: LocalizedString
    /**
    * @title JobTitle placeholder
    * @description Text for persons JobTitle placeholder
    * @title_de JobTitle placeholder
    * @description_de Text for persons JobTitle placeholder
    */
     jobTitleDePlaceholder: LocalizedString
    /**
     * @title Phone label
     * @description Text for persons phone label
     * @title_de Phone label
     * @description_de Text for persons phone label 
     */
    phoneLabel: LocalizedString
    /**
     * @title Phone placeholder
     * @description Text for persons phone placeholder
     * @title_de Phone placeholder
     * @description_de Text for persons phone placeholder 
     */
     phonePlaceholder: LocalizedString
    /**
     * @title E-mail label
     * @description Text for persons e-mail label
     * @title_de E-mail label
     * @description_de Text for persons e-mail label 
     */
     emailLabel: LocalizedString
    /**
     * @title Address 1 label
     * @description Text for persons address 1 label
     * @title_de Address 1 label
     * @description_de Text for persons address 1 label 
     */
    adress1Label: LocalizedString
    /**
     * @title Address 1 placeholder
     * @description Text for persons address 1 placeholder
     * @title_de Address 1 placeholder
     * @description_de Text for persons address 1 placeholder 
     */
     adress1Placeholder: LocalizedString
    /**
    * @title Address 2 label
    * @description Text for persons address 2 label
    * @title_de Address 2 label
    * @description_de Text for persons address 2 label 
    */
    adress2Label: LocalizedString
     /**
     * @title Address 2 placeholder
     * @description Text for persons address 2 placeholder
     * @title_de Address 2 placeholder
     * @description_de Text for persons address 2 placeholder 
     */
      adress2Placeholder: LocalizedString
    /**
    * @title Address 3 label
    * @description Text for persons address 3 label
    * @title_de Address 3 label
    * @description_de Text for persons address 3 label 
    */
    adress3Label: LocalizedString
     /**
     * @title Address 3 placeholder
     * @description Text for persons address 3 placeholder
     * @title_de Address 3 placeholder
     * @description_de Text for persons address 3 placeholder 
     */
      adress3Placeholder: LocalizedString
    /**
    * @title City label
    * @description Text for persons city label
    * @title_de City label
    * @description_de Text for persons city label 
    */
    cityLabel: LocalizedString
    /**
    * @title City placeholder
    * @description Text for persons city placeholder
    * @title_de City placeholder
    * @description_de Text for persons city placeholder 
    */
     cityPlaceholder: LocalizedString
    /**
     * @title Postal code label
     * @description Text for persons postal code label
     * @title_de Postal code label
     * @description_de Text for persons postal code label 
     */
    postalCodeLabel: LocalizedString
    /**
     * @title Postal code label
     * @description Text for persons postal code label
     * @title_de Postal code label
     * @description_de Text for persons postal code label 
     */
     postalCodePlaceholder: LocalizedString
    /**
     * @title Mobile label
     * @description Text for persons mobile label
     * @title_de Mobile label
     * @description_de Text for persons mobile label 
     */
    mobileLabel: LocalizedString
    /**
     * @title Mobile placeholder
     * @description Text for persons mobile placeholder
     * @title_de Mobile placeholder
     * @description_de Text for persons mobile placeholder 
     */
     mobilePlaceholder: LocalizedString
    /**
     * @title Time zone label
     * @description Text for persons time zone label
     * @title_de Time zone label
     * @description_de Text for persons time zone label 
     */
    timeZoneLabel: LocalizedString
    /**
     * @title Industry label
     * @description Text for persons industry label
     * @title_de Industry label
     * @description_de Text for persons industry label 
     */
    industryLabel: LocalizedString
     /**
     * @title Industry placeholder
     * @description Text for persons industry placeholder
     * @title_de Industry placeholder
     * @description_de Text for persons industry placeholder 
     */
      industryPlaceholder: LocalizedString
    /**
     * @title IndustryDe label
     * @description Text for persons industryDe label
     * @title_de IndustryDe label
     * @description_de Text for persons industryDe label 
     */
    industryDeLabel: LocalizedString
    /**
     * @title IndustryDe placeholder
     * @description Text for persons industryDe placeholder
     * @title_de IndustryDe placeholder
     * @description_de Text for persons industryDe placeholder 
     */
     industryDePlaceholder: LocalizedString
    /**
     * @title Country label
     * @description Text for persons country label
     * @title_de Country label
     * @description_de Text for persons country label 
     */
    countryLabel: LocalizedString
    /**
    * @title Country switcher text
    * @description Text for select country label
    * @title_de Country switcher text
    * @description_de Text for select country label
    */
    countrySwitcherText: LocalizedString
    /**
     * @title Languages label
     * @description Text for persons Languages label
     * @title_de Languages label
     * @description_de Text for persons Languages label 
     */
    languagesLabel: LocalizedString
    /**
     * @title Languages switcher text
     * @description Text for select languages label
     * @title_de Languages switcher text
     * @description_de Text for select languages label
     */
    languagesSwitcherText: LocalizedString
    /**
     * @title Facebook link label
     * @description Text for persons Facebook link label
     * @title_de Facebook link label
     * @description_de Text for persons Facebook link label 
     */
    facebookLinkLabel: LocalizedString
    /**
     * @title Twitter link label
     * @description Text for persons Twitter link label
     * @title_de Twitter link label
     * @description_de Text for persons Twitter link label 
     */
    twitterLinkLabel: LocalizedString
    /**
     * @title Linked in link label
     * @description Text for persons Linked in link label
     * @title_de Linked in link label
     * @description_de Text for persons Linked in link label 
     */
    linkedInLinkLabel: LocalizedString
    /**
     * @title Xing link label
     * @description Text for persons Xing link label
     * @title_de Xing link label
     * @description_de Text for persons Xing link label 
     */
    xingLinkLabel: LocalizedString
    /**
    * @title Instagram link label
    * @description Text for persons Instagram link label
    * @title_de Instagram link label
    * @description_de Text for persons Instagram link label 
    */
    instagramLinkLabel: LocalizedString
    /**
     * @title Youtube link label
     * @description Text for persons Youtube link label
     * @title_de Youtube link label
     * @description_de Text for persons Youtube link label 
     */
    youTubeLinkLabel: LocalizedString
    /**
     * @title Pinterest link label
     * @description Text for persons Pinterest link label
     * @title_de Pinterest link label
     * @description_de Text for persons Pinterest link label 
     */
    pinterestLinkLabel: LocalizedString
    /**
     * @title Biography label
     * @description Text for persons biography label
     * @title_de Biography label
     * @description_de Text for persons biography label 
     */
    biographyLabel: LocalizedString
    /**
     * @title Biography placeholder
     * @description Text for persons biography placeholder
     * @title_de Biography placeholder
     * @description_de Text for persons biography placeholder 
     */
     biographyPlaceholder: LocalizedString
    /**
     * @title BiographyDe label
     * @description Text for persons biographyDe label
     * @title_de BiographyDe label
     * @description_de Text for persons biographyDe label 
     */
    biographyDeLabel: LocalizedString
     /**
     * @title BiographyDe placeholder
     * @description Text for persons biographyDe placeholder
     * @title_de BiographyDe placeholder
     * @description_de Text for persons biographyDe placeholder 
     */
      biographyDePlaceholder: LocalizedString
    /**
     * @title Biography mobile label
     * @description Text for persons biography label on mobile version of profile page
     * @title_de Biography mobile label
     * @description_de Text for persons biography label on mobile version of profile page
     */
     biographyMobileLabel: LocalizedString
    /**
     * @title Number of categories
     * @description Number of categories shown on my profile page
     * @title_de Number of categories
     * @description_de Number of categories shown on my profile page 
     */
    numberOfCategories: number
    /**
    * @title Interests label
    * @description Text for persons interests label
    * @title_de Interests label
    * @description_de Text for persons interests label 
    */
    interestsLabel: LocalizedString
    /**
    * @title Im offering label
    * @description Text for Im offering label
    * @title_de Im offering label
    * @description_de Text for Im offering label 
    */
    imOfferingLabel: LocalizedString
    /**
     * @title Number of offers
     * @description Number of offers shown on my profile page
     * @title_de Number of offers
     * @description_de Number of offers shown on my profile page 
     */
    numberOfOffers: number
    /**
     * @title Im looking to buy label
     * @description Text for Im looking to buy label
     * @title_de Im looking to buy label
     * @description_de Text for Im looking to buy label 
     */
    imLookingToBuyLabel: LocalizedString
    /**
     * @title Number of needs
     * @description Number of needs shown on my profile page
     * @title_de Number of needs
     * @description_de Number of needs shown on my profile page 
     */
    numberOfNeeds: number
    /**
    * @title Show more button text
    * @description Text for show more button 
    * @title_de Show more button text
    * @description_de Text for show more button  
    */
    showMoreButtonText: LocalizedString
    /**
     * @title Show less button text
     * @description Text for show less button 
     * @title_de Show less button text
     * @description_de Text for show less button  
     */
    showLessButtonText: LocalizedString
    /**
     * @title Required fields label
     * @description Text for required fields label
     * @title_de Required fields label
     * @description_de Text for required fields label
     */
    requiredFieldsLabel: LocalizedString
    /**
     * @title Save and update button text
     * @description Text for save and update button
     * @title_de Save and update button text
     * @description_de Text for save and update button
     */
    saveAndUpdateButtonText: LocalizedString
    /**
    * @title Discard button text
    * @description Text for discard button
    * @title_de Discard button text
    * @description_de Text for discard button
    */
    discardChangesButtonText: LocalizedString
    /**
     * @title Data successfully updated text
     * @description Text for successfully updated data message
     * @title_de Data successfully updated text
     * @description_de Text for successfully updated data message
     */
    dataSuccessfullyUpdatedText: LocalizedString
    /**
     * @title One required field empty warning
     * @description Text for one required field empty warning
     * @title_de One required field empty warning
     * @description_de Text for one required field empty warning 
     */
    oneRequiredFieldEmptyWarning: LocalizedString
    /**
    * @title Required fields empty warning
    * @description Text for all required fields empty warning
    * @title_de Required fields empty warning
    * @description_de Text for all required fields empty warning
    */
    requiredFieldsEmptyWarning: LocalizedString
    /**
     * @title Validation incorrect warning
     * @description Text for incorrect validation warning 
     * @title_de Validation incorrect warning
     * @description_de Text for incorrect validation warning 
     */
    validationIncorrectWarning: LocalizedString
    /**
    * @title Invalid name warning 
    * @description Text for invalid name warning message 
    * @title_de Invalid name warning 
    * @description_de Text for invalid name warning message 
    */
    invalidNameWarning: LocalizedString
    /**
     * @title Invalid surname warning 
     * @description Text for invalid surname warning message 
     * @title_de Invalid surname warning 
     * @description_de Text for invalid surname warning message 
     */
    invalidSurnameWarning: LocalizedString
    /**
     * @title Invalid company name warning 
     * @description Text for invalid company name warning message 
     * @title_de Invalid company name warning 
     * @description_de Text for invalid company name warning message 
     */
    invalidCompanyNameWarning: LocalizedString
    /**
    * @title Invalid job title warning 
    * @description Text for invalid job title warning message 
    * @title_de Invalid job title warning 
    * @description_de Text for invalid job title warning message 
    */
    invalidJobTitleWarning: LocalizedString
    /**
    * @title Invalid industry name warning 
    * @description Text for invalid industry name warning message 
    * @title_de Invalid industry name warning 
    * @description_de Text for invalid industry name warning message 
    */
    invalidIndustryNameWarning: LocalizedString
    /**
     * @title Invalid phone number warning 
     * @description Text for invalid phone number warning message 
     * @title_de Invalid phone number warning 
     * @description_de Text for invalid phone number warning message 
     */
    invalidPhoneNumberWarning: LocalizedString
    /**
    * @title Invalid address warning 
    * @description Text for invalid address warning message 
    * @title_de Invalid address warning 
    * @description_de Text for invalid address warning message 
    */
    invalidAdressWarning: LocalizedString
    /**
    * @title Invalid city warning 
    * @description Text for invalid city warning message 
    * @title_de Invalid city warning 
    * @description_de Text for invalid city warning message 
    */
    invalidCityWarning: LocalizedString
    /**
     * @title Invalid postal code warning 
     * @description Text for invalid postal code warning message 
     * @title_de Invalid postal code warning 
     * @description_de Text for invalid postal code warning message 
     */
    invalidPostalCodeWarning: LocalizedString
    /**
     * @title Invalid Facebook link warning 
     * @description Text for invalid Facebook link warning message 
     * @title_de Invalid Facebook link warning 
     * @description_de Text for invalid Facebook link warning message 
     */
    invalidFacebookLinkWarning: LocalizedString
    /**
     * @title Invalid Twitter link warning 
     * @description Text for invalid Twitter link warning message 
     * @title_de Invalid Twitter link warning 
     * @description_de Text for invalid Twitter link warning message 
     */
    invalidTwitterLinkWarning: LocalizedString
    /**
     * @title Invalid Linked in link warning 
     * @description Text for invalid Linked in link warning message 
     * @title_de Invalid Linked in link warning 
     * @description_de Text for invalid Linked in link warning message 
     */
    invalidLinkedInLinkWarning: LocalizedString
    /**
     * @title Invalid Xing link warning 
     * @description Text for invalid Xing link warning message 
     * @title_de Invalid Xing link warning 
     * @description_de Text for invalid Xing link warning message 
     */
    invalidXingLinkWarning: LocalizedString
    /**
     * @title Invalid Instagram link warning 
     * @description Text for invalid Instagram link warning message 
     * @title_de Invalid Instagram link warning 
     * @description_de Text for invalid Instagram link warning message 
     */
    invalidInstagramLinkWarning: LocalizedString
    /**
     * @title Invalid Youtube link warning 
     * @description Text for invalid Youtube link warning message 
     * @title_de Invalid Youtube link warning 
     * @description_de Text for invalid Youtube link warning message 
     */
    invalidYouTubeLinkWarning: LocalizedString
    /**
     * @title Invalid Pinterest link warning 
     * @description Text for invalid Pinterest link warning message 
     * @title_de Invalid Pinterest link warning 
     * @description_de Text for invalid Pinterest link warning message 
     */
    invalidPinterestLinkWarning: LocalizedString
    /**
     * @title Picture size warning 
     * @description Incorrect picture size warning message 
     * @title_de Picture size warning 
     * @description_de Incorrect picture size warning message 
     */
    pictureSizeWarning: LocalizedString
    /**
    * @title Backend error warning 
    * @description Text for backend error warning message 
    * @title_de Backend error warning 
    * @description_de Text for backend error warning message 
    */
    backendErrorWarning: LocalizedString
    /**
     * @title Search placeholder 
     * @description Text for Search placeholder 
     * @title_de Search placeholder 
     * @description_de Text for Search placeholder 
     */
    searchPlaceholder: LocalizedString
    /**
    * @title Min picture height 
    * @description Number for minimum picture height 
    * @title_de Min picture height 
    * @description_de Number for minimum picture height 
    */
    minPictureHeight: number
    /**
   * @title Min picture width 
   * @description Number for minimum picture width 
   * @title_de Min picture width 
   * @description_de Number for minimum picture width 
   */
    minPictureWidth: number
    /**
     * @title Required fields 
     * @description String array of required fields (first name and last name are required by default)
     * @title_de Required fields 
     * @description_de String array of required fields (first name and last name are required by default)
     */
     editRequiredFields: Field[]
    /**
     * @title Edit disabled fields 
     * @description String array of disabled fields 
     * @title_de Edit disabled fields 
     * @description_de String array of disabled fields 
     * 
     * @UsedByBackend
     */
    editDisabledFields: Field[]
    /**
     * @title Edit disabled fields 
     * @description String array of disabled fields 
     * @title_de Edit disabled fields 
     * @description_de String array of disabled fields 
     * 
     * @UsedByBackend
     */
     editDisabledSocialMedia: SocialMediaType[]
    /**
     * @title Available social medias
     * @description List of available social medias 
     * @title_de Available social medias
     * @description_de List of available social medias 
     */
    availableSocialMedias: SocialMediaType[]
    /**
    * @title Offers Needs Categories
    * @description List of offers and needs categories 
    * @title_de Offers Needs Categories
    * @description_de List of offers and needs categories  
    */
    offersNeedsCategories: Array<OffersNeedsCategoryType>
    /**
     * @title Categories order
     * @description Order for displaying categories in my profile page - Possible values in array
     * @title_de Categories order
     * @description_de Order for displaying categories in my profile page - Possible values in array
     */
    categoriesOrder: CategoryType[]
    /**
     * @title Hide contact informations
     * @description Contact info Values to hide from edit my profile page
     * @title_de Kontaktinformationen ausblenden
     * @description_de Kontaktinformationen Werte, die vor dem Bearbeiten meiner Profilseite verborgen werden sollen
     */
    contactInfosHiddenFields: Field[]
    /**
    * @title Interests required
    * @description Set interests as required
    * @title_de Interessen erforderlich 
    * @description_de Interessen nach Bedarf festlegen 
    */
    interestsRequired: boolean
    /**
     * @title Hide industry and country
     * @description Hide industry and country fields in edit profile page
     * @title_de Industrie und Land verstecken 
     * @description_de Industrie und Land verstecken in der Profilbearbeitungsseite 
     */
    hideIndustryAndCountry: boolean
    /**
     * @title Hide Job title de
     * @description Hide Job title de field in edit profile page
     * @title_de Hide Job title de 
     * @description_de Hide Job title de field in edit profile page 
     */
    hideJobTitleDe: boolean
    /**
    * @title Hide industry de
    * @description Hide industry de field in edit profile page
    * @title_de Hide industry de 
    * @description_de Hide industry de field in edit profile page 
    */
    hideIndustryDe: boolean
    /**
     * @title Hide biography de
     * @description Hide biography de field in edit profile page
     * @title_de Hide biography de 
     * @description_de Hide biography de field in edit profile page 
     */
    hideBiographyDe: boolean
    /**
     * @title Contact button background color
     * @description Background color of mobile button in mobile version of profile page
     * @title_de Contact button background color
     * @description_de Background color of mobile button in mobile version of profile page
     */
    contactButtonBgColor: string
    /**
     * @title Social media section title
     * @description Title of social media section in mobile version of profile page
     * @title_de Social media section title
     * @description_de Title of social media section in mobile version of profile page
    */
    socialMediaSectionTitle: LocalizedString
    /**
    * @title Edit button color
    * @description Color of edit button in mobile version of profile page
    * @title_de Edit button color
    * @description_de Color of edit button in mobile version of profile page
    */
    editButtonColor: string
    /**
    * @title Mobile page background color
    * @description Background color of mobile version of profile page
    * @title_de Mobile page background color
    * @description_de Background color of mobile version of profile page
    */
    mobilePageBackgroundColor: string

    /**
    * @title List of fields in edit profile page (only on mobile version currently)
    * @description List of fields in edit profile page (only on mobile version currently)
    * @title_de List of fields in edit profile page (only on mobile version currently)
    * @description_de List of fields in edit profile page (only on mobile version currently)
    */
    editProfileFieldsList: Field[]
    /**
    * @title Edit photo button title (mobile version)
    * @description Edit photo button title (mobile version)
    * @title_de Edit photo button title (mobile version)
    * @description_de Edit photo button title (mobile version)
    */
    editPhotoMobileButtonTitle: LocalizedString

    /**
    * @title Edit photo button text color (mobile version)
    * @description Edit photo button text color (mobile version)
    * @title_de Edit photo button text color (mobile version)
    * @description_de Edit photo button text color (mobile version)
    */
    editPhotoMobileButtonTextColor: string

    /**
    * @title Save changes button title (mobile version)
    * @description Save changes button title (mobile version)
    * @title_de Save changes button title (mobile version)
    * @description_de Save changes button title (mobile version)
    */
    saveChangesMobileTitle: LocalizedString

    /**
    * @title Save changes button text color (mobile version)
    * @description Save changes button text color (mobile version)
    * @title_de Save changes button text color (mobile version)
    * @description_de Save changes button text color (mobile version)
    */
    saveChangesMobileTextColor: string

    /**
    * @title Save changes button background color (mobile version)
    * @description Save changes button background color (mobile version)
    * @title_de Save changes button background color (mobile version)
    * @description_de Save changes button background color (mobile version)
    */
    saveChangesMobileBgColor: string

    /**
    * @title Success saving message color (mobile version)
    * @description Success saving message color (mobile version)
    * @title_de Success saving message color (mobile version)
    * @description_de Success saving message color (mobile version)
    */
    successSavingMessageColor: string
    /**
    * @title Text fields border color (mobile version)
    * @description Border color of text fields in mobile version of edit profile page
    * @title_de Text fields text color (mobile version)
    * @description_de Border color of text fields in mobile version of edit profile page
    */
    textFieldsBorderColor: string

    /**
    * @title Text fields background color (mobile version)
    * @description Background color of text fields in mobile version of edit profile page
    * @title_de Text fields background color (mobile version)
    * @description_de Background color of text fields in mobile version of edit profile page
    */
    textFieldsBackgroundColor: string

    /**
    * @title Not selected category item color (mobile version)
    * @description Color of text and border of not selected category items in mobile version of edit profile page
    * @title_de Not selected category item color (mobile version)
    * @description_de Color of text and border of not selected category items in mobile version of edit profile page
    */
    notSelectedCategoryItemColor: string
}



// Fields that can be disabled through branding -> MyProfilePageBranding -> field editDisabledFields
export enum Field {
    FIRSTNAME = "FIRSTNAME",
    LASTNAME = "LASTNAME",
    LOGO = "LOGO",
    COMPANY = "COMPANY",
    POSITION = "POSITION",
    POSITION_DE = "POSITION_DE",
    INDUSTRY = "INDUSTRY",
    INDUSTRY_DE = "INDUSTRY_DE",
    COUNTRY = "COUNTRY",
    TIMEZONE = "TIMEZONE",
    BIOGRAPHY = "BIOGRAPHY",
    BIOGRAPHY_DE = "BIOGRAPHY_DE",
    PHONE = "PHONE",
    MOBILE = "MOBILE",
    ADRESS1 = "ADRESS1",
    ADRESS2 = "ADRESS2",
    ADRESS3 = "ADRESS3",
    CITY = "CITY",
    POSTAL_CODE = "POSTAL_CODE",
    SOCIAL_MEDIA = "SOCIAL_MEDIA",
    LANGUAGE = "LANGUAGE"
}

export enum SocialMediaType{
    FACEBOOK = "FACEBOOK",
    TWITTER = "TWITTER",
    INSTAGRAM = "INSTAGRAM",
    YOUTUBE = "YOUTUBE",
    LINKEDIN = "LINKEDIN",
    XING = "XING",
    PINTEREST = "PINTEREST"
}

export enum CategoryType {
    INTERESTS = "INTERESTS",
    OFFERS = "OFFERS",
    NEEDS = "NEEDS"
}