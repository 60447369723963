import React, { useState } from 'react';
import { Button } from "react-bootstrap";
import styled from "styled-components";
import { DataPrivacyDoc } from '../../backendServices/BackendServices';
import branding from "../../branding/branding";
import { useLanguageState } from '../../globalStates/LanguageState';



export const ErrorMessagesContainer = styled.div`
    margin-top: 2rem;
    font-size: inherit;

    & .invalid-email-link{
        color: ${branding.loginRegistrationSite.invalidEmailPanelWithLink.textColor};  
    }
`


const CheckboxParent = styled.div`
    display: flex;
    align-items: center;
    font: ${branding.font1};
    
    & a{
        color: ${branding.loginRegistrationSite.loginInputMailTextColor};
        text-decoration: underline;
    }
`

const CheckboxInput = styled.input`
    width: 15px;
    height: 15px;
    background: #FFF;
    border: 1px solid #000;
`

const CheckboxLabel = styled.label`
    margin-bottom: 0;
`

const Checkbox = (props: any) => (
    <CheckboxInput type="checkbox" {...props} />
)

export function usePrivacyCheckbox(dataPrivacyDoc: DataPrivacyDoc): [boolean, () => JSX.Element] {
    const privacyCheckboxVisible = branding.loginRegistrationSite.privacyDocsCheckbox.visible
    const privacySecondCheckboxVisible = branding.loginRegistrationSite.privacyDocsSecondCheckbox.visible
    const [accepted, setAccepted] = useState(!privacyCheckboxVisible || false)
    const [acceptedSecond, setAcceptedsecond] = useState(!privacySecondCheckboxVisible || false)
    const strings = useLanguageState().getStrings()

    return [accepted && acceptedSecond,
    () => <>
        {privacyCheckboxVisible &&
            <CheckboxParent className="mt-3" >
                <Checkbox checked={accepted} id="privacyCheckbox" onChange={() => {
                    setAccepted(!accepted)
                }} />
                <CheckboxLabel htmlFor="privacyCheckbox" className="ml-3">{strings.loginRegistrationSite.privacyDocsCheckbox.labelBeforeLink}<a href={dataPrivacyDoc?.url} target="_blank" rel="noopener noreferrer">{strings.loginRegistrationSite.privacyDocsCheckbox.linkTitle}</a>{strings.loginRegistrationSite.privacyDocsCheckbox.labelAfterLink}</CheckboxLabel>
            </CheckboxParent >
        }

        {privacySecondCheckboxVisible &&
            <CheckboxParent className="mt-3" >
                <Checkbox checked={acceptedSecond} id="privacyCheckbox" onChange={() => {
                    setAcceptedsecond(!acceptedSecond)
                }} />
                <CheckboxLabel htmlFor="privacySecondCheckbox" className="ml-3">{strings.loginRegistrationSite.privacyDocsSecondCheckbox.labelBeforeLink}<a href={dataPrivacyDoc?.url} target="_blank" rel="noopener noreferrer">{strings.loginRegistrationSite.privacyDocsSecondCheckbox.linkTitle}</a>{strings.loginRegistrationSite.privacyDocsSecondCheckbox.labelAfterLink}</CheckboxLabel>
            </CheckboxParent >
        }

    </>
    ]
}



export const CenterLoginStyleTicketBtn = styled(Button)`
    font-size: 16px;
    height: 45px;
    min-width: 250px;
    width: auto;
    font-family: ${branding.font1};
    border-radius: 40px;
    border: ${branding.loginRegistrationSite.centerLoginStyleTicketBorderBtn};
    background-color: ${branding.loginRegistrationSite.centerLoginStyleTicketBtnBgColor};
    color: ${branding.loginRegistrationSite.centerLoginStyleTicketTextColorBtn};
    padding: .5rem 2rem;
    
    &:hover {
        background-color: ${branding.loginRegistrationSite.centerLoginStyleTicketBtnOnHoverBgColor};
        border: ${branding.loginRegistrationSite.centerLoginStyleTicketBorderBtn};
        outline: none !important;
        color: ${branding.loginRegistrationSite.centerLoginStyleTicketBtnOnHoverTextColor};
    }

    &:focus {
        outline: none;
    }
`


export const CenterLoginStyleTicketButton = () => {
    const strings = useLanguageState().getStrings();
    return <CenterLoginStyleTicketBtn onClick={() => window.open(`${branding.ticketSale.ticketsUrl}`, "_blank")}>{strings.loginRegistrationSite.buyTicketButton}</CenterLoginStyleTicketBtn>
}

export const CenterLoginTextFieldContainer = styled.div`
    margin: 20px 0;
`

export const CenterLoginStyleHeader = styled.div`
    font-family: ${branding.font2};
    position: absolute; 
    top: 0; 
    left: 0;
    right: 0;
    color: ${branding.secondaryTextColor ?? "#fff"};
    display: flex; 
    justify-content: center;
    flex-direction: column; 
    align-items: center; 
`

export const CenterLoginStyleHeaderTitle = styled.h1`
    font-size: ${branding.loginRegistrationSite.loginRegistrationTitleFontSize ?? "3vw"};
    white-space: pre;
    color: ${branding.loginRegistrationSite.loginTitleTextColor ?? "#fff"};
    @media(max-width: 1300px){
        font-size: 2.3vw;
    }
    
    @media(max-height: 700px){
        font-size: 2.2vw;
    }
`

export const CenterLoginStyleHeaderSubitle = styled.p`
    font-size: ${branding.loginRegistrationSite.loginRegistrationSubtitleFontSize ?? "1.5vw"};
    white-space: pre;
    color: ${branding.loginRegistrationSite.loginSubTitleTextColor ?? "#fff"};
    font-weight: ${branding.loginRegistrationSite.loginSubTitleFontWeight ?? "normal"};
`

export const CenterLoginStyleErrorMessage = styled.div`
    color: ${branding.dangerButtonColor};;
`

export const CenterLoginStyleInputContainer = styled.div<{ windowHeight?: number }>`
    width: 600px;
    min-height: 400px;
    padding: ${props => props.windowHeight && props.windowHeight < 1200 ? "2rem" : "2.5rem"}; 
    font-size: inherit;
    border: 2px solid ${branding.loginRegistrationSite.loginModalTextColor ?? "#fff"};
    border-radius: 5px;
    position: absolute;
    top: ${props => props.windowHeight && props.windowHeight < 1200 ? "55%" : "50%"};
    left: 50%;
    transform: translate(-50%, -50%);
    color: ${branding.loginRegistrationSite.loginModalTextColor ?? "#fff"};
    font-family: ${branding.font1};

    &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: ${branding.loginRegistrationSite.loginBoxOverlayColor ?? "rgba(0,0,0,.5)"};
        backdrop-filter: blur(3px);
        z-index: -1;
        border-radius: 5px;
    }
    &.loading::before {
        z-index: 2;
    }

    
    @media only screen and (max-width: 640px) {
      width: 90%;
    }
`

export const CenterLoginStyleForgotPassword = styled.div`
    text-align: center;
    font-size: .9rem;
    padding: 1rem;
    color: ${branding.loginRegistrationSite.changeEmailAddressLinkColor ?? "#fff"};
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
`

export const CenterLoginStyleSubmitButton = styled(Button)`
    display: block;
    outline: none;
    width: 250px;
    height: 45px;
    margin: 2rem auto 0;
    border-radius: 40px;
    text-align: center;
    font-size: 16px;
    border: none;
    background-color: ${branding.loginRegistrationSite.loginButtonBackgroundColor}!important;
    color: ${branding.loginRegistrationSite.loginButtonTextColor}!important;
   
    &.btn:disabled {
        background-color: ${branding.loginRegistrationSite.loginButtonDisabledBackgroundColor}!important;
        opacity: 1;
        border: none;
    }
`


export const CenterLoginStyleLoaderAttributes: React.CSSProperties = {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 3
}



export const CenterLoginStyleContactUsLabel = styled.div`
   font-family: ${branding.font1};
   width: 100%;
   margin-bottom: 10px;
   text-align: center;
   color: ${branding.loginRegistrationSite.contactUsLabelColor ?? "#000"};
`