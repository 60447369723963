/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const emptyMutation = /* GraphQL */ `
  mutation EmptyMutation {
    _emptyMutation
  }
`;
export const createCalendarEntry = /* GraphQL */ `
  mutation CreateCalendarEntry(
    $condition: ModelCalendarEntryConditionInput
    $input: CreateCalendarEntryInput!
  ) {
    createCalendarEntry(condition: $condition, input: $input) {
      id
      title
      description
      start
      end
      userId
      participants {
        items {
          id
          calendarEntryId
          userId
          status
          start
          userIdStatus
          createdAt
          updatedAt
        }
        nextToken
      }
      organizationId
      status
      organizationIdStatus
      topicName
      userPoolName
      createdAt
      updatedAt
      user {
        id
        presenceStatus
        lastConnected
        name
        pictureUrl
        outgoingMeetings {
          nextToken
        }
        incomingMeetings {
          nextToken
        }
        createdAt
        updatedAt
        conversations {
          nextToken
        }
      }
    }
  }
`;
export const updateCalendarEntry = /* GraphQL */ `
  mutation UpdateCalendarEntry(
    $condition: ModelCalendarEntryConditionInput
    $input: UpdateCalendarEntryInput!
  ) {
    updateCalendarEntry(condition: $condition, input: $input) {
      id
      title
      description
      start
      end
      userId
      participants {
        items {
          id
          calendarEntryId
          userId
          status
          start
          userIdStatus
          createdAt
          updatedAt
        }
        nextToken
      }
      organizationId
      status
      organizationIdStatus
      topicName
      userPoolName
      createdAt
      updatedAt
      user {
        id
        presenceStatus
        lastConnected
        name
        pictureUrl
        outgoingMeetings {
          nextToken
        }
        incomingMeetings {
          nextToken
        }
        createdAt
        updatedAt
        conversations {
          nextToken
        }
      }
    }
  }
`;
export const deleteCalendarEntry = /* GraphQL */ `
  mutation DeleteCalendarEntry(
    $condition: ModelCalendarEntryConditionInput
    $input: DeleteCalendarEntryInput!
  ) {
    deleteCalendarEntry(condition: $condition, input: $input) {
      id
      title
      description
      start
      end
      userId
      participants {
        items {
          id
          calendarEntryId
          userId
          status
          start
          userIdStatus
          createdAt
          updatedAt
        }
        nextToken
      }
      organizationId
      status
      organizationIdStatus
      topicName
      userPoolName
      createdAt
      updatedAt
      user {
        id
        presenceStatus
        lastConnected
        name
        pictureUrl
        outgoingMeetings {
          nextToken
        }
        incomingMeetings {
          nextToken
        }
        createdAt
        updatedAt
        conversations {
          nextToken
        }
      }
    }
  }
`;
export const createCalendarEntryParticipation = /* GraphQL */ `
  mutation CreateCalendarEntryParticipation(
    $condition: ModelCalendarEntryParticipationConditionInput
    $input: CreateCalendarEntryParticipationInput!
  ) {
    createCalendarEntryParticipation(condition: $condition, input: $input) {
      id
      calendarEntryId
      calendarEntry {
        id
        title
        description
        start
        end
        userId
        participants {
          nextToken
        }
        organizationId
        status
        organizationIdStatus
        topicName
        userPoolName
        createdAt
        updatedAt
        user {
          id
          presenceStatus
          lastConnected
          name
          pictureUrl
          createdAt
          updatedAt
        }
      }
      userId
      status
      start
      userIdStatus
      createdAt
      updatedAt
      user {
        id
        presenceStatus
        lastConnected
        name
        pictureUrl
        outgoingMeetings {
          nextToken
        }
        incomingMeetings {
          nextToken
        }
        createdAt
        updatedAt
        conversations {
          nextToken
        }
      }
    }
  }
`;
export const updateCalendarEntryParticipation = /* GraphQL */ `
  mutation UpdateCalendarEntryParticipation(
    $condition: ModelCalendarEntryParticipationConditionInput
    $input: UpdateCalendarEntryParticipationInput!
  ) {
    updateCalendarEntryParticipation(condition: $condition, input: $input) {
      id
      calendarEntryId
      calendarEntry {
        id
        title
        description
        start
        end
        userId
        participants {
          nextToken
        }
        organizationId
        status
        organizationIdStatus
        topicName
        userPoolName
        createdAt
        updatedAt
        user {
          id
          presenceStatus
          lastConnected
          name
          pictureUrl
          createdAt
          updatedAt
        }
      }
      userId
      status
      start
      userIdStatus
      createdAt
      updatedAt
      user {
        id
        presenceStatus
        lastConnected
        name
        pictureUrl
        outgoingMeetings {
          nextToken
        }
        incomingMeetings {
          nextToken
        }
        createdAt
        updatedAt
        conversations {
          nextToken
        }
      }
    }
  }
`;
export const deleteCalendarEntryParticipation = /* GraphQL */ `
  mutation DeleteCalendarEntryParticipation(
    $condition: ModelCalendarEntryParticipationConditionInput
    $input: DeleteCalendarEntryParticipationInput!
  ) {
    deleteCalendarEntryParticipation(condition: $condition, input: $input) {
      id
      calendarEntryId
      calendarEntry {
        id
        title
        description
        start
        end
        userId
        participants {
          nextToken
        }
        organizationId
        status
        organizationIdStatus
        topicName
        userPoolName
        createdAt
        updatedAt
        user {
          id
          presenceStatus
          lastConnected
          name
          pictureUrl
          createdAt
          updatedAt
        }
      }
      userId
      status
      start
      userIdStatus
      createdAt
      updatedAt
      user {
        id
        presenceStatus
        lastConnected
        name
        pictureUrl
        outgoingMeetings {
          nextToken
        }
        incomingMeetings {
          nextToken
        }
        createdAt
        updatedAt
        conversations {
          nextToken
        }
      }
    }
  }
`;
export const createConversation = /* GraphQL */ `
  mutation CreateConversation(
    $condition: ModelConversationConditionInput
    $input: CreateConversationInput!
  ) {
    createConversation(condition: $condition, input: $input) {
      id
      type
      name
      description
      userId
      mostRecentMessage
      mostRecentMessageCreatedAt
      memberIdsHash
      updatedAt
      createdAt
      messages {
        items {
          id
          authorId
          content
          conversationId
          isSent
          createdAt
          sotName
          members
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
        nextToken
        startedAt
      }
      members {
        items {
          id
          userId
          conversationId
          isMuted
          isChatOpen
          mostRecentMessageCreatedAt
          lastReadMessageCreatedAt
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateConversation = /* GraphQL */ `
  mutation UpdateConversation(
    $condition: ModelConversationConditionInput
    $input: UpdateConversationInput!
  ) {
    updateConversation(condition: $condition, input: $input) {
      id
      type
      name
      description
      userId
      mostRecentMessage
      mostRecentMessageCreatedAt
      memberIdsHash
      updatedAt
      createdAt
      messages {
        items {
          id
          authorId
          content
          conversationId
          isSent
          createdAt
          sotName
          members
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
        nextToken
        startedAt
      }
      members {
        items {
          id
          userId
          conversationId
          isMuted
          isChatOpen
          mostRecentMessageCreatedAt
          lastReadMessageCreatedAt
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createMeetingParticipant = /* GraphQL */ `
  mutation CreateMeetingParticipant(
    $condition: ModelMeetingParticipantConditionInput
    $input: CreateMeetingParticipantInput!
  ) {
    createMeetingParticipant(condition: $condition, input: $input) {
      id
      meetingId
      meeting {
        id
        participants {
          nextToken
        }
        start
        end
        createdAt
        updatedAt
      }
      inviterId
      inviteeId
      status
      created
      createdAt
      updatedAt
      inviter {
        id
        presenceStatus
        lastConnected
        name
        pictureUrl
        outgoingMeetings {
          nextToken
        }
        incomingMeetings {
          nextToken
        }
        createdAt
        updatedAt
        conversations {
          nextToken
        }
      }
      invitee {
        id
        presenceStatus
        lastConnected
        name
        pictureUrl
        outgoingMeetings {
          nextToken
        }
        incomingMeetings {
          nextToken
        }
        createdAt
        updatedAt
        conversations {
          nextToken
        }
      }
    }
  }
`;
export const updateMeetingParticipant = /* GraphQL */ `
  mutation UpdateMeetingParticipant(
    $condition: ModelMeetingParticipantConditionInput
    $input: UpdateMeetingParticipantInput!
  ) {
    updateMeetingParticipant(condition: $condition, input: $input) {
      id
      meetingId
      meeting {
        id
        participants {
          nextToken
        }
        start
        end
        createdAt
        updatedAt
      }
      inviterId
      inviteeId
      status
      created
      createdAt
      updatedAt
      inviter {
        id
        presenceStatus
        lastConnected
        name
        pictureUrl
        outgoingMeetings {
          nextToken
        }
        incomingMeetings {
          nextToken
        }
        createdAt
        updatedAt
        conversations {
          nextToken
        }
      }
      invitee {
        id
        presenceStatus
        lastConnected
        name
        pictureUrl
        outgoingMeetings {
          nextToken
        }
        incomingMeetings {
          nextToken
        }
        createdAt
        updatedAt
        conversations {
          nextToken
        }
      }
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $condition: ModelMessageConditionInput
    $input: CreateMessageInput!
  ) {
    createMessage(condition: $condition, input: $input) {
      id
      authorId
      content
      conversationId
      isSent
      createdAt
      sotName
      members
      _version
      _deleted
      _lastChangedAt
      updatedAt
      conversation {
        id
        type
        name
        description
        userId
        mostRecentMessage
        mostRecentMessageCreatedAt
        memberIdsHash
        updatedAt
        createdAt
        messages {
          nextToken
          startedAt
        }
        members {
          nextToken
        }
      }
      author {
        id
        presenceStatus
        lastConnected
        name
        pictureUrl
        outgoingMeetings {
          nextToken
        }
        incomingMeetings {
          nextToken
        }
        createdAt
        updatedAt
        conversations {
          nextToken
        }
      }
    }
  }
`;
export const createUserConversation = /* GraphQL */ `
  mutation CreateUserConversation(
    $condition: ModelUserConversationConditionInput
    $input: CreateUserConversationInput!
  ) {
    createUserConversation(condition: $condition, input: $input) {
      id
      userId
      conversationId
      isMuted
      isChatOpen
      mostRecentMessageCreatedAt
      lastReadMessageCreatedAt
      createdAt
      updatedAt
      conversation {
        id
        type
        name
        description
        userId
        mostRecentMessage
        mostRecentMessageCreatedAt
        memberIdsHash
        updatedAt
        createdAt
        messages {
          nextToken
          startedAt
        }
        members {
          nextToken
        }
      }
      user {
        id
        presenceStatus
        lastConnected
        name
        pictureUrl
        outgoingMeetings {
          nextToken
        }
        incomingMeetings {
          nextToken
        }
        createdAt
        updatedAt
        conversations {
          nextToken
        }
      }
    }
  }
`;
export const updateUserConversation = /* GraphQL */ `
  mutation UpdateUserConversation(
    $condition: ModelUserConversationConditionInput
    $input: UpdateUserConversationInput!
  ) {
    updateUserConversation(condition: $condition, input: $input) {
      id
      userId
      conversationId
      isMuted
      isChatOpen
      mostRecentMessageCreatedAt
      lastReadMessageCreatedAt
      createdAt
      updatedAt
      conversation {
        id
        type
        name
        description
        userId
        mostRecentMessage
        mostRecentMessageCreatedAt
        memberIdsHash
        updatedAt
        createdAt
        messages {
          nextToken
          startedAt
        }
        members {
          nextToken
        }
      }
      user {
        id
        presenceStatus
        lastConnected
        name
        pictureUrl
        outgoingMeetings {
          nextToken
        }
        incomingMeetings {
          nextToken
        }
        createdAt
        updatedAt
        conversations {
          nextToken
        }
      }
    }
  }
`;
export const deleteUserConversation = /* GraphQL */ `
  mutation DeleteUserConversation(
    $condition: ModelUserConversationConditionInput
    $input: DeleteUserConversationInput!
  ) {
    deleteUserConversation(condition: $condition, input: $input) {
      id
      userId
      conversationId
      isMuted
      isChatOpen
      mostRecentMessageCreatedAt
      lastReadMessageCreatedAt
      createdAt
      updatedAt
      conversation {
        id
        type
        name
        description
        userId
        mostRecentMessage
        mostRecentMessageCreatedAt
        memberIdsHash
        updatedAt
        createdAt
        messages {
          nextToken
          startedAt
        }
        members {
          nextToken
        }
      }
      user {
        id
        presenceStatus
        lastConnected
        name
        pictureUrl
        outgoingMeetings {
          nextToken
        }
        incomingMeetings {
          nextToken
        }
        createdAt
        updatedAt
        conversations {
          nextToken
        }
      }
    }
  }
`;
export const createMeeting = /* GraphQL */ `
  mutation CreateMeeting(
    $input: CreateMeetingInput!
    $condition: ModelMeetingConditionInput
  ) {
    createMeeting(input: $input, condition: $condition) {
      id
      participants {
        items {
          id
          meetingId
          inviterId
          inviteeId
          status
          created
          createdAt
          updatedAt
        }
        nextToken
      }
      start
      end
      createdAt
      updatedAt
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage(
    $input: DeleteMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    deleteMessage(input: $input, condition: $condition) {
      id
      authorId
      content
      conversationId
      isSent
      createdAt
      sotName
      members
      _version
      _deleted
      _lastChangedAt
      updatedAt
      conversation {
        id
        type
        name
        description
        userId
        mostRecentMessage
        mostRecentMessageCreatedAt
        memberIdsHash
        updatedAt
        createdAt
        messages {
          nextToken
          startedAt
        }
        members {
          nextToken
        }
      }
      author {
        id
        presenceStatus
        lastConnected
        name
        pictureUrl
        outgoingMeetings {
          nextToken
        }
        incomingMeetings {
          nextToken
        }
        createdAt
        updatedAt
        conversations {
          nextToken
        }
      }
    }
  }
`;
export const updateNotification = /* GraphQL */ `
  mutation UpdateNotification(
    $input: UpdateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    updateNotification(input: $input, condition: $condition) {
      id
      userId
      type
      displayGroup
      content
      userIdDisplayGroup
      createdAt
      updatedAt
    }
  }
`;
export const deleteNotification = /* GraphQL */ `
  mutation DeleteNotification(
    $input: DeleteNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    deleteNotification(input: $input, condition: $condition) {
      id
      userId
      type
      displayGroup
      content
      userIdDisplayGroup
      createdAt
      updatedAt
    }
  }
`;
export const deleteUnreadCounter = /* GraphQL */ `
  mutation DeleteUnreadCounter(
    $input: DeleteUnreadCounterInput!
    $condition: ModelUnreadCounterConditionInput
  ) {
    deleteUnreadCounter(input: $input, condition: $condition) {
      id
      requests
      contacts
      conversations
      schedules
      createdAt
      updatedAt
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      presenceStatus
      lastConnected
      name
      pictureUrl
      outgoingMeetings {
        items {
          id
          meetingId
          inviterId
          inviteeId
          status
          created
          createdAt
          updatedAt
        }
        nextToken
      }
      incomingMeetings {
        items {
          id
          meetingId
          inviterId
          inviteeId
          status
          created
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      conversations {
        items {
          id
          userId
          conversationId
          isMuted
          isChatOpen
          mostRecentMessageCreatedAt
          lastReadMessageCreatedAt
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      presenceStatus
      lastConnected
      name
      pictureUrl
      outgoingMeetings {
        items {
          id
          meetingId
          inviterId
          inviteeId
          status
          created
          createdAt
          updatedAt
        }
        nextToken
      }
      incomingMeetings {
        items {
          id
          meetingId
          inviterId
          inviteeId
          status
          created
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      conversations {
        items {
          id
          userId
          conversationId
          isMuted
          isChatOpen
          mostRecentMessageCreatedAt
          lastReadMessageCreatedAt
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      presenceStatus
      lastConnected
      name
      pictureUrl
      outgoingMeetings {
        items {
          id
          meetingId
          inviterId
          inviteeId
          status
          created
          createdAt
          updatedAt
        }
        nextToken
      }
      incomingMeetings {
        items {
          id
          meetingId
          inviterId
          inviteeId
          status
          created
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      conversations {
        items {
          id
          userId
          conversationId
          isMuted
          isChatOpen
          mostRecentMessageCreatedAt
          lastReadMessageCreatedAt
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createUserAction = /* GraphQL */ `
  mutation CreateUserAction(
    $input: CreateUserActionInput!
    $condition: ModelUserActionConditionInput
  ) {
    createUserAction(input: $input, condition: $condition) {
      id
      userId
      sotName
      actionType
      param
      startTime
      endTime
      duration
      source
      topic
      userType
      countryCode
      createdAt
      updatedAt
      user {
        id
        presenceStatus
        lastConnected
        name
        pictureUrl
        outgoingMeetings {
          nextToken
        }
        incomingMeetings {
          nextToken
        }
        createdAt
        updatedAt
        conversations {
          nextToken
        }
      }
    }
  }
`;
export const updateUserAction = /* GraphQL */ `
  mutation UpdateUserAction(
    $input: UpdateUserActionInput!
    $condition: ModelUserActionConditionInput
  ) {
    updateUserAction(input: $input, condition: $condition) {
      id
      userId
      sotName
      actionType
      param
      startTime
      endTime
      duration
      source
      topic
      userType
      countryCode
      createdAt
      updatedAt
      user {
        id
        presenceStatus
        lastConnected
        name
        pictureUrl
        outgoingMeetings {
          nextToken
        }
        incomingMeetings {
          nextToken
        }
        createdAt
        updatedAt
        conversations {
          nextToken
        }
      }
    }
  }
`;
export const deleteUserAction = /* GraphQL */ `
  mutation DeleteUserAction(
    $input: DeleteUserActionInput!
    $condition: ModelUserActionConditionInput
  ) {
    deleteUserAction(input: $input, condition: $condition) {
      id
      userId
      sotName
      actionType
      param
      startTime
      endTime
      duration
      source
      topic
      userType
      countryCode
      createdAt
      updatedAt
      user {
        id
        presenceStatus
        lastConnected
        name
        pictureUrl
        outgoingMeetings {
          nextToken
        }
        incomingMeetings {
          nextToken
        }
        createdAt
        updatedAt
        conversations {
          nextToken
        }
      }
    }
  }
`;
export const createUserSession = /* GraphQL */ `
  mutation CreateUserSession(
    $input: CreateUserSessionInput!
    $condition: ModelUserSessionConditionInput
  ) {
    createUserSession(input: $input, condition: $condition) {
      id
      userId
      sotName
      location
      locationLevel1
      locationLevel2
      locationLevel3
      ttl
      time
      source
      queryHelper
      topic
      userType
      countryCode
      createdAt
      updatedAt
      user {
        id
        presenceStatus
        lastConnected
        name
        pictureUrl
        outgoingMeetings {
          nextToken
        }
        incomingMeetings {
          nextToken
        }
        createdAt
        updatedAt
        conversations {
          nextToken
        }
      }
    }
  }
`;
export const updateUserSession = /* GraphQL */ `
  mutation UpdateUserSession(
    $input: UpdateUserSessionInput!
    $condition: ModelUserSessionConditionInput
  ) {
    updateUserSession(input: $input, condition: $condition) {
      id
      userId
      sotName
      location
      locationLevel1
      locationLevel2
      locationLevel3
      ttl
      time
      source
      queryHelper
      topic
      userType
      countryCode
      createdAt
      updatedAt
      user {
        id
        presenceStatus
        lastConnected
        name
        pictureUrl
        outgoingMeetings {
          nextToken
        }
        incomingMeetings {
          nextToken
        }
        createdAt
        updatedAt
        conversations {
          nextToken
        }
      }
    }
  }
`;
export const deleteUserSession = /* GraphQL */ `
  mutation DeleteUserSession(
    $input: DeleteUserSessionInput!
    $condition: ModelUserSessionConditionInput
  ) {
    deleteUserSession(input: $input, condition: $condition) {
      id
      userId
      sotName
      location
      locationLevel1
      locationLevel2
      locationLevel3
      ttl
      time
      source
      queryHelper
      topic
      userType
      countryCode
      createdAt
      updatedAt
      user {
        id
        presenceStatus
        lastConnected
        name
        pictureUrl
        outgoingMeetings {
          nextToken
        }
        incomingMeetings {
          nextToken
        }
        createdAt
        updatedAt
        conversations {
          nextToken
        }
      }
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage(
    $input: UpdateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    updateMessage(input: $input, condition: $condition) {
      id
      authorId
      content
      conversationId
      isSent
      createdAt
      sotName
      members
      _version
      _deleted
      _lastChangedAt
      updatedAt
      conversation {
        id
        type
        name
        description
        userId
        mostRecentMessage
        mostRecentMessageCreatedAt
        memberIdsHash
        updatedAt
        createdAt
        messages {
          nextToken
          startedAt
        }
        members {
          nextToken
        }
      }
      author {
        id
        presenceStatus
        lastConnected
        name
        pictureUrl
        outgoingMeetings {
          nextToken
        }
        incomingMeetings {
          nextToken
        }
        createdAt
        updatedAt
        conversations {
          nextToken
        }
      }
    }
  }
`;
export const createNotification = /* GraphQL */ `
  mutation CreateNotification(
    $input: CreateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    createNotification(input: $input, condition: $condition) {
      id
      userId
      type
      displayGroup
      content
      userIdDisplayGroup
      createdAt
      updatedAt
    }
  }
`;
export const createUnreadCounter = /* GraphQL */ `
  mutation CreateUnreadCounter(
    $input: CreateUnreadCounterInput!
    $condition: ModelUnreadCounterConditionInput
  ) {
    createUnreadCounter(input: $input, condition: $condition) {
      id
      requests
      contacts
      conversations
      schedules
      createdAt
      updatedAt
    }
  }
`;
export const updateUnreadCounter = /* GraphQL */ `
  mutation UpdateUnreadCounter(
    $input: UpdateUnreadCounterInput!
    $condition: ModelUnreadCounterConditionInput
  ) {
    updateUnreadCounter(input: $input, condition: $condition) {
      id
      requests
      contacts
      conversations
      schedules
      createdAt
      updatedAt
    }
  }
`;
