import React, { useState } from "react";
import styled from "styled-components";
import { IconArrowHeadRight, IconRecommend } from "../ui/Icons";
import { useHistory } from "react-router-dom";
import BookmarkWithToggle from "../ui/BookmarkWithToggle";
import branding from "../branding/branding";
import { ShareTargetType, ModalType } from "../backendServices/Types";
import { useLanguageState } from "../globalStates/LanguageState";
import { buildDetailLink, DetailNavLinkType } from "../contentArea/detailPages/DetailNavLink";
import { useAppState } from "../globalStates/AppState";
import { useLoggedInState } from "../globalStates/LoggedInUser";
import { useFavoriteState } from "../globalStates/Favorites";
import { useMeetingContext } from "../conference/context/MeetingContext";
import { useChimeContext } from "../conference/context/ChimeContext";
import { useContactState } from "../communicationArea/ContactState";
import ContextMenu from "./ContextMenu";
import { createActions } from "../communicationArea/CommunicationOptions";
import useWindowDimensions from "./WindowDimensionsHook";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { checkIfMobile } from "..";

const PersonActions = styled.div`
  display: flex;
  flex-flow: row;
  position: absolute;
  min-width: 80px;
  right: 15px;
  top: 10px;
  visibility: hidden;
`;
const PersonInfos = styled.div`
  display: flex;
  flex-flow: column;
  position: absolute;
  left: 20px;
  right: 20px;
  bottom: 20px;

  & ::selection {
    background: transparent;
  }
  & ::-moz-selection {
    background: transparent;
  }
`;

const MobilePersonInfos = styled.div`
  display: flex;
  flex-flow: column;
  position: absolute;
  left: 10px;
  bottom: 5px;
  right: 10px;

  & ::selection {
    background: transparent;
  }
  & ::-moz-selection {
    background: transparent;
  }
`;

const PersonName = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-wrap: normal;
  font-size: 18px;
  font-weight: 700;

  @media(max-width: 600px) {
    font-size: 12px;
  }
`

const MobilePersonName = styled.div`
  overflow: hidden;
  word-break: normal;
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 16px;
`

const PersonPosition = styled.div`
display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
overflow: hidden;
text-overflow: ellipsis;
font-size: 12px;
font-weight: 400;

@media(max-width: 600px) {
    font-size: 10px;
  }

`;

const DarkenOverlay = styled.div<{ contextMenuOpened: boolean }>`
  display: flex;
  visibility: hidden;
  position: absolute;
  background: ${(props) =>
    props.contextMenuOpened
      ? "rgba(0, 0, 0, .7)"
      : branding.receptionPage.darkenOverlayForParticipants ??
      "rgba(0, 0, 0, .2)"};
  width: 100%;
  height: 100%;
  border-radius: 5px;
  z-index: 1;
  top: 0;
  right: 0;

  & > * {
    flex-shrink: 0;
  }
`;

const IconContainer = styled.div`
  margin-top: 15px;
  margin-left: 80px;
  /* width: 100%; */
  border-radius: 5px;

  span {
    margin-left: 5px;
    margin-top: 5px;
  }
`;

const Tile = styled.div<{ $pictureUrl?: string; $size?: string }>`
  position: relative;
  ${(props) =>
    props.$pictureUrl ? `background: url("${props.$pictureUrl}");` : ""}
  color: ${(props) => (props.$pictureUrl ? "#fff" : "#000")};
  cursor: pointer;

  ${IconContainer} {
    margin-top: ${branding.receptionPage.personComponentActionsMarginTop ??
  "4px"};
    margin-left: 0;
    margin-right: ${branding.receptionPage.personComponentActionsMarginRight ??
  "20px"};
  }

  &:hover,
  &:active,
  &:link {
    text-decoration: none;
    color: #fff;
  }

  & h2 {
    font-family: ${branding.font2};
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    text-transform: uppercase;
  }
  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #000;
    opacity: 0.2;
    z-index: 1;
  }
  & > * {
    z-index: 2;
  }
`;
interface PersonTileProps {
  pictureurl?: string;
}

const PersonTile = styled(Tile) <PersonTileProps>`
  color: white !important;
  ${(props) =>
    props.pictureurl
      ? `background: url("${props.pictureurl}");`
      : `background: url('/branding/no-person-default-image.png');`}
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding-top: 100%;
  max-width: 100%;
  position: relative;
  border-radius: 5px;
  overflow: hidden;

  ${DarkenOverlay} {
    visibility: visible;
  }

  &:active,
  &:link {
    text-decoration: none;
  }

  :hover {
    ${PersonActions} {
      visibility: visible;
    }
  }

  &:before {
    content: none;
  }
`;

const HoverContainerRoot = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 5;
  bottom: 0;
  background: rgba(255, 255, 255, 0.9);
  color: #000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & .context-menu-container {
    /* height: 100%; */
    align-items: center;
    padding: 0;
  }
`;

const HCHeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 20px;
  position: relative;
  & .user-name {
    font-size: 16px;
    font-weight: bold;
  }
  & .user-position {
    font-size: 12px;
    line-height: 12px;
  }

  &::after {
    content: "";
    display: block;
    width: 95%;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 1px;
    background-color: #000;
  }

  @media (max-width: 1950px) {
    padding: 7px 15px;
    & .user-name {
      font-size: 12px;
    }
    & .user-position {
      font-size: 8px;
    }
  }
`;

const HCFooterContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  padding: 12px 20px;
  border-top: 1px solid #000;

  @media (max-width: 1950px) {
    padding: 7px 15px;
    font-size: 10px;
  }
`;




interface PersonProps {
  id: string;
  userId: string;
  title?: string;
  firstName: string;
  lastName?: string;
  logoUrl?: string;
  userLogoUrl?: string;
  position?: string;
  company?: string;
  organization?: string;
  setTargetId: (target: string) => void;
  setShowRecommendPersonModal: (toggled: boolean) => void;
  setLink: (link: string) => void;
  setType?: (type: ShareTargetType) => void;
  onToggle?: Function;
  componentType: DetailNavLinkType;
  user?: any;
  callback?: (param: { modalType?: ModalType }) => void;
  setSelectedUser?: (value: any) => void;
  showContextMenuOnHover?: boolean;
  modalOpen?: boolean;
  customOnBookmarkedFunction?: () => void;
}

const PersonComponent: React.FunctionComponent<PersonProps> = (props) => {
  const link = buildDetailLink(
    props.id,
    `/person/${props.firstName}_${props.lastName}`,
    props.componentType
  );
  const languageState = useLanguageState();
  const strings = languageState.getStrings();
  const appState = useAppState();
  const history = useHistory();

  // Context menu
  let userState = useLoggedInState();
  const favoriteState = useFavoriteState();
  const meeting = useMeetingContext();
  const chime = useChimeContext();
  const contactState = useContactState();
  var connectionStatus = props.userId
    ? contactState.getConnectionStatus(props.userId)
    : "UNRELATED";
  const userTypeStatus = props.userId
    ? contactState.getUserType(props.userId)
    : "";
  const [contextMenuOpened, setContextMenuOpened] = useState(false);
  const windowSize = useWindowDimensions();

  const ifMobile = checkIfMobile(windowSize)

  const personName: string = [props.title, props.firstName, props.lastName]
    .filter(Boolean)
    .join(" ");
  const personPosition: string = [
    props.position,
    props.company || props.organization,
  ]
    .filter(Boolean)
    .join(" " + strings.communicationArea.personCompanyLink + " ");

  const handleMouseEnter = () => {
    if (props.showContextMenuOnHover && props.setSelectedUser) {
      props.setSelectedUser(props.user);
      setContextMenuOpened(true);
    }
  };

  const handleMouseLeave = () => {
    if (props.showContextMenuOnHover) {
      setContextMenuOpened(false);
    }
  };

  if (ifMobile) {
    return (<PersonTile
      pictureurl={props.logoUrl || props.userLogoUrl}
      onClick={(e) => {
        history.push(link);
      }}
    >
      <DarkenOverlay
        contextMenuOpened={contextMenuOpened && windowSize.width > 1820}
      />
      <MobilePersonInfos>

        <MobilePersonName title={personName}>
          {personName}
        </MobilePersonName>

      </MobilePersonInfos>
    </PersonTile>)
  }

  return (
    <PersonTile
      pictureurl={props.logoUrl || props.userLogoUrl}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={(e) => {
        history.push(link);
      }}
    >
      <DarkenOverlay
        contextMenuOpened={contextMenuOpened && windowSize.width > 1820}
      />

      {!props.showContextMenuOnHover && (
        <PersonActions>
          {
            <OverlayTrigger
              trigger={["focus", "click", "hover"]}
              placement="bottom"
              delay={{ show: 250, hide: 250 }}
              overlay={
                <Tooltip id="tooltip" style={{ fontFamily: branding.font1 }}>
                  {strings.communicationArea.chatToolRecommend}
                </Tooltip>
              }
            >
              <IconContainer
                onClick={(event) => {
                  props.setLink(link);
                  props.setTargetId(
                    props.componentType === "user" ? props.userId : props.id
                  );
                  props.setShowRecommendPersonModal(true);
                  if (props.setType)
                    props.setType(
                      props.componentType === "user"
                        ? ShareTargetType.SOTUSER
                        : ShareTargetType.PERSON
                    );
                  event.preventDefault();
                  event.stopPropagation();
                }}
              >
                {IconRecommend({ fill: "currentColor"})}
              </IconContainer>
            </OverlayTrigger>
          }
          {props.modalOpen ? (
            <div>
              <BookmarkWithToggle
                onToggle={() => {
                  if (props.onToggle) props.onToggle(props.id);
                  if (props.componentType === "user")
                    appState.refreshToggleLobbyNetworking();
                }}
                customOnBookmarkedFunction={() => {
                  if (props.customOnBookmarkedFunction) {
                    props.customOnBookmarkedFunction();
                  }
                }}
                type={"person"}
                id={props.id}
                color={"white"}
                stroke={"white"}
              ></BookmarkWithToggle>
            </div>
          ) : (
              <OverlayTrigger
                trigger={["focus", "click", "hover"]}
                placement="bottom"
                delay={{ show: 250, hide: 250 }}
                overlay={
                  <Tooltip id="tooltip" style={{ fontFamily: branding.font1 }}>
                    {branding.organizationDetailPageContent.bookmarkPerson}
                  </Tooltip>
                }
              >
                <div>
                  <BookmarkWithToggle
                    onToggle={() => {
                      if (props.onToggle) props.onToggle(props.id);
                      if (props.componentType === "user")
                        appState.refreshToggleLobbyNetworking();
                    }}
                    customOnBookmarkedFunction={() => {
                      if (props.customOnBookmarkedFunction) {
                        props.customOnBookmarkedFunction();
                      }
                    }}
                    type={"person"}
                    id={props.id}
                    color={"white"}
                    stroke={"white"}
                  ></BookmarkWithToggle>
                </div>
              </OverlayTrigger>
            )}
        </PersonActions>
      )}
      {!contextMenuOpened && (
        <PersonInfos>

          <PersonName title={personName}>
            {personName}
          </PersonName>

          <PersonPosition title={personPosition}>
            {personPosition}
          </PersonPosition>

        </PersonInfos>
      )}
      {props.showContextMenuOnHover &&
        contextMenuOpened &&
        windowSize.width > 1820 && (
          <HoverContainerRoot>
            <HCHeaderContent>
              <PersonName>
                {personName}
              </PersonName>

              <PersonPosition>
                {personPosition}
              </PersonPosition>
            </HCHeaderContent>
            <ContextMenu
              itemsPerRow={3}
              collapsed={false}
              items={() =>
                createActions(
                  userState.user(),
                  props.user,
                  favoriteState,
                  contactState,
                  appState,
                  meeting,
                  chime,
                  strings,
                  favoriteState.is("sotuser", props.userId),
                  connectionStatus,
                  userTypeStatus,
                  props.callback!,
                  undefined,
                  undefined,
                  true
                )
              }
            />

            <HCFooterContent>
              {strings.networkingArea.goToProfilePageText}{" "}
              {IconArrowHeadRight({
                fill: branding.sideIconBar.sideIconColorDark,
                width: "15",
                height: "15",
              })}
            </HCFooterContent>
          </HoverContainerRoot>
        )}
    </PersonTile>
  );
};

export default PersonComponent;
