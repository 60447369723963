import moment from "moment"
import React, { useEffect } from "react"
import { ListGroup } from "react-bootstrap"
import { Link, useLocation, useRouteMatch } from "react-router-dom"
import styled from "styled-components"
import branding, { TopSponsor } from "../../branding/branding"
import { buildDetailLink } from "../../contentArea/detailPages/DetailNavLink"
import { useLanguageState } from "../../globalStates/LanguageState"
import { useAppState } from "../../globalStates/AppState"
import TrackingLink from "../../tracking/NavigationSource"
import { IconMyFair, IconMyFairBlackWhite, IconMenu } from "../../ui/Icons"
import LanguageToggler from "../../ui/LanguageToggle"
import { meetingPageRoute, homePageRoute } from "../RoutePaths"
import { SideBarConfig, SideIconBarItemKeys } from "./SIdeIconBarConfig"
import { Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap"
import useComponentVisible from "../useComponentVisible"

const drawerWidth = 350

/* #region  Branding */


/* #endregion */

/* #region  SideBar global */
const SideIconBarRoot = styled.div<{ backgroundColor: string, foregroundColor: string, isMeetingPage: any | null }>`
  position: absolute;
  background-color: ${props => props.backgroundColor};
  color: ${props => props.foregroundColor};
  overflow-y: auto;
  border-right: ${branding.mainBorder ? branding.mainBorder : '1px solid #d9d9d9'};
  z-index: 9999;

  & a, & a:hover, & a:link, & a:visited {
    color: ${props => props.isMeetingPage ? branding.sideIconBar.sideIconBarMeetingTextColor : branding.mainInfoColor};
  }
`
const StyledDrawer = styled.div<{ backgroundColor: string }>`
  width: ${drawerWidth};
  z-index: 99;
  height: 100vh;
  min-height: 100vh;
  background-color:${props => props.backgroundColor};
  white-space: nowrap; 

  &.drawerOpen {
      width: ${drawerWidth};
      transition: width 2s cubic-bezier(0.4, 0, 0.6, 1) 0ms; 
  }
  
  &.drawerClose {
      overflow-x: hidden;
      width: 80px;
  }
`

const SideBarLogo = styled.img` 
    width: ${branding.sideIconBar.sideBarLogoWidth ?? "30px"};
    height: ${branding.sideIconBar.sideBarLogoHeight ?? "30px"};
    min-width: 30px;
    margin-top: 10px;
    margin-left: 25px;  
    object-fit: contain;
    cursor: pointer;
`
const SideBarLogo2 = styled.img` 
    min-width: 30px;
    max-width: ${branding.sideIconBar.maxWidthForSecondLogoIcon};
    margin-left: 2px;
    object-fit: contain;
    cursor: pointer;
`

const SidebarToggleIcon = styled.div`
  width: 25px;
  height: 25px;
  cursor: pointer;
  z-index: 100;
  color: ${branding.mainInfoColor ?? "black"};
`
/* #endregion */

/* #region  SponsorItem */
const SponsorLogo = styled.div<{ pictureUrl?: string }>`
  height: 40px;
  width: 40px;

  ${props => props.pictureUrl ? `background: url("${props.pictureUrl}");` : `background: url('/branding/no-profile.png');`}
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
`
interface SponsorItemProps {
  sponsor: TopSponsor
  onClick: (e: any) => void
  isNavOpen: boolean
}

const SponsorGridItem: React.FunctionComponent<SponsorItemProps> = (props: SponsorItemProps) => {
  let isMeetingPage = useRouteMatch(meetingPageRoute)

  return <TrackingLink doClick={(e: any) => props.onClick(e)} to={buildDetailLink(props.sponsor.id, props.sponsor.name, "organization")} style={{ textDecoration: 'none' }} src="SIDEBAR">
    {props.isNavOpen &&
      <OverlayTrigger
        trigger={["focus", "click", "hover"]}
        placement="top"
        delay={{ show: 0, hide: 0 }}
        overlay={<Tooltip id={props.sponsor.id} show={!props.isNavOpen} style={{ color: branding.mainInfoColor, pointerEvents: "none", fontFamily: branding.font1 }}>
          {props.sponsor.name}
        </Tooltip>
        }>
        <ListItemIconContainer style={{ background: isMeetingPage ? branding.sideIconBar.backgroundColorForTopSponsors ?? "#fff" : "#fff", width: "40px", height: "40px", lineHeight: "40px" }}>
          <SponsorLogo pictureUrl={props.sponsor.logo} />
        </ListItemIconContainer>
      </OverlayTrigger>
    }
    {!props.isNavOpen &&
      <ListItemIconContainer style={{ background: isMeetingPage ? branding.sideIconBar.backgroundColorForTopSponsors ?? "#fff" : "#fff", width: "40px", height: "40px", lineHeight: "40px" }}>
        <SponsorLogo pictureUrl={props.sponsor.logo} />
      </ListItemIconContainer>
    }
  </TrackingLink>
}

const SponsorListItem: React.FunctionComponent<SponsorItemProps> = (props: SponsorItemProps) => {
  return <TrackingLink doClick={(e: any) => props.onClick(e)} to={buildDetailLink(props.sponsor.id, props.sponsor.name, "organization")} style={{ textDecoration: 'none' }} src="SIDEBAR">
    <ListItem key="text">
      <ListItemIconContainer style={{ background: "#fff" }}>
        <SponsorLogo pictureUrl={props.sponsor.logo} style={{marginTop: "12px"}} />
      </ListItemIconContainer>
      <ListItemTextContainer style={{ marginTop: "20px" }}>
        {props.isNavOpen && <ListItemText>
          {props.sponsor.name}
        </ListItemText>}
      </ListItemTextContainer>
    </ListItem>
  </TrackingLink>
}

/* #endregion */

/* #region  SponsorItemList */

const SponsorsListRoot = styled.div`
  margin-top: 30px;
  margin-left: -5px;

  @media (max-height: 900px) {
    margin-top: 2px;
  }
`

const SpecialSponsorLogoDivRoot = styled.div`
  width: 100%;
  justify-content: center;

  @media (max-height: 900px) {
    margin-top: 2px;
  }
`

const SponsorsGridRoot = styled.div<{ navigationOpen: boolean }>`
    margin-top: 30px;
    margin-left: ${(props) => props.navigationOpen ? "22px" : "18px"};
    overflow-x: hidden;
    display: grid;
    grid-template-columns: auto auto auto;
    grid-gap: 20px;
`

const SpecialSponsorLogoContainer = styled.div`
    width: 60px;
    height: 60px;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px;
    margin-left: 5px;
`

const SpecialSponsorLogoRoot = styled.img`
    object-fit: contain;
    height: 100%;
    width: 100%;
`


interface SpecialSponsorLogoProps {
  isNavOpen: boolean
}

const SpecialSponsorLogo: React.FunctionComponent<SpecialSponsorLogoProps> = (props: SpecialSponsorLogoProps) => {
  return <>
    {props.isNavOpen &&
      <SpecialSponsorLogoDivRoot>
        <Link to={branding.sideIconBar.specialSponsorLogoLink} onClick={(e: any) => e.stopPropagation()}>
          <SpecialSponsorLogoContainer style={{ width: "200px", height: "200px", marginLeft: "75px" }}>
            <SpecialSponsorLogoRoot src={branding.sideIconBar.specialSponsorLogo} alt=" " />
          </SpecialSponsorLogoContainer>
        </Link>
      </SpecialSponsorLogoDivRoot>
    }
    {!props.isNavOpen &&
      <SponsorsListRoot>
        <ListItem key="text" style={{ marginBottom: "-25px" }}>
        </ListItem>
        <Link to={branding.sideIconBar.specialSponsorLogoLink} onClick={(e: any) => e.stopPropagation()}>
          <SpecialSponsorLogoContainer>
            <SpecialSponsorLogoRoot src={branding.sideIconBar.specialSponsorLogo} alt=" " />
          </SpecialSponsorLogoContainer>
        </Link>
      </SponsorsListRoot>
    }
  </>
}

interface SponsorsListProps {
  items: TopSponsor[]
  title: string
  onClick: (e: any) => void
  isNavOpen: boolean
}



const SponsorsList: React.FunctionComponent<SponsorsListProps> = (props: SponsorsListProps) => {

  return <>
    {props.items.length <= 3 &&
      <SponsorsListRoot>
        <br />
        <ListItem key="text" style={{ marginBottom: "-25px" }}>
          <div style={{ fontFamily: branding.font1, fontSize: "1rem", color: "grey", visibility: props.isNavOpen ? "visible" : "hidden" }}>{props.title}</div>
        </ListItem>
        {
          props.items.map((sponsor, index) =>
            <SponsorListItem key={index} sponsor={sponsor} isNavOpen={props.isNavOpen} onClick={(e: any) => props.onClick(e)} />
          )
        }
      </SponsorsListRoot>
    }
    {props.items.length > 3 &&
      <SponsorsListRoot>
        <br />
        <ListItem key="text" style={{ marginBottom: "-25px" }}>
          <div style={{ fontFamily: branding.font1, fontSize: "1rem", color: "grey", visibility: props.isNavOpen ? "visible" : "hidden", marginLeft: "6px" }}>{props.title}</div>
        </ListItem>
        <SponsorsGridRoot navigationOpen={props.isNavOpen} style={{ backgroundColor: "transparent" }}>
          {
            props.items.map((sponsor, index) =>
              <SponsorGridItem key={index} sponsor={sponsor} isNavOpen={props.isNavOpen} onClick={(e: any) => props.onClick(e)} />
            )
          }
        </SponsorsGridRoot>
      </SponsorsListRoot>
    }
  </>
}
/* #endregion */

/* #region  ListItemCustom */
const ListItem = styled(ListGroup.Item)<{ isNavOpen: boolean}>`
  display: flex;
  border: none;
  position: relative;
  min-width: 350px;
  max-width: 350px;
  max-height: 70px;
  z-index: 100;
  background-color: transparent;
  /* padding: .75rem 1.7rem !important; */

  &.drawerButtonSelected {
    //background-color: rgba(0, 0, 0, 0.1);
    background-color: ${props => props.isNavOpen === false ? branding.sideIconBar.drawerClosedButtonSelectBackgroundColor : branding.sideIconBar.drawerButtonSelectBackgroundColor};
  }

  &.hovered:hover{
    /* background-color: rgba(0, 0, 0, .04); */
  }
`

const ListItemText = styled.div`
  font-size: ${branding.sideIconBar.sideBarListItemFontSize ?? "16px"};
  line-height: 25px;
  max-width: 350px;
  white-space: pre-line;
  font-family: ${branding.font1};
  font-weight: ${branding.sideIconBar.listItemTextFontWeight ?? "normal"};
`

const ListItemIconContainer = styled.div<{ selected?: boolean }>`
  margin-left: 7px;
  width: 25px;
  height: 25px;
  margin-top: 0px;
  /* line-height: 25px; */
`

const ListItemTextContainer = styled.div`
  margin-left: 20px;
`
interface ListItemProps {
  onClick: (e: any) => void
  selected: boolean
  icon: any
  title: string
  path: string
  textVisible: boolean
  isExternalLink?: boolean
  customUrl?: string
  isNavOpen?: boolean
}

const ListItemCustom: React.FunctionComponent<ListItemProps> = (props: ListItemProps) => {
  const className: string[] = ['hovered']

  if (props.selected)
    className.push('drawerButtonSelected')

  let isMeetingPage = useRouteMatch(meetingPageRoute)

  const color: string = props.selected ?
    (isMeetingPage ? branding.sideIconBar.sideIconColorLight : branding.sideIconBar.sideIconColorDark)
    : "gray"

  const content: JSX.Element = <ListItem className={className.join(' ')} isNavOpen={props.isNavOpen} key="text">
    {!props.textVisible &&
      <OverlayTrigger
        placement="right"
        trigger={["focus", "click", "hover"]}
        delay={{ show: 0, hide: 0 }}
        overlay={<Tooltip id={props.title} show={!props.textVisible} style={{ color: branding.mainInfoColor, pointerEvents: "none", fontFamily: branding.font1 }}>
          {props.title}
        </Tooltip>
        }>
        <div style={{display: "block", flexFlow: "column", maxWidth: "40px", textAlign: "center",float: "left" ,fontSize: "9px", color: !props.isNavOpen && props.selected ? branding.sideIconBar.sideIconColorLight : branding.sideIconBar.inactiveItemColor}}> 
          <ListItemIconContainer selected={props.selected}>
          {props.icon}
          </ListItemIconContainer>
         <div style={{width: "40px"}}> <span style={{whiteSpace: "pre-wrap", wordBreak: "keep-all"}}>{props.title}</span></div>
        </div>
      </OverlayTrigger>
    }
    {
      props.textVisible &&
      <ListItemIconContainer selected={props.selected}>
        {props.icon}
      </ListItemIconContainer>
    }
    <ListItemTextContainer>
      {props.textVisible &&
        <ListItemText style={{ color: color }}>
          {props.title}
        </ListItemText>
      }
    </ListItemTextContainer>
  </ListItem>

  return <>
    {props.isExternalLink ?
      <a href={props.customUrl ?? ""} target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }}>
        {content}
      </a> :
      <Link to={props.path} onClick={(e: any) => props.onClick(e)} style={{ textDecoration: "none" }}>
        {content}
      </Link>
    }
  </>
}
/* #endregion */

/* #region  BottomMenu */
const BottomMenuContainer = styled.div`
    position: fixed;
    padding-top: 15px;
    padding-left: 10px;
    bottom: 0px;
    display: flex;
    align-items: flex-end;
    transition: width 200ms cubic-bezier(0.4, 0, 0.6, 1) 0ms; 
    z-index: 100;
    
    >.open-link {
      display: flex;
      text-align: center;
      padding-bottom: 5px;
      transition: display 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    }

    >.closed-link {
      display: none;
      padding-bottom: 5px;
      transition: display 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    }

    >.open-language {
      width: 30px;
      text-align: center;
    }

    & #ot-sdk-btn.ot-sdk-show-settings, #ot-sdk-btn.optanon-show-settings{
      font-family: ${branding.font1};
      background: transparent !important;
      border: none !important;
      color: inherit !important;
      padding: 0;
      font-size: inherit;
      line-height: 18px;
    }

    & #ot-sdk-btn.ot-sdk-show-settings:hover, #ot-sdk-btn.optanon-show-settings:hover{
      background: transparent !important;
      color: inherit !important;
      text-decoration: underline;
    }
`

interface BottomMenuItemProps {
  isExternalLink: boolean
  link: string
  title: string
  index: number
}

const BottomMenuItem: React.FunctionComponent<BottomMenuItemProps> = (props: BottomMenuItemProps) => {

  const hideTheDot: boolean = props.index === (branding.sideIconBar.sideIconBarFooterItemsOrder.length - 1)

  return <>
    {props.isExternalLink ?
      <a href={props.link} target="_blank" rel="noopener noreferrer">
        {props.title}<p style={{ display: hideTheDot ? "none" : "inline-block", marginLeft: "5px", marginRight: "5px" }}>•</p>
      </a> :
      <Link to={props.link}>
        {props.title}<p style={{ display: hideTheDot ? "none" : "inline-block", marginLeft: "5px", marginRight: "5px" }}>•</p>
      </Link>
    }
  </>
}

interface BottomMenuProps {
  isNavOpen: boolean
  foregroundColor: string
}


const BottomMenu: React.FunctionComponent<BottomMenuProps> = (props: BottomMenuProps) => {
  const strings = useLanguageState().getStrings()
  let isMeetingPage = useRouteMatch(meetingPageRoute)

  useEffect(() => {
    var closeBtn = document.getElementById("close-pc-btn-handler")
    closeBtn?.addEventListener("click", () => {
      var darkOverlay = document.querySelector(".onetrust-pc-dark-filter")
      if (darkOverlay)
        (darkOverlay as HTMLElement).style.display = "none"
    })
  }, [document.getElementById("onetrust-pc-sdk")]) // eslint-disable-line

  return <div style={{ backgroundColor: isMeetingPage ? branding.mainInfoColor : "#fff", width: "345px" }}>
    <BottomMenuContainer style={{ display: props.isNavOpen ? "flex" : "none", fontFamily: branding.font1, background: isMeetingPage ? branding.mainInfoColor : "#fff", width: "349px", zIndex: 100 }}>
      <div style={{ position: "relative", fontSize: "12px", lineHeight: "18px", marginLeft: "10px", paddingBottom: "5px" }}>
        <Row>
          <Col xs={24}>
            <LanguageToggler fullToggler={true} color={isMeetingPage ? props.foregroundColor : branding.mainInfoColor} fontSize="inherit" />
          </Col>
        </Row>
        <div style={{ display: "flex", flexWrap: "wrap", marginTop: "10px", marginLeft: "1px" }}>
          {branding.sideIconBar.sideIconBarFooterItemsOrder.map((item, index) => {
            switch (item) {
              case "HELP": {
                return <BottomMenuItem
                  key={index}
                  index={index}
                  isExternalLink={branding.sideIconBar.helpItem.isExternalLink}
                  link={strings.sideIconBar.helpItem.link}
                  title={strings.sideIconBar.helpItem.title}
                />
              }
              case "RULES": {
                return <BottomMenuItem
                  key={index}
                  index={index}
                  isExternalLink={branding.sideIconBar.rulesItem.isExternalLink}
                  link={strings.sideIconBar.rulesItem.link}
                  title={strings.sideIconBar.rulesItem.title}
                />
              }
              case "TERMS_OF_USE": {
                return <BottomMenuItem
                  key={index}
                  index={index}
                  isExternalLink={branding.sideIconBar.termsOfUseItem.isExternalLink}
                  link={strings.sideIconBar.termsOfUseItem.link}
                  title={strings.sideIconBar.termsOfUseItem.title}
                />
              }
              case "DATA_PRIVACY_POLICY": {
                return <BottomMenuItem
                  key={index}
                  index={index}
                  isExternalLink={branding.sideIconBar.dataPrivacyPolicyItem.isExternalLink}
                  link={strings.sideIconBar.dataPrivacyPolicyItem.link}
                  title={strings.sideIconBar.dataPrivacyPolicyItem.title}
                />
              }
              case "IMPRINT": {
                return <BottomMenuItem
                  key={index}
                  index={index}
                  isExternalLink={branding.sideIconBar.imprintItem.isExternalLink}
                  link={strings.sideIconBar.imprintItem.link}
                  title={strings.sideIconBar.imprintItem.title}
                />
              }
              case "COOKIE_SETTINGS": {
                return branding.cookieOneTrustScript && branding.cookieOneTrustScript !== "" && <Row key={index}>
                  <Col xs={12}>
                    <div id="ot-sdk-btn" className="ot-sdk-show-settings">{strings.sideIconBar.cookieSettingsTitle}</div>
                  </Col>
                </Row>
              }
              default: return null
            }
          })}
        </div>
      </div>
    </BottomMenuContainer>
    {
      !props.isNavOpen &&
      <BottomMenuContainer>
        <div style={{ background: isMeetingPage ? branding.mainInfoColor : "#fff", position: "relative", fontSize: "15px", lineHeight: "20px", marginTop: "75px", alignItems: "center", width: "60px", bottom: 0, paddingBottom: "10px" }}>
          <LanguageToggler color={isMeetingPage ? props.foregroundColor : branding.mainInfoColor} fontSize="inherit" margin="0 1.2rem 5px 1.2rem" />
        </div>
      </BottomMenuContainer>
    }
  </div >
}
/* #endregion */

const WrapperContainer = styled.div<{ isNavOpen: boolean }>`
  @media(max-height: 1300px) {
    padding-bottom: ${props => (props.isNavOpen) ? '10px' : '0'};
  }
`

/* #region  MenuDrawerLayout */
interface MenuDrawerLayoutProps {
  isNavOpen: boolean
  receptionPageToggle: (route: string) => void
  myToggle: string
  myIcon: any
  logo: any
  setTrigger: (trigger: number) => void
}
const MenuDrawerLayout: React.FunctionComponent<MenuDrawerLayoutProps> = (props: MenuDrawerLayoutProps) => {
  let checkIsNavOpen =  props.isNavOpen ? true : false;
  let isMeetingPage = useRouteMatch(meetingPageRoute)

  const location = useLocation().pathname.substring(1)
  const appState = useAppState()

  function getCurrentItem(): string {


    if (location.includes("sponsor"))
      return "sponsor";

    else if (location.includes("livestreams"))
      return "livestreams"

    else if (location.includes("channel"))
      return "program"

    else if (location.includes("networking") || location.includes("user"))
      return "network"

    else if (location.includes("history"))
      return "history"

    else if (location.includes("showfloor") ||
      location.includes("company") ||
      location.includes("product") ||
      location.includes("trademark"))
      return "organization"

    else if (location.includes("experiences"))
      return "experiences"

    else if (location.includes("coupon"))
      return "coupon"

    else if (location.includes("meetings") ||
      location.includes("meeting"))
      return "meetings"

    else if (location.includes("press") || location.includes("mediapartner"))
      return "press_media"

    else if (location.includes("business"))
      return "business"

    else if (location.includes("iframetest"))
      return "iframe_test"

    else if (location.includes("help"))
      return "help"

    else if (location.includes("startup"))
      return "startup"


    else if (location.includes("myprofile") ||
      location.includes("myspeakers") ||
      location.includes("myexhibitors")) {
      props.receptionPageToggle("MY_FAIR")
      return "home"
    }

    else if (location.includes("help") ||
      location.includes("rules") ||
      location.includes("info") ||
      location.includes("termsofuse")) {
      props.receptionPageToggle("LOBBY")
      return "home"
    }

    else if (location.includes("eventdate") ||
      location.includes("program") ||
      location.includes("channel") ||
      location.includes("news"))
      return "program"

    else return "home"
  }

  useEffect(() => {
    if (!(location.includes("person") || location.includes("user"))) {
      appState.setCurrentItem(getCurrentItem())
    }
    // eslint-disable-next-line
  }, [location])



  function updateCurrentItem(item: string, receptionPageToggle: string) {
    appState.setCurrentItem(item)

    props.receptionPageToggle(receptionPageToggle)
    if (receptionPageToggle.length > 0) {
      props.setTrigger(moment().valueOf())
    }
  }


  const foregroundColor = isMeetingPage ? "#fff" : branding.primaryColor;
  const strings = useLanguageState().getStrings()

  const sideBarItems = SideBarConfig(strings, appState.currentItem, isMeetingPage ? true : false, props.myToggle, checkIsNavOpen)
  return (
    <>
      <WrapperContainer isNavOpen={props.isNavOpen}>
        <ListGroup style={{ marginTop: '8px', marginBottom: "110px", lineHeight: '18px' }}>
          <br />

          {/* sidebar navigation items */}
          {branding.sideIconBar.sideIconBarItemsOrder.map((item) => {
            const sideBarItem = sideBarItems.filter(x => x.id === item)[0]
            if (sideBarItem) {

              const selected = sideBarItem.lobbyCheck ?
                (appState.currentItem === sideBarItem.key && props.myToggle === sideBarItem.checkString) :
                appState.currentItem === sideBarItem.key
              const updateCurrentItemParam = sideBarItem.lobbyCheck ? sideBarItem.checkString! : ""

              return (
                <ListItemCustom
                  key={sideBarItem.id}
                  path={sideBarItem.path}
                  icon={sideBarItem.icon}
                  textVisible={props.isNavOpen}
                  isNavOpen={props.isNavOpen}
                  title={sideBarItem.title}
                  selected={selected}
                  isExternalLink={sideBarItem.isExternalLink}
                  customUrl={sideBarItem.customUrl}
                  onClick={(e: any) => {


                    // reset the last visited tab
                    if (appState) {
                      appState.setLastVisitedTab(null)
                    }

                    if (!(sideBarItem.isExternalLink)) {
                      updateCurrentItem(sideBarItem.key, updateCurrentItemParam)

                    }

                    e.stopPropagation()
                  }
                  } />
              )
            }

            return null
          })}

          {/* top sponsors */}
          {branding.sideIconBar.topSponsorsVisible &&
            <SponsorsList
              title={strings.sideIconBar.topSponsorsText}
              items={branding.topSponsors}
              isNavOpen={props.isNavOpen}
              onClick={(e: any) => {
                updateCurrentItem(SideIconBarItemKeys.organization, "")

                e.stopPropagation()
              }
              } />
          }

          {/* idea sponsors */}
          {branding.sideIconBar.ideaSponsorsVisible &&
            <SponsorsList
              title={strings.sideIconBar.ideaSponsorsText}
              items={branding.ideaSponsors}
              isNavOpen={props.isNavOpen}
              onClick={(e: any) => {
                updateCurrentItem(SideIconBarItemKeys.organization, "")

                e.stopPropagation()
              }
              } />

          }

          {/* special sponsor's logo */}
          {branding.sideIconBar.specialSponsorLogoVisible &&
            <SpecialSponsorLogo isNavOpen={props.isNavOpen} />
          }

        </ListGroup>


        {/* bottom menu (language switcher, help, rules, imprint, terms of use ) */}
        <BottomMenu isNavOpen={props.isNavOpen} foregroundColor={foregroundColor} />
      </WrapperContainer>
    </>
  );
}
/* #endregion */

/* #region  MenuToolbarLayout  */
const MenuToolbarLayoutRoot = styled.div`
  justify-content: space-between;
  display: flex;
  height: 50px;
  margin-top: 13px;
  min-height: 60px;
  padding-left: 24px;
  padding-right: 12px;
`

interface MenuToolbarLayoutProps {
  isNavOpen: boolean
  isMeetingPage: any
  logo: any
  handleDrawerOpen: () => void
  handleDrawerClose: () => void
}

const MenuToolbarLayout: React.FunctionComponent<MenuToolbarLayoutProps> = (props: MenuToolbarLayoutProps) => {
  return <>
    <MenuToolbarLayoutRoot>

      {props.isNavOpen ?
        <>
          {branding.sideIconBar.hasSecondLogoIcon && <div>
            <a href={homePageRoute}>
              <SideBarLogo2 src={props.logo} />
            </a>
          </div>}
          {!branding.sideIconBar.hasSecondLogoIcon && <div>
            <a href={homePageRoute}>
              <SideBarLogo
                // onClick={props.handleDrawerOpen}
                src={props.logo}
                style={{ margin: 0, marginLeft: 2 }} />
            </a>
          </div>
          }
        </>
        :
        <div>
          <a href={homePageRoute}>
            <SideBarLogo
              // onClick={props.handleDrawerOpen}
              src={props.logo}
              style={{ margin: 0, marginLeft: 2 }} />
          </a>
        </div>
      }
      <div style={{ visibility: props.isNavOpen ? "visible" : "hidden" }} onClick={() => props.handleDrawerClose()}>
        <SidebarToggleIcon>
          {IconMenu({ fill: props.isMeetingPage ? branding.sideIconBar.sideIconColorLight ?? "#fff" : branding.sideIconBar.sideIconColorDark ?? branding.mainInfoColor })}
        </SidebarToggleIcon>
      </div>
    </MenuToolbarLayoutRoot>
    <MenuToolbarLayoutRoot style={{ marginTop: "-15px" }}>
      {!props.isNavOpen && <SidebarToggleIcon onClick={props.handleDrawerOpen} style={{ margin: 0, marginLeft: "6px" }}>
        {IconMenu({ fill: props.isMeetingPage ? branding.sideIconBar.sideIconColorLight ?? "#fff" : branding.sideIconBar.sideIconColorDark ?? branding.mainInfoColor })}
      </SidebarToggleIcon>
      }
    </MenuToolbarLayoutRoot>
  </>
}
/* #endregion */


/* #region  Main content */
interface SideIconBarProps {
  navToggle: (open: boolean) => void,
  isNavOpen: boolean,
  receptionPageToggle: (route: string) => void,
  setTrigger: (trigger: number) => void
  myToggle: string;
}

const SideIconBar: React.FC<SideIconBarProps> = (props) => {

  let isMeetingPage = useRouteMatch(meetingPageRoute)

  const foregroundColor = isMeetingPage ? "#fff" : branding.primaryColor
  const backgroundColor = isMeetingPage ? branding.mainInfoColor : "#fff"
  const logo = isMeetingPage ? "/branding/navbarLogoWhite.png" : "/branding/rsz_navbarlogo.png"
  const logo_second = isMeetingPage ? "/branding/navbarLogoWhite2.png" : "/branding/rsz_second_logo.png"
  const myIcon = isMeetingPage ? IconMyFairBlackWhite({ stroke: "white" }) : IconMyFair({ fill: "#fff" })

  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false)

  const handleDrawerOpen = () => {
    props.navToggle(true)
    setIsComponentVisible(true)
  }

  const handleDrawerClose = () => {
    props.navToggle(false)
    setIsComponentVisible(false)
  }

  useEffect(() => {
    if (isComponentVisible) {
      handleDrawerOpen()
    }
    else {
      handleDrawerClose()
    }

    // eslint-disable-next-line
  }, [isComponentVisible])



  return (
    <SideIconBarRoot
      foregroundColor={foregroundColor}
      backgroundColor={backgroundColor}
      isMeetingPage={isMeetingPage}>
      <div className="d-flex">
        <StyledDrawer
          ref={ref}
          onClick={() => {!props.isNavOpen ? handleDrawerOpen() : handleDrawerClose()}}
          backgroundColor={backgroundColor}
          className={props.isNavOpen ? "drawerOpen" : "drawerClose"}
        >
          <MenuToolbarLayout
            isNavOpen={props.isNavOpen}
            isMeetingPage={isMeetingPage}
            logo={props.isNavOpen && branding.sideIconBar.hasSecondLogoIcon ? logo_second : logo}
            handleDrawerOpen={handleDrawerOpen}
            handleDrawerClose={handleDrawerClose} />

          <MenuDrawerLayout
            isNavOpen={props.isNavOpen}
            logo={logo}
            receptionPageToggle={props.receptionPageToggle}
            myToggle={props.myToggle}
            myIcon={myIcon}
            setTrigger={props.setTrigger} />
        </StyledDrawer>
      </div>
    </SideIconBarRoot >
  );
}
/* #endregion */

export default SideIconBar