import FileSaver from "file-saver";
import React, { useState } from "react"
import AwesomeSlider from "react-awesome-slider";
import { Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import ReactDOM from "react-dom";
import ReactPlayer from "react-player";
import styled from "styled-components";
import { hasAccessToOrganization } from "../../../utils/Authorization";
import { trackVisit } from "../../../backendServices/BackendServices";
import { Attachment } from "../../../backendServices/Types";
import branding from "../../../branding/branding";
import { useLanguageState } from "../../../globalStates/LanguageState";
import { useLoggedInState, User } from "../../../globalStates/LoggedInUser";
import { IconArrowLeft, IconArrowRight, IconClose, IconDownload, IconFile, IconPlay } from "../../../ui/Icons";
import { usePrivacyPolicyModal } from "../../../ui/PrivacyPolicyModal";
import { FormattedDateExtensionDiv, HeaderTitle } from "./StyledComponents";



enum GalleryContentTypes {
    JPG = "jpg",
    JPEG = "jpeg",
    PNG = "png",
    MP4 = "mp4",
    GIF = "gif",
    BMP = "bmp",
    MOV = "mov"
}

export enum GalleryTypes {
    VIDEO = "video",
    IMAGE = "image"
}

function isGalleryType(value: string | null) {
    return value === GalleryTypes.VIDEO ||
        value === GalleryTypes.IMAGE ||
        value === GalleryContentTypes.JPEG ||
        value === GalleryContentTypes.JPG ||
        value === GalleryContentTypes.PNG ||
        value === GalleryContentTypes.MP4 ||
        value === GalleryContentTypes.BMP ||
        value === GalleryContentTypes.GIF ||
        value === GalleryContentTypes.MOV
}

function isVideo(value: string | null) {
    return value === GalleryTypes.VIDEO ||
        value === GalleryContentTypes.MP4 ||
        value === GalleryContentTypes.MOV
}

const getContentType = (item: Attachment) => {
    const contentTypeTemp = item.contentType ? item.contentType.substring(0, item.contentType.lastIndexOf("/")) : null
    const contentType = contentTypeTemp ? contentTypeTemp : item.url ? item.url.substring(item.url.lastIndexOf(".") + 1, item.url.length) : null
    return contentType
}

const filterMedia = (items: Attachment[], galleryType: boolean): Attachment[] => {
    return items.filter(x => {
        if (!x.url)
            return false

        const contentType = getContentType(x)
        return (isGalleryType(contentType) && galleryType) || (!isGalleryType(contentType) && !galleryType)
    });
}

const GalleryItemRoot = styled.div`
    width: 100%;
    height: 100%;
    cursor: pointer;
    position: relative;
    
    video{
        object-fit: cover;
        border-radius: 5px;
    }

    img{
        object-fit: cover;
        width: 100%;
        height: 100%;
        border-radius: 5px;
    }
`

const PlayIcon = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`

interface GalleryItemProps {
    media: Attachment
    index: number
    onAction: () => void
}

const GalleryItem: React.FunctionComponent<GalleryItemProps> = (props) => {
    const contentType = props.media.contentType ? props.media.contentType.substring(0, props.media.contentType.lastIndexOf("/")) : props.media.url.substring(props.media.url.lastIndexOf(".") + 1, props.media.url.length);
    const strings = useLanguageState().getStrings();
    const tooltipMessage = strings.organizationDetailPageContent.showFullScreen;

    if (contentType !== GalleryTypes.VIDEO && contentType !== GalleryContentTypes.MP4 && contentType !== GalleryContentTypes.MOV && contentType !== GalleryContentTypes.GIF) {
        return (

            <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={
                    <Tooltip id="tooltip" style={{ fontFamily: branding.font1 }}>
                        {tooltipMessage}
                    </Tooltip>}>
                <GalleryItemRoot onClick={() => props.onAction()}>
                    <img src={props.media.url} alt={""}></img>
                </GalleryItemRoot>
            </OverlayTrigger>
        )
    }
    else {
        return (
            <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={
                    <Tooltip id="tooltip" style={{ fontFamily: branding.font1 }}>
                        {tooltipMessage}
                    </Tooltip>}>
                <GalleryItemRoot onClick={() => props.onAction()}>
                    <PlayIcon>{IconPlay({ width: '40', height: '40' })}</PlayIcon>
                    <ReactPlayer width={"100%"} height={"100%"} url={props.media.url} light={props.media.largeThumbnailUrl ? props.media.largeThumbnailUrl : props.media.smallThumbnailUrl ? props.media.smallThumbnailUrl : false} config={{
                        file: {
                            attributes: {
                                controlsList: "nodownload",
                                onContextMenu: (e: any) => e.preventDefault()
                            }
                        }
                    }} />
                </GalleryItemRoot >
            </OverlayTrigger>
        )
    }
}


const GalleryItemCol = styled(Col)`
    height: 300px;
    padding: 10px;

    @media (max-width: 1600px) {
        height:200px    
    }
`

interface GalleryProps {
    media: Attachment[]
    organizationId?: string
    onAction: (att: Attachment) => void
}

export const Gallery: React.FunctionComponent<GalleryProps> = (props) => {

    const logedInUser = useLoggedInState().user()
    const isQuest = !props.organizationId || hasAccessToOrganization(logedInUser!, props.organizationId) === false

    //making grid for gallery
    let x = [3, 6, 3, 6, 3, 3, 3, 3, 6];
    let colValues: Array<number> = [];

    props.media.forEach((item, index) => {
        colValues.push(x[index % 9]);
    });

    return (
        <Row>
            {
                props.media.map((item, index) => {
                    return (
                        <GalleryItemCol disable={isQuest} key={index} xs={colValues[index]} onContextMenu={(e: { preventDefault: () => any }) => { if (isQuest) e.preventDefault() }}><GalleryItem
                            media={item}
                            index={index}
                            onAction={() => props.onAction(item)}
                        />
                        </GalleryItemCol>
                    )
                })
            }
        </Row >
    )
}


const MediaRow = styled(Row)`
    display: flex;
    border-bottom: 1px solid ${branding.listDividerColor};
    padding: 25px 0;
    justify-content: space-between;
`

const MediaImageCol = styled(Col)`
    flex: 0 0 20px;
    display: flex;
    align-items: center;
    padding: 0;
`

const MediaColInfo = styled(Col)`
    padding-left: 25px;
    display:flex;
    align-items:center;
`

const MediaTitle = styled.p`
    font-family: ${branding.font1};
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 0;
    cursor: pointer;
`

const MediaColIcons = styled(Col)`
    display:flex;
    justify-content:flex-end;
    align-items:center;
    padding: 0;
`

const FormattedDateExtensionDivExtended = styled(FormattedDateExtensionDiv)`
    height: 20px;
    border: 1px solid ${branding.listDividerColor};
    padding: 0 5px;
    margin-left: 15px;
    cursor: pointer;
`

const ExstensionText = styled.p`
    font-family: ${branding.font1};
    font-size:12px;
    margin: 0;
    padding: 0;
`

const DownloadIconDiv = styled.div`
    cursor: pointer;
`

interface MediaProps {
    media: Attachment[]
    organizationId?: string
}

export const Media: React.FunctionComponent<MediaProps> = (props) => {
    const strings = useLanguageState().getStrings()
    const user = useLoggedInState().user()
    const { showPrivacyPolicyModal, PrivacyModal } = usePrivacyPolicyModal()
    const titleHeader = strings.organizationDetailPageContent.mediaTitle + " (" + props.media.length + ")"
    /* Media Slider */
    const [showMediaSlider, setShowMediaSlider] = useState(false);
    const [selectedAtt, setSelecedAtt] = useState<Attachment>();

    // Exporting media from attachments
    const galleryMedia = filterMedia(props.media, true)
    const otherMedia = filterMedia(props.media, false)
    const mediaItems: Array<any> = []

    galleryMedia.forEach((item) => {
        mediaItems.push({
            src: item.url,
            width: 4,
            height: 3
        });
    })

    if (!props.media || props.media.length === 0)
        return null

    return (
        <div className="w-100" style={{ marginLeft: "20px", marginRight: "25px" }}>
            <HeaderTitle className="mb-3">{titleHeader}</HeaderTitle>
            <Gallery media={galleryMedia} organizationId={props.organizationId}
                onAction={(att) => {
                    console.log(props.organizationId)
                    if (props.organizationId)
                        showPrivacyPolicyModal(props.organizationId, (accepted) => {
                            trackVisit(user!.profileId, props.organizationId!, "MEDIA#PREVIEW", att.id)
                            setSelecedAtt(att)
                            setShowMediaSlider(true)
                        })
                    else {
                        setSelecedAtt(att)
                        setShowMediaSlider(true)
                    }
                }} />
            <div className="col-12 mt-2">
                {otherMedia.map((att, key) => {
                    const exstension = getContentType(att)
                    return (
                        <MediaRow key={key}>
                            <MediaImageCol>
                                {IconFile({ width: "20px", height: "20px", fill: branding.sideIconBar.sideIconColorDark })}
                            </MediaImageCol>
                            <MediaColInfo  onClick={() => downloadClick(props, showPrivacyPolicyModal, att, user)}>
                                <MediaTitle>{att.title ? att.title : "NO TITLE"}</MediaTitle>
                                {exstension && exstension.length < 10 &&
                                    <FormattedDateExtensionDivExtended>
                                        <ExstensionText>{exstension}</ExstensionText>
                                    </FormattedDateExtensionDivExtended>}
                            </MediaColInfo>
                            <MediaColIcons>
                                <DownloadIconDiv onClick={() => {
                                    downloadClick(props, showPrivacyPolicyModal, att, user);
                                }}>
                                    {IconDownload({ fill: branding.sideIconBar.sideIconColorDark })}
                                </DownloadIconDiv>
                            </MediaColIcons>
                        </MediaRow>
                    )
                })}
            </div>
            <PrivacyModal />
            {ReactDOM.createPortal(
                showMediaSlider && <MediaSlider
                    organizationId={props.organizationId}
                    media={props.media}
                    selectedAtt={selectedAtt}
                    setShowMediaSlider={setShowMediaSlider}
                />, document.body
            )}
        </div>
    )
}

/* #region  Media slider */
const MediaSliderContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #FFF;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    z-index: 100;
    height: 100%;

    .awssld img, .awssld video{
        object-fit: contain;
    }

`

const CenteredDiv = styled.div`
    width: 75%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @media(max-width: 1400px){
        width: 100%;
    }
`

const DescriptionDownloadRoot = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    width: 100%;
`

const MediaDescriptionDiv = styled.div`
    width: 60%;
    color: ${branding.organizationDetailPageContent.mediaSliderPrimaryColor};
    font-family: ${branding.font1};
`

const MediaTitleSlider = styled.p`
    font-size: 22px;
    font-family: ${branding.font1};
`

const MediaDescription = styled.p`
    font-family: ${branding.font1};
    font-size: 18px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
`

const Arrow = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    cursor: pointer;
`

const SideBar = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px 30px;
    width: 120px;
    height: 100%;
    color: ${branding.organizationDetailPageContent.mediaSliderPrimaryColor};
`
const LeftSideBar = styled(SideBar)`
    align-items: flex-start;
`

const RightSideBar = styled(SideBar)`
    align-items: flex-end;
`

const CloseIconDiv = styled.div`
    cursor: pointer;
`

const Placeholder = styled.div`
`
const DownloadButtonDiv = styled.div`
    /* margin-bottom: 20px; */
    cursor: pointer;
`
const MediaSliderCounter = styled.div`
    font-size: 20px;
    font-family: ${branding.font1};
    @media(max-width: 1400px){
        font-size: 16px;
    }

    @media(max-width: 1200px){
        font-size: 14px;
    }
`

interface MediaSliderProps {
    organizationId?: string
    media: Attachment[]
    selectedAtt: Attachment | undefined
    setShowMediaSlider: (value: boolean) => void
}

export const MediaSlider: React.FunctionComponent<MediaSliderProps> = (props) => {
    const filteredMedia = filterMedia(props.media, true)
    const [selected, setSelected] = useState(props.selectedAtt ? filteredMedia.findIndex(x => x.url === props.selectedAtt!.url) : 0)
    const loggedInUser = useLoggedInState().user()
    let data = filteredMedia.map(item => {
        let source = null
        var type = item.url.split('.').pop();
        if (isGalleryType(type!))
            source = item.url
        if (!source && item.contentType) {
            var contentType = item.contentType ? item.contentType.substring(item.contentType.lastIndexOf("/") + 1, item.contentType.length) : null;
            var contentTypeBefore = item.contentType.substring(0, item.contentType.lastIndexOf("/"));
            source = contentTypeBefore === GalleryTypes.VIDEO ? item.url + ".mp4" : item.url + "." + contentType
        }

        return {
            source: source
        };
    }).filter(x => x.source != null);

    function changeMediaSource(element: any) {
        var mediaItem;
        if (element) {
            mediaItem = element.firstChild as HTMLImageElement;
            mediaItem.src = filteredMedia[selected].url;
            mediaItem.setAttribute("controlsList", "nodownload")
            mediaItem.setAttribute("oncontextmenu", "return false;")
        }
    }

    return (
        <MediaSliderContainer>
            <LeftSideBar>
                <CloseIconDiv onClick={() => props.setShowMediaSlider(false)}>{IconClose({ fill: branding.sideIconBar.sideIconColorDark })}</CloseIconDiv>
                <Arrow onClick={() => setSelected(selected - 1)} hidden={selected === 0}>{IconArrowLeft({ fill: branding.sideIconBar.sideIconColorDark })}</Arrow>
                <Placeholder />
            </LeftSideBar>
            <CenteredDiv>
                <AwesomeSlider className="controlsList nodownload" media={data} selected={selected} buttons={false} bullets={false} animation="scaleOutAnimation"
                    onFirstMount={() => { changeMediaSource(document.getElementsByClassName("awssld__content")[0]); }}
                    onTransitionEnd={() => { changeMediaSource(document.querySelector(".awssld--active .awssld__content")); }} />
                <DescriptionDownloadRoot>
                    <MediaDescriptionDiv>
                        <MediaTitleSlider>{filteredMedia[selected].title}</MediaTitleSlider>
                        <MediaDescription>{filteredMedia[selected].description}</MediaDescription>
                    </MediaDescriptionDiv>
                    {(!isVideo(filteredMedia[selected].contentType?.substring(0, filteredMedia[selected].contentType?.lastIndexOf("/"))) || (isVideo(filteredMedia[selected].contentType?.substring(0, filteredMedia[selected].contentType?.lastIndexOf("/"))) && branding.organizationDetailPageContent.videoDownloadEnabled)) &&
                        <DownloadButtonDiv onClick={() => startDownloadProcess(filteredMedia[selected], loggedInUser!, props.organizationId)}>{IconDownload({ fill: branding.sideIconBar.sideIconColorDark })}</DownloadButtonDiv>
                    }
                </DescriptionDownloadRoot>
            </CenteredDiv>
            <RightSideBar>
                <MediaSliderCounter>{selected + 1} / {filteredMedia.length}</MediaSliderCounter>
                <Arrow onClick={() => setSelected(selected + 1)} hidden={selected === filteredMedia.length - 1}>{IconArrowRight({ fill: branding.sideIconBar.sideIconColorDark })}</Arrow>
                <Placeholder />
            </RightSideBar>
        </MediaSliderContainer>
    )
}
function downloadClick(props: React.PropsWithChildren<MediaProps>, showPrivacyPolicyModal: (organizationId: string, callback: (accepted: boolean) => void) => void, att: Attachment, user: User | undefined) {
    if (props.organizationId)
        showPrivacyPolicyModal(props.organizationId, (accepted) => {
            startDownloadProcess(att, user!, props.organizationId);
        });

    else
        startDownloadProcess(att, user!, props.organizationId);
}

/* #endregion */

function startDownloadProcess(attachment: Attachment, loggedInUser: User, organizationId?: string) {
    if (attachment) {
        const fileName = attachment.title
        FileSaver.saveAs(attachment.url, fileName)
        if (organizationId)
            trackVisit(loggedInUser.profileId, organizationId, "MEDIA#DOWNLOAD", attachment.id)
    }
}

