import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { TabContentArea, TabRoot } from "../CommunicationArea"
import ToggleSwitch from "../../ui/ToggleSwitch"
import branding from "../../branding/branding"
import { useLoggedInState } from "../../globalStates/LoggedInUser"
import { useLanguageState } from "../../globalStates/LanguageState"
import { useHistory } from "react-router-dom"
import { AvatarWithPresenceState } from "../../ui/AvatarWithPresenceState"
import DeviceSwitcher from '../../conference/components/DeviceSwitcher'
import {
    BackendServiceError,
    PresenceType,
    updateUserValues,
    logout,
    setUserSetting,
    Settings,
    SettingsKey
} from "../../backendServices/BackendServices"
import { IconEdit, IconBlockContact, IconChevronRight, IconChevronLeft, IconCamera, IconShow, IconHide, IconTimeZone, IconArrowHeadDown, IconArrowHeadUp } from "../../ui/Icons"
import { BlockedTab } from "../NetworkingTab"
import { DetailNavLink } from "../../contentArea/detailPages/DetailNavLink"
import { ContentScrollContainer } from "../../ui/ScrollContainer"
import { useAppState } from "../../globalStates/AppState"
import { useChimeContext } from "../../conference/context/ChimeContext"
import { useFavoriteState } from "../../globalStates/Favorites"
import { endSession } from "../../tracking/RouteTracker"
import { Divider } from "../ContactEntry"
import moment from 'moment-timezone';
import { findTimezoneName, getTimezones, Timezone } from "../../utils/DateUtils"
import NetworkingToggle from "../components/NetworkingToggle"
import { myProfilePageRoute, editMyProfilePageRoute } from "../../navigationArea/RoutePaths"
import { DoNotDisturbToggle } from "../components/DoNotDisturbToggle"
import { logout as loginModuleLogout } from "../../contentArea/loginregistration/LoginModule"
import { Accordion, Button } from "react-bootstrap"
import ThirdPartySettingsComponent from "./ThirdPartySettingsModule"
import { ButtonColored } from "../../ui/Buttons"

const version = document.getElementById("version")?.getAttribute("data-value")


const HeaderArea = styled.div`
    display: grid;
    grid-template-columns: 20% 80%;
    flex-direction: row;
    margin-left: 15px;
    background-color: #fff;
    width: inherit;
    overflow: hidden;
    padding-bottom:5px;
    color: ${branding.mainInfoColor ?? "black"};
`;

const HeaderButtons = styled.div`
    display: flex;
    flex-flow: row;
    flex-wrap: nowrap;
    margin-top: 8px;

    & > :first-child {
        flex-grow: 1.61;
    }

    & > :last-child {
        flex-grow: 1;
        cursor: pointer;
    }

    & button {
        padding: 0;
        font-size: 0.7rem;
        line-height: 0.7rem;
        height: 25px;
        margin: 0 15px 5px 0;
    }

    & svg {
        transform: scale(0.8);
    }
`

const SettingsArea = styled.div`
  .settingsOpen & {
    visibility: visible;
  }
  svg {
    color: ${branding.primaryColor ?? "black"};
  }
`;

export const SettingsAreaRow = styled.div`
    font-family: ${branding.font1};
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    display: grid;
    margin-left: 15px; 
    grid-template-columns: 15% 65% 20%; 	
    padding-bottom:10px !important;
    padding-top:0px;
    margin-top: 5px;	
    margin-bottom: 5px;
    background-color: #fff;
    width: inherit;
    color: ${branding.settingsTab.settingsAreaRowColor ?? "#000"};
    & svg  {
        width: 20px;
        height: 20px;
    }

    & path {
        fill: ${branding.sideIconBar.sideIconColorDark};
        stroke: ${branding.sideIconBar.sideIconColorDark};
    }
`

const CurrentUserOrganization = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 15px;
    font-family: ${branding.font1};
    font-style: normal;
    font-weight: bold;
    display: inline-block;
    width: 250px;
    margin-left: 10px;
`;

const OrganizationRoot = styled.div<{ visibility: string }>`
    visibility: ${props => props.visibility};
    display: flex;
    flex-flow: column;
    margin-left: 20px; 
    padding-bottom:10px !important;
    padding-top:0px;
    margin-top: 5px;	
    margin-bottom: 5px;
    cursor: pointer;
`

const OrganizationLogoDiv = styled.div`
    width: 50px;
    height: 50px;

    img{
        width: 100%; 
        height: 100%;
        object-fit: contain;
    }
`

const CurrentUserName = styled.div`
  margin-top: 5px;
  margin-left: 2px;
  font-family: ${branding.font1};
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 17px;
  .networkingCollapsed & {
    visibility: hidden;
  }
`;

const CurrentUserPosition = styled.div`
  font-family: ${branding.font1};
  font-style: normal;
  font-size: 12px;
  margin-left: 2px;
  .networkingCollapsed & {
    visibility: hidden;
  }
`;

const BottomMenuCompanyLogo = styled.div`
    height: 100px;
    font-family: ${branding.font1};
    font-weight: 300;
    font-size: 15px;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: #fff;
    color: ${branding.mainInfoColor ?? "black"};


    & img {
        width: 26%;
        margin-bottom: 10px;
    }

    & div {
        font-size: 14px;
    }
`;

const SubTabRoot = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 320px);
  overflow-x: hidden;
  color: ${branding.mainInfoColor ?? "black"};
`;

const CustomDivider = styled(Divider)`

 .networkingCollapsed & {
    visibility: hidden;
  }
`

interface TimezonePickerProps {
    timezoneToggle: boolean
    timezonePicker: boolean
    setTimezonePicker: (timezonePicker: boolean) => void
}

const TimezonePickerRow = styled.div<{ selected?: boolean }>`
  display: flex;
  margin: ${branding.settingsTab.timezonePickerRowMarginTop} 23px 5px 15px;
  color: ${branding.deviceSwitcher.deviceSwitcherRowColor ?? "#000"};
  p {
      flex: 1;
      color: ${props => props.selected ? "green " : ""};
      text-overflow: ellipsis;
      overflow: hidden;
  }

  svg {
      width: 20px;
      cursor: pointer;
  }
`;

const TimezoneSelectRoot = styled.div`
    height: 300px;
    overflow: auto;
`

const TimezonePicker: React.FC<TimezonePickerProps> = (props) => {

    const timezones = getTimezones()
    const appState = useAppState()
    const [selectedTimezone, setSelectedTimezone] = useState<string>(appState.timezone)
    const strings = useLanguageState().getStrings()

    function onTimezoneSelect(item: Timezone) {
        setSelectedTimezone(item.timezones[0])
        appState.setTimeZone(item.timezones[0])
    }

    useEffect(() => {
        if (props.timezoneToggle)
            setSelectedTimezone(moment.tz.guess())

    }, [props.timezoneToggle])


    return (
        <>
            <TimezonePickerRow style={{ opacity: props.timezoneToggle ? "0.25" : "", pointerEvents: props.timezoneToggle ? "none" : "auto", borderBottom: props.timezonePicker ? "1px solid" : "" }}>
                <p style={{ marginBottom: "15px" }}>{selectedTimezone ? findTimezoneName(selectedTimezone) : strings.communicationArea.timezonePickerText}</p>
                <div onClick={() => props.setTimezonePicker(!props.timezonePicker)}>
                    {props.timezonePicker ? IconArrowHeadUp({ fill: branding.sideIconBar.sideIconColorDark }) : IconArrowHeadDown({ fill: branding.sideIconBar.sideIconColorDark })}
                </div>
                <br />
            </TimezonePickerRow>
            {props.timezonePicker && <TimezoneSelectRoot>
                {timezones.map(item => {
                    return <TimezonePickerRow selected={selectedTimezone === item.timezones[0]} onClick={() => onTimezoneSelect(item)}>
                        <p>{item.name}</p>
                        <div style={{ marginLeft: "25px" }}>
                        </div>
                    </TimezonePickerRow>
                })}
            </TimezoneSelectRoot>}
        </>)
}


interface WithBlockedContentToggle {
    setShowBlocked: (showBlocked: boolean) => void
}

interface SettingsTabProps {
}

const SettingsTab: React.FunctionComponent<SettingsTabProps> = (props) => {
    const userState = useLoggedInState()

    const [showBlocked, setShowBlocked] = useState(false)
    const [isLoaded, setIsLoaded] = useState<boolean>(false)

    useEffect(() => {
        if (!isLoaded) {
            setIsLoaded(true)
        }
        // eslint-disable-next-line
    }, [userState, isLoaded])

    const content = showBlocked ? <BlockedContacts setShowBlocked={setShowBlocked} /> : <SettingsContent setShowBlocked={setShowBlocked} />

    return (
        <TabRoot>
            <TabContentArea className="rs-nav-content">
                <SettingsArea>
                    {content}
                </SettingsArea>
            </TabContentArea>
        </TabRoot>
    );
};

export default SettingsTab;


const StyledAccordion = styled(Accordion) <{ open: boolean }>`
    position: relative;

    & > button {
        width: 100%;
        text-align: left;
        border-bottom: 1px solid ${branding.crsTabs.tabItemDefaultTextColor};
        border-radius: 0px;
        margin: 0 30px 0 0;
        color: ${props => props.open ? branding.crsTabs.tabItemDefaultActiveStateColor : branding.crsTabs.tabItemDefaultTextColor} !important;
        text-decoration: none !important;
        padding: 0 0 0 20px;
        height: 55px;

        &:focus {
            box-shadow: none;
        }
    }

    & > div {
        padding-top: 20px;
        border-bottom: 1px solid ${branding.crsTabs.tabItemDefaultTextColor};
    }

    &:after {
        content: '';
        display: block;
        position: absolute;
        right: 12px;
        top: 24px;
        width: 0;
        height: 0;
        border-style: solid;
        ${props => props.open ? `border-width: 0 6px 6px 6px; border-color: transparent transparent ${branding.crsTabs.tabItemDefaultActiveStateColor} transparent;`
        : `border-width: 6px 6px 0 6px; border-color: ${branding.crsTabs.tabItemDefaultTextColor} transparent transparent transparent;`}
    }
`

interface SettingsAccordionProps {
    title: string
    defaultActiveKey?: string
}
export const SettingsAccordion: React.FunctionComponent<SettingsAccordionProps> = (props) => {
    const [currentEventKey, setCurrentEventKey] = useState<string | undefined>()
    return <StyledAccordion key={props.title} defaultActiveKey={props.defaultActiveKey} open={currentEventKey === "0"} activeKey={currentEventKey}>
        <Button variant="link" onClick={() => setCurrentEventKey(currentEventKey === "0" ? undefined : "0")}>{props.title}</Button>
        <Accordion.Collapse eventKey="0">
            <>{props.children}</>
        </Accordion.Collapse>
    </StyledAccordion>
}

interface SettingsContentProps extends WithBlockedContentToggle {

}
function SettingsContent(props: SettingsContentProps) {
    const userState = useLoggedInState()
    const strings = useLanguageState().getStrings()
    const lang = useLanguageState().getLanguage()
    const history = useHistory()
    const appState = useAppState()
    const chime = useChimeContext()
    const favoriteState = useFavoriteState()


    const currentUserName: string = [userState.user()?.firstName, userState.user()?.lastName].filter(Boolean).join(" ")
    const currentUserPosition: string = [lang === "en" ? userState.user()?.position : (userState.user()?.positionDe ?? userState.user()?.position), userState.user()?.company].filter(Boolean).join(" " + strings.communicationArea.personCompanyLink + " ")
    const corussoftLogo = "/corus_RGB_L_dark.png"


    const onAvatarClick = () => {
        history.push(myProfilePageRoute)
    }

    const onEditProfileClick = () => {
        history.push(editMyProfilePageRoute)
    }


    const logoutUser = async () => {
        const profileId = userState.user()?.profileId
        if (profileId) {
            await updateUserValues({ id: profileId, presenceStatus: PresenceType.OFFWORK, lastConnected: new Date().toISOString() })
            await endSession()
            await logout(profileId)
            await loginModuleLogout()
        }
        history.push("/")
        userState.setUserState({})
        localStorage.removeItem("routeBeforeCall")
        appState.reset()
        favoriteState.reset()
        const chimeName = chime.getName()
        if (chimeName && chimeName.length > 0) {
            chime.leaveRoom()
        }

        const element = document.getElementById("hubspot-messages-iframe-container")
        if (element)
            element.style.cssText += ';right: 0px !important;'
    }


    return <>
        <HeaderArea>
            <div style={{ cursor: "pointer" }} onClick={() => onAvatarClick()}>
                <AvatarWithPresenceState
                    avatarSize={56}
                    badgeSize={15}
                    showAvatarBadge={true}
                    badgeRight={0.1}
                    badgeTop={37}
                    userId={userState.user()?.profileId || ''}
                    initPresenceByList={false}
                    userType={userState.user()?.type}
                    content={{ pictureUrl: userState.user()?.logoUrl, alt: currentUserName }}>
                </AvatarWithPresenceState>
            </div>
            <div>
                <div style={{ cursor: "pointer" }} onClick={() => onAvatarClick()}>
                    <CurrentUserName style={{ fontSize: 16 }}>{currentUserName}<br /></CurrentUserName>
                    <CurrentUserPosition style={{ fontSize: 12 }}>{currentUserPosition}</CurrentUserPosition>
                </div>
                <HeaderButtons>
                    <ButtonColored color={branding.sideIconBar.sideIconColorDark} onClick={() => onEditProfileClick()} >
                        <IconEdit fill={branding.sideIconBar.sideIconColorDark} />{strings.myProfilePageContent.editProfileButtonText}
                    </ButtonColored>
                    <ButtonColored color="red" onClick={() => logoutUser()}>{strings.communicationArea.logOutTitle}</ButtonColored>
                </HeaderButtons>
            </div>
        </HeaderArea>
        <CustomDivider style={{ marginLeft: "0px" }} />
        <SubTabRoot>
            <ContentScrollContainer padding="0">
                <ThirdPartySettingsComponent />
                <GeneralSettings setShowBlocked={props.setShowBlocked} />
                <AudioVideoSettings />
                <MyOrganization />
            </ContentScrollContainer>
        </SubTabRoot>
        <BottomMenuCompanyLogo>
            <img src={corussoftLogo} alt="Corussoft GmbH" />
            <div>Version {version}</div>
        </BottomMenuCompanyLogo>
    </>
}

interface BlockedContactsProps extends WithBlockedContentToggle {
}
function BlockedContacts(props: BlockedContactsProps) {
    const strings = useLanguageState().getStrings()
    return <>
        <SettingsAreaRow style={{ cursor: "pointer" }} onClick={() => props.setShowBlocked(false)}>
            <div>
                {IconChevronLeft({ fill: branding.sideIconBar.sideIconColorDark })}
            </div>
            <p>{strings.communicationArea.blockedContactsTitle}</p>
            <br />
        </SettingsAreaRow>
        <BlockedTab />
    </>
}


interface GeneralSettingsProps extends WithBlockedContentToggle {
}
function GeneralSettings(props: GeneralSettingsProps) {
    const strings = useLanguageState().getStrings()
    const appState = useAppState()


    const [timezoneToggle, setTimezoneToggle] = useState<boolean>(appState.timezone === "" || appState.timezone === undefined || appState.timezone === moment.tz.guess())
    const [timezonePicker, setTimezonePicker] = useState<boolean>(false)


    useEffect(() => {
        if (timezoneToggle === false) {
            setTimezonePicker(false)

        } else {
            appState.setTimeZone(moment.tz.guess())
            setTimezonePicker(false)
        }
    }, [timezoneToggle]) //eslint-disable-line

    return <SettingsAccordion title={strings.communicationArea.generalSettings}>
        <SettingsAreaRow style={{ cursor: "pointer" }} onClick={() => props.setShowBlocked(true)}>
            {IconBlockContact({ fill: branding.sideIconBar.sideIconColorDark })}
            <p>{strings.communicationArea.blockedContactsTitle}</p>
            <div style={{ marginLeft: "25px" }}>
                {IconChevronRight({ fill: branding.sideIconBar.sideIconColorDark, width: "20px", height: "20px" })}
            </div>
        </SettingsAreaRow>
        <NetworkingToggle />
        <DoNotDisturbToggle />

        <SettingsAreaRow>
            {IconTimeZone({ fill: branding.sideIconBar.sideIconColorDark })}
            <p>{strings.communicationArea.timezone}</p>
            <ToggleSwitch isEnabled={timezoneToggle} onClick={() => setTimezoneToggle(!timezoneToggle)} />
        </SettingsAreaRow>

        <TimezonePicker setTimezonePicker={setTimezonePicker} timezoneToggle={timezoneToggle} timezonePicker={timezonePicker}></TimezonePicker>
    </SettingsAccordion>
}

function AudioVideoSettings() {
    const strings = useLanguageState().getStrings()
    return <SettingsAccordion title={strings.communicationArea.audioVideoSettings}>
        <DeviceSwitcher />
    </SettingsAccordion>
}

function MyOrganization() {
    const strings = useLanguageState().getStrings()
    const userState = useLoggedInState()


    function setSettingVisible() {
        (async () => {
            const profileId = userState.user()?.profileId
            if (profileId === undefined) {
                return
            }

            const response = await setUserSetting(profileId as string, SettingsKey.VISIBLE, "" + (!userState.user()!.visible));
            if ((response as BackendServiceError).httpStatus) {
            } else {
                userState.setVisible((response as Settings).visible)
            }
        })()
    }

    function setSettingShowroomStandby() {
        (async () => {
            const profileId = userState.user()?.profileId
            if (profileId === undefined) {
                return
            }

            const response = await setUserSetting(profileId as string, SettingsKey.SHOWROOMSTANDBY, "" + (!userState.user()!.showroomStandby));
            if ((response as BackendServiceError).httpStatus) {
            } else {
                userState.setShowroomStandby((response as Settings).showroomStandby)
            }
        })()
    }

    if (!userState.user()?.organizations || userState.user()?.organizations?.length! === 0)
        return null

    return <SettingsAccordion title={strings.communicationArea.myOrganizationTitle}>
        <div>
            <OrganizationRoot visibility={'visible'} className="d-flex align-items-center justify-content-center">
                {userState.user()?.organizations?.map(organization => {
                    return <DetailNavLink id={organization?.id!} type="organization" name={organization?.name ?? ""} source="PROFILE" width={"100%"}>
                        <div className="d-flex align-items-center pt-2 pb-2">
                            <OrganizationLogoDiv>
                                <img src={organization?.logo!} alt="" />
                            </OrganizationLogoDiv>
                            <CurrentUserOrganization>{organization.name}</CurrentUserOrganization>
                        </div>
                    </DetailNavLink>
                })}
            </OrganizationRoot>
            <CustomDivider />
            <br />
        </div>

        <SettingsAreaRow>
            {userState.user()?.visible ? IconShow({ fill: branding.sideIconBar.sideIconColorDark }) : IconHide({ fill: branding.sideIconBar.sideIconColorDark })}
            <p>{strings.communicationArea.showAsContactTitle}</p>
            <ToggleSwitch isEnabled={userState.user()?.visible} onClick={() => setSettingVisible()} />
        </SettingsAreaRow>

        <SettingsAreaRow>
            {IconCamera({ fill: branding.sideIconBar.sideIconColorDark })}
            <p>{strings.communicationArea.standByForShowroom}</p>
            <ToggleSwitch isEnabled={userState.user()?.showroomStandby} onClick={() => setSettingShowroomStandby()} />
        </SettingsAreaRow>

    </SettingsAccordion>
}

export const SettingsButtonStyled = styled(Button)`
    background: none !important;
    color: inherit !important;
    border: none !important;
    text-align: left !important;
    padding: 0 !important;
    margin: 0;
    &:focus {
        box-shadow: none  !important;
    }
`