import React, { Fragment, useState, useEffect } from 'react'
import { IconOrganization, IconMeetingSchedule, IconMeetings, IconLiveStream, IconSpeakers, IconNetworking, IconCall, IconShare, IconBusinessArea } from '../../ui/Icons'
import { useLanguageState } from '../../globalStates/LanguageState'
import { useLoggedInState } from "../../globalStates/LoggedInUser"
import { showfloorPageRoute, conferencePageRoute, programPageRoute, speakersPageRoute, networkingPageRoute, businessAreaPageRoute } from '../../navigationArea/RoutePaths'
import { Exhibitor, Person, ShareTargetType } from '../../backendServices/Types'
import { CalendarEntryModalViewMode } from '../../ui/CalendarEntryModal'
import { useNavigationSource } from '../../tracking/NavigationSource'
import LiveEvent from '../../ui/LiveEvent'
import MeetingDetails from '../../ui/MeetingDetails'
import styled from 'styled-components'
import { buildDetailLink } from '../detailPages/DetailNavLink'
import { loadExhibitorsData, ChannelResponse, getChannels, BackendServiceError, getAccessStatusForAllVirtualCafes, UserRestrictedAreaAccess, loadRelevantProfilesListData, ContactListResponse, ContactItem, loadEventSpeakersData, UserOrganizationVisitSource } from '../../backendServices/BackendServices'
import branding from '../../branding/branding'
import BookmarkWithToggle from '../../ui/BookmarkWithToggle'
import MeetingRoomGroup from '../lounges/MeetingRoomGroup'
import { isLivePhase, isSoftOpeningPhase } from '../../utils/EventPhaseChecker'
import { getIamPartOf } from '../../globalStates/IAmPartOf'
import queryString from 'query-string'
import PersonComponent from '../../ui/PersonComponent'
import { NavLink } from 'react-router-dom'
import TileRow from '../../ui/TileRow'
import { TileWrapperSpeakers, MobileTileWrapperSpeakers } from './ReceptionPageContent'
import { BasisPremiumType } from '../../branding/BasisPremiumBranding'
import DynamicBanner from './DynamicBanner'
import { orderBy } from 'lodash'
import VizSensor from 'react-visibility-sensor'
import { MeetingRoomGroupType } from '../../conference/AudioVideoBranding'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'


const IconContainer = styled.div`
    margin-top: 15px;
    margin-left: 85px;
    color: white;
    display:flex;
    justify-content: center;

    span{
      margin-left: 2px; 
      //margin-left: 3px;
      margin-top: 0px;
    }
`

const ShowfloorTile = styled(NavLink) <{ $background?: string }>`
    position: relative;
    display: flex;
    flex-flow: column;
    height: 280px;
    width: 500px;
    color: white !important;
    ${props => props.$background ? `background: url("${props.$background}");` : "background: url('/branding/no-profile.png');"}
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top; /* left top */
    padding: 25px;
    //padding: 20px;
    border-radius: 5px;
    margin: ${branding.receptionPage.showfloorTileMargins ?? "0px 10px 20px 0px"};

    ${IconContainer} {
        margin-top: -5px;
        //margin-top: 4px;
        margin-left: 0;
        margin-right: 20px;
    }

    .rs-row {
      position: initial;
      
      div{
        margin-top: 5px;
        width:50px;
        height: 50px;
        display: none;
        img {
          width:40px;
          height: 40px;
          margin-top: 3px;
        }
      }
      
      div:first-child{
        display: initial;
      }
    }

    &:active, &:link {
        text-decoration: none;
        
    }
    &::after {
        content: "";
        display: block;
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: ${branding.receptionPage.lobbypageShowfloorTilesDarkenOverlay ?? "rgba(0,0,0, .5)"};
        border-radius: 5px;
    } 

    &>*{
        z-index: 2;
    }
`


export const MobileShowfloorTile = styled(NavLink) <{ $background?: string }>`
    position: relative;
    display: flex;
    flex-direction: row;
    height: 95px;
    width: 205px;
    color: white !important;
    ${props => props.$background ? `background: url("${props.$background}");` : "background: url('/branding/no-profile.png');"}
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top; /* left top */
    padding: 25px;
    padding-left: 8px;
    border-radius: 5px;
    margin: ${branding.receptionPage.showfloorTileMargins ?? "0px 10px 20px 0px"};

    ${IconContainer} {
        margin-top: -5px;
        //margin-top: 4px;
        margin-left: 0;
        margin-right: 20px;
    }

    .rs-row {
      position: initial;
      
      div{
        margin-top: 5px;
        width:50px;
        height: 50px;
        display: none;
        img {
          width:40px;
          height: 40px;
          margin-top: 3px;
        }
      }
      
      div:first-child{
        display: initial;
      }
    }

    &:active, &:link {
        text-decoration: none;
        
    }
    &::after {
        content: "";
        display: block;
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: ${branding.receptionPage.lobbypageShowfloorTilesDarkenOverlay ?? "rgba(0,0,0, .5)"};
        border-radius: 5px;
    } 

    &>*{
        z-index: 2;
    }
`

const ShowfloorSessionRoot = styled.div`
  position: absolute;
  display: flex;
  flex-flow: row;
  flex-wrap: wrap-reverse;
  align-items: flex-end;
  right: 20px;
  bottom: 20px;
  margin-bottom: -10px;
`

const CompanyLogoDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border: 1px solid #D9D9D9;
    border-radius: 5px;
    width: 80px;
    height: 80px;
    padding: 10px;

    & p{
    font-family: ${branding.font1};
    max-width: 40px;
    font-size: 14px;
    line-height: 0.6rem;
    color: rgb(0, 0, 0);
    text-align: center;
    display: inline-table;
    margin-bottom: 0;
    white-space: normal;
    word-break: break-word;
   }
`

const MobileCompanyLogoDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border: 1px solid #D9D9D9;
    border-radius: 5px;
    width: 60px;
    height: 60px;
    padding: 2px;
    margin-top: -10px;

    & p{
    font-family: ${branding.font1};
    max-width: 40px;
    font-size: 14px;
    line-height: 0.6rem;
    color: rgb(0, 0, 0);
    text-align: center;
    display: inline-table;
    margin-bottom: 0;
    white-space: normal;
    word-break: break-word;
   }
`

const CompanyLogo = styled.img`
    object-fit: contain;
    height: 100%;
    width: 100%;
`

const ShowfloorActions = styled.div`
  display: flex;
  flex-flow: row;
  position: absolute;
  min-width: 160px;
  //min-width: 150px;
  right: 0px;
  //right: 10px;
  justify-content: flex-end;

  div{
    position: relative ;
  }
`

const ShowfloorInfosItem = styled.div`
  display: flex;
  font-family: ${branding.font1};
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  width: ${branding.receptionPage.lobbyTileTextWidth ?? "220px"};

  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
`

const MobileShowfloorInfosItem = styled.div`
  display: flex;
  font-family: ${branding.font1};
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  width: 110px;
  margin-top: 10px;

  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`

const ShowfloorInfos = styled.div`
  display: flex;
  flex-flow: column;
  position: absolute;
  bottom: 10px;

  ${ShowfloorInfosItem}:first-child {
    font-weight: bold !important;
    font-size: 18px !important;
    -webkit-line-clamp: 3;
  }
`

const MobileShowfloorInfos = styled.div`
  display: flex;
  flex-flow: column;
  position: absolute;
  top: 5px;
  margin-left: 75px;

  ${MobileShowfloorInfosItem}:first-child {
    width: 103px;
    font-weight: bold !important;
    font-size: 14px !important;
    line-height: 16px !important;
    -webkit-line-clamp: 3;
  }
`

interface ShowfloorProps {
    exhibitor: Exhibitor
    backgroundUrl?: string
    src: UserOrganizationVisitSource
    setViewMode?: (value: CalendarEntryModalViewMode) => void
    onAction: (action: "MEETING" | "INTEREST" | "RECOMMEND", organization: Exhibitor) => void
    isMobile?: boolean
}

export const Showfloor: React.FunctionComponent<ShowfloorProps> = (props) => {

    const nav = useNavigationSource()

    const handleEvent = (event: any, action: "MEETING" | "INTEREST" | "RECOMMEND") => {
        event.preventDefault()
        event.stopPropagation()
        props.onAction(action, props.exhibitor)
    }


    return <div style={{ borderRadius: "5px" }}>
        <>
            {
                props.isMobile ?
                    <MobileShowfloorTile onClick={() => nav.set(props.src)} to={buildDetailLink(props.exhibitor.id, props.exhibitor.name, "organization")} $background={props.backgroundUrl}>
                        <>
                            {
                                <MobileCompanyLogoDiv>
                                    {
                                        props.exhibitor.pictureURL ?
                                            <CompanyLogo src={props.exhibitor.pictureURL} /> :
                                            <p>{props.exhibitor.initials}</p>
                                    }
                                </MobileCompanyLogoDiv>
                            }
                        </>

                        <MobileShowfloorInfos>
                            <MobileShowfloorInfosItem>{props.exhibitor.name}</MobileShowfloorInfosItem>
                            <MobileShowfloorInfosItem>
                                {(props.exhibitor.teaser && props.exhibitor.teaser.split('\\n').map((item, i) => <span key={i}>{item}<br /></span>)) || " "}
                            </MobileShowfloorInfosItem>
                        </MobileShowfloorInfos>
                    </MobileShowfloorTile> :


                    <ShowfloorTile onClick={() => nav.set(props.src)} to={buildDetailLink(props.exhibitor.id, props.exhibitor.name, "organization")} $background={props.backgroundUrl}>
                        <CompanyLogoDiv>
                            {
                                props.exhibitor.pictureURL ?
                                    <CompanyLogo src={props.exhibitor.pictureURL} /> :
                                    <p>{props.exhibitor.initials}</p>
                            }
                        </CompanyLogoDiv>
                        <ShowfloorActions>
                        {branding.exhibitorsPageContent.iconsVisibleOnTiles.map((item, index) => {
                        switch (item) {
                            case "SCHEDULE_MEETING": {
                            return props.exhibitor.contacts && <OverlayTrigger
                                trigger={["focus", "click", "hover"]}
                                placement="bottom"
                                delay={{ show: 250, hide: 400 }}
                                overlay={
                                    <Tooltip id="tooltip" style={{ fontFamily: branding.font1 }}>
                                        {branding.contactEntry.requestMeetingHint}
                                    </Tooltip>}>
                                <IconContainer onClick={e => { handleEvent(e, 'MEETING'); props.setViewMode!(CalendarEntryModalViewMode.CREATE); }}>{IconMeetingSchedule({ stroke: "white" })}</IconContainer>
                            </OverlayTrigger>
                            }
                            case "REGISTER_INTEREST": {
                            return props.exhibitor.email && <OverlayTrigger
                                trigger={["focus", "click", "hover"]}
                                placement="bottom"
                                delay={{ show: 250, hide: 400 }}
                                overlay={
                                    <Tooltip id="tooltip" style={{ fontFamily: branding.font1 }}>
                                        {branding.organizationDetailPageContent.registerInterest}
                                    </Tooltip>}>
                                <IconContainer onClick={e => handleEvent(e, 'INTEREST')}>{IconCall({ stroke: "#FFF", fill: "#FFF" })}</IconContainer>
                            </OverlayTrigger>
                            }
                            case "RECOMMEND_EXHIBITOR": {  
                                return <OverlayTrigger
                                trigger={["focus", "click", "hover"]}
                                placement="bottom"
                                delay={{ show: 250, hide: 400 }}
                                overlay={
                                    <Tooltip id="tooltip" style={{ fontFamily: branding.font1 }}>
                                        {branding.contactEntry.shareHint}
                                    </Tooltip>}><IconContainer onClick={e => handleEvent(e, 'RECOMMEND')}>{IconShare({ stroke: "#FFF", fill: "#FFF" })}</IconContainer></OverlayTrigger>
                            }
                            case "BOOKMARK_EXHIBITOR": {
                            return <OverlayTrigger
                                trigger={["focus", "click", "hover"]}
                                placement="bottom"
                                delay={{ show: 250, hide: 400 }}
                                overlay={
                                    <Tooltip id="tooltip" style={{ fontFamily: branding.font1 }}>
                                        {branding.organizationDetailPageContent.bookmarkPerson}
                                    </Tooltip>}>
                                <IconContainer><BookmarkWithToggle newBookmarkItem={true} type={"organization"} id={props.exhibitor.id} color={'white'} stroke={'white'}></BookmarkWithToggle></IconContainer></OverlayTrigger>
                                }
                                default: return null
                                }
                        })}
                        </ShowfloorActions>
                        <ShowfloorInfos>
                            <ShowfloorInfosItem>{props.exhibitor.name}</ShowfloorInfosItem>
                            <ShowfloorInfosItem>
                                {(props.exhibitor.teaser && props.exhibitor.teaser.split('\\n').map((item, i) => <span key={i}>{item}<br /></span>)) || " "}
                            </ShowfloorInfosItem>
                        </ShowfloorInfos>
                        <ShowfloorSessionRoot>
                            <MeetingDetails content={props.exhibitor}></MeetingDetails>
                        </ShowfloorSessionRoot>
                    </ShowfloorTile>
            }
        </>
    </div>
}


interface LobbyPageProps {
    onShowfloorAction: (action: "MEETING" | "INTEREST" | "RECOMMEND", organization: Exhibitor) => void
    onViewModeSet?: ((value: CalendarEntryModalViewMode) => void) | undefined
    eventKey: string
    refreshKey: number
    profileId: string
    onSetShowOrganizationModal: React.Dispatch<React.SetStateAction<boolean>>
    showBookmarkEventsModal: boolean
    onSetShowBookmarkEventsModal: React.Dispatch<React.SetStateAction<boolean>>
    onSetTargetId: React.Dispatch<React.SetStateAction<string>>
    onSetLink: React.Dispatch<React.SetStateAction<string>>
    onSetType: React.Dispatch<React.SetStateAction<ShareTargetType | undefined>>
    isMobile: boolean
}

const LobbyPageContent: React.FunctionComponent<LobbyPageProps> = (props) => {
    const abortController = new AbortController();
    const abortSignal = abortController.signal;
    const languageState = useLanguageState()
    const lang = languageState.getLanguage()
    const strings = languageState.getStrings()

    const userState = useLoggedInState()

    const [topSponsors, setTopSponsors] = useState<Exhibitor[]>()

    const [exhibitors, setExhibitors] = useState<Exhibitor[]>([])
    const [livestreams, setLiveStreams] = useState<ChannelResponse[]>([])
    const [persons, setPersons] = useState<Person[]>([])
    const [relevantUsers, setRelevantUsers] = useState<ContactItem[]>([])
    const [isLoaded, setIsLoaded] = useState<boolean>(false)

    const [accessList, setAccessList] = useState<UserRestrictedAreaAccess[]>([])
    const [meetingRoomGroups, setMeetingRoomGroups] = useState<MeetingRoomGroupType[]>([])

    const queryParams: any = queryString.parse(window.location.search)
    const componentOrder: string[] = branding.receptionPage.lobbyComponentOrder ?? ["SHOWFLOOR", "LOUNGES", "LIVE_SESSIONS", "SPEAKERS", "BANNER"]

    const loadPremiumOrStandardExhibitors = (basisPremium: number, numResultRows: number) => {
        let premiumStandardShowfloorParams: any = {
            'filterlist': 'entity_orga',
            'basispremium': basisPremium,
            'startresultrow': 0,
            'numresultrows': numResultRows,
            'order': 'random',
            'lang': lang
        }

        loadExhibitorsData(premiumStandardShowfloorParams, abortSignal).then((resp) => {
            const respData = resp.exhibitors.filter(it => !branding.receptionPage.lobbyTopSponsors.some(e => e.id === it.id))
            setExhibitors(exhibitors.length === 0 ? respData : exhibitors.concat(respData))
            setIsLoaded(true)
        })
    }

    useEffect(() => {
        const sponsoredExhibitorsMyPageList = branding.receptionPage.lobbyTopSponsors;

        if (branding.receptionPage.lobbyExhibitorsShowTopSponsors && sponsoredExhibitorsMyPageList) {
            let sponsoredExhibitorsFilterList: string[] = [];

            sponsoredExhibitorsMyPageList.forEach(sponsor => {
                sponsoredExhibitorsFilterList.push('id_orga_' + sponsor.id);
            })

            let sponsorSlotParams: any = {
                'filterlist': 'entity_orga, ' + sponsoredExhibitorsFilterList.join(', '),
                'startresultrow': 0,
                'order': 'lexic',
                'lang': lang
            }

            loadExhibitorsData(sponsorSlotParams, abortSignal).then((resp) => {
                if (resp.exhibitors) {
                    resp.exhibitors.forEach(exhibitor => {
                        exhibitor.order = sponsoredExhibitorsMyPageList.find(x => x.id === exhibitor.id)?.order || -1
                    });

                    setTopSponsors((e) => orderBy(resp.exhibitors, ['order'], ['asc']))
                }
            })
        }

        //loading premium exhibitors for top sponsors section
        loadPremiumOrStandardExhibitors(2, branding.receptionPage.lobbyExhibitorsPremiumNumber)

        getAccessStatusForAllVirtualCafes()
            .then((response) => {
                if ((response as BackendServiceError).httpStatus) {

                } else {
                    setAccessList(response as UserRestrictedAreaAccess[])
                    setMeetingRoomGroups(strings.meetingRoomGroups)
                }
            })

        //eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (isLoaded && branding.receptionPage.lobbyExhibitorsBasisPremiumTypes.find((e: string) => e === BasisPremiumType.STANDARD)) {
            //loading standard exhibitors for top sponsors section
            loadPremiumOrStandardExhibitors(1, branding.receptionPage.lobbyExhibitorsStandardNumber)
        }

        //eslint-disable-next-line
    }, [isLoaded])

    useEffect(() => {
        let speakerPersonFunctions = branding.configuration.speakerPersonFunctions
        let defaultPersonParams: any = {
            'personFunctions': speakerPersonFunctions,
            'eventDateParticipation': branding.configuration.eventDateParticipation,
            'startresultrow': 0,
            'numresultrows': 15,
            'order': 'random',
            'lang': lang
        }

        loadEventSpeakersData(defaultPersonParams, abortSignal).then((response) => {
            setPersons(response.persons)
        })



        getChannels().then((resp) => {
            if ((resp as BackendServiceError).httpStatus) {
                // TODO ERROR
            } else {
                setLiveStreams(resp as ChannelResponse[])
            }
        })
        return () => {
            abortController.abort(); // Cancel all loads on unmount // TODO verify
        }
        // eslint-disable-next-line
    }, [props.eventKey, lang, props.refreshKey])

    useEffect(() => {
        loadRelevantProfilesListData(props.profileId, {
            searchString: "",
            itemsPerPage: 25,
            page: 0
        }).then(data => {
            let contactsTemp = (data as ContactListResponse).contacts?.filter(function (contact) {
                return contact.sotUser.id !== userState.user()?.profileId
            })
            setRelevantUsers(contactsTemp)
        })

        // eslint-disable-next-line
    }, [props.profileId, lang])


    return (
        <Fragment>
            {componentOrder.map((component, key) => {
                switch (component) {
                    case "SHOWFLOOR": return (
                        /* Showfloor */
                        <>
                            {
                                (topSponsors?.length! > 0 || exhibitors?.length! > 0) &&
                                <TileRow
                                    key={key}
                                    icon={IconOrganization({ fill: branding.sideIconBar.sideIconColorDark ?? branding.mainInfoColor })}
                                    iconVisible={true}
                                    title={strings.sideIconBar.showfloorMenuText}
                                    titleVisible={true}
                                    navLink={showfloorPageRoute}
                                    navLinkTextVisible={true}
                                    hideShadows={props.isMobile}
                                    navLinkText={strings.receptionPage.showFloorNavLinkText}
                                    height={props.isMobile ? "95px" : "220px"}
                                    scrollBar={topSponsors?.length! < 3 || exhibitors?.length! < 3 ? false : true}
                                    childWidth={props.isMobile ? (205 + 4) : (500 + 4)} /* Basis + Grid Gap */
                                    childCount={(topSponsors?.length ?? 0) + (exhibitors?.length ?? 0)}
                                >
                                    {branding.receptionPage.lobbyExhibitorsShowTopSponsors && topSponsors && topSponsors.map((sponsor, index) =>
                                        <VizSensor key={sponsor.id} partialVisibility={true}>
                                            {({ isVisible }) => isVisible ?
                                                <Showfloor isMobile={props.isMobile} backgroundUrl={sponsor.backgroundImageURL} exhibitor={sponsor} src="LOBBYSPONSOR"
                                                    onAction={props.onShowfloorAction}
                                                    setViewMode={props.onViewModeSet}
                                                />
                                                : <div style={{ width: props.isMobile ? 205 : 500, height: props.isMobile ? 95 : 280 }}></div>
                                            }
                                        </VizSensor>
                                    )}
                                    {exhibitors && exhibitors.map((showfloor, index) =>
                                        <VizSensor key={showfloor.id} partialVisibility={true}>
                                            {({ isVisible }) => isVisible ?
                                                <Showfloor isMobile={props.isMobile} backgroundUrl={showfloor.backgroundImageURL} exhibitor={showfloor} src="LOBBY"
                                                    onAction={props.onShowfloorAction}
                                                    setViewMode={props.onViewModeSet}
                                                />
                                                : <div style={{ width: props.isMobile ? 205 : 500, height: props.isMobile ? 95 : 280 }}></div>
                                            }
                                        </VizSensor>
                                    )}
                                </TileRow>
                            }
                        </>
                    )
                    case "LOUNGES": return (
                        /* Virtual cafe */
                        <>
                            {
                                meetingRoomGroups.length > 0 &&
                                <TileRow
                                    key={key}
                                    icon={IconMeetings({ fill: branding.sideIconBar.sideIconColorDark ?? branding.mainInfoColor })}
                                    iconVisible={true}
                                    title={strings.receptionPage.featuredCompanies}
                                    titleVisible={true}
                                    navLink={conferencePageRoute}
                                    navLinkTextVisible={true}
                                    hideShadows={props.isMobile}
                                    navLinkText={strings.receptionPage.virtualCaffeNavLinkText}
                                    height={props.isMobile ? "95px" : "220px"}
                                    scrollBar={strings.meetingRoomGroups && strings.meetingRoomGroups.length > 0}
                                    childWidth={props.isMobile ? (205 + 4) : (500 + 4)} /* Basis + Grid Gap */
                                    childCount={meetingRoomGroups.length}
                                >
                                    {meetingRoomGroups.map((meetingRoomGroup, index) => <VizSensor key={meetingRoomGroup.id} partialVisibility={true}>
                                        {({ isVisible }) => isVisible ?
                                            <div style={{ marginRight: "0px" }}>
                                                <MeetingRoomGroup margin="0 20px 20px 0" accessStatus={(accessList.find((e: UserRestrictedAreaAccess) => e.restrictedAreaId === meetingRoomGroup.id))?.status || " "} source="LOBBY" meetingRoomGroupConfig={meetingRoomGroup} roomType="virtualCafe" isMobile={props.isMobile} />
                                            </div>
                                            :
                                            <div style={{ width: props.isMobile ? 205 : 500, height: props.isMobile ? 95 : 280 }}></div>
                                        }
                                    </VizSensor>
                                    )}
                                </TileRow>
                            }
                        </>
                    )
                    case "LIVE_SESSIONS": return (
                        (isLivePhase || (isSoftOpeningPhase && getIamPartOf(queryParams) === "streamonboarding")) &&
                        /* IsLiveNow section */
                        <>
                            {
                                livestreams?.length! > 0 &&
                                <TileRow
                                    key={key}
                                    icon={IconLiveStream({ fill: branding.sideIconBar.sideIconColorDark ?? branding.mainInfoColor })}
                                    iconVisible={true}
                                    title={strings.receptionPage.liveSessions}
                                    titleVisible={true}
                                    navLink={programPageRoute}
                                    navLinkTextVisible={true}
                                    hideShadows={props.isMobile}
                                    navLinkText={strings.receptionPage.liveSessionsNavLinkText}
                                    scrollBar={livestreams && livestreams.length > 0}
                                    childWidth={(props.isMobile ? 135 : 480) + 10}
                                    childCount={livestreams?.length ?? 0}
                                >
                                    {livestreams && livestreams.map((livestream, index) => <VizSensor key={livestream.channelId} partialVisibility={true}>
                                        {({ isVisible }) => isVisible ?
                                            <LiveEvent
                                                liveSessionName={livestream.currentEventDate?.name ?? ""}
                                                id={index.toString()}
                                                title={livestream.name ?? ""}
                                                thumbnailUrl={livestream.thumbnailUrl}
                                                isMobile={props.isMobile}
                                                channelId={livestream.channelId} channelIcon={IconLiveStream({ fill: "#fff" })}
                                            />
                                            : <div style={{ width: props.isMobile ? 135 : 480, height: props.isMobile ? 120 : 270 }}></div>

                                        }
                                    </VizSensor>
                                    )}
                                </TileRow>
                            }
                        </>
                    )
                    case "SPEAKERS": return (
                        /* Speakers */
                        <>
                            {
                                persons?.length! > 0 &&
                                <TileRow
                                    key={key}
                                    icon={IconSpeakers({ fill: branding.sideIconBar.sideIconColorDark ?? branding.mainInfoColor })}
                                    iconVisible={true}
                                    title={strings.receptionPage.speakers}
                                    titleVisible={true}
                                    navLink={speakersPageRoute}
                                    navLinkTextVisible={true}
                                    hideShadows={props.isMobile}
                                    navLinkText={strings.receptionPage.speakersNavLinkText}
                                    scrollBar={persons && persons.length > 0}
                                    childWidth={280 + 9}
                                    childCount={persons?.length ?? 0}>
                                    {persons &&
                                        <>
                                            {props.isMobile ?
                                                <MobileTileWrapperSpeakers $count={persons.length}>
                                                    {persons.map((person, index) =>
                                                        <VizSensor key={person.id} partialVisibility={true}>
                                                            {({ isVisible }) => isVisible ?
                                                                <div style={{ width: 95, height: 95 }}>
                                                                    <PersonComponent {...person}
                                                                        setShowRecommendPersonModal={props.onSetShowOrganizationModal}
                                                                        setTargetId={props.onSetTargetId}
                                                                        setLink={props.onSetLink}
                                                                        setType={props.onSetType}
                                                                        componentType={"person"}
                                                                        modalOpen={props.showBookmarkEventsModal}
                                                                        customOnBookmarkedFunction={() => {
                                                                            props.onSetTargetId(person.id)
                                                                            props.onSetShowBookmarkEventsModal(true)
                                                                        }}
                                                                    ></PersonComponent>
                                                                </div>
                                                                : <div style={{ width: 95, height: 95 }}></div>
                                                            }
                                                        </VizSensor>
                                                    )}
                                                </MobileTileWrapperSpeakers> :
                                                <TileWrapperSpeakers $count={persons.length}>
                                                    {persons.map((person, index) =>
                                                        <VizSensor key={person.id} partialVisibility={true}>
                                                            {({ isVisible }) => isVisible ?
                                                                <div style={{ width: 280, height: 280 }}>
                                                                    <PersonComponent {...person}
                                                                        setShowRecommendPersonModal={props.onSetShowOrganizationModal}
                                                                        setTargetId={props.onSetTargetId}
                                                                        setLink={props.onSetLink}
                                                                        setType={props.onSetType}
                                                                        componentType={"person"}
                                                                        modalOpen={props.showBookmarkEventsModal}
                                                                        customOnBookmarkedFunction={() => {
                                                                            props.onSetTargetId(person.id)
                                                                            props.onSetShowBookmarkEventsModal(true)
                                                                        }}
                                                                    ></PersonComponent>
                                                                </div>
                                                                : <div style={{ width: 280, height: 280 }}></div>
                                                            }
                                                        </VizSensor>
                                                    )}
                                                </TileWrapperSpeakers>
                                            }
                                        </>
                                    }
                                </TileRow>
                            }
                        </>
                    )
                    case "PARTICIPANTS": return (
                        /* Networking relevant contacts */
                        <>
                            {
                                relevantUsers?.length! > 0 &&
                                <TileRow
                                    key={key}
                                    icon={IconNetworking({ fill: branding.sideIconBar.sideIconColorDark ?? branding.mainInfoColor })}
                                    iconVisible={true}
                                    title={strings.navigationArea.participantsItemTitle}
                                    titleVisible={true}
                                    navLink={networkingPageRoute}
                                    navLinkTextVisible={true}
                                    hideShadows={props.isMobile}
                                    navLinkText={strings.receptionPage.participantsNavLinkText}
                                    scrollBar={relevantUsers && relevantUsers.length > 0}
                                    childWidth={280 + 9}
                                    childCount={relevantUsers?.length ?? 0}
                                >
                                    {relevantUsers &&
                                        <>
                                            {
                                                props.isMobile ?
                                                    <MobileTileWrapperSpeakers $count={relevantUsers.length}>
                                                        {relevantUsers.map((person, index) => <VizSensor key={person.sotUser.id} partialVisibility={true} >
                                                            {({ isVisible }) => isVisible ?
                                                                <div style={{ width: 95, height: 95 }}>
                                                                    <PersonComponent {...person.sotUser}
                                                                        setShowRecommendPersonModal={props.onSetShowOrganizationModal}
                                                                        setTargetId={props.onSetTargetId}
                                                                        setLink={props.onSetLink}
                                                                        setType={props.onSetType}
                                                                        componentType={"user"}
                                                                    ></PersonComponent>
                                                                </div>
                                                                : <div style={{ width: 95, height: 95 }}></div>
                                                            }
                                                        </VizSensor>
                                                        )}
                                                    </MobileTileWrapperSpeakers> :
                                                    <TileWrapperSpeakers $count={relevantUsers.length}>
                                                        {relevantUsers.map((person, index) => <VizSensor key={person.sotUser.id} partialVisibility={true} >
                                                            {({ isVisible }) => isVisible ?
                                                                <div style={{ width: 280, height: 280 }}>
                                                                    <PersonComponent {...person.sotUser}
                                                                        setShowRecommendPersonModal={props.onSetShowOrganizationModal}
                                                                        setTargetId={props.onSetTargetId}
                                                                        setLink={props.onSetLink}
                                                                        setType={props.onSetType}
                                                                        componentType={"user"}
                                                                    ></PersonComponent>
                                                                </div>
                                                                : <div style={{ width: 280, height: 280 }}></div>
                                                            }
                                                        </VizSensor>
                                                        )}
                                                    </TileWrapperSpeakers>
                                            }
                                        </>
                                    }
                                </TileRow>
                            }
                        </>
                    )
                    case "BANNER": //this is for compatibility purposes, in branding you should use NETWORKING_BANNER or LEADFINDER_BANNER instead
                    case "NETWORKING_BANNER": return (
                        <>
                            {
                                /* Networking banner */
                                !props.isMobile &&
                                <TileRow
                                    key={key}
                                    icon={IconNetworking({ fill: branding.sideIconBar.sideIconColorDark ?? branding.mainInfoColor })}
                                    iconVisible={strings.receptionPage.tileRowIconVisible}
                                    title={strings.receptionPage.networkingBannerTitle}
                                    titleVisible={strings.receptionPage.tileRowTitleVisible}
                                    navLink={networkingPageRoute}
                                    navLinkTextVisible={strings.receptionPage.tileRowNavLinkVisible}
                                    hideShadows={props.isMobile}
                                    navLinkText={strings.receptionPage.networkingNavLinkText}
                                    childCount={1}
                                    childWidth={0}
                                    scrollBar={false}>
                                    <DynamicBanner
                                        navLink={networkingPageRoute}
                                        buttonText={strings.receptionPage.networkingButton}
                                        text1={strings.receptionPage.networkingText1}
                                        text2={strings.receptionPage.networkingText2} />
                                </TileRow>
                            }
                        </>
                    )

                    case "LEADFINDER_BANNER": return (
                        <>
                            {
                                /* Lead Finder banner */
                                !props.isMobile &&
                                <TileRow
                                    key={key}
                                    icon={IconBusinessArea({ fill: branding.sideIconBar.sideIconColorDark ?? branding.mainInfoColor })}
                                    iconVisible={true}
                                    title={strings.receptionPage.leadFinderBannerTitle}
                                    titleVisible={true}
                                    navLink={businessAreaPageRoute}
                                    navLinkTextVisible={true}
                                    hideShadows={props.isMobile}
                                    navLinkText={strings.receptionPage.networkingNavLinkText}
                                    childCount={1}
                                    childWidth={0}
                                    scrollBar={false}>
                                    <DynamicBanner
                                        navLink={businessAreaPageRoute}
                                        buttonText={strings.receptionPage.leadFinderButton}
                                        text1={strings.receptionPage.leadFinderText1}
                                        text2={strings.receptionPage.leadFinderText2} />
                                </TileRow>

                            }
                        </>
                    )
                    default: return null
                }
            })}


        </Fragment >
    )
}

export default LobbyPageContent