import React, { useState, ChangeEvent } from "react"
import { ConversationParticipant, ChatConversationParam } from "./ChatPage"
import { ConversationCreationHeader } from "./ChatPageHeader"
import styled, { css } from "styled-components"
import { IconChevronRight, IconStartChat } from "../ui/Icons"
import SearchPersonInput from "./SearchPersonInput"
import { useLoggedInState } from "../globalStates/LoggedInUser"
import { Contact } from "../backendServices/Types"
import { useAppState } from "../globalStates/AppState"
import { StagedOpponentsArea } from "./ConversationDetails"
import { useLanguageState } from "../globalStates/LanguageState"
import { createGroupChatConversation } from "../backendServices/BackendServices"
import { ConversationType } from "../API"
import branding from "../branding/branding"
import { Divider } from "./ContactEntry"
import TextLimit from "../ui/TextLimit"

const ConversationCreationContainer = styled.div`
    display: flex;
    flex-direction: column;
    color: ${branding.mainInfoColor ?? "black"};
    font-family: ${branding.font1};
`
const StyledSearchPersonInput = styled(SearchPersonInput)`
    margin: 16px;
    padding-right: 4px;
    border-bottom: 1px solid;
    border-color: ${branding.mainInfoColor};
    line-height: 40px;
    font-family: ${branding.font1};
`
const DetailsSectionTitle = styled.div`
    margin-top: 16px;
    margin-left: 16px;
    font-size: 12px;
    &.error {
        color: ${branding.dangerButtonColor};
        font-weight: bold;
    }
`
const ConversationNameInput = styled.input`
    border: 0px;
    height: 50px;
    margin-left: 16px;
    border-bottom: 1px solid #000;
    
    &:focus {
        outline: none;
    }
`

const ConversationDetailInput = styled.textarea`
    border: 0px;
    height: 50px;
    margin-top: 10px;
    
    margin-left: 16px;
    word-break: break-word;
    white-space: pre-line;
    border-bottom: 1px solid #000;
    
    &:focus {
        outline: none;
    }
`

const RoundedButton = styled.button`
    flex: 0 0 auto;
    margin: 8px 16px 24px 16px;
    padding: 7px;
    border-radius: 20px;
    color: ${branding.chatBranding.roundedButtonStartChatTextColor ?? "#fff"};
    background-color: ${branding.chatBranding.roundedButtonStartChatBgColor ?? "hsl(0,0%,0%)"}; 
    transition: 0.5s;
    cursor: pointer;

    &:focus { outline: none; }

    ${props => props.disabled
        ? css`
            background-color: ${branding.chatBranding.roundedButtonStartChatBgDisabledColor ?? "hsl(0,0%,80%)"};
            transition-property: none;
        `
        : css`
            &:hover { 
                background-color: ${branding.chatBranding.roundedButtonStartChatBgOnHoverColor ?? "hsl(0,0%,30%)"};
            }
        `
    };
`

interface ConversationCreationPageProps {
    close: () => void
}

const ConversationCreationPage: React.FC<ConversationCreationPageProps> = (props) => {

    const [isGroupChat, setIsGroupChat] = useState(false)

    if (isGroupChat) {
        return <GroupConversationCreationPage close={props.close} maxParticipants={branding.configuration.chatParticipantLimit ?? 10} />
    } else {
        return <PrivateConversationCreationPage close={props.close} setIsGroupChat={setIsGroupChat} />
    }

}

interface PrivateConversationCreationPageProps {
    setIsGroupChat: (isGroupChat: boolean) => void
    close: () => void
}

const PrivateConversationCreationPage: React.FC<PrivateConversationCreationPageProps> = (props) => {
    const appState = useAppState()
    const loggedInState = useLoggedInState()
    const strings = useLanguageState().getStrings()

    const onPersonSelected = (person: Contact) => {
        appState.setShowChatsTab(ChatConversationParam.privateConversationByOpponentId(person.id))
    }
    return <ConversationCreationContainer>
        <ConversationCreationHeader title={strings.chatBranding.newChatText} close={props.close} />
        <StartNewChatButton title={strings.chatBranding.newGroupText} onClick={() => props.setIsGroupChat(true)} />
        <StyledSearchPersonInput placeholder={strings.chatBranding.searchForPeopleText} personClicked={onPersonSelected} excludeIds={[loggedInState.user()!.profileId]} />
    </ConversationCreationContainer>
}

interface GroupConversationCreationPageProps {
    close: () => void
    opponents?: ConversationParticipant[]
    maxParticipants: number
}

const GroupConversationCreationPage: React.FC<GroupConversationCreationPageProps> = (props) => {
    const appState = useAppState()
    const profileId = useLoggedInState().user()!.profileId
    const [conversationName, setConversationName] = useState<string | undefined>(undefined)
    const [conversationDesc, setConversationDesc] = useState<string | undefined>(undefined)
    const [startChatPending, setStartChatPending] = useState<boolean>(false)
    const [stagedOpponents, setStagedOpponents] = useState<ConversationParticipant[]>(props.opponents ?? [])
    const strings = useLanguageState().getStrings()

    const maxTextLength: number = 150

    const onPersonSelected = (person: Contact) => {
        const newOpponent = { id: person.id, name: [person.firstName, person.lastName].join(" "), pictureUrl: person.logoUrl, position: person.position, organization: person.organization }
        setStagedOpponents(stagedOpponents.concat([newOpponent]))
    }

    const onStartChatClicked = async () => {
        setStartChatPending(true)

        const result = await createGroupChatConversation(profileId, stagedOpponents.map(op => op.id), conversationName, conversationDesc)
        if (result) {
            appState.setShowChatsTab(ChatConversationParam.conversationByConversationId(ConversationType.GROUP, result.createdConversationId))
        } else {
            // TODO error handling
        }
    }

    const participantsSectionTitle = strings.chatBranding.participantsText + " (" + (stagedOpponents.length + 1) + " / " + (props.maxParticipants) + ")"
    const participantCountErrorClass = stagedOpponents.length >= props.maxParticipants ? "error" : ""
    const startChatButtonDisabled = stagedOpponents.length <= 0 || stagedOpponents.length >= props.maxParticipants || startChatPending

    return <ConversationCreationContainer>
        <ConversationCreationHeader title={strings.chatBranding.newGroupChatText} close={props.close} />
        <DetailsSectionTitle>{strings.chatBranding.detailsText}</DetailsSectionTitle>
        <ConversationNameInput maxLength={50} placeholder={strings.chatBranding.setGroupNameText} onChange={(e: ChangeEvent<HTMLInputElement>) => setConversationName(e.target.value)} />

        <ConversationDetailInput maxLength={maxTextLength} rows={5} placeholder={strings.chatBranding.addGroupDescriptionText} onChange={(e: ChangeEvent<HTMLTextAreaElement>) => { setConversationDesc(e.target.value) }} />
        <TextLimit textLength={conversationDesc?.length || 0} maxTextLength={maxTextLength} />
        <DetailsSectionTitle style={{ marginTop: "32px" }} className={participantCountErrorClass}>{participantsSectionTitle}</DetailsSectionTitle>
        <StagedOpponentsArea includeSelf={true} opponents={stagedOpponents} unstageOpponent={(unstageMe) => setStagedOpponents(stagedOpponents.filter((op) => op.id !== unstageMe.id))} />

        <Divider />
        <StyledSearchPersonInput placeholder={strings.chatBranding.searchForPeopleText} personClicked={onPersonSelected} disableIds={stagedOpponents.map((p) => p.id)} disabledText={strings.chatBranding.disableAddedText} />
        {/* <ParticipantsListContainer>
            <ParticipantList participants={stagedOpponents.concat([me])} />
        </ParticipantsListContainer> */}
        <RoundedButton onClick={onStartChatClicked} disabled={startChatButtonDisabled}>{strings.chatBranding.startChatText}</RoundedButton>
    </ConversationCreationContainer>
}

const StartNewChatButtonContent = styled.div`
    display: flex;
    height: 62px;
    flex: 0 0 auto;
    align-items: center;
    padding: 0 20px;
    cursor: pointer;
    background-color: #fff;
    font-family: ${branding.font1};
  
    &:hover {
      background-color: rgba(240, 240, 240, 1);
    }
    text-decoration: none !important;
    border-bottom: ${branding.mainBorder ? branding.mainBorder : '1px solid #d9d9d9'};
    color: ${branding.mainInfoColor ?? "black"};

    svg{
        color: ${branding.primaryColor ?? "black"};
    }
`

const StartNewChatIcon = styled.span`
    flex: 0 0 auto;
`

const StartNewChatTitle = styled.span`
    flex: 1 0 auto;
    padding-left: 10px;
`

const Chevron = styled.span`
    flex: 0 0 auto;
`

export interface StartNewChatButtonProps {
    title: string
    onClick: () => void
}

export const StartNewChatButton: React.FC<StartNewChatButtonProps> = (props) => {
    return (
        <StartNewChatButtonContent title={props.title} onClick={props.onClick}>
            <StartNewChatIcon>{IconStartChat({fill: branding.sideIconBar.sideIconColorDark})}</StartNewChatIcon>
            <StartNewChatTitle>{props.title}</StartNewChatTitle>
            <Chevron>{IconChevronRight({fill: branding.sideIconBar.sideIconColorDark, width: "20px", height: "20px"})}</Chevron>
        </StartNewChatButtonContent>
    )
}

export default ConversationCreationPage
